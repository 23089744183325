.avatar {
    width:inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    border-radius: 50%;
    // font-size: 25px;
    background: #E6E6E6;
    color: #404040;
    img {
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }
}
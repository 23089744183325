
/*------------------Component Tooltip---------------*/
.crm_tooltip {
    position: fixed; // transition: all 200ms;
    cursor: pointer;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    visibility: hidden;
    &.show {
        zoom: 1;
        filter: alpha(opacity=50);
        opacity: 1;
        visibility: visible;
    }
    .tooltip-content {
        position: absolute;
        background-color: #222222d1;
        text-align: center;
        color: #fff;
        transform: translateX(-50%);
        white-space: nowrap;
        border-radius: 4px;
        padding: 6px 10px;
        font-size: 13px;
        font-weight: normal;
        &::before {
            content: "";
            position: absolute;
        }
        &.top {
            bottom: 100%;
            left: 50%;
            margin-bottom: 10px;
            &::before {
                top: 100%;
                left: 50%;
                margin-left: -4px;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid #222222d1;
            }
        }
    }
}

/*------------------Component Tooltip---------------*/
.theme{
    &-red{
        span{
            background:$theme-red !important;
        }
    }
    &-default{
        span{
            background:$theme-default !important;
        }
    }
    &-blue{
        span{
            background:$theme-blue !important;
        }
    }
    &-purple{
        span{
            background:$theme-purple !important;
        }
    }
    &-oranger{
        span{
            background:$theme-oranger !important;
        }
    }
    &-dark{
        span{
            background:$theme-dark !important;
        }
    }
}
.theme_default .sc-bxivhb{
    background: $theme-default !important;
}
/*-- Themes Red ---*/
.theme_red .navigation .navigation_setting .navigation_setting_sub li,.theme_red .navigation .navigation_menu ul li.menu_item a,.theme_red .navigation .navigation_setting .navigation_setting_sub a,.theme_red .navigation .navigation_change_color,.theme_red .navigation .navigation_notification,
.theme_red .depots{
    border-color: #e44148;
}
.theme_red .ReactVirtualized__Grid__innerScrollContainer .active {
    background: rgba(255, 107, 113, .2) !important;
}
.theme_red .bpos_box_sell_right_box .form-group-sale-box-btn a.active,.theme_red .bpos_box_sell_right_box .form-group-sale-box-btn a:hover,.theme_red .bpos_box_sell_left_toggle,.theme_red .ReactVirtualized__Grid__innerScrollContainer .col-md-2 .input-content:after,.theme_red .product_upload_image_child0_avatar,.theme_red .bpos_box_sell_right_box_payment .form-group .input-content:after,
.theme_red .bpos_box_sell_left_action_list li:hover,.theme_red .bpos_box_sell_right_list_table tbody :nth-child(4) .form-group .input-content:after,.theme_red .bpos_list_pr_box_img p,.theme_red .bpos_box_sell_left_action:after,.theme_red .bpos_box_sell_right_box_title h2,
.theme_red .label_checkbox input:checked ~ .checkmark,.theme_red .product_sort .product_sort_child,.theme_red .popconfirm_content_btn button.primary,.theme_red .setting_on_off .setting_on_off_ctrol.active,.theme_red .btn.btn-primary,.theme_red table thead,.theme_red .content_menu_item_child_item.active ,
.theme_red .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item:hover,.theme_red .panel_modal_close,.theme_red .navigation, .theme_red .navigation .navigation_menu ul .sub_menu,.theme_red .navigation .navigation_menu ul li.menu_item ul.sub_menu li a:hover,
.theme_red .navigation .navigation_setting .navigation_setting_sub,
.theme_red .order_save_check,
.theme_red .return_title,
.theme_red .open_panel_fixed,
.theme_red .add_customer_sell,
.theme_red .sc-bxivhb,
.theme_red .bnc_dashboard_aff .bpos_dashboard_current_balance{
    background: $theme-red !important;
}
.theme_red .order_transport_title{
    border-color: $theme-red !important;
}
.theme_red .bpos_box_sell_right_box_search input:hover,.theme_red .bpos_box_sell_right_box_price_table,.theme_red .bpos_list_pr_box_img,.theme_red .bpos_box_sell_left_box,.theme_red .bpos_box_sell_right_box,.theme_red .output_complete_search,.theme_red .slider2 .image-detail-small:hover,.theme_red .slider2 .slick-current .image-detail-small,.theme_red .navigation .navigation_menu ul .sub_menu li a,
.theme_red .product_sort_child.item_width button:not(:last-child){
    border-color: $theme-red !important;
}
.theme_red .react-sweet-progress-symbol-absolute .react-sweet-progress-symbol,.theme_red .product_upload_image_child0_del,.theme_red .la-credit-card,.theme_red .color-green,.theme_red .cell_price_sell_bpos,.theme_red .la-success,.theme_red .navigation_icon_search_sell i,.theme_red .k-content .slick-dots li:hover button:before,.theme_red .k-content .slick-dots li.slick-active button:before,.theme_red .k-content .title,.theme_red .btn.btn-none:hover,.theme_red .btn.btn-none i,.theme_red .panel_modal_list li a:hover,.theme_red .bpos_dashboard_this_month_revenue .css-1492t68,.theme_red .bpos_dashboard_selling_title span,.theme_red .bpos_dashboard_recent_activities_list ul li a{
    color: $theme-red !important;
}
.theme_red .product_sort .product_sort_child .label_checkbox .checkmark,.theme_red .navigation .navigation_setting .navigation_setting_sub li:hover,.theme_red .navigation .navigation_menu ul li.menu_item:hover a:hover,.theme_red .navigation .navigation_menu ul li.menu_item:hover a{
    background: #e44148 !important;
}
.theme_red .btn.btn-success,.theme_red .pagination > li > a:hover,.theme_red .pagination > li > span:hover,.theme_red .pagination > li > a:focus,
.theme_red .pagination > li > span:focus,.theme_red .pagination > .active > a,.theme_red .pagination > .active > span,
.theme_red .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus,
.theme_red .pagination > .active > span:focus{
    background: $theme-red !important;
    border-color: $theme-red !important;    
}
.theme_red .navigation_list_invoice,.theme_red .bpos_sell_network_mode{
    background: #f35d63;
}
.theme_red .navigation_list_invoice.active button,.theme_red .navigation_list_invoice.active .la,
.theme_red .navigation_list_invoice:hover button,.theme_red .navigation_list_invoice:hover .la,.theme_red .navigation_list_invoice.active,.theme_red .navigation_list_invoice:hover{
    color: #f35d63;
}
.theme_red{
    .bpos_price_list_time ul li:first-child a {
        color: $theme-red;
        border-color: $theme-red;
    }
    .bpos_price_list_time ul li.active a, .bpos_price_list_time ul li:hover a {
        background: $theme-red;
    }
    .bpos_price_list_time ul li:last-child a {
        color: $theme-red;
        border-color: $theme-red;
    }
    .bpos_price_list_box_name {
        background: linear-gradient(45deg, $theme-red, #e07981);
    }
    .bpos_price_list_box_price {
        background: #e85866;
    }
    .label_checkbox .checkmark{
        border-color: $theme-red;
    }
    .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input .form-group-sale-box-btn a.active{
        background: $theme-red !important;
    }
}

/*-- Themes Blue ---*/
.theme_blue .navigation .navigation_setting .navigation_setting_sub li,.theme_blue .navigation .navigation_menu ul li.menu_item a,.theme_blue .navigation .navigation_setting .navigation_setting_sub a,.theme_blue .navigation .navigation_change_color,.theme_blue .navigation .navigation_notification,
.theme_blue .depots{
    border-color: #009bd0;
}
.theme_blue .ReactVirtualized__Grid__innerScrollContainer .active {
    background: rgba(0, 173, 232, .2) !important;
}
.theme_blue .bpos_box_sell_right_box .form-group-sale-box-btn a.active,.theme_blue .bpos_box_sell_right_box .form-group-sale-box-btn a:hover,.theme_blue .bpos_box_sell_left_toggle,.theme_blue .ReactVirtualized__Grid__innerScrollContainer .col-md-2 .input-content:after,.theme_blue .product_upload_image_child0_avatar,.theme_blue .bpos_box_sell_right_box_payment .form-group .input-content:after,.theme_blue .bpos_box_sell_left_action_list li:hover,.theme_blue .bpos_box_sell_right_list_table tbody :nth-child(4) .form-group .input-content:after,
.theme_blue .bpos_list_pr_box_img p,.theme_blue .bpos_box_sell_left_action:after,.theme_blue .bpos_box_sell_right_box_title h2,.theme_blue .label_checkbox input:checked ~ .checkmark,.theme_blue .product_sort .product_sort_child,.theme_blue .popconfirm_content_btn button.primary,.theme_blue .setting_on_off .setting_on_off_ctrol.active,
.theme_blue .btn.btn-primary,.theme_blue table thead,.theme_blue .content_menu_item_child_item.active ,.theme_blue .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item:hover,.theme_blue .panel_modal_close,.theme_blue .navigation, .theme_blue .navigation .navigation_menu ul .sub_menu,.theme_blue .navigation .navigation_menu ul li.menu_item ul.sub_menu li a:hover,
.theme_blue .navigation .navigation_setting .navigation_setting_sub,
.theme_blue .order_save_check,
.theme_blue .return_title,
.theme_blue .open_panel_fixed,.theme_blue .add_customer_sell,
.theme_blue .sc-bxivhb,
.theme_blue .bnc_dashboard_aff .bpos_dashboard_current_balance{
    background: $theme-blue !important;
}
.theme_blue  .order_transport_title{
    border-left-color: $theme-blue !important;
}
.theme_blue .bpos_box_sell_right_box_search input:hover,
.theme_blue .bpos_box_sell_right_box_price_table,.theme_blue .bpos_list_pr_box_img,
.theme_blue .bpos_box_sell_left_box,.theme_blue .bpos_box_sell_right_box,
.theme_blue .output_complete_search,.theme_blue .slider2 .image-detail-small:hover,
.theme_blue .slider2 .slick-current .image-detail-small,.theme_blue .navigation .navigation_menu ul .sub_menu li a,
.theme_blue .product_sort_child.item_width button:not(:last-child){
    border-color: $theme-blue !important;
}
.theme_blue .react-sweet-progress-symbol-absolute .react-sweet-progress-symbol,.theme_blue .product_upload_image_child0_del,.theme_blue .la-credit-card,.theme_blue .color-green,.theme_blue .cell_price_sell_bpos,.theme_blue .la-success,.theme_blue .navigation_icon_search_sell i,.theme_blue .k-content .slick-dots li:hover button:before,.theme_blue .k-content .slick-dots li.slick-active button:before,.theme_blue .k-content .title,.theme_blue .btn.btn-none:hover,.theme_blue .btn.btn-none i,.theme_blue .panel_modal_list li a:hover,.theme_blue .bpos_dashboard_this_month_revenue .css-1492t68,.theme_blue .bpos_dashboard_selling_title span,.theme_blue .bpos_dashboard_recent_activities_list ul li a{
    color: $theme-blue !important;
}
.theme_blue .product_sort .product_sort_child .label_checkbox .checkmark,.theme_blue .navigation .navigation_setting .navigation_setting_sub li:hover,.theme_blue .navigation .navigation_menu ul li.menu_item:hover a:hover,.theme_blue .navigation .navigation_menu ul li.menu_item:hover a{
    background: #009bd0 !important;
}
.theme_blue .btn.btn-success,.theme_blue .pagination > li > a:hover,.theme_blue .pagination > li > span:hover,.theme_blue .pagination > li > a:focus,
.theme_blue .pagination > li > span:focus,.theme_blue .pagination > .active > a,.theme_blue .pagination > .active > span,
.theme_blue .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus,
.theme_blue .pagination > .active > span:focus{
    background: $theme-blue !important;
    border-color: $theme-blue !important;
}
.theme_blue .navigation_list_invoice,.theme_blue .bpos_sell_network_mode{
    background: #1abcf3;
}
.theme_blue .navigation_list_invoice.active button,.theme_blue .navigation_list_invoice.active .la,
.theme_blue .navigation_list_invoice:hover button,.theme_blue .navigation_list_invoice:hover .la,.theme_blue .navigation_list_invoice.active,.theme_blue .navigation_list_invoice:hover{
    color: #00ade8;
}
.theme_blue{
    .bpos_price_list_time ul li:first-child a {
        color: $theme-blue;
        border-color: $theme-blue;
    }
    .bpos_price_list_time ul li.active a, .bpos_price_list_time ul li:hover a {
        background: $theme-blue;
    }
    .bpos_price_list_time ul li:last-child a {
        color: $theme-blue;
        border-color: $theme-blue;
    }
    .bpos_price_list_box_name {
        background: linear-gradient(45deg, $theme-blue, #0089b7);
    }
    .bpos_price_list_box_price {
        background: #0087b7;
    }
    .label_checkbox .checkmark{
        border-color: $theme-blue;
    }
    .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input .form-group-sale-box-btn a.active{
        background: $theme-blue !important;
    }
}
/*-- Themes Purple ---*/
.theme_purple .navigation .navigation_setting .navigation_setting_sub li,.theme_purple .navigation .navigation_menu ul li.menu_item a,.theme_purple .navigation .navigation_setting .navigation_setting_sub a,.theme_purple .navigation .navigation_change_color,.theme_purple .navigation .navigation_notification,
.theme_purple .depots{
    border-color: #6875dc;
}
.theme_purple .ReactVirtualized__Grid__innerScrollContainer .active {
    background: rgba(110, 122, 216, .2) !important;
}
.theme_purple .bpos_box_sell_right_box .form-group-sale-box-btn a.active,.theme_purple .bpos_box_sell_right_box .form-group-sale-box-btn a:hover,.theme_purple .bpos_box_sell_left_toggle,.theme_purple .ReactVirtualized__Grid__innerScrollContainer .col-md-2 .input-content:after,.theme_purple .product_upload_image_child0_avatar,.theme_purple .bpos_box_sell_right_box_payment .form-group .input-content:after,.theme_purple .bpos_box_sell_left_action_list li:hover,.theme_purple .bpos_box_sell_right_list_table tbody :nth-child(4) .form-group .input-content:after,
.theme_purple .bpos_list_pr_box_img p,.theme_purple .bpos_box_sell_left_action:after,.theme_purple .bpos_box_sell_right_box_title h2,.theme_purple .label_checkbox input:checked ~ .checkmark,.theme_purple .product_sort .product_sort_child,
.theme_purple .popconfirm_content_btn button.primary,.theme_purple .setting_on_off .setting_on_off_ctrol.active,.theme_purple .btn.btn-primary,.theme_purple table thead,.theme_purple .content_menu_item_child_item.active ,
.theme_purple .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item:hover,.theme_purple .panel_modal_close,.theme_purple .navigation, .theme_purple .navigation .navigation_menu ul .sub_menu,.theme_purple .navigation .navigation_menu ul li.menu_item ul.sub_menu li a:hover,
.theme_purple .navigation .navigation_setting .navigation_setting_sub,
.theme_purple .order_save_check,
.theme_purple .return_title,
.theme_purple .open_panel_fixed,
.theme_purple .add_customer_sell,
.theme_purple .sc-bxivhb,
.theme_purple .bnc_dashboard_aff .bpos_dashboard_current_balance{
    background: $theme-purple !important;
}
.theme_purple  .order_transport_title{
    border-left-color: $theme-purple !important;
}
.theme_purple .bpos_box_sell_right_box_search input:hover,.theme_purple .bpos_box_sell_right_box_price_table,.theme_purple .bpos_list_pr_box_img,.theme_purple .bpos_box_sell_left_box,.theme_purple .bpos_box_sell_right_box,.theme_purple .output_complete_search,.theme_purple .slider2 .image-detail-small:hover,.theme_purple .slider2 .slick-current .image-detail-small,.theme_purple .navigation .navigation_menu ul .sub_menu li a,
.theme_purple .product_sort_child.item_width button:not(:last-child){
    border-color: $theme-purple !important;
}
.theme_purple .react-sweet-progress-symbol-absolute .react-sweet-progress-symbol,.theme_purple .product_upload_image_child0_del,.theme_purple .la-credit-card,.theme_purple .color-green,.theme_purple .cell_price_sell_bpos,.theme_purple .la-success,.theme_purple .navigation_icon_search_sell i,.theme_purple .k-content .slick-dots li:hover button:before,.theme_purple .k-content .slick-dots li.slick-active button:before,.theme_purple .k-content .title,.theme_purple .btn.btn-none:hover,.theme_purple .btn.btn-none i,.theme_purple .panel_modal_list li a:hover,.theme_purple .bpos_dashboard_this_month_revenue .css-1492t68,.theme_purple .bpos_dashboard_selling_title span,.theme_purple .bpos_dashboard_recent_activities_list ul li a{
    color: $theme-purple !important;
}
.theme_purple .product_sort .product_sort_child .label_checkbox .checkmark,.theme_purple .navigation .navigation_setting .navigation_setting_sub li:hover,.theme_purple .navigation .navigation_menu ul li.menu_item:hover a:hover,.theme_purple .navigation .navigation_menu ul li.menu_item:hover a{
    background: #6875dc !important;
}
.theme_purple .btn.btn-success,.theme_purple .pagination > li > a:hover,.theme_purple .pagination > li > span:hover,.theme_purple .pagination > li > a:focus,
.theme_purple .pagination > li > span:focus,.theme_purple .pagination > .active > a,.theme_purple .pagination > .active > span,
.theme_purple .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus,
.theme_purple .pagination > .active > span:focus{
    background: $theme-purple !important;
    border-color: $theme-purple !important;
}
.theme_purple .navigation_list_invoice,.theme_purple .bpos_sell_network_mode{
    background: #5d69c7;
}
.theme_purple .navigation_list_invoice.active button,.theme_purple .navigation_list_invoice.active .la,
.theme_purple .navigation_list_invoice:hover button,.theme_purple .navigation_list_invoice:hover .la,.theme_purple .navigation_list_invoice.active,.theme_purple .navigation_list_invoice:hover{
    color: #5d69c7;
}
.theme_purple{
    .bpos_price_list_time ul li:first-child a {
        color: $theme-purple;
        border-color: $theme-purple;
    }
    .bpos_price_list_time ul li.active a, .bpos_price_list_time ul li:hover a {
        background: $theme-purple;
    }
    .bpos_price_list_time ul li:last-child a {
        color: $theme-purple;
        border-color: $theme-purple;
    }
    .bpos_price_list_box_name {
        background: linear-gradient(45deg, $theme-purple, #8e97ca);
    }
    .bpos_price_list_box_price {
        background: #8690d2;
    }
    .label_checkbox .checkmark{
        border-color: $theme-purple;
    }
    .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input .form-group-sale-box-btn a.active{
        background: $theme-purple !important;
    }
}
/*-- Themes Oranger ---*/
.theme_oranger .navigation .navigation_setting .navigation_setting_sub li,.theme_oranger .navigation .navigation_menu ul li.menu_item a,.theme_oranger .navigation .navigation_setting .navigation_setting_sub a,.theme_oranger .navigation .navigation_change_color,.theme_oranger .navigation .navigation_notification,
.theme_oranger .depots{
    border-color: #e06f03;
}
.theme_oranger .bpos_box_sell_right_box .form-group-sale-box-btn a.active,.theme_oranger .bpos_box_sell_right_box .form-group-sale-box-btn a:hover,.theme_oranger .bpos_box_sell_left_toggle,.theme_oranger .ReactVirtualized__Grid__innerScrollContainer .col-md-2 .input-content:after,.theme_oranger .product_upload_image_child0_avatar,.theme_oranger .bpos_box_sell_right_box_payment .form-group .input-content:after,.theme_oranger .bpos_box_sell_left_action_list li:hover,.theme_oranger .bpos_box_sell_right_list_table tbody :nth-child(4) .form-group .input-content:after,.theme_oranger .bpos_list_pr_box_img p,.theme_oranger .bpos_box_sell_left_action:after,.theme_oranger .bpos_box_sell_right_box_title h2,.theme_oranger .label_checkbox input:checked ~ .checkmark,
.theme_oranger .product_sort .product_sort_child,.theme_oranger .popconfirm_content_btn button.primary,
.theme_oranger .setting_on_off .setting_on_off_ctrol.active,.theme_oranger .btn.btn-primary,.theme_oranger table thead,.theme_oranger .content_menu_item_child_item.active ,.theme_oranger .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item:hover,.theme_oranger .panel_modal_close,.theme_oranger .navigation, 
.theme_oranger .navigation .navigation_menu ul .sub_menu,.theme_oranger .navigation .navigation_menu ul li.menu_item ul.sub_menu li a:hover,
.theme_oranger .navigation .navigation_setting .navigation_setting_sub,
.theme_oranger .order_save_check,
.theme_oranger .return_title,
.theme_oranger .open_panel_fixed,
.theme_oranger .add_customer_sell,
.theme_oranger .sc-bxivhb,
.theme_oranger .bnc_dashboard_aff .bpos_dashboard_current_balance{
    background: $theme-oranger !important;
}
.theme_oranger  .order_transport_title{
    border-left-color: $theme-oranger !important;
}
.theme_oranger .ReactVirtualized__Grid__innerScrollContainer .active {
    background: rgba(245, 120, 0, .2) !important;
}
.theme_oranger .bpos_box_sell_right_box_search input:hover,.theme_oranger .bpos_box_sell_right_box_price_table,.theme_oranger .bpos_list_pr_box_img,.theme_oranger .bpos_box_sell_left_box,.theme_oranger .bpos_box_sell_right_box,.theme_oranger .output_complete_search,.theme_oranger .slider2 .image-detail-small:hover,.theme_oranger .slider2 .slick-current .image-detail-small,.theme_oranger .navigation .navigation_menu ul .sub_menu li a,
.theme_oranger .product_sort_child.item_width button:not(:last-child){
    border-color: $theme-oranger !important;
}
.theme_oranger .react-sweet-progress-symbol-absolute .react-sweet-progress-symbol,.theme_oranger .product_upload_image_child0_del,.theme_oranger .la-credit-card,.theme_oranger .color-green,.theme_oranger .cell_price_sell_bpos,.theme_oranger .la-success,.theme_oranger .navigation_icon_search_sell i,.theme_oranger .k-content .slick-dots li:hover button:before,.theme_oranger .k-content .slick-dots li.slick-active button:before,.theme_oranger .k-content .title,.theme_oranger .btn.btn-none:hover,.theme_oranger .btn.btn-none i,.theme_oranger .panel_modal_list li a:hover,.theme_oranger .bpos_dashboard_this_month_revenue .css-1492t68,.theme_oranger .bpos_dashboard_selling_title span,.theme_oranger .bpos_dashboard_recent_activities_list ul li a{
    color: $theme-oranger !important;
}
.theme_oranger .product_sort .product_sort_child .label_checkbox .checkmark,.theme_oranger .navigation .navigation_setting .navigation_setting_sub li:hover,.theme_oranger .navigation .navigation_menu ul li.menu_item:hover a:hover,.theme_oranger .navigation .navigation_menu ul li.menu_item:hover a{
    background: #e06f03 !important;
}
.theme_oranger .btn.btn-success,.theme_oranger .pagination > li > a:hover,.theme_oranger .pagination > li > span:hover,.theme_oranger .pagination > li > a:focus,
.theme_oranger .pagination > li > span:focus,.theme_oranger .pagination > .active > a,.theme_oranger .pagination > .active > span,
.theme_oranger .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus,
.theme_oranger .pagination > .active > span:focus{
    background: $theme-oranger !important;
    border-color: $theme-oranger !important;
}
.theme_oranger .navigation_list_invoice,.theme_oranger .bpos_sell_network_mode{
    background:#bf5e00;
}
.theme_oranger .navigation_list_invoice.active button,.theme_oranger .navigation_list_invoice.active .la,
.theme_oranger .navigation_list_invoice:hover button,.theme_oranger .navigation_list_invoice:hover .la,.theme_oranger .navigation_list_invoice.active,.theme_oranger .navigation_list_invoice:hover{
    color: #bf5e00;
}
.theme_oranger{
    .bpos_price_list_time ul li:first-child a {
        color: $theme-oranger;
        border-color: $theme-oranger;
    }
    .bpos_price_list_time ul li.active a, .bpos_price_list_time ul li:hover a {
        background: $theme-oranger;
    }
    .bpos_price_list_time ul li:last-child a {
        color: $theme-oranger;
        border-color: $theme-oranger;
    }
    .bpos_price_list_box_name {
        background: linear-gradient(45deg, $theme-oranger, #fda650);
    }
    .bpos_price_list_box_price {
        background: #f67c00;
    }
    .label_checkbox .checkmark{
        border-color: $theme-oranger;
    }
    .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input .form-group-sale-box-btn a.active{
        background: $theme-oranger !important;
    }
}
/*-- Themes Dark ---*/
.theme_dark .navigation .navigation_setting .navigation_setting_sub li,.theme_dark .navigation .navigation_menu ul li.menu_item a,.theme_dark .navigation .navigation_setting .navigation_setting_sub a,.theme_dark .navigation .navigation_change_color,.theme_dark .navigation .navigation_notification,
.theme_dark .depots{
    border-color: #3f4a5a;
}
.theme_dark .ReactVirtualized__Grid__innerScrollContainer .active {
    background: rgba(74, 86, 104, .2) !important;
}
.theme_dark .bpos_box_sell_right_box .form-group-sale-box-btn a.active,.theme_dark .bpos_box_sell_right_box .form-group-sale-box-btn a:hover,.theme_dark .bpos_box_sell_left_toggle,.theme_dark .ReactVirtualized__Grid__innerScrollContainer .col-md-2 .input-content:after,.theme_dark .navigation .navigation_menu ul li.menu_item ul.sub_menu li a:hover,.theme_dark .product_upload_image_child0_avatar,.theme_dark .bpos_box_sell_right_box_payment .form-group .input-content:after,.theme_dark .bpos_box_sell_left_action_list li:hover,
.theme_dark .bpos_box_sell_right_list_table tbody :nth-child(4) .form-group .input-content:after,.theme_dark .bpos_list_pr_box_img p,.theme_dark .bpos_box_sell_left_action:after,.theme_dark .bpos_box_sell_right_box_title h2,.theme_dark .label_checkbox input:checked ~ .checkmark,
.theme_dark .product_sort .product_sort_child,.theme_dark .popconfirm_content_btn button.primary,.theme_dark .setting_on_off .setting_on_off_ctrol.active,.theme_dark .btn.btn-primary,.theme_dark table thead,.theme_dark .content_menu_item_child_item.active ,
.theme_dark .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item:hover,.theme_dark .panel_modal_close,.theme_dark .navigation, .theme_dark .navigation .navigation_menu ul .sub_menu,
.theme_dark .navigation .navigation_setting .navigation_setting_sub,
.theme_dark .order_save_check,
.theme_dark .return_title,
.theme_dark .open_panel_fixed,
.theme_dark .add_customer_sell,
.theme_dark .sc-bxivhb,
.theme_dark .bnc_dashboard_aff .bpos_dashboard_current_balance{
    background: $theme-dark !important;
}
.theme_dark .order_transport_title{
    border-left-color: $theme-dark !important;
}
.theme_dark .bpos_box_sell_right_box_search input:hover,.theme_dark .bpos_box_sell_right_box_price_table,.theme_dark .bpos_list_pr_box_img,.theme_dark .bpos_box_sell_left_box,.theme_dark .bpos_box_sell_right_box,.theme_dark .output_complete_search,.theme_dark .slider2 .image-detail-small:hover,.theme_dark .slider2 .slick-current .image-detail-small,.theme_dark .navigation .navigation_menu ul .sub_menu li a,
.theme_dark .product_sort_child.item_width button:not(:last-child){
    border-color: $theme-dark !important;
}
.theme_dark .react-sweet-progress-symbol-absolute .react-sweet-progress-symbol,.theme_dark .product_upload_image_child0_del,.theme_dark .la-credit-card,.theme_dark .color-green,.theme_dark .cell_price_sell_bpos,.theme_dark .la-success,.theme_dark .navigation_icon_search_sell i,.theme_dark .k-content .slick-dots li:hover button:before,.theme_dark .k-content .slick-dots li.slick-active button:before,.theme_dark .k-content .title,.theme_dark .btn.btn-none:hover,.theme_dark .btn.btn-none i,.theme_dark .panel_modal_list li a:hover,.theme_dark .bpos_dashboard_this_month_revenue .css-1492t68,.theme_dark .bpos_dashboard_selling_title span,.theme_dark .bpos_dashboard_recent_activities_list ul li a{
    color: $theme-dark !important;
}
.theme_dark .product_sort .product_sort_child .label_checkbox .checkmark,.theme_dark .navigation .navigation_setting .navigation_setting_sub li:hover,.theme_dark .navigation .navigation_menu ul li.menu_item:hover a:hover,.theme_dark .navigation .navigation_menu ul li.menu_item:hover a{
    background: #3f4a5a !important;
}
.theme_dark .btn.btn-success,.theme_dark .pagination > li > a:hover,.theme_dark .pagination > li > span:hover,.theme_dark .pagination > li > a:focus,
.theme_dark .pagination > li > span:focus,.theme_dark .pagination > .active > a,.theme_dark .pagination > .active > span,
.theme_dark .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus,
.theme_dark .pagination > .active > span:focus{
    background: $theme-dark !important;
    border-color: $theme-dark !important;
}
.theme_dark .navigation_list_invoice,.theme_dark .bpos_sell_network_mode{
    background:#313e50;
}
.theme_dark .navigation_list_invoice.active button,.theme_dark .navigation_list_invoice.active .la,
.theme_dark .navigation_list_invoice:hover button,.theme_dark .navigation_list_invoice:hover .la,.theme_dark .navigation_list_invoice.active,.theme_dark .navigation_list_invoice:hover{
    color: #313e50;
}
.theme_dark{
    .bpos_price_list_time ul li:first-child a {
        color: $theme-dark;
        border-color: $theme-dark;
    }
    .bpos_price_list_time ul li.active a, .bpos_price_list_time ul li:hover a {
        background: $theme-dark;
    }
    .bpos_price_list_time ul li:last-child a {
        color: $theme-dark;
        border-color: $theme-dark;
    }
    .bpos_price_list_box_name {
        background: linear-gradient(45deg, $theme-dark, #616d84);
    }
    .bpos_price_list_box_price {
        background: #5a677f;
    }
    .label_checkbox .checkmark{
        border-color: $theme-dark;
    }
    .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input .form-group-sale-box-btn a.active{
        background: $theme-dark !important;
    }
}
/*---- Themes Dark Mode ----*/
.theme_dark_mode .react-datepicker select{
    background: unset;
    border-color: #a6a6a6;
}
.theme_dark_mode .title_menu .title a, .theme_dark_mode .btn.btn-none:hover, .theme_dark_mode a{
    color: #888888;
}
.theme_dark_mode .btn:hover,.theme_dark_mode .la:hover {
    opacity: .8;
}
.theme_dark_mode .personnel_list_btn button.btn.btn-primary:hover{
    opacity:1;
}
.theme_dark_mode .st_body .st_left_menu,
.theme_dark_mode tr.list_product.tab td,
.theme_dark_mode .bnc_dashboard_aff .bpos_dashboard_current_balance,
.theme_dark_mode .bnc_dashboard_aff .bpos_dashboard_selling_today,
.theme_dark_mode .bnc_dashboard_aff .bpos_dashboard_recent_activities,
.theme_dark_mode .bnc_help_aff_list_tab,
.theme_dark_mode .bnc_help_aff_info{
    background:$theme-dark-mode !important;
}
.theme_dark_mode{
    .nav_stocktakes.nav-tabs > li.active > a, 
    .nav_stocktakes.nav-tabs > li.active > a:hover, 
    .nav_stocktakes.nav-tabs > li.active > a:focus, 
    .nav_stocktakes.nav-tabs > li.active > a:focus, 
    .nav_stocktakes.nav-tabs > li > a:hover,
    .nav_stocktakes.nav-tabs > li > a:focus{
        background-color: $theme-dark-mode-bg;
        border: 1px solid $theme-dark-mode;
        border-bottom: 1px solid $theme-dark-mode-bg;
    }
    .nav_stocktakes.nav-tabs{
        border-color: $theme-dark-mode;
    }
    .bnc_dashboard_aff .bpos_dashboard_recent_activities h2,
    & .bpos_dashboard_recent_activities.bpos_dashboard_news_hot .bpos_dashboard_news_hot_box, &
    .bnc_dashboard_aff .bpos_dashboard_recent_activities ul,
    & .bpos_dashboard_statistical_list ul li,
    & .bpos_application_box.bpos_aff_box .bpos_application_field{
        border-color: $theme-dark-mode-bg !important;
    }
    & .bnc_help_aff_list_tab ul li{
        border-bottom-color: $theme-dark-mode-bg !important;
    }
}
.theme_dark_mode,.theme_dark_mode .order_transport_title,.theme_dark_mode .order_transport_modal table thead,
.theme_dark_mode .order_transport_modal table thead tr,
.theme_dark_mode .list_customer .btn.btn-primary{
    background: $theme-dark-mode-bg !important;
}
.theme_dark_mode .order_transport_title{
    border-left-color:#404040;
    color:$whiteColor;
}
.theme_dark_mode .table>thead>tr>th,.theme_dark_mode .table>tbody>tr>th, 
.theme_dark_mode .table>tfoot>tr>th, 
.theme_dark_mode .table>thead>tr>td,.theme_dark_mode .table>tbody>tr>td, 
.theme_dark_mode .table>tfoot>tr>td,
.theme_dark_mode .inventory-list .table > tbody > tr > td,
.theme_dark_mode .product-detail.form-wrapper.content-list td
.theme_dark_mode .navigation .navigation_menu ul .sub_menu li a,
.theme_dark_mode .k-content .form-group{
    border-color:#353738;
}
.theme_dark_mode .navigation_list_invoice,.theme_dark_mode .bpos_sell_network_mode,
.theme_dark_mode .sc-bxivhb{
    background:$theme-dark-mode-bg;
}
.theme_dark_mode .navigation_list_invoice.active .la,
.theme_dark_mode .navigation_list_invoice:hover button,
.theme_dark_mode .navigation_list_invoice:hover .la,
.theme_dark_mode .navigation_list_invoice.active,
.theme_dark_mode .navigation_list_invoice:hover{
    color: $theme-dark-mode-bg;
}
.theme_dark_mode .cke_reset_all table tr td:nth-child(1),
.theme_dark_mode .sk-fading-circle .sk-circle:before, .theme_dark_mode .cke_reset_all table tr {
    background: $whiteColor !important;
}
.theme_dark_mode .setting_general_email_chart_title,.theme_dark_mode .panel_modal_list li a:hover,.theme_dark_mode .autocomplete_content_item,.theme_dark_mode .nav-tabs>li>a,.theme_dark_mode .la,.theme_dark_mode a:hover,
.theme_dark_mode .k-content .title,.theme_dark_mode,
.theme_dark_mode .k-content .slick-dots li button:before,
.theme_dark_mode .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item,
.theme_dark_mode .account_list_brands a,.theme_dark_mode .dragUploadExcelRoot .react-sweet-progress-symbol,
.theme_dark_mode .w-barcode-title h2,.theme_dark_mode .spin .overlay .overlay_icon .txt,
.theme_dark_mode .navigation_icon_search_sell i,
.theme_dark_mode .bpos_help_version a{
    color: $whiteColor !important;
}
.theme_dark_mode .st_body .st_left_menu .content_menu .content_menu_title,
.theme_dark_mode .navigation .navigation_setting .navigation_setting_sub li,
.theme_dark_mode .navigation .navigation_setting .navigation_setting_sub a,
.theme_dark_mode .navigation .navigation_change_color,.theme_dark_mode .navigation .navigation_notification,
.theme_dark_mode .depots,.theme_dark_mode .discount_hr,.theme_dark_mode .discount_hr:nth-child(1),
.theme_dark_mode .discount_add_quanity,.theme_dark_mode .discount_add_quanity_hr{
    border-color: $theme-dark-mode-bg;
}
.theme_dark_mode .content_menu_item_child_item.active,.theme_dark_mode .st_body_content.scroll_bar,.theme_dark_mode .table,.theme_dark_mode .setting_on_off .setting_on_off_ctrol,.theme_dark_mode .setting_on_off .setting_on_off_ctrol.active,
.theme_dark_mode .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item:hover,
.theme_dark_mode .setting_general .btn.btn-primary,.theme_dark_mode .k-content,
.theme_dark_mode .label_checkbox input:checked ~ .checkmark,
.theme_dark_mode .order_save_check,
.theme_dark_mode .return_title{ 
    background: $theme-dark-mode-bg !important;
}
.theme_dark_mode .role_permission table tr td[colspan],.theme_dark_mode .autocomplete .autocomplete_content
,.theme_dark_mode .table-hover>tbody>tr:hover,.theme_dark_mode table tr,.theme_dark_mode table tr:nth-child(2n),.theme_dark_mode .bpos_box_sell_right_box .form-group-sale-box-btn a.active,.theme_dark_mode .bpos_box_sell_right_box .form-group-sale-box-btn a:hover,.theme_dark_mode .bpos_box_sell_left_toggle,.theme_dark_mode .ReactVirtualized__Grid__innerScrollContainer .col-md-2 .input-content:after,.theme_dark_mode .navigation .navigation_menu ul li.menu_item ul.sub_menu li a:hover,.theme_dark_mode .product_upload_image_child0_avatar,.theme_dark_mode .bpos_box_sell_right_box_payment .form-group .input-content:after,.theme_dark_mode .bpos_box_sell_left_action_list li:hover,
.theme_dark_mode .bpos_box_sell_right_list_table tbody :nth-child(4) .form-group .input-content:after,.theme_dark_mode .bpos_list_pr_box_img p,.theme_dark_mode .bpos_box_sell_left_action:after,.theme_dark_mode .bpos_box_sell_right_box_title h2,
.theme_dark_mode .product_sort .product_sort_child,
.theme_dark_mode table thead ,
.theme_dark_mode .panel_modal_close,.theme_dark_mode .navigation, .theme_dark_mode .navigation .navigation_menu ul .sub_menu,
.theme_dark_mode .navigation .navigation_setting .navigation_setting_sub,
.theme_dark_mode tr.customer_view td,
.theme_dark_mode .btn.btn-primary,.theme_dark_mode .setting_general .printed_form_design .btn.btn-primary,
.theme_dark_mode .bpos_box_sell .bpos_list_pr_box_img,
.theme_dark_mode .open_panel_fixed{
    background: $theme-dark-mode !important;
}
.theme_dark_mode .bpos_box_sell_right_box_search input:hover,
.theme_dark_mode .bpos_list_pr_box_img,.theme_dark_mode .bpos_box_sell_left_box,
.theme_dark_mode .bpos_box_sell_right_box,.theme_dark_mode .output_complete_search,
.theme_dark_mode .slider2 .image-detail-small:hover,.theme_dark_mode .slider2 .slick-current .image-detail-small,
.theme_dark_mode .navigation .navigation_menu ul li.menu_item a,
.theme_dark_mode hr,
.theme_dark_mode .bpos_help .col-md-10,
.theme_dark_mode .bpos_help_title,
.theme_dark_mode .bpos_sell_left_box{
    border-color: $theme-dark-mode !important;
}
.theme_dark_mode .react-sweet-progress-symbol-absolute .react-sweet-progress-symbol,
.theme_dark_mode .product_upload_image_child0_del,
.theme_dark_mode .la-success,
.theme_dark_mode .k-content .slick-dots li:hover button:before,
.theme_dark_mode .k-content .slick-dots li.slick-active button:before{
    color: $theme-dark-mode !important;
}
.theme_dark_mode .product_sort .product_sort_child .label_checkbox .checkmark,
.theme_dark_mode .navigation .navigation_setting .navigation_setting_sub li:hover,
.theme_dark_mode .navigation .navigation_menu ul li.menu_item:hover a:hover,
.theme_dark_mode .navigation .navigation_menu ul li.menu_item:hover a,
.theme_dark_mode .customer_tabs_sub,.theme_dark_mode .bpos_box_sell_right_box_content .form-group-btn-sell .btn,
.theme_dark_mode .spin .overlay .overlay_icon,
.theme_dark_mode .btn.btn-primary.btn-properties
{
    background: $theme-dark-mode-bg !important;
}
.theme_dark_mode .product_sort_child.item_width button:not(:last-child),
.theme_dark_mode .sms_before_customer_birthday_item{
    border-color: $theme-dark-mode-bg !important;
}
.theme_dark_mode .btn.btn-success{
    background: $theme-dark-mode-bg !important;
    border-color: $theme-dark-mode-bg !important;
}
.theme_dark_mode .k-content .btn.btn-success{
    background: #202527 !important;
    border-color: #202527 !important;  
}
.theme_dark_mode{
    & .setting_general .setting_general_item .setting_general_content {
        border-color: $theme-dark-mode;
        background: $theme-dark-mode;
    }
    & .setting_general .setting_general_item .setting_general_header {
        background: $theme-dark-mode;
        border-bottom: 1px solid $theme-dark-mode-bg !important;
        border: none;
    }
    .setting_general .setting_general_item .setting_general_content .setting_general_content_item.on_off,
    & .setting_general .setting_general_item .setting_general_content .setting_general_content_item{
        border-color: $theme-dark-mode-bg;
    }   
}
.theme_dark_mode .setting_general .printed_form .printed_form_design .printed_form_view iframe{
    background: $whiteColor;
}
.theme_dark_mode{
    .modal_tags{
        .modal_tags-input .modal_tags-input-input {
            background: $theme-dark-mode;
            & input {
                background: #111111;
            }
        }
        .modal_tags-input {
            border-color: $theme-dark-mode-bg;
        }
        .modal_tags-list {
            border-color: $theme-dark-mode-bg;
            & .modal_tags-list-item {
                & .modal_tags-list-item-select .menu_color{
                    background: $theme-dark-mode;
                    & .menu_color-list:before{
                        border-bottom: 8px solid $theme-dark-mode;
                    }
                }
                border-color: $theme-dark-mode-bg;
                &:hover {
                    background: #111111;
                    & .modal_tags-list-item-act {
                        color:$whiteColor;
                    }
                }
            }
        }
    }
}
.theme_dark_mode{
    .la-credit-card,& .bpos_box_sell_right_box_content  .color-green{
        color: $whiteColor;
    }
    .bpos_box_sell {
        background: $theme-dark-mode-bg !important;
        &_left_box {
            background: $theme-dark-mode-bg;
        }
        &_right_box {
            &_search .add_customer_sell{
                color: $whiteColor;
                background: $theme-dark-mode-bg !important;
            }
            background: $theme-dark-mode;
            & .css-19bqh2r,& .css-1492t68 {
                color: $whiteColor;
            }
            &_payment label {
                color: $whiteColor;
                & .badge {
                    color: $whiteColor;
                }
            }
            &_content .form-group-note-sell .form-group textarea {
                background: $theme-dark-mode-bg;
                border-color: #232323;
            }
        }
        &_right_list_table .table-striped>tbody>tr:nth-of-type(odd) {
            background: #232323;
        }
        .table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
            border-color: $theme-dark-mode-bg;
        }
    }
    .cell_price_sell_bpos,& .react-datepicker-wrapper:before,& .la-actions {
        color: $whiteColor !important;
    }
    .price_sell_bpos,& .number_sell_bpos{
        background: $theme-dark-mode-bg;
        border: none;
        padding: 5px;
    }
    .bnc_product_banner_aff_list_tab .nav-tabs{
        border-color: $theme-dark-mode;
    }
    .css-vj8t7z,& .input-group-addon {
        background: $theme-dark-mode-bg;
        border-color: #404040;
    }
    .css-xp4uvy{
        color:$whiteColor;
    }
}
.theme_dark_mode .product_add_category_btn_add .btn.btn-primary {
    background: $theme-dark-mode-bg !important;
}
.theme_dark_mode{
    & .pagination>li>a,& .pagination>li>span,& .pagination > .active > a{
        background: $theme-dark-mode;
        color: $whiteColor;
        border: 1px solid $theme-dark-mode;
    }
    & .pagination > li > a:hover,& .pagination > li > span:hover,
    & .pagination > li > a:focus,& .pagination > .active > a,
    & .pagination > .active > span,& .pagination > .active > a:hover,
    & .pagination > .active > span:hover,& .pagination > .active > a:focus, & .pagination > .active > span:focus{
        background: $theme-dark-mode-bg !important;
    }
}
.theme_dark_mode{
    .nav-tabs>li.active>a,& .nav-tabs>li.active>a:hover{
        color: $whiteColor !important;
    }
    .nav-tabs>li.active>a,& .nav-tabs>li.active>a:hover,& .nav-tabs>li.active>a:focus,
    & .nav-tabs>li.active>a:focus,& .nav>li>a:hover,& .nav>li>a:focus{
        background-color: $theme-dark-mode-bg;
        border: 1px solid $theme-dark-mode-bg;
    }
    .nav-tabs {
        border-bottom: 1px solid $theme-dark-mode-bg;
    }
    select {
        background: $theme-dark-mode-bg;
        border: 1px solid #404040;
    }
    .form-control,& .css-162g8z5 {
        background: $theme-dark-mode-bg;
        border: 1px solid #404040;
        color: $whiteColor;
    }
    .css-10nd86i{
        color:$theme-dark-mode-bg !important;
    }
    .css-1aya2g8,& .bpos_box_sell_right_box .css-1aya2g8, & .bpos_box_sell_right_box_user time{
        background: $theme-dark-mode-bg;
        border-color: #404040; 
    }
    & .dragUploadExcelRoot .dragUploadExcel .uploadZone,& .dragUploadExcelRoot .dragUploadExcel .info .info_file .info_file_table tr {
        background: $theme-dark-mode-bg !important;
        border-color: $theme-dark-mode;
    }
    & .dragUploadExcelRoot .rowInfoExcel .table-container{
        border: 1px solid $theme-dark-mode-bg !important;
    }
    & .dragUploadExcelRoot .rowInfoExcel .table-container tbody tr {
        background: $theme-dark-mode-bg !important;
        &:nth-child(2n) {
            background: $theme-dark-mode !important;
        }
    }
    .css-va7pk8,& .css-rsyb7x,& .css-10odw4q ,& .css-1alnv5e{
        color: $whiteColor;
    }
    .css-2o5izw,& .css-2o5izw:hover{
        background: $theme-dark-mode-bg;
        border-color: #404040 !important;
    }
    .css-xwjg1b {
        background: $theme-dark-mode;
    }
    .add_customer_address_bpos .product_upload_image_child0 img{
        border-color: #404040 !important;
    }
}
.theme_dark_mode {
    .bpos_dashboard_selling_today {
        background: $theme-dark-mode;
    }
    .bpos_dashboard_selling_title span{
        color: $whiteColor;
    }
    .bpos_dashboard_this_month_revenue .css-1492t68{
        color: $whiteColor;
    }
    .bpos_dashboard_recent_activities_list ul li a{
        color: $whiteColor;
    }
    .bpos_dashboard_recent_activities {
        background: $theme-dark-mode;
    }
    .bpos_dashboard_selling_today_list ul li + li,& .bpos_dashboard_recent_activities_list ul,
    & .bpos_dashboard_recent_activities h2{
        border-color: $theme-dark-mode-bg;
    }
}
.theme_dark_mode{
    .popconfirm .popconfirm_content_root::after {
        border-top: 8px solid $theme-dark-mode;
    }
    .popconfirm .popconfirm_content_root .popconfirm_content {
        background: $theme-dark-mode;
        color: $whiteColor;
        & .popconfirm_content_btn button.primary{
            background: $theme-dark-mode-bg;
        }
    }
}
.theme_dark_mode{
    .panel_modal.show {
        background: $theme-dark-mode;
        border-left: 1px solid $theme-dark-mode;
    }
    .panel_modal_list {
        background: $theme-dark-mode-bg;
        color: $whiteColor;
        li{
            border-color: $theme-dark-mode;
            a {
                color: $whiteColor;
            }
        }
        .price_modal span {
            color: $whiteColor;
        }
    }
}
.theme_dark_mode{
    .modal-content {
        background: $theme-dark-mode;
    }
    .modal-header .close {
        color: $whiteColor;
    }
    .modal .modal-dialog .modal-content .modal-footer .btn.btn-primary,& .modal-content .btn.btn-primary,
    .modal .label_checkbox input:checked ~ .checkmark {
        background: $theme-dark-mode-bg !important;
    }
    .modal .modal-dialog .modal-content .modal-footer .btn.btn-default {
        color: $theme-dark-mode;
    }
    .modal-header, & .modal-footer, & .debt_list_item_box_modal .row:not(:last-child) {
        border-color: $theme-dark-mode-bg;
    }
}
.theme_dark_mode{
    .navigation{
        .navigation_menu ul .sub_menu,& .navigation_setting .navigation_setting_sub{
            box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
        }
        .navigation_change_color_box {
            background: $theme-dark-mode;
            color: $whiteColor;
            border-color: $theme-dark-mode-bg;
            &:before {
                border-bottom: 10px solid $theme-dark-mode-bg;
            }
            hr {
                border-color: $theme-dark-mode-bg;
            }
            .navigation_themes_color_box_list li span{
                border-color: $theme-dark-mode-bg;
            }
        }
        .navigation_notification_list {
            background: $theme-dark-mode;
            color: $whiteColor;
            border-color: $theme-dark-mode-bg;
            & > p {
                color: $whiteColor;
                border-bottom: 1px solid $theme-dark-mode-bg;
            }
            &_main{
                li {
                    border-bottom: 1px solid $theme-dark-mode-bg;
                    &:hover {
                        background: $theme-dark-mode-bg;
                    }
                    &:nth-child(even) {
                        background: $theme-dark-mode;
                        &:hover {
                            background: $theme-dark-mode-bg;
                        }
                    }
                }
            }
            &_see_all:hover {
                background: $theme-dark-mode-bg;
            }
            &:before {
                border-bottom-color: $theme-dark-mode-bg;
            }
        }
        .navigation_notification_list_main a {
            color: $whiteColor;
        }
        .navigation_notification_list > p a {
            color: $whiteColor;
        }
    }
}
.theme_dark_mode{
    .container_login .form_login .form_login_content,
    & .container_login .login_process .form_login_content,& .form_line span {
        background: $theme-dark-mode-bg;
    }
    .container_login .form-group .input-content .form-control {
        border-color:  #4c4c4c !important;
    }  
}
.theme_dark_mode{
    & .ReactVirtualized__Grid__innerScrollContainer .row:nth-child(odd),
    & .ReactVirtualized__Grid__innerScrollContainer .number_sell_bpos,
    & .ReactVirtualized__Grid__innerScrollContainer .row:hover  {
        background: $theme-dark-mode;
        border-color: $theme-dark-mode-bg;
    }
    & .ReactVirtualized__Grid__innerScrollContainer .row,& .navigation_list_invoice.active button,
    & .navigation_list_invoice button:hover{
        color: $whiteColor;
    }
    & .ReactVirtualized__Grid__innerScrollContainer .la-actions,
    & .ReactVirtualized__Grid__innerScrollContainer .bpos_sell_quantity .la,
    & .navigation_list_invoice.active, .navigation_list_invoice:hover,
    & .bpos_box_sell_right_box_title h2{
        background: $theme-dark-mode-bg !important;
    }
    .ReactVirtualized__Grid__innerScrollContainer .outstock{
        background: #c33b3b !important;
    }
    .ReactVirtualized__Grid__innerScrollContainer .active.outstock{
        background: $theme-dark-mode !important;
    }
    .bpos_box_sell_left_action_list li {
        background: #1f2527;
        border-color: #33393c;
    }
    .bpos_box_sell_right_box_price_table{
        border-color: $theme-dark-mode-bg !important;
    }
    & .ReactVirtualized__Grid__innerScrollContainer .active{
        background: #33393c !important;
    }
    .navigation_setting_exclamation_list .la {
        color: #000 !important;
    }
}
.theme_dark_mode{
    .bpos_application_box {
        border-color: $theme-dark-mode;
    }
    .bpos_application_field,& .bpos_application_hover_body{
        border-color: $theme-dark-mode-bg;
    }
    .bpos_application_hover {
        background: $theme-dark-mode-bg;
    }
    .bpos_application_hover_header,& .bpos_application_hover_footer,& .bpos_application_box,& .total_receipts {
        background: $theme-dark-mode;
    }
    .table_return thead tr{
        background:$theme-dark-mode-bg !important;
    }
    .table_report thead tr th{
        border-color: $theme-dark-mode-bg !important;
    }
    .pagination_return{
        li a{
            background:$theme-dark-mode-bg !important;
        }
        li.active a,& li a:hover{
            background:$theme-dark-mode !important;
        }
    }
    .customer_tabs .customer_tabs_upload_image .avatar{
        background:$theme-dark-mode-bg !important;
    }
    .setting_general_content_item_content_sub .table thead,& .setting_general_content_item_content_sub .table thead tr,
    & .setting_general_content_item_content .table thead,& .setting_general_content_item_content .table thead tr {
        background: $theme-dark-mode-bg !important;
    }
    .load_type_receipts,& .load_type_receipts_1{
        background: #2b3133;
    }
    .bpos_dashboard_right_top {
        background: #1f2426;
    }
    .bpos_dashboard_count_number_span {
        background: $theme-dark-mode-bg;
    }
    .bpos_dashboard_choose_btn .btn {
        background: $theme-dark-mode-bg !important;
    }
}
.theme_dark_mode{
    .bpos_price_list_box {
        box-shadow: none;
    }
    .bpos_price_list_title h1 {
        color: $whiteColor;
    }
    .bpos_price_list_box_feature .btn{
        background: $theme-dark-mode-bg !important;
    }
    .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item:hover img{
        filter: invert(0);
        -webkit-filter: invert(0);
    }
    .label_checkbox .checkmark{
        border-color: #ddd;
    }
    .properties_images_box_hr{
        border-color: #2c3134 !important;
    }
    .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input .form-group-sale-box-btn a.active{
        background: $theme-dark-mode-bg !important;
    }
    .ReactVirtualized__Grid__innerScrollContainer .row{
        border-color:$theme-dark-mode-bg !important;
    }
    .buy-person .css-vj8t7z {
        background: none;
    }
    .buy-person .css-xp4uvy {
        color: #fff;
    }
}

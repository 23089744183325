/*-- Fix all --*/
$login_w:400px;
$font-size-body:13px;
$hover_color_bg_nav:#006837;
$color_txt:#637280;
$color_nav_left:#1d364b;
$color_nav_icon:#8696a4;
$border_radius_global:5px;
$with_menu:240px;
$color_border:#e9eef2;
$with_nav_left:70px;
$color_primary:#286EFA;
$color_txt_nav:#e8eaed;
$color_warning:#FFAB00;
$color_secondary_blue:#00B8D9;
$font_size_desc:13px;
$color_text_left:#90949c;   
$menu_height:56px;
$color_green:rgb(66, 183, 42);
$margin_height:10px;
$color-white:#fff;
/*-- Fix Width,Height Menu ---*/
$headerHeight:50px;
$setting_menu:240px;

/*-- Fix ---*/
$color_error:#FF5630;
$themeNavBorder: #28af6b;
$themePrimaryColor: #1cb467;
$themeLighterAltColor: #f4fbf4;
$themeLighterColor: #d5efd5;
$themeLightColor: #b3e0b3;
$themeTertiaryColor: #74c274;
$themeSecondaryColor: #44a544;
$themeDarkAltColor: #2d8a2d;
$themeDarkColor: #267426;
$themeDarkerColor: #1c561c;
$neutralLighterAltColor: #f8f8f8;
$neutralLighterColor: #f4f4f4;
$neutralLightColor: #eaeaea;
$neutralQuaternaryAltColor: #dadada;
$neutralQuaternaryColor: #d0d0d0;
$neutralTertiaryAltColor: #c8c8c8;
$neutralTertiaryColor: #c2c2c2;
$neutralSecondaryColor: #858585;
$neutralPrimaryAltColor: #4b4b4b;
$neutralPrimaryColor: #333;
$neutralDarkColor: #272727;
$blackColor: #1d1d1d;
$whiteColor: #fff;
$primaryBackgroundColor: #fff;
$primaryTextColor: #333;
$bodyBackgroundColor: #fff;
$bodyTextColor: #333;
$disabledBackgroundColor: #f4f4f4;
$disabledTextColor: #c8c8c8;
$errorTextColor: #FF5630;
$successTextColor: #74c274;
$errorBackgroundColor: #fee7e9;
$warningBackgroundColor: #fff5ce;

/*-- Bpos ---*/
$setting_header_bg: #eeeeee;
$setting_header_border: #d6d6d6;

/*-- Themes Color ---*/
$theme-red:#ff7676;
$theme-default:#28af6b;
$theme-blue:#2cabe3;
$theme-purple:#707cd2;
$theme-oranger:#E67E22;
$theme-dark:#4c5667;

/*-- Themes Background ---*/
$theme-dark-mode:#212527;
$theme-dark-mode-bg:#2c3134;
@import "../_variable.scss";
.nxt_checkbox {
    label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 5px;
        font-weight: normal;
    }
    .label_checkbox {
        display: flex;
        position: relative;
        margin-bottom: 10px;
        cursor: pointer;
        user-select: none;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }
        .checkmark {
            //position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            border: none;
            background-color: #eaedee;
            border-radius: 3px;
            border: solid 1px $themePrimaryColor;
        }
        .title {
            margin-left: 10px;
            align-items: center;
            display: flex;
            vertical-align: middle;
        }
    }
}
/* On mouse-over, add a grey background color */
.label_checkbox:hover input~.checkmark {
    opacity:.6;
}
/* When the checkbox is checked, add a blue background */
.label_checkbox input:checked~.checkmark {
    background-color: $themePrimaryColor;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
/* Show the checkmark when checked */
.label_checkbox input:checked~.checkmark:after {
    display: block;
}
/* Style the checkmark/indicator */
.label_checkbox .checkmark:after {
    left: 7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Mono&amp;subset=vietnamese");
/*-- Fix all --*/
/*-- Fix Width,Height Menu ---*/
/*-- Fix ---*/
/*-- Bpos ---*/
/*-- Themes Color ---*/
/*-- Themes Background ---*/
/*-- Fix all --*/
/*-- Fix Width,Height Menu ---*/
/*-- Fix ---*/
/*-- Bpos ---*/
/*-- Themes Color ---*/
/*-- Themes Background ---*/
.animated {
  animation-duration: 500ms; }

.modal-dialog {
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  max-height: 650px; }

.modal-title {
  font-size: 16px; }

.alert {
  border-radius: 0px;
  user-select: none;
  padding: 10px 15px;
  margin-bottom: 15px; }

.alert > p + p {
  line-height: 25px; }

.modal {
  display: block; }
  .modal_logout .modal-body {
    display: none; }
  .modal_logout .modal-header {
    border: none; }
  .modal.show_md {
    visibility: visible;
    overflow-x: hidden;
    overflow-y: auto; }
  .modal.hide_md {
    visibility: hidden; }
  .modal .modal-dialog .modal-content {
    border-radius: 0px;
    border: none; }
    .modal .modal-dialog .modal-content .modal-footer {
      position: relative;
      display: flex;
      justify-content: flex-end; }
      .modal .modal-dialog .modal-content .modal-footer button {
        float: left; }

.md_overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99; }

button:focus {
  outline: none; }

.no-resize {
  resize: none; }

a {
  color: #333;
  text-decoration: none; }
  a:focus, a:hover {
    color: #0d0d0d !important;
    text-decoration: none;
    outline: none; }
  a.btn:hover {
    color: #fff !important; }

hr {
  margin: 10px 0; }

.form-group .input-content {
  outline: none; }

select {
  height: 32px;
  padding: 6px 15px;
  text-transform: capitalize; }

select:not([multiple]) {
  appearance: none;
  background-position: calc(100% - 5px) 50%;
  background-repeat: no-repeat;
  background-image: url("/image/icon/drop-down-arrow.svg");
  padding: .5em;
  padding-right: 1.5em;
  border-radius: 0px; }

select.form-control {
  margin-bottom: 15px;
  height: 32px; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s; }

.btn:focus,
.btn:active,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  box-shadow: none; }

a.btn.btn-primary {
  color: #fff; }

.btn {
  min-width: 108px;
  border: none;
  font-weight: bold;
  outline: transparent;
  user-select: none;
  text-decoration: none;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 13px;
  position: relative;
  padding: 7px 8px;
  font-weight: 600;
  border-radius: 0.1875rem;
  height: auto; }
  .btn i {
    font-size: 18px;
    margin-right: 5px; }
  .btn.button_min_width_none {
    min-width: auto;
    max-width: 100%; }
  .btn.btn-primary {
    background-color: #1cb467; }
    .btn.btn-primary:hover {
      background-color: #1bab62; }
    .btn.btn-primary:active {
      background-color: #189a58 !important; }
  .btn.btn-default {
    background-color: #c2c2c2;
    color: #fff; }
    .btn.btn-default:hover {
      background-color: #bdbdbd; }
    .btn.btn-default:active {
      background-color: #b3b3b3 !important; }
  .btn.btn-success {
    color: #fff;
    background-color: #00bc52;
    border-color: #00bc52; }
  .btn.btn-none {
    background-color: transparent;
    min-width: none;
    background-color: transparent;
    width: auto;
    min-width: auto;
    height: auto;
    min-height: auto;
    padding: 0px;
    margin: 0px;
    display: inline-flex; }
    .btn.btn-none:hover {
      color: #1cb467; }
    .btn.btn-none i {
      color: #1cb467; }
  .btn .btn-slash-root {
    position: absolute;
    right: 0; }
    .btn .btn-slash-root .btn-slash {
      position: relative;
      width: 38px;
      margin-left: 10px;
      height: 100%; }
      .btn .btn-slash-root .btn-slash::before {
        position: absolute;
        content: " ";
        width: 1px;
        height: 100%;
        background-color: white;
        left: 0px;
        top: 0px; }
  .btn.btn-menu {
    padding-right: 50px; }
  .btn .btn-menu-list {
    position: absolute;
    left: 0;
    top: 100%;
    display: block;
    width: 100%;
    background: none;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    transition: all 160ms; }
    .btn .btn-menu-list ul {
      padding: 0;
      list-style: none;
      margin: 0;
      width: 100%;
      margin-top: 11px;
      box-shadow: 0px 0px 3px 1px #bdbdbd;
      background: white;
      position: relative; }
      .btn .btn-menu-list ul li {
        padding: 5px 10px; }
        .btn .btn-menu-list ul li:hover {
          background: #f1f1f1; }
      .btn .btn-menu-list ul:after {
        content: '';
        position: absolute;
        right: 15px;
        top: -8px;
        border-right: 14px solid transparent;
        border-top: 14px solid #c2c2c2;
        transform: rotate(44deg); }
    .btn .btn-menu-list.btn-menu-list-show {
      visibility: visible;
      opacity: 1;
      transition: all 200ms; }

.require {
  color: red; }

.form-control {
  box-shadow: none;
  border: 1px solid #e5e5e5;
  height: 32px;
  border-radius: 6px !important;
  padding: 0 10px !important;
  font-size: 13px; }
  .form-control:focus {
    box-shadow: none;
    translate: all 200ms;
    border-color: #b5b5b5;
    outline: none; }

.form-group {
  position: relative;
  width: 100%; }
  .form-group:last-child {
    margin: 0; }
  .form-group .show-error {
    color: #FF5630; }
  .form-group .input-content {
    position: relative; }
    .form-group .input-content input {
      border-radius: 0px;
      padding-left: 5px; }
    .form-group .input-content .icon_input_left {
      position: absolute;
      display: none;
      width: 30px;
      top: 0;
      justify-content: center;
      align-content: center;
      height: 100%; }
      .form-group .input-content .icon_input_left .icon {
        height: 100%;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #c8c8c8;
        cursor: pointer; }
        .form-group .input-content .icon_input_left .icon.icon_right {
          right: 0; }
          .form-group .input-content .icon_input_left .icon.icon_right:hover {
            color: #333; }
        .form-group .input-content .icon_input_left .icon.icon_left {
          left: 0; }
          .form-group .input-content .icon_input_left .icon.icon_left:hover {
            color: #333; }
    .form-group .input-content .form-control:focus {
      border-color: #b5b5b5; }
    .form-group .input-content .form-control:hover {
      border-color: #b5b5b5; }
      .form-group .input-content .form-control:hover:focus {
        border-color: #b5b5b5; }
    .form-group .input-content .form-control.ic_left {
      padding-left: 30px; }
    .form-group .input-content .form-control.ic_right {
      padding-right: 30px; }
    .form-group .input-content .form-control.error {
      border-color: #fee7e9 !important; }

.form-control-feedback {
  top: 27px;
  display: none; }

.has-feedback .form-control-feedback {
  display: block;
  top: 27px !important; }

label {
  position: relative;
  font-size: 13px;
  line-height: 1;
  vertical-align: top;
  display: inline-block;
  margin-bottom: 1rem; }
  label .color_require {
    background: none !important; }

body label span.color_require {
  background: none !important; }

textarea.form-control {
  padding: 10px !important; }

/*--- Amination ----*/
.spiner {
  animation: spin 1500ms linear infinite; }

@keyframes spin {
  to {
    transform: rotate(359deg); } }

/*-------- Fix color ------*/
.colorPrimary {
  color: #1cb467; }

.color_require {
  color: #FF5630; }

/*----------Fix Select--------*/
.css-2o5izw {
  box-shadow: none !important;
  border-color: #eaeaea !important;
  min-height: 32px !important; }

.css-1aya2g8, .css-vj8t7z, .css-1wy0on6, .css-162g8z5 {
  min-height: 32px !important; }

.css-1ep9fjw, .css-1sjym57, .css-1uq0kb5 {
  padding: 4px !important; }

/*----------Fix Datepicker--------*/
.react-datepicker-wrapper {
  position: relative; }
  .react-datepicker-wrapper:before {
    content: "\f15c";
    position: absolute;
    font: normal normal normal 19px/1 LineAwesome;
    display: block;
    color: #000;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    right: 10px; }

.react-datepicker-wrapper, .react-datepicker-wrapper input {
  height: 32px !important; }

.react-datepicker {
  font-size: 1em !important;
  display: flex !important; }

.react-datepicker__header {
  padding-top: 0.8em !important; }

.react-datepicker__month {
  margin: 0.4em 1em !important; }

.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em !important;
  line-height: 1.9em !important;
  margin: 0.166em !important; }

.react-datepicker__current-month {
  font-size: 1em !important; }

.react-datepicker__navigation {
  top: 1em !important;
  line-height: 1.7em !important;
  border: 0.45em solid transparent !important; }

.react-datepicker__navigation--previous {
  border-right-color: #ccc !important;
  left: 1em !important; }

.react-datepicker__navigation--next {
  border-left-color: #ccc !important;
  right: 1em !important;
  left: 220px !important; }

.react-datepicker__time-container {
  width: 6em !important; }

.react-datepicker-time__header {
  font-size: 1em !important; }

.react-datepicker-time__header--time {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.react-datepicker__time-box {
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100% !important; }

.react-datepicker__time-list {
  padding: 0 !important; }

.react-datepicker__input-container {
  width: 100%; }

.react-datepicker-wrapper {
  width: 100%; }

/*----------Fix Pagination--------*/
.pagination {
  margin: 15px 0 10px; }

.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus, .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: #28af6b;
  border-color: #28af6b;
  color: #fff !important;
  cursor: pointer; }

.pagination > li > a, .pagination > li > span {
  color: #000;
  margin: 0 !important; }

/*----------Fix Table--------*/
table {
  width: 100% !important;
  margin: 0 !important;
  border-collapse: collapse;
  border-radius: 3px;
  background: #f9f9f9;
  border: none !important; }
  table thead {
    background: #1cb467;
    color: #fff; }
    table thead tr th {
      border: none !important;
      border-bottom-width: 0;
      white-space: pre;
      text-overflow: ellipsis;
      overflow: hidden; }
  table tr:nth-child(2n) {
    background: #f1f1f1; }
  table tr th:nth-child(1) {
    text-align: center; }
  table tr td {
    vertical-align: middle !important; }
    table tr td:nth-child(1) {
      text-align: center; }
  table .sub_category {
    font-family: monospace; }

.table > thead > tr > th {
  padding: 12px 8px; }

.table-padding-th-5 > thead > tr > th {
  padding: 5px !important; }

.table-align-left th:nth-child(1) {
  text-align: left !important; }

.table-align-left td {
  vertical-align: middle !important; }
  .table-align-left td:nth-child(1) {
    text-align: left !important; }

/*---SMOOTH-SCROLLBAR-REACT---*/
.scrollbar-thumb-y, .scrollbar-track-y {
  width: 5px !important; }

.scrollbar-thumb-x, .scrollbar-track-x {
  height: 5px !important; }

.scroll-content, [data-scrollbar] {
  width: 100% !important; }
  .scroll-content table th, .scroll-content table td, [data-scrollbar] table th, [data-scrollbar] table td {
    white-space: pre; }

.scroll-content-inner {
  max-height: 450px;
  overflow-y: auto; }

/*-- CkEditer --*/
.cke_reset_all table {
  width: auto !important;
  margin: 0px !important;
  border-collapse: collapse;
  border-radius: 0;
  background: none;
  border: none !important; }
  .cke_reset_all table tr td:nth-child(1) {
    text-align: unset; }
  .cke_reset_all table tr:nth-child(2n) {
    background: none; }

.cke_reset_all .cke_dialog_footer table {
  margin: 5px 10px !important; }

.cke_reset_all .cke_dialog_ui_vbox table, .cke_reset_all .cke_dialog_ui_hbox table {
  margin: auto !important; }

.cke_reset_all .cke_dialog_contents_body table {
  width: 100% !important; }

.cke_reset_all .cke_dialog_tabs {
  position: static;
  margin: 10px 0;
  width: 100%;
  border-bottom: 1px solid #ddd; }
  .cke_reset_all .cke_dialog_tabs a {
    margin: 0 5px; }

.blockbuilder-branding {
  display: none  !important; }

.btn_add_box .row {
  margin: 0 -7.5px; }

.btn_add_box [class*="col-"] {
  padding: 0 7.5px; }

.btn_add_box .btn {
  min-width: 100%;
  margin-bottom: 15px; }

@media only screen and (min-width: 1024px) {
  .btn_add_box > .row > .col-md-2 {
    width: auto; } }

/* 
    Document   : reset css
    Created on : 6-6-2018, 12:00:00
    Author     : DTM
    Description: Code reset SASS Main
*/
.text-primary {
  color: #1b64d2; }

.text-success {
  color: #00b75e; }

.text-danger {
  color: #f30d0a; }

strong, .btn, .container_login label {
  font-weight: 500 !important; }

button[disabled], html input[disabled] {
  cursor: no-drop !important; }

input, textarea {
  outline: none; }

a, img {
  -webkit-transition: all ease .5s;
  -moz-transition: all ease .5s;
  -ms-transition: all ease .5s;
  -o-transition: all ease .5s;
  transition: all ease .5s;
  text-decoration: none !important; }

.img_cover {
  width: 100%;
  height: 100% !important;
  object-fit: cover !important; }

.img_scale_down {
  width: 100%;
  height: 100% !important;
  object-fit: scale-down !important; }
  .img_scale_down.max100 {
    max-width: 100px; }

.no-drop {
  cursor: no-drop; }

/*=== Btn Width-full ===*/
.btn-w100 {
  width: 100%; }

.btn-min-w {
  min-width: 100%; }

/*=== Misc tools ===*/
.clearfix {
  clear: both; }

.margin-auto {
  margin: auto; }

.no-margin {
  margin: 0px !important; }

.no-margin-right {
  margin-right: 0 !important; }

.no-margin-left {
  margin-left: 0 !important; }

.no-padding-right {
  padding-right: 0 !important; }

.no-padding-left {
  padding-left: 0 !important; }

.no-padding {
  padding: 0px !important; }

.no-bottom-space {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important; }

.no-top-space {
  padding-top: 0px !important;
  margin-top: 0px !important; }

.no-text-shadow {
  text-shadow: none !important; }

.no-border {
  border: none !important; }

.no-border-right {
  border-right: none !important; }

.no-border-left {
  border-left: none !important; }

.margin-10 {
  margin: 10px !important; }

.margin-top-10 {
  margin-top: 10px !important; }

.margin-bottom-10 {
  margin-bottom: 10px !important; }

.margin-right-10 {
  margin-right: 10px !important; }

.margin-left-10 {
  margin-left: 10px !important; }

.margin-15 {
  margin: 15px !important; }

.margin-top-15 {
  margin-top: 15px !important; }

.margin-bottom-15 {
  margin-bottom: 15px !important; }

.margin-right-15 {
  margin-right: 15px !important; }

.margin-left-15 {
  margin-left: 15px !important; }

.margin-20 {
  margin: 20px !important; }

.margin-top-20 {
  margin-top: 20px !important; }

.margin-bottom-20 {
  margin-bottom: 20px !important; }

.margin-right-20 {
  margin-right: 20px !important; }

.margin-left-20 {
  margin-left: 20px !important; }

.margin-25 {
  margin: 25px !important; }

.margin-top-25 {
  margin-top: 25px !important; }

.margin-bottom-25 {
  margin-bottom: 25px !important; }

.margin-right-25 {
  margin-right: 25px !important; }

.margin-left-25 {
  margin-left: 25px !important; }

.margin-30 {
  margin: 30px !important; }

.margin-top-30 {
  margin-top: 30px !important; }

.margin-bottom-30 {
  margin-bottom: 30px !important; }

.margin-right-30 {
  margin-right: 30px !important; }

.margin-left-30 {
  margin-left: 30px !important; }

.margin-300 {
  margin: 300px !important; }

.margin-top-300 {
  margin-top: 300px !important; }

.margin-bottom-300 {
  margin-bottom: 300px !important; }

.margin-right-300 {
  margin-right: 300px !important; }

.margin-left-300 {
  margin-left: 300px !important; }

.padding-10 {
  padding: 10px !important; }

.padding-top-10 {
  padding-top: 10px !important; }

.padding-bottom-10 {
  padding-bottom: 10px !important; }

.padding-right-10 {
  padding-right: 10px !important; }

.padding-left-10 {
  padding-left: 10px !important; }

.padding-15 {
  padding: 15px !important; }

.padding-top-15 {
  padding-top: 15px !important; }

.padding-bottom-15 {
  padding-bottom: 15px !important; }

.padding-right-15 {
  padding-right: 15px !important; }

.padding-left-15 {
  padding-left: 15px !important; }

.padding-20 {
  padding: 20px !important; }

.padding-top-20 {
  padding-top: 20px !important; }

.padding-bottom-20 {
  padding-bottom: 20px !important; }

.padding-right-20 {
  padding-right: 20px !important; }

.padding-left-20 {
  padding-left: 20px !important; }

.padding-25 {
  padding: 25px !important; }

.padding-top-25 {
  padding-top: 25px !important; }

.padding-bottom-25 {
  padding-bottom: 25px !important; }

.padding-right-25 {
  padding-right: 25px !important; }

.padding-left-25 {
  padding-left: 25px !important; }

.padding-30 {
  padding: 30px !important; }

.padding-top-30 {
  padding-top: 30px !important; }

.padding-bottom-30 {
  padding-bottom: 30px !important; }

.padding-right-30 {
  padding-right: 30px !important; }

.padding-left-30 {
  padding-left: 30px !important; }

/*=== Reset Mobile Col ==*/
@media only screen and (max-width: 480px) {
  .full-xs {
    width: 100% !important;
    float: left; } }

@media only screen and (min-width: 1024px) {
  .col-xl-20 {
    width: 20% !important;
    float: left;
    padding: 0 15px; } }

@media (min-width: 1332px) {
  .modal-xl {
    width: 100%;
    padding: 0 30px; } }

/*-- Fix all --*/
/*-- Fix Width,Height Menu ---*/
/*-- Fix ---*/
/*-- Bpos ---*/
/*-- Themes Color ---*/
/*-- Themes Background ---*/
.nxt_checkbox label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: normal; }

.nxt_checkbox .label_checkbox {
  display: flex;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none; }
  .nxt_checkbox .label_checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
  .nxt_checkbox .label_checkbox .checkmark {
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: none;
    background-color: #eaedee;
    border-radius: 3px;
    border: solid 1px #1cb467; }
  .nxt_checkbox .label_checkbox .title {
    margin-left: 10px;
    align-items: center;
    display: flex;
    vertical-align: middle; }

/* On mouse-over, add a grey background color */
.label_checkbox:hover input ~ .checkmark {
  opacity: .6; }

/* When the checkbox is checked, add a blue background */
.label_checkbox input:checked ~ .checkmark {
  background-color: #1cb467; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the checkmark when checked */
.label_checkbox input:checked ~ .checkmark:after {
  display: block; }

/* Style the checkmark/indicator */
.label_checkbox .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

/*-- Fix all --*/
/*-- Fix Width,Height Menu ---*/
/*-- Fix ---*/
/*-- Bpos ---*/
/*-- Themes Color ---*/
/*-- Themes Background ---*/
.nxt_radio label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: normal; }

.nxt_radio .label_radio {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none; }
  .nxt_radio .label_radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
  .nxt_radio .label_radio .checkmark_radio {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: solid 1px #5454544a;
    border-radius: 50%;
    background-color: #fff; }
  .nxt_radio .label_radio .title {
    margin-left: 25px; }

.label_radio:hover input ~ .checkmark_radio {
  border-color: #1cb467; }

.label_radio input:checked ~ .checkmark_radio {
  background-color: #1cb467; }

.checkmark_radio:after {
  content: "";
  position: absolute;
  display: none; }

.label_radio input:checked ~ .checkmark_radio:after {
  display: block; }

.label_radio .checkmark_radio:after {
  left: 5px;
  top: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff; }

.avatar {
  width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  border-radius: 50%;
  background: #E6E6E6;
  color: #404040; }
  .avatar img {
    width: 100%;
    height: 100%;
    border-radius: inherit; }

/*-- Fix all --*/
/*-- Fix Width,Height Menu ---*/
/*-- Fix ---*/
/*-- Bpos ---*/
/*-- Themes Color ---*/
/*-- Themes Background ---*/
.popconfirm {
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  animation-duration: 300ms;
  z-index: 15; }
  .popconfirm.show {
    visibility: visible; }
  .popconfirm .popconfirm_content_root {
    position: relative; }
    .popconfirm .popconfirm_content_root::after {
      position: absolute;
      content: " ";
      width: 0px;
      height: 0px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #fff;
      left: 51%; }
    .popconfirm .popconfirm_content_root .popconfirm_content {
      top: 0;
      right: 0;
      min-width: 300px;
      min-height: 80px;
      background-color: #fff;
      background-clip: padding-box;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      padding: 15px;
      color: #333; }
      .popconfirm .popconfirm_content_root .popconfirm_content .popconfirm_content_msg {
        display: flex;
        align-items: center;
        margin-bottom: 15px; }
        .popconfirm .popconfirm_content_root .popconfirm_content .popconfirm_content_msg .popconfirm_content_msg_info {
          font-size: 24px;
          margin-right: 10px; }
        .popconfirm .popconfirm_content_root .popconfirm_content .popconfirm_content_msg .popconfirm_content_msg_info_txt {
          max-width: 300px; }
      .popconfirm .popconfirm_content_root .popconfirm_content .popconfirm_content_btn {
        display: flex;
        justify-content: flex-end; }
        .popconfirm .popconfirm_content_root .popconfirm_content .popconfirm_content_btn button {
          float: left;
          margin-left: 10px;
          padding: 2px 15px;
          background-color: #205b1f;
          border: none; }
          .popconfirm .popconfirm_content_root .popconfirm_content .popconfirm_content_btn button.default {
            color: #333;
            background-color: #c2c2c2; }
          .popconfirm .popconfirm_content_root .popconfirm_content .popconfirm_content_btn button.primary {
            color: #fff;
            background-color: #1cb467; }

.popconfirm_modal {
  z-index: 1115; }

/*-- Fix all --*/
/*-- Fix Width,Height Menu ---*/
/*-- Fix ---*/
/*-- Bpos ---*/
/*-- Themes Color ---*/
/*-- Themes Background ---*/
.autocomplete {
  position: relative;
  width: 100%; }
  .autocomplete .form-group {
    margin-bottom: 0px; }
  .autocomplete .autocomplete_content {
    display: flex;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 9;
    position: absolute;
    width: 100%;
    background-color: #ffff; }
    .autocomplete .autocomplete_content .autocomplete_content_list {
      position: relative;
      width: 100%;
      max-height: 200px;
      overflow: auto;
      height: 0px;
      transition: height 200ms ease-out; }
      .autocomplete .autocomplete_content .autocomplete_content_list.show {
        height: 200px;
        transition: height 200ms ease-in; }
      .autocomplete .autocomplete_content .autocomplete_content_list .autocomplete_content_item {
        display: flex;
        padding: 12px;
        align-items: center;
        cursor: pointer;
        transition: all 200ms;
        color: #333; }
        .autocomplete .autocomplete_content .autocomplete_content_list .autocomplete_content_item:hover {
          background-color: #2c3134;
          transition: all 200ms;
          color: white; }
        .autocomplete .autocomplete_content .autocomplete_content_list .autocomplete_content_item .tag_color {
          border-radius: 50%;
          height: 16px;
          width: 16px;
          margin-right: 5px;
          border: 1px solid #dddfe2;
          margin-right: 5px; }
        .autocomplete .autocomplete_content .autocomplete_content_list .autocomplete_content_item .tag_name {
          user-select: none; }

.tag_root {
  position: relative;
  display: inline-block; }
  .tag_root .tag {
    float: left;
    padding: 3px;
    background: #c3c3c3;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 2px;
    color: white;
    user-select: none;
    transition: all 200ms;
    border: dashed 1px; }
    .tag_root .tag span {
      overflow: hidden;
      white-space: nowrap;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      width: 89%;
      display: block;
      float: left; }
    .tag_root .tag i {
      cursor: pointer; }
      .tag_root .tag i:hover {
        color: red; }
    .tag_root .tag.hidden {
      display: none; }

.magenta {
  color: #ec3095 !important;
  background-color: #fdebf5 !important;
  border-color: #eb2790 !important; }

.red {
  color: #f5222d !important;
  background-color: #fee5e6 !important;
  border-color: #f51824 !important; }

.volcano {
  color: #fa541c !important;
  background-color: #feeae4 !important;
  border-color: #fa4c12 !important; }

.orange {
  color: #fa8c16 !important;
  background-color: #feefde !important;
  border-color: #fa870c !important; }

.gold {
  color: #faad14 !important;
  background-color: #fef3dc !important;
  border-color: #faaa0a !important; }

.lime {
  color: #a0d911 !important;
  background-color: #e8fabc !important;
  border-color: #99d010 !important; }

.green {
  color: #52c41a !important;
  background-color: #caf5b5 !important;
  border-color: #4ebb19 !important; }

.cyan {
  color: #13c2c2 !important;
  background-color: #aaf7f7 !important;
  border-color: #12b9b9 !important; }

.blue {
  color: #1890ff !important;
  background-color: #e4f2ff !important;
  border-color: #0e8bff !important; }

.geekblue {
  color: #2f54eb !important;
  background-color: #e9edfd !important;
  border-color: #264cea !important; }

.purple {
  color: #722ed1 !important;
  background-color: #e3d5f6 !important;
  border-color: #6d2cc9 !important; }

/*-- Fix all --*/
/*-- Fix Width,Height Menu ---*/
/*-- Fix ---*/
/*-- Bpos ---*/
/*-- Themes Color ---*/
/*-- Themes Background ---*/
.nav_header {
  background: #1cb467;
  width: 100%;
  position: fixed;
  height: 50px;
  display: flex;
  flex: 1;
  color: white;
  padding: 0 24px;
  top: 0;
  left: 0; }
  .nav_header .nav_header_left {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .nav_header .nav_header_right {
    display: flex;
    flex: 1;
    position: relative; }
    .nav_header .nav_header_right .nav_header_right_ul {
      display: flex;
      height: 100%;
      align-items: center; }
      .nav_header .nav_header_right .nav_header_right_ul li {
        list-style: none;
        height: 100%;
        display: flex;
        cursor: pointer;
        position: relative;
        transition: all 200ms;
        /*Submenu*/ }
        .nav_header .nav_header_right .nav_header_right_ul li a {
          color: white;
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0 15px;
          width: 100%; }
          .nav_header .nav_header_right .nav_header_right_ul li a:hover {
            color: white !important; }
        .nav_header .nav_header_right .nav_header_right_ul li:hover {
          background-color: #2d8a2d;
          transition: all 200ms; }
          .nav_header .nav_header_right .nav_header_right_ul li:hover ul {
            opacity: 1;
            transition: opacity 200ms;
            visibility: visible; }
        .nav_header .nav_header_right .nav_header_right_ul li ul {
          transition: all 200ms;
          visibility: hidden;
          opacity: 0;
          position: absolute;
          top: 50px;
          padding: 0px;
          width: 240px;
          color: red;
          left: 0;
          background-color: #2d8a2d; }
          .nav_header .nav_header_right .nav_header_right_ul li ul li.sub_menu {
            height: 40px; }
            .nav_header .nav_header_right .nav_header_right_ul li ul li.sub_menu a:hover {
              background-color: #267426; }
    .nav_header .nav_header_right .nav_header_right_profile {
      display: flex;
      position: absolute;
      right: 0;
      justify-content: center;
      height: 100%;
      align-items: center; }
      .nav_header .nav_header_right .nav_header_right_profile .nav_header_right_profile_icon {
        display: flex;
        height: 100%; }
        .nav_header .nav_header_right .nav_header_right_profile .nav_header_right_profile_icon .icon {
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 15px; }
          .nav_header .nav_header_right .nav_header_right_profile .nav_header_right_profile_icon .icon:hover {
            background-color: #2d8a2d; }
      .nav_header .nav_header_right .nav_header_right_profile .nav_header_right_profile_pro {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        height: 100%;
        cursor: pointer; }
        .nav_header .nav_header_right .nav_header_right_profile .nav_header_right_profile_pro .nav_header_right_profile_pro_avt {
          justify-content: center;
          align-items: center;
          display: flex;
          padding-left: 5px; }

/*----- Notify -----*/
#notification-box {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50px;
  right: 0px;
  z-index: 9999; }
  #notification-box .crm_notification {
    display: flex;
    position: relative;
    color: white;
    width: 380px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    padding: 24px;
    margin: 0 0 6px;
    overflow: hidden;
    color: #262626;
    font-size: 13px; }
    #notification-box .crm_notification.success {
      background: #28af6b;
      background-position: 15px center;
      background-repeat: no-repeat;
      color: white; }
    #notification-box .crm_notification.info {
      background: #fff;
      background-position: 15px center;
      background-repeat: no-repeat; }
    #notification-box .crm_notification.warning {
      background: #fff5ce;
      background-position: 15px center;
      background-repeat: no-repeat; }
    #notification-box .crm_notification.error {
      background: #fee7e9;
      background-position: 15px center;
      background-repeat: no-repeat; }
    #notification-box .crm_notification .crm_notification_content {
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%; }
      #notification-box .crm_notification .crm_notification_content .crm_notification_content_title {
        font-weight: 700; }
      #notification-box .crm_notification .crm_notification_content .crm_notification_content_desc {
        font-weight: normal; }
    #notification-box .crm_notification .crm_notification_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 60px;
      flex: 1;
      cursor: pointer;
      user-select: none;
      top: 24px;
      position: absolute;
      right: 24px; }
      #notification-box .crm_notification .crm_notification_btn:hover {
        font-weight: 700; }

.headerForm-enter {
  animation: 0.8s flipInX ease; }

.headerForm-leave {
  animation: 0.5s flipOutX ease; }

/*-- Fix all --*/
/*-- Fix Width,Height Menu ---*/
/*-- Fix ---*/
/*-- Bpos ---*/
/*-- Themes Color ---*/
/*-- Themes Background ---*/
.body_container {
  top: 50px;
  position: relative; }
  .body_container .body_container_head {
    border-bottom: solid 0.5px #eaeaea;
    height: 40px; }
  .body_container .body_container_side_left {
    width: 240px;
    height: 100%;
    position: fixed;
    border-right: solid 0.5px #eaeaea;
    background-color: #ffffff; }
    .body_container .body_container_side_left .body_container_side_left_item {
      cursor: pointer;
      display: flex;
      flex: 1;
      position: relative;
      flex-direction: column; }
      .body_container .body_container_side_left .body_container_side_left_item a {
        padding: 10px 0;
        color: #333;
        display: flex; }
        .body_container .body_container_side_left .body_container_side_left_item a:hover {
          background: #f4f4f4;
          color: #333; }
        .body_container .body_container_side_left .body_container_side_left_item a .body_container_side_left_item_icon {
          width: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px; }
      .body_container .body_container_side_left .body_container_side_left_item .body_container_side_left_item_child {
        display: flex;
        flex-direction: column; }
        .body_container .body_container_side_left .body_container_side_left_item .body_container_side_left_item_child .body_container_side_left_item_child_sub {
          position: relative;
          display: inherit;
          padding-left: 50px; }
          .body_container .body_container_side_left .body_container_side_left_item .body_container_side_left_item_child .body_container_side_left_item_child_sub:hover {
            background: #f4f4f4; }
          .body_container .body_container_side_left .body_container_side_left_item .body_container_side_left_item_child .body_container_side_left_item_child_sub.active {
            background: #f4f4f4; }
            .body_container .body_container_side_left .body_container_side_left_item .body_container_side_left_item_child .body_container_side_left_item_child_sub.active a::after {
              content: " ";
              height: 100%;
              position: absolute;
              top: 0px;
              right: 0px;
              bottom: 0px;
              left: 1px;
              border-left: 2px solid #2d8a2d; }
      .body_container .body_container_side_left .body_container_side_left_item.active {
        background: #f4f4f4; }
        .body_container .body_container_side_left .body_container_side_left_item.active a::after {
          content: " ";
          height: 100%;
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 1px;
          border-left: 2px solid #2d8a2d; }

/*------------------Component Tooltip---------------*/
.crm_tooltip {
  position: fixed;
  cursor: pointer;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  visibility: hidden; }
  .crm_tooltip.show {
    zoom: 1;
    filter: alpha(opacity=50);
    opacity: 1;
    visibility: visible; }
  .crm_tooltip .tooltip-content {
    position: absolute;
    background-color: #222222d1;
    text-align: center;
    color: #fff;
    transform: translateX(-50%);
    white-space: nowrap;
    border-radius: 4px;
    padding: 6px 10px;
    font-size: 13px;
    font-weight: normal; }
    .crm_tooltip .tooltip-content::before {
      content: "";
      position: absolute; }
    .crm_tooltip .tooltip-content.top {
      bottom: 100%;
      left: 50%;
      margin-bottom: 10px; }
      .crm_tooltip .tooltip-content.top::before {
        top: 100%;
        left: 50%;
        margin-left: -4px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #222222d1; }

/*------------------Component Tooltip---------------*/
/*------ Component spin ------*/
.spin {
  position: relative; }
  .spin .overlay {
    position: absolute;
    *background: #ffffff87;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: progress; }
    .spin .overlay.hide {
      display: none; }
    .spin .overlay .overlay_icon {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: rgba(255, 255, 255, 0.7); }
      .spin .overlay .overlay_icon .loader {
        border: 3px solid #ccc;
        border-top: 3px solid #286EFA;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        animation: spin 2s linear infinite;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto; }
      .spin .overlay .overlay_icon .txt {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding-top: 55px;
        font-size: 13px;
        color: #666;
        user-select: none; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/*------ Component spin -------*/
/*-- Fix all --*/
/*-- Fix Width,Height Menu ---*/
/*-- Fix ---*/
/*-- Bpos ---*/
/*-- Themes Color ---*/
/*-- Themes Background ---*/
.dragUploadExcelRoot .dragUploadExcel {
  position: relative; }
  .dragUploadExcelRoot .dragUploadExcel .uploadZone {
    background: white;
    height: 141px;
    border: dashed 2px #c2c2c2;
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px; }
    .dragUploadExcelRoot .dragUploadExcel .uploadZone span {
      z-index: 1; }
    .dragUploadExcelRoot .dragUploadExcel .uploadZone input[type=file] {
      display: none; }
    .dragUploadExcelRoot .dragUploadExcel .uploadZone::after {
      background-size: contain;
      position: absolute;
      content: " ";
      background-image: url("../style/scss/common/image/xls.svg");
      width: 100px;
      height: 100px;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      opacity: 0.3;
      background-repeat: no-repeat; }
    .dragUploadExcelRoot .dragUploadExcel .uploadZone:hover {
      background-color: #eaeaea; }
    .dragUploadExcelRoot .dragUploadExcel .uploadZone.uploadZone_hover {
      background-color: #eaeaea; }
    .dragUploadExcelRoot .dragUploadExcel .uploadZone.uploadZone_reject {
      background-color: #FF5630; }
    .dragUploadExcelRoot .dragUploadExcel .uploadZone.uploadZone_accept {
      background-color: #74c274; }
  .dragUploadExcelRoot .dragUploadExcel .info .info_file .info_file_table tr {
    background: white !important; }
    .dragUploadExcelRoot .dragUploadExcel .info .info_file .info_file_table tr td {
      word-break: break-word; }
      .dragUploadExcelRoot .dragUploadExcel .info .info_file .info_file_table tr td:nth-child(1) {
        word-break: unset;
        text-align: left;
        font-weight: bold; }
      .dragUploadExcelRoot .dragUploadExcel .info .info_file .info_file_table tr td .react-sweet-progress-symbol {
        width: 40px; }
  .dragUploadExcelRoot .dragUploadExcel .info .info_file .info_file_table_opt_insert td:nth-child(1) {
    width: 150px;
    text-align: left;
    font-weight: bold;
    line-height: 32px; }
  .dragUploadExcelRoot .dragUploadExcel .info .info_file .info_file_table_opt_insert select.form-control {
    margin-bottom: 0px; }

.dragUploadExcelRoot .rowInfoExcel .rowInfoExcel_title {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin: 15px 0; }

.dragUploadExcelRoot .rowInfoExcel select.form-control {
  margin-bottom: 0px; }

.dragUploadExcelRoot .rowInfoExcel .table-container {
  height: 305px; }
  .dragUploadExcelRoot .rowInfoExcel .table-container.table tr > td:nth-child(2) {
    width: 30px; }
  .dragUploadExcelRoot .rowInfoExcel .table-container.table tr > td:nth-child(6) {
    width: 100px; }
  .dragUploadExcelRoot .rowInfoExcel .table-container.table tr > td:nth-child(5) {
    width: 100px; }
  .dragUploadExcelRoot .rowInfoExcel .table-container.table tr > td:nth-child(4) {
    width: 100px; }
  .dragUploadExcelRoot .rowInfoExcel .table-container tbody tr {
    display: block;
    padding: 9px; }

.dragUploadExcelRoot .rowInfoExcel table {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%; }

.dragUploadExcelRoot .rowInfoExcel table thead {
  /* head takes the height it requires, 
            and it's not scaled when table is resized */
  flex: 0 0 auto; }

.dragUploadExcelRoot .rowInfoExcel table tbody {
  /* body takes all the remaining available space */
  flex: 1 1 auto;
  display: block;
  overflow-y: auto; }

.dragUploadExcelRoot .rowInfoExcel table tbody tr {
  width: 100%; }

.dragUploadExcelRoot .rowInfoExcel table thead,
.dragUploadExcelRoot .rowInfoExcel table tbody tr {
  display: table;
  table-layout: fixed; }

/*== Sms Excel ==*/
.account_list_brands {
  padding: 0; }
  .account_list_brands li {
    list-style-position: inside; }

.info_file_table {
  height: 141px;
  overflow-y: auto; }

textarea.form-control.content_sms_excel {
  height: 108px; }

/*-----Modules User-------*/
.container_bg {
  background-size: cover !important;
  position: fixed;
  width: 100%;
  height: 100%; }

.container_login_bg {
  background: url("/image/bg_login_1.jpg"); }

.container_register_bg {
  background: url("/image/bg_register.jpg") center center no-repeat; }

.power-text {
  color: rgba(255, 255, 255, 0.4);
  font-size: 13px;
  padding: 20px 0; }

.check_captcha {
  display: flex;
  flex: 1; }
  .check_captcha img {
    height: 32px;
    display: flex;
    flex: 1; }
  .check_captcha .form-group {
    margin-right: 15px; }
  .check_captcha .show-error {
    margin: 10px 0 0 0; }

.title_form h2 {
  color: #000;
  margin: 20px 0;
  text-transform: capitalize;
  font-size: 20px; }

.color-white h2 {
  color: #fff; }

.show-error {
  margin: 10px 0 0 !important;
  font-size: 13px !important; }

.fogot_password a:hover, .register_link a:hover, .more_link a:hover {
  color: #1cb467 !important; }

.check_captcha img {
  height: 40px; }

@media (max-width: 480px) {
  .container_login_bg, .container_register_bg, .container_login {
    background: #fff;
    position: relative !important; }
  .color-white h2, .power-text {
    color: #000; } }

.container_login {
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;
  flex: 1; }
  .container_login .form-group .input-content .form-control {
    border: 1px solid #e5e5e5;
    height: 40px;
    border-radius: 6px;
    padding: 0 10px !important; }
  .container_login .form_login, .container_login .login_process {
    width: 33.33%;
    margin: auto; }
    @media (max-width: 1024px) {
      .container_login .form_login, .container_login .login_process {
        width: 500px; } }
    @media (max-width: 767px) {
      .container_login .form_login, .container_login .login_process {
        width: 100%; } }
    .container_login .form_login .input-content .icon_input_left .icon, .container_login .login_process .input-content .icon_input_left .icon {
      display: none; }
    .container_login .form_login .form_login_content, .container_login .login_process .form_login_content {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      height: 100%;
      background: #ffffff;
      padding: 40px 40px 30px;
      border-radius: 4px;
      box-shadow: 0 -25px 37.7px 11.3px rgba(8, 143, 220, 0.07);
      z-index: 9; }
      @media (max-width: 768px) {
        .container_login .form_login .form_login_content, .container_login .login_process .form_login_content {
          padding: 20px 20px 10px; } }
      @media (max-width: 480px) {
        .container_login .form_login .form_login_content, .container_login .login_process .form_login_content {
          padding: 0px 20px 10px;
          box-shadow: none; } }
      .container_login .form_login .form_login_content .logo img, .container_login .login_process .form_login_content .logo img {
        width: 150px; }
      .container_login .form_login .form_login_content .form_check_input, .container_login .login_process .form_login_content .form_check_input {
        float: left; }
      .container_login .form_login .form_login_content .form_check_label .form-group, .container_login .login_process .form_login_content .form_check_label .form-group {
        float: left;
        width: auto;
        margin: 0 10px 0 0; }
      .container_login .form_login .form_login_content .text-title, .container_login .login_process .form_login_content .text-title {
        color: #404040;
        font-size: 1.5rem;
        font-weight: 600;
        padding: 0;
        margin-top: 12px;
        margin-bottom: 12px; }
      .container_login .form_login .form_login_content .form_btn, .container_login .login_process .form_login_content .form_btn {
        display: block;
        margin: 15px 0; }
        .container_login .form_login .form_login_content .form_btn .btn, .container_login .login_process .form_login_content .form_btn .btn {
          margin: 0;
          width: 100%;
          height: auto;
          font-size: 13px;
          padding: 11px 8px;
          font-weight: 600;
          border-radius: 0.1875rem; }
        .container_login .form_login .form_login_content .form_btn .btn.btn-default, .container_login .login_process .form_login_content .form_btn .btn.btn-default {
          background-color: #c2c2c2;
          margin: 0 0 10px;
          color: #fff; }
          .container_login .form_login .form_login_content .form_btn .btn.btn-default:hover, .container_login .login_process .form_login_content .form_btn .btn.btn-default:hover {
            background: #a5a5a5; }
      .container_login .form_login .form_login_content .more_link_root, .container_login .login_process .form_login_content .more_link_root {
        display: flex;
        justify-content: space-between;
        margin: 20px 0 0; }
  @media (max-width: 1024px) {
    .container_login .login_process {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); } }
  .container_login .login_process_box {
    width: 100%;
    margin: auto; }
  .container_login .form_login_process {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 40px 40px 30px;
    border-radius: 4px;
    box-shadow: 0 -25px 37.7px 11.3px rgba(8, 143, 220, 0.07);
    text-align: center; }
    @media (max-width: 767px) {
      .container_login .form_login_process {
        box-shadow: none !important; } }
    .container_login .form_login_process .form_login_process_number {
      font-size: 25px;
      color: #fff;
      position: relative;
      overflow: hidden;
      height: 100px;
      z-index: 999;
      line-height: 100px;
      z-index: 999;
      width: 100px;
      background: #ddd;
      margin: auto;
      border-radius: 100%; }
    .container_login .form_login_process .form_login_process_load {
      background: #28af6b;
      *border-radius: 100%;
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translate(-50%, 0%);
      height: 100px;
      z-index: -1;
      width: 100px; }
    .container_login .form_login_process p {
      font-size: 18px;
      color: #000;
      margin-top: 10px; }

.form_line {
  position: relative;
  text-align: center;
  margin: 0 0 15px; }
  .form_line:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background: #ddd;
    top: 50%;
    transform: translateY(-50%);
    left: 0; }
  .form_line span {
    position: relative;
    z-index: 0;
    background: #fff;
    padding: 0 20px;
    text-transform: capitalize; }

.btn.btn-login {
  font-size: 13px;
  padding: 11px 8px;
  font-weight: 600;
  border-radius: 0.1875rem;
  color: #fff; }

.btn.btn-login-facebook {
  background: #3065cb;
  margin: 0 0 15px; }
  .btn.btn-login-facebook:hover {
    opacity: .9; }

.btn.btn-login-google {
  background: #e03325; }
  .btn.btn-login-google:hover {
    opacity: .9; }

.react-sweet-progress-circle-outer {
  margin: auto; }

.react-sweet-progress-symbol-absolute .react-sweet-progress-symbol {
  color: #28af6b; }

/*---- Clouds Loading ----*/
#clouds {
  top: 40%;
  position: absolute;
  opacity: 0; }
  @media (max-width: 767px) {
    #clouds {
      display: none; } }

.cloud {
  width: 60px;
  height: 60px;
  animation-delay: 3.5s !important;
  position: relative; }

/*Time to animate*/
.x1 {
  -webkit-animation: moveclouds 6s linear infinite;
  -moz-animation: moveclouds 6s linear infinite;
  -o-animation: moveclouds 6s linear infinite; }

.x2 {
  left: 200px;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
  opacity: 0.6;
  -webkit-animation: moveclouds 8s linear infinite;
  -moz-animation: moveclouds 8s linear infinite;
  -o-animation: moveclouds 8s linear infinite; }

.x3 {
  left: -250px;
  top: -200px;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0.8;
  -webkit-animation: moveclouds 10s linear infinite;
  -moz-animation: moveclouds 10s linear infinite;
  -o-animation: moveclouds 10s linear infinite; }

.x4 {
  left: 470px;
  top: -250px;
  -webkit-transform: scale(0.75);
  -moz-transform: scale(0.75);
  transform: scale(0.75);
  opacity: 0.75;
  -webkit-animation: moveclouds 8s linear infinite;
  -moz-animation: moveclouds 8s linear infinite;
  -o-animation: moveclouds 8s linear infinite; }

.x5 {
  left: -150px;
  top: -150px;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0.8;
  -webkit-animation: moveclouds 20s linear infinite;
  -moz-animation: moveclouds 20s linear infinite;
  -o-animation: moveclouds 20s linear infinite; }

.x6 {
  left: -150px;
  top: -130px;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0.8;
  -webkit-animation: moveclouds 10s linear infinite;
  -moz-animation: moveclouds 10s linear infinite;
  -o-animation: moveclouds 10s linear infinite; }

.x7 {
  left: -150px;
  top: -600px;
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  opacity: 0.9;
  -webkit-animation: moveclouds 10s linear infinite;
  -moz-animation: moveclouds 10s linear infinite;
  -o-animation: moveclouds 10s linear infinite; }

.x8 {
  left: -150px;
  top: -150px;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
  opacity: 0.6;
  -webkit-animation: moveclouds 10s linear infinite;
  -moz-animation: moveclouds 10s linear infinite;
  -o-animation: moveclouds 10s linear infinite; }

.x9 {
  left: -150px;
  bottom: 120px;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
  opacity: 0.5;
  -webkit-animation: moveclouds 11s linear infinite;
  -moz-animation: moveclouds 11s linear infinite;
  -o-animation: moveclouds 11s linear infinite; }

@-webkit-keyframes moveclouds {
  0% {
    left: 0px;
    fill: #fff; }
  20% {
    fill: #d1d1d1; }
  40% {
    fill: #fff; }
  50% {
    fill: #28af6b; }
  100% {
    left: 1366px;
    fill: #fff; } }

@-moz-keyframes moveclouds {
  0% {
    left: 0px;
    color: #fff; }
  20% {
    color: #d1d1d1; }
  40% {
    color: #fff; }
  50% {
    color: #28af6b; }
  100% {
    left: 1366px;
    color: #fff; } }

@-o-keyframes moveclouds {
  0% {
    left: 0px;
    color: #fff; }
  20% {
    color: #d1d1d1; }
  40% {
    color: #fff; }
  50% {
    color: #28af6b; }
  100% {
    left: 1366px;
    color: #fff; } }

/*== Check User ==*/
.container_login_check {
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto; }
  .container_login_check_box {
    display: flex;
    justify-content: 'center';
    align-items: 'center'; }

.avatar_check {
  width: 100px; }

.cssload-loader {
  width: 97px;
  height: 19px;
  position: relative;
  margin: 20px 0 30px; }

.cssload-loader > div {
  content: " ";
  width: 19px;
  height: 19px;
  background: #2196f3;
  border-radius: 100%;
  position: absolute;
  animation: cssload-shift 2.3s linear infinite;
  -o-animation: cssload-shift 2.3s linear infinite;
  -ms-animation: cssload-shift 2.3s linear infinite;
  -webkit-animation: cssload-shift 2.3s linear infinite;
  -moz-animation: cssload-shift 2.3s linear infinite; }

.cssload-loader > div:nth-of-type(1) {
  animation-delay: -0.46s;
  -o-animation-delay: -0.46s;
  -ms-animation-delay: -0.46s;
  -webkit-animation-delay: -0.46s;
  -moz-animation-delay: -0.46s; }

.cssload-loader > div:nth-of-type(2) {
  animation-delay: -0.92s;
  -o-animation-delay: -0.92s;
  -ms-animation-delay: -0.92s;
  -webkit-animation-delay: -0.92s;
  -moz-animation-delay: -0.92s; }

.cssload-loader > div:nth-of-type(3) {
  animation-delay: -1.38s;
  -o-animation-delay: -1.38s;
  -ms-animation-delay: -1.38s;
  -webkit-animation-delay: -1.38s;
  -moz-animation-delay: -1.38s; }

.cssload-loader > div:nth-of-type(4) {
  animation-delay: -1.84s;
  -o-animation-delay: -1.84s;
  -ms-animation-delay: -1.84s;
  -webkit-animation-delay: -1.84s;
  -moz-animation-delay: -1.84s; }

@keyframes cssload-shift {
  0% {
    left: -58px;
    opacity: 0;
    background-color: yellow; }
  10% {
    left: 0;
    opacity: 1; }
  90% {
    left: 97px;
    opacity: 1; }
  100% {
    left: 156px;
    background-color: red;
    opacity: 0; } }

@-o-keyframes cssload-shift {
  0% {
    left: -58px;
    opacity: 0;
    background-color: yellow; }
  10% {
    left: 0;
    opacity: 1; }
  90% {
    left: 97px;
    opacity: 1; }
  100% {
    left: 156px;
    background-color: red;
    opacity: 0; } }

@-ms-keyframes cssload-shift {
  0% {
    left: -58px;
    opacity: 0;
    background-color: yellow; }
  10% {
    left: 0;
    opacity: 1; }
  90% {
    left: 97px;
    opacity: 1; }
  100% {
    left: 156px;
    background-color: red;
    opacity: 0; } }

@-webkit-keyframes cssload-shift {
  0% {
    left: -58px;
    opacity: 0;
    background-color: yellow; }
  10% {
    left: 0;
    opacity: 1; }
  90% {
    left: 97px;
    opacity: 1; }
  100% {
    left: 156px;
    background-color: red;
    opacity: 0; } }

@-moz-keyframes cssload-shift {
  0% {
    left: -58px;
    opacity: 0;
    background-color: yellow; }
  10% {
    left: 0;
    opacity: 1; }
  90% {
    left: 97px;
    opacity: 1; }
  100% {
    left: 156px;
    background-color: red;
    opacity: 0; } }

.personnel_list {
  display: flex;
  flex-direction: column; }
  .personnel_list_btn {
    display: flex;
    margin-bottom: 15px; }
    .personnel_list_btn .form-group {
      margin: 0 0 15px; }
    .personnel_list_btn button {
      margin: 0 15px 0 0; }
  .personnel_list_form {
    max-height: 0;
    -webkit-transition: max-height .2s ease-in;
    -o-transition: max-height .2s ease-in;
    transition: max-height .2s ease;
    margin-top: 10px;
    display: none; }
    .personnel_list_form.show {
      max-height: 500px;
      display: block; }

.personnel_list_content.scroll_bar {
  max-height: 407px;
  overflow-y: scroll;
  overflow-x: hidden; }

.add_customer_info_bpos .form-group {
  margin: 15px 0; }

.add_customer_address_bpos label {
  margin: 15px 0; }

.add_customer_address_bpos .form-group {
  margin: 0; }

.add_customer_address_bpos .edit_avatar_upload_image {
  margin: 0; }

.add_customer_address_bpos .product_upload_image_child {
  width: 100%; }

.add_customer_address_bpos .product_upload_image_child0 {
  padding: 0 !important;
  height: 185px;
  width: 100%; }
  .add_customer_address_bpos .product_upload_image_child0_img {
    height: 182px !important;
    display: flex;
    align-items: center;
    justify-content: center; }

.add_customer_address_bpos .modal {
  overflow: inherit; }

.add_customer_address_bpos .right-two-tag-title {
  cursor: pointer;
  margin: 15px 0 7px;
  font-weight: bold; }

.add_customer_address_bpos .right-two-tag-input {
  margin: 3px 0; }

.add_customer_address_bpos .tag_root .tag {
  margin: 10px 5px 5px 0; }

.add_customer_address_bpos .tag_root .tag span {
  float: none;
  display: inline; }

.add_customer_address_bpos .right-two-tag-input .form-group {
  margin: 0; }

.promissory_note_refesh .form-group:last-child {
  margin: 0 0 15px; }

.edit_user_upload_image_box {
  height: 171px;
  border: dashed 1px #e5e5e5;
  border-radius: 6px; }
  .edit_user_upload_image_box p {
    padding: 10px; }
  .edit_user_upload_image_box img {
    width: 100%;
    height: 100%;
    object-fit: scale-down; }

/*-----Modules Setting-------*/
.container_welcome {
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;
  flex: 1; }
  .container_welcome .form_welcome {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    background: #ffffff;
    padding: 40px 40px 30px;
    border-radius: 4px;
    -webkit-box-shadow: 0 -25px 37.7px 11.3px rgba(8, 143, 220, 0.07);
    box-shadow: 0 -25px 37.7px 11.3px rgba(8, 143, 220, 0.07); }
    @media (max-width: 640px) {
      .container_welcome .form_welcome {
        padding: 0px 20px 10px;
        box-shadow: none; } }
    .container_welcome .form_welcome .avatar_form {
      height: 120px;
      width: 120px;
      margin: auto; }
      .container_welcome .form_welcome .avatar_form img {
        border-radius: 50%;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); }
    .container_welcome .form_welcome .email {
      position: relative;
      height: 120px;
      display: flex;
      align-items: center;
      *justify-content: center; }
    .container_welcome .form_welcome .title_email {
      font-weight: 600; }
    .container_welcome .form_welcome .content_welcome {
      padding: 20px 0 0 0; }
      .container_welcome .form_welcome .content_welcome .desc {
        margin-bottom: 12px;
        line-height: 25px; }
      .container_welcome .form_welcome .content_welcome .using_serice .nxt_checkbox {
        float: left;
        padding-right: 5px; }
    .container_welcome .form_welcome .btn {
      margin: 0;
      width: 100%;
      height: auto;
      font-size: 13px;
      padding: 9px 8px;
      font-weight: 600;
      border-radius: 0.1875rem; }
    .container_welcome .form_welcome .css-1rtrksz {
      padding: 9px 8px; }

.navigation {
  background: #1cb467;
  display: flex;
  flex: 1;
  flex-direction: row;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10; }
  .navigation_mobile {
    display: flex;
    flex: 7 1; }
  .navigation_setting_user_name {
    display: none; }
  .navigation_sell {
    position: static; }
  .navigation_themes_color_box_list {
    display: block !important;
    margin: 0 0px 10px !important; }
    .navigation_themes_color_box_list li {
      padding: 0 7px; }
      .navigation_themes_color_box_list li span {
        border: 1px solid #ddd;
        width: 100%;
        float: left;
        text-align: center;
        padding: 8px 0;
        border-radius: 100px; }
      .navigation_themes_color_box_list li.active span, .navigation_themes_color_box_list li span:hover {
        background: #121212;
        color: #fff;
        border-color: #121212; }
  .navigation ul {
    list-style: none;
    list-style: none;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0; }
  .navigation i {
    color: white; }
  .navigation li {
    position: relative; }
    .navigation li a {
      color: #fff;
      padding: 16px 15px;
      display: block; }
  .navigation .navigation_logo {
    width: 110px;
    margin: 10px 15px; }
  .navigation .navigation_master {
    display: flex;
    position: relative;
    flex: 1;
    border-right: 1px solid #28af6b; }
    .navigation .navigation_master button {
      flex: 1;
      background: none;
      border: 0;
      color: white; }
    .navigation .navigation_master .navigation_master_content {
      display: none;
      position: absolute;
      left: 0;
      top: 50px;
      width: 718px;
      height: auto;
      z-index: 123;
      background: #2b2b2b;
      overflow: hidden; }
      .navigation .navigation_master .navigation_master_content ul {
        display: table;
        width: 100%; }
        .navigation .navigation_master .navigation_master_content ul li {
          display: block;
          float: left;
          width: 25%;
          border-right: 1px solid #28af6b;
          border-bottom: 1px solid #28af6b; }
          .navigation .navigation_master .navigation_master_content ul li a {
            text-align: center; }
            .navigation .navigation_master .navigation_master_content ul li a i {
              clear: both;
              display: block; }
        .navigation .navigation_master .navigation_master_content ul li:nth-child(5) {
          clear: both; }
  .navigation .navigation_menu {
    display: flex;
    flex: 7; }
    .navigation .navigation_menu ul li.menu_item {
      position: relative; }
      .navigation .navigation_menu ul li.menu_item a {
        padding: 16px 15px;
        *border-right: 1px solid #28af6b;
        transition: all 0.2s ease; }
      .navigation .navigation_menu ul li.menu_item span {
        padding: 16px 15px;
        display: block;
        color: #fff;
        cursor: pointer; }
        .navigation .navigation_menu ul li.menu_item span i {
          color: white;
          font-style: normal; }
      .navigation .navigation_menu ul li.menu_item:hover a {
        color: white !important;
        background: #006837; }
        .navigation .navigation_menu ul li.menu_item:hover a:hover {
          background: #006837; }
      .navigation .navigation_menu ul li.menu_item:hover .sub_menu {
        opacity: 1;
        visibility: visible;
        z-index: 9;
        transform: translateY(0); }
    .navigation .navigation_menu ul li.menu_item_master i {
      position: relative;
      top: auto;
      right: auto;
      padding: 15px; }
    .navigation .navigation_menu ul li.menu_item_master:hover a {
      background: black; }
    .navigation .navigation_menu ul li.menu_item_master:hover i {
      color: white; }
    .navigation .navigation_menu ul .sub_menu {
      position: absolute;
      left: 0;
      top: 50px;
      background: #1cb467;
      min-width: 200px;
      transition: all 0.2s ease;
      opacity: 0;
      visibility: hidden;
      z-index: 9;
      display: block;
      transform: translateY(50px); }
      .navigation .navigation_menu ul .sub_menu.show {
        display: block; }
      .navigation .navigation_menu ul .sub_menu li a {
        border-bottom: 1px solid rgba(28, 180, 103, 0.5); }
        .navigation .navigation_menu ul .sub_menu li a:hover {
          background: #1cb467 !important; }
  .navigation .navigation_notification {
    display: flex;
    border-right: 1px solid #28af6b;
    justify-content: flex-end;
    align-items: center; }
    .navigation .navigation_notification_box {
      float: right;
      padding: 0 10px;
      position: relative;
      cursor: pointer; }
      .navigation .navigation_notification_box i {
        font-size: 20px; }
      .navigation .navigation_notification_box .badge {
        position: absolute;
        top: -9px;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 3px;
        font-size: 9px;
        padding: 0;
        min-width: 15px;
        background: #FF0017; }
    .navigation .navigation_notification_list {
      position: absolute;
      top: 36px;
      background: #fff;
      width: 300px;
      left: -120px;
      border: 1px solid rgba(100, 100, 100, 0.4);
      border-radius: 0 0 2px 2px;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
      color: #1d2129;
      -webkit-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      transition: all 0.2s ease;
      opacity: 0;
      visibility: hidden;
      transform: translateY(0);
      z-index: 2; }
      .navigation .navigation_notification_list:before {
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        position: absolute;
        top: -10px;
        left: 128px; }
      .navigation .navigation_notification_list > p {
        border-bottom: 1px solid #ddd;
        padding: 10px;
        font-weight: 600;
        font-size: 13px;
        color: #000;
        margin: 0; }
      .navigation .navigation_notification_list_see_all {
        padding: 10px 0 !important;
        width: 100%;
        float: left;
        border: none !important; }
        .navigation .navigation_notification_list_see_all:hover {
          background: #efefef; }
      .navigation .navigation_notification_list_main {
        display: block !important;
        max-height: 400px;
        overflow-y: auto; }
        .navigation .navigation_notification_list_main a {
          padding: 0;
          color: #000; }
        .navigation .navigation_notification_list_main li {
          padding: 10px 0;
          display: block;
          float: left;
          width: 100%;
          border-bottom: 1px solid #f7f7f7; }
          .navigation .navigation_notification_list_main li:nth-child(even) {
            background-color: #f3f3f34d; }
          .navigation .navigation_notification_list_main li:hover {
            background: #efefef; }
      .navigation .navigation_notification_list_ava {
        width: 50px;
        height: 50px;
        float: left;
        margin: 0 10px; }
        .navigation .navigation_notification_list_ava img {
          border-radius: 50px; }
      .navigation .navigation_notification_list_info {
        display: table; }
        .navigation .navigation_notification_list_info p {
          margin: 0 0 5px;
          max-height: 36px;
          overflow: hidden;
          font-size: 12px; }
        .navigation .navigation_notification_list_info time, .navigation .navigation_notification_list_info .la {
          font-size: 12px;
          color: #90949c; }
    .navigation .navigation_notification_box:hover .navigation_notification_list {
      opacity: 1;
      visibility: visible;
      transform: translateY(0); }
  .navigation .navigation_change_color {
    position: relative;
    display: flex;
    justify-content: flex-end;
    color: #fff;
    align-items: center;
    border-right: 1px solid #28af6b;
    padding: 0 10px;
    cursor: pointer; }
    .navigation .navigation_change_color img {
      width: 18px;
      height: 18px; }
    .navigation .navigation_change_color .la {
      font-size: 18px;
      margin: 0 5px 0 0; }
    .navigation .navigation_change_color:hover .navigation_change_color_box {
      opacity: 1;
      visibility: visible;
      transform: translateY(0); }
    .navigation .navigation_change_color_box {
      position: absolute;
      top: 50px;
      background: #fff;
      width: 200px;
      left: -59px;
      border: 1px solid rgba(100, 100, 100, 0.4);
      border-radius: 0 0 2px 2px;
      -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
      color: #1d2129;
      -webkit-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      transition: all 0.2s ease;
      opacity: 0;
      visibility: hidden;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
      z-index: 3; }
      .navigation .navigation_change_color_box:before {
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        position: absolute;
        top: -10px;
        left: 67px; }
      .navigation .navigation_change_color_box p {
        padding: 10px;
        font-weight: 600;
        margin: 0; }
      .navigation .navigation_change_color_box_list {
        display: block !important;
        margin: 0 5px !important; }
        .navigation .navigation_change_color_box_list li {
          padding: 0 5px;
          margin: 0 0 10px; }
          .navigation .navigation_change_color_box_list li.active:before {
            content: "\f17b";
            font: normal normal normal 16px/1 LineAwesome;
            font-weight: bold;
            font-size: 20px;
            line-height: 50px;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            color: #fff;
            text-align: center; }
          .navigation .navigation_change_color_box_list li span {
            height: 50px;
            background: red;
            display: block;
            width: 100%; }
  .navigation .navigation_setting {
    display: flex;
    padding: 0 15px 0 0;
    color: #fff; }
    .navigation .navigation_setting_user {
      display: flex;
      padding: 0 15px 0 0;
      position: relative; }
      .navigation .navigation_setting_user span {
        display: table; }
        .navigation .navigation_setting_user span h3 {
          margin: 10px 22px 3px 0;
          color: #fff;
          font-size: 13px;
          font-weight: 600;
          text-transform: capitalize;
          white-space: pre;
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis; }
        .navigation .navigation_setting_user span p {
          font-size: 10px;
          margin: 0 10px 0 0; }
        .navigation .navigation_setting_user span i {
          position: absolute;
          top: 50%;
          right: 5px;
          transform: translateY(-50%);
          font-size: 20px; }
    .navigation .navigation_setting .navigation_setting_sub {
      position: absolute;
      z-index: 10;
      right: 0;
      top: 50px;
      visibility: hidden;
      opacity: 0;
      background: #1cb467;
      display: table;
      min-width: 200px;
      transition: all 0.2s ease;
      transform: translateY(50px); }
      .navigation .navigation_setting .navigation_setting_sub a:hover {
        color: #fff !important; }
      .navigation .navigation_setting .navigation_setting_sub i {
        padding-right: 8px; }
      .navigation .navigation_setting .navigation_setting_sub li {
        border-top: 1px solid #28af6b; }
        .navigation .navigation_setting .navigation_setting_sub li .btn.btn-none {
          color: #fff !important;
          padding: 15px 0 15px 10px;
          font-weight: 400; }
          .navigation .navigation_setting .navigation_setting_sub li .btn.btn-none i {
            margin-right: 0;
            padding-right: 8px;
            color: #fff !important; }
        .navigation .navigation_setting .navigation_setting_sub li:hover {
          background: #006837; }
    .navigation .navigation_setting li {
      width: 100%; }
      .navigation .navigation_setting li:hover .navigation_setting_sub {
        visibility: inherit;
        opacity: 1;
        transform: translateY(0); }
      .navigation .navigation_setting li .navigation_setting_img {
        float: left;
        width: 35px;
        height: 35px;
        padding: 0;
        margin: 7px 7px 7px 10px; }
        .navigation .navigation_setting li .navigation_setting_img img {
          max-width: 35px;
          max-height: 35px;
          border-radius: 50%;
          object-fit: scale-down; }

.setting_profile {
  padding: 15px 0 0 0;
  position: relative; }
  .setting_profile button {
    position: absolute;
    bottom: -63px;
    right: 125px;
    z-index: 999; }
  .setting_profile.file button {
    bottom: -63px; }
  .setting_profile_tab2 .form-group:nth-child(3) {
    margin: 0; }
  .setting_profile_tab3 .form-group:first-child {
    margin: 0; }

.depots {
  width: 200px;
  border-right: 1px solid #28af6b;
  padding: 8px 10px; }
  .depots .css-1aya2g8 {
    border: none !important; }

.panel_modal {
  width: 400px;
  box-shadow: -30px 0px 30px -30px rgba(0, 0, 0, 0.2);
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  top: 0;
  right: -100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 11;
  transition: all ease .3s;
  line-height: 25px; }
  .panel_modal.show {
    right: -1px; }
  .panel_modal_close {
    width: 100%;
    overflow: hidden;
    background: #00b85e;
    padding: 12px 30px; }
    .panel_modal_close span {
      float: left;
      color: #fff; }
    .panel_modal_close i {
      float: right;
      color: #fff;
      font-size: 20px;
      cursor: pointer; }
      .panel_modal_close i:hover {
        color: #eaeaea; }
  .panel_modal_list {
    display: block !important;
    padding: 0 30px 0px !important;
    margin: 0 0 30px !important;
    overflow-y: auto; }
    .panel_modal_list li {
      border-bottom: 1px solid #f5f5f5;
      position: relative;
      list-style: none; }
      .panel_modal_list li a {
        color: #000;
        padding: 10px 0;
        overflow: hidden;
        display: table; }
        .panel_modal_list li a:hover {
          color: #28af6b !important; }
    .panel_modal_list span {
      padding: 0 0 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 200px;
      display: block; }
    .panel_modal_list .price_modal {
      color: red;
      padding: 0 0 0 10px;
      margin: 0; }
      .panel_modal_list .price_modal span {
        display: inline;
        padding: 0 3px !important;
        color: #000; }
    .panel_modal_list .delete_item {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }
      .panel_modal_list .delete_item .la {
        color: #6f6f6f;
        font-size: 20px; }
        .panel_modal_list .delete_item .la:hover {
          opacity: .5; }
    .panel_modal_list .form-group {
      margin: 0 10px;
      width: 90px; }
      .panel_modal_list .form-group input {
        height: 25px !important; }
    .panel_modal_list_ava {
      width: 50px;
      height: 50px;
      display: table-cell;
      margin: 0 10px 0 0;
      vertical-align: middle; }
      .panel_modal_list_ava img {
        border-radius: 50px; }
    .panel_modal_list .delete-list {
      position: absolute;
      right: 0;
      top: 20px;
      color: red;
      font-size: 16px;
      cursor: pointer; }

.open_panel_fixed {
  position: fixed;
  top: 60px;
  z-index: 3;
  right: 15px;
  background: #1eb367;
  color: #fff;
  padding: 20px 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  border-radius: 6px 0px 0 6px; }
  .open_panel_fixed .la {
    font-size: 24px; }
  .open_panel_fixed span {
    position: absolute;
    top: 15%;
    width: 17px;
    height: 17px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 3px;
    font-size: 9px;
    padding: 0;
    min-width: 15px;
    background: #FF0017; }

.theme-red span {
  background: #ff7676 !important; }

.theme-default span {
  background: #28af6b !important; }

.theme-blue span {
  background: #2cabe3 !important; }

.theme-purple span {
  background: #707cd2 !important; }

.theme-oranger span {
  background: #E67E22 !important; }

.theme-dark span {
  background: #4c5667 !important; }

.theme_default .sc-bxivhb {
  background: #28af6b !important; }

/*-- Themes Red ---*/
.theme_red .navigation .navigation_setting .navigation_setting_sub li, .theme_red .navigation .navigation_menu ul li.menu_item a, .theme_red .navigation .navigation_setting .navigation_setting_sub a, .theme_red .navigation .navigation_change_color, .theme_red .navigation .navigation_notification,
.theme_red .depots {
  border-color: #e44148; }

.theme_red .ReactVirtualized__Grid__innerScrollContainer .active {
  background: rgba(255, 107, 113, 0.2) !important; }

.theme_red .bpos_box_sell_right_box .form-group-sale-box-btn a.active, .theme_red .bpos_box_sell_right_box .form-group-sale-box-btn a:hover, .theme_red .bpos_box_sell_left_toggle, .theme_red .ReactVirtualized__Grid__innerScrollContainer .col-md-2 .input-content:after, .theme_red .product_upload_image_child0_avatar, .theme_red .bpos_box_sell_right_box_payment .form-group .input-content:after,
.theme_red .bpos_box_sell_left_action_list li:hover, .theme_red .bpos_box_sell_right_list_table tbody :nth-child(4) .form-group .input-content:after, .theme_red .bpos_list_pr_box_img p, .theme_red .bpos_box_sell_left_action:after, .theme_red .bpos_box_sell_right_box_title h2,
.theme_red .label_checkbox input:checked ~ .checkmark, .theme_red .product_sort .product_sort_child, .theme_red .popconfirm_content_btn button.primary, .theme_red .setting_on_off .setting_on_off_ctrol.active, .theme_red .btn.btn-primary, .theme_red table thead, .theme_red .content_menu_item_child_item.active,
.theme_red .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item:hover, .theme_red .panel_modal_close, .theme_red .navigation, .theme_red .navigation .navigation_menu ul .sub_menu, .theme_red .navigation .navigation_menu ul li.menu_item ul.sub_menu li a:hover,
.theme_red .navigation .navigation_setting .navigation_setting_sub,
.theme_red .order_save_check,
.theme_red .return_title,
.theme_red .open_panel_fixed,
.theme_red .add_customer_sell,
.theme_red .sc-bxivhb,
.theme_red .bnc_dashboard_aff .bpos_dashboard_current_balance {
  background: #ff7676 !important; }

.theme_red .order_transport_title {
  border-color: #ff7676 !important; }

.theme_red .bpos_box_sell_right_box_search input:hover, .theme_red .bpos_box_sell_right_box_price_table, .theme_red .bpos_list_pr_box_img, .theme_red .bpos_box_sell_left_box, .theme_red .bpos_box_sell_right_box, .theme_red .output_complete_search, .theme_red .slider2 .image-detail-small:hover, .theme_red .slider2 .slick-current .image-detail-small, .theme_red .navigation .navigation_menu ul .sub_menu li a,
.theme_red .product_sort_child.item_width button:not(:last-child) {
  border-color: #ff7676 !important; }

.theme_red .react-sweet-progress-symbol-absolute .react-sweet-progress-symbol, .theme_red .product_upload_image_child0_del, .theme_red .la-credit-card, .theme_red .color-green, .theme_red .cell_price_sell_bpos, .theme_red .la-success, .theme_red .navigation_icon_search_sell i, .theme_red .k-content .slick-dots li:hover button:before, .theme_red .k-content .slick-dots li.slick-active button:before, .theme_red .k-content .title, .theme_red .btn.btn-none:hover, .theme_red .btn.btn-none i, .theme_red .panel_modal_list li a:hover, .theme_red .bpos_dashboard_this_month_revenue .css-1492t68, .theme_red .bpos_dashboard_selling_title span, .theme_red .bpos_dashboard_recent_activities_list ul li a {
  color: #ff7676 !important; }

.theme_red .product_sort .product_sort_child .label_checkbox .checkmark, .theme_red .navigation .navigation_setting .navigation_setting_sub li:hover, .theme_red .navigation .navigation_menu ul li.menu_item:hover a:hover, .theme_red .navigation .navigation_menu ul li.menu_item:hover a {
  background: #e44148 !important; }

.theme_red .btn.btn-success, .theme_red .pagination > li > a:hover, .theme_red .pagination > li > span:hover, .theme_red .pagination > li > a:focus,
.theme_red .pagination > li > span:focus, .theme_red .pagination > .active > a, .theme_red .pagination > .active > span,
.theme_red .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus,
.theme_red .pagination > .active > span:focus {
  background: #ff7676 !important;
  border-color: #ff7676 !important; }

.theme_red .navigation_list_invoice, .theme_red .bpos_sell_network_mode {
  background: #f35d63; }

.theme_red .navigation_list_invoice.active button, .theme_red .navigation_list_invoice.active .la,
.theme_red .navigation_list_invoice:hover button, .theme_red .navigation_list_invoice:hover .la, .theme_red .navigation_list_invoice.active, .theme_red .navigation_list_invoice:hover {
  color: #f35d63; }

.theme_red .bpos_price_list_time ul li:first-child a {
  color: #ff7676;
  border-color: #ff7676; }

.theme_red .bpos_price_list_time ul li.active a, .theme_red .bpos_price_list_time ul li:hover a {
  background: #ff7676; }

.theme_red .bpos_price_list_time ul li:last-child a {
  color: #ff7676;
  border-color: #ff7676; }

.theme_red .bpos_price_list_box_name {
  background: linear-gradient(45deg, #ff7676, #e07981); }

.theme_red .bpos_price_list_box_price {
  background: #e85866; }

.theme_red .label_checkbox .checkmark {
  border-color: #ff7676; }

.theme_red .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input .form-group-sale-box-btn a.active {
  background: #ff7676 !important; }

/*-- Themes Blue ---*/
.theme_blue .navigation .navigation_setting .navigation_setting_sub li, .theme_blue .navigation .navigation_menu ul li.menu_item a, .theme_blue .navigation .navigation_setting .navigation_setting_sub a, .theme_blue .navigation .navigation_change_color, .theme_blue .navigation .navigation_notification,
.theme_blue .depots {
  border-color: #009bd0; }

.theme_blue .ReactVirtualized__Grid__innerScrollContainer .active {
  background: rgba(0, 173, 232, 0.2) !important; }

.theme_blue .bpos_box_sell_right_box .form-group-sale-box-btn a.active, .theme_blue .bpos_box_sell_right_box .form-group-sale-box-btn a:hover, .theme_blue .bpos_box_sell_left_toggle, .theme_blue .ReactVirtualized__Grid__innerScrollContainer .col-md-2 .input-content:after, .theme_blue .product_upload_image_child0_avatar, .theme_blue .bpos_box_sell_right_box_payment .form-group .input-content:after, .theme_blue .bpos_box_sell_left_action_list li:hover, .theme_blue .bpos_box_sell_right_list_table tbody :nth-child(4) .form-group .input-content:after,
.theme_blue .bpos_list_pr_box_img p, .theme_blue .bpos_box_sell_left_action:after, .theme_blue .bpos_box_sell_right_box_title h2, .theme_blue .label_checkbox input:checked ~ .checkmark, .theme_blue .product_sort .product_sort_child, .theme_blue .popconfirm_content_btn button.primary, .theme_blue .setting_on_off .setting_on_off_ctrol.active,
.theme_blue .btn.btn-primary, .theme_blue table thead, .theme_blue .content_menu_item_child_item.active, .theme_blue .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item:hover, .theme_blue .panel_modal_close, .theme_blue .navigation, .theme_blue .navigation .navigation_menu ul .sub_menu, .theme_blue .navigation .navigation_menu ul li.menu_item ul.sub_menu li a:hover,
.theme_blue .navigation .navigation_setting .navigation_setting_sub,
.theme_blue .order_save_check,
.theme_blue .return_title,
.theme_blue .open_panel_fixed, .theme_blue .add_customer_sell,
.theme_blue .sc-bxivhb,
.theme_blue .bnc_dashboard_aff .bpos_dashboard_current_balance {
  background: #2cabe3 !important; }

.theme_blue .order_transport_title {
  border-left-color: #2cabe3 !important; }

.theme_blue .bpos_box_sell_right_box_search input:hover,
.theme_blue .bpos_box_sell_right_box_price_table, .theme_blue .bpos_list_pr_box_img,
.theme_blue .bpos_box_sell_left_box, .theme_blue .bpos_box_sell_right_box,
.theme_blue .output_complete_search, .theme_blue .slider2 .image-detail-small:hover,
.theme_blue .slider2 .slick-current .image-detail-small, .theme_blue .navigation .navigation_menu ul .sub_menu li a,
.theme_blue .product_sort_child.item_width button:not(:last-child) {
  border-color: #2cabe3 !important; }

.theme_blue .react-sweet-progress-symbol-absolute .react-sweet-progress-symbol, .theme_blue .product_upload_image_child0_del, .theme_blue .la-credit-card, .theme_blue .color-green, .theme_blue .cell_price_sell_bpos, .theme_blue .la-success, .theme_blue .navigation_icon_search_sell i, .theme_blue .k-content .slick-dots li:hover button:before, .theme_blue .k-content .slick-dots li.slick-active button:before, .theme_blue .k-content .title, .theme_blue .btn.btn-none:hover, .theme_blue .btn.btn-none i, .theme_blue .panel_modal_list li a:hover, .theme_blue .bpos_dashboard_this_month_revenue .css-1492t68, .theme_blue .bpos_dashboard_selling_title span, .theme_blue .bpos_dashboard_recent_activities_list ul li a {
  color: #2cabe3 !important; }

.theme_blue .product_sort .product_sort_child .label_checkbox .checkmark, .theme_blue .navigation .navigation_setting .navigation_setting_sub li:hover, .theme_blue .navigation .navigation_menu ul li.menu_item:hover a:hover, .theme_blue .navigation .navigation_menu ul li.menu_item:hover a {
  background: #009bd0 !important; }

.theme_blue .btn.btn-success, .theme_blue .pagination > li > a:hover, .theme_blue .pagination > li > span:hover, .theme_blue .pagination > li > a:focus,
.theme_blue .pagination > li > span:focus, .theme_blue .pagination > .active > a, .theme_blue .pagination > .active > span,
.theme_blue .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus,
.theme_blue .pagination > .active > span:focus {
  background: #2cabe3 !important;
  border-color: #2cabe3 !important; }

.theme_blue .navigation_list_invoice, .theme_blue .bpos_sell_network_mode {
  background: #1abcf3; }

.theme_blue .navigation_list_invoice.active button, .theme_blue .navigation_list_invoice.active .la,
.theme_blue .navigation_list_invoice:hover button, .theme_blue .navigation_list_invoice:hover .la, .theme_blue .navigation_list_invoice.active, .theme_blue .navigation_list_invoice:hover {
  color: #00ade8; }

.theme_blue .bpos_price_list_time ul li:first-child a {
  color: #2cabe3;
  border-color: #2cabe3; }

.theme_blue .bpos_price_list_time ul li.active a, .theme_blue .bpos_price_list_time ul li:hover a {
  background: #2cabe3; }

.theme_blue .bpos_price_list_time ul li:last-child a {
  color: #2cabe3;
  border-color: #2cabe3; }

.theme_blue .bpos_price_list_box_name {
  background: linear-gradient(45deg, #2cabe3, #0089b7); }

.theme_blue .bpos_price_list_box_price {
  background: #0087b7; }

.theme_blue .label_checkbox .checkmark {
  border-color: #2cabe3; }

.theme_blue .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input .form-group-sale-box-btn a.active {
  background: #2cabe3 !important; }

/*-- Themes Purple ---*/
.theme_purple .navigation .navigation_setting .navigation_setting_sub li, .theme_purple .navigation .navigation_menu ul li.menu_item a, .theme_purple .navigation .navigation_setting .navigation_setting_sub a, .theme_purple .navigation .navigation_change_color, .theme_purple .navigation .navigation_notification,
.theme_purple .depots {
  border-color: #6875dc; }

.theme_purple .ReactVirtualized__Grid__innerScrollContainer .active {
  background: rgba(110, 122, 216, 0.2) !important; }

.theme_purple .bpos_box_sell_right_box .form-group-sale-box-btn a.active, .theme_purple .bpos_box_sell_right_box .form-group-sale-box-btn a:hover, .theme_purple .bpos_box_sell_left_toggle, .theme_purple .ReactVirtualized__Grid__innerScrollContainer .col-md-2 .input-content:after, .theme_purple .product_upload_image_child0_avatar, .theme_purple .bpos_box_sell_right_box_payment .form-group .input-content:after, .theme_purple .bpos_box_sell_left_action_list li:hover, .theme_purple .bpos_box_sell_right_list_table tbody :nth-child(4) .form-group .input-content:after,
.theme_purple .bpos_list_pr_box_img p, .theme_purple .bpos_box_sell_left_action:after, .theme_purple .bpos_box_sell_right_box_title h2, .theme_purple .label_checkbox input:checked ~ .checkmark, .theme_purple .product_sort .product_sort_child,
.theme_purple .popconfirm_content_btn button.primary, .theme_purple .setting_on_off .setting_on_off_ctrol.active, .theme_purple .btn.btn-primary, .theme_purple table thead, .theme_purple .content_menu_item_child_item.active,
.theme_purple .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item:hover, .theme_purple .panel_modal_close, .theme_purple .navigation, .theme_purple .navigation .navigation_menu ul .sub_menu, .theme_purple .navigation .navigation_menu ul li.menu_item ul.sub_menu li a:hover,
.theme_purple .navigation .navigation_setting .navigation_setting_sub,
.theme_purple .order_save_check,
.theme_purple .return_title,
.theme_purple .open_panel_fixed,
.theme_purple .add_customer_sell,
.theme_purple .sc-bxivhb,
.theme_purple .bnc_dashboard_aff .bpos_dashboard_current_balance {
  background: #707cd2 !important; }

.theme_purple .order_transport_title {
  border-left-color: #707cd2 !important; }

.theme_purple .bpos_box_sell_right_box_search input:hover, .theme_purple .bpos_box_sell_right_box_price_table, .theme_purple .bpos_list_pr_box_img, .theme_purple .bpos_box_sell_left_box, .theme_purple .bpos_box_sell_right_box, .theme_purple .output_complete_search, .theme_purple .slider2 .image-detail-small:hover, .theme_purple .slider2 .slick-current .image-detail-small, .theme_purple .navigation .navigation_menu ul .sub_menu li a,
.theme_purple .product_sort_child.item_width button:not(:last-child) {
  border-color: #707cd2 !important; }

.theme_purple .react-sweet-progress-symbol-absolute .react-sweet-progress-symbol, .theme_purple .product_upload_image_child0_del, .theme_purple .la-credit-card, .theme_purple .color-green, .theme_purple .cell_price_sell_bpos, .theme_purple .la-success, .theme_purple .navigation_icon_search_sell i, .theme_purple .k-content .slick-dots li:hover button:before, .theme_purple .k-content .slick-dots li.slick-active button:before, .theme_purple .k-content .title, .theme_purple .btn.btn-none:hover, .theme_purple .btn.btn-none i, .theme_purple .panel_modal_list li a:hover, .theme_purple .bpos_dashboard_this_month_revenue .css-1492t68, .theme_purple .bpos_dashboard_selling_title span, .theme_purple .bpos_dashboard_recent_activities_list ul li a {
  color: #707cd2 !important; }

.theme_purple .product_sort .product_sort_child .label_checkbox .checkmark, .theme_purple .navigation .navigation_setting .navigation_setting_sub li:hover, .theme_purple .navigation .navigation_menu ul li.menu_item:hover a:hover, .theme_purple .navigation .navigation_menu ul li.menu_item:hover a {
  background: #6875dc !important; }

.theme_purple .btn.btn-success, .theme_purple .pagination > li > a:hover, .theme_purple .pagination > li > span:hover, .theme_purple .pagination > li > a:focus,
.theme_purple .pagination > li > span:focus, .theme_purple .pagination > .active > a, .theme_purple .pagination > .active > span,
.theme_purple .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus,
.theme_purple .pagination > .active > span:focus {
  background: #707cd2 !important;
  border-color: #707cd2 !important; }

.theme_purple .navigation_list_invoice, .theme_purple .bpos_sell_network_mode {
  background: #5d69c7; }

.theme_purple .navigation_list_invoice.active button, .theme_purple .navigation_list_invoice.active .la,
.theme_purple .navigation_list_invoice:hover button, .theme_purple .navigation_list_invoice:hover .la, .theme_purple .navigation_list_invoice.active, .theme_purple .navigation_list_invoice:hover {
  color: #5d69c7; }

.theme_purple .bpos_price_list_time ul li:first-child a {
  color: #707cd2;
  border-color: #707cd2; }

.theme_purple .bpos_price_list_time ul li.active a, .theme_purple .bpos_price_list_time ul li:hover a {
  background: #707cd2; }

.theme_purple .bpos_price_list_time ul li:last-child a {
  color: #707cd2;
  border-color: #707cd2; }

.theme_purple .bpos_price_list_box_name {
  background: linear-gradient(45deg, #707cd2, #8e97ca); }

.theme_purple .bpos_price_list_box_price {
  background: #8690d2; }

.theme_purple .label_checkbox .checkmark {
  border-color: #707cd2; }

.theme_purple .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input .form-group-sale-box-btn a.active {
  background: #707cd2 !important; }

/*-- Themes Oranger ---*/
.theme_oranger .navigation .navigation_setting .navigation_setting_sub li, .theme_oranger .navigation .navigation_menu ul li.menu_item a, .theme_oranger .navigation .navigation_setting .navigation_setting_sub a, .theme_oranger .navigation .navigation_change_color, .theme_oranger .navigation .navigation_notification,
.theme_oranger .depots {
  border-color: #e06f03; }

.theme_oranger .bpos_box_sell_right_box .form-group-sale-box-btn a.active, .theme_oranger .bpos_box_sell_right_box .form-group-sale-box-btn a:hover, .theme_oranger .bpos_box_sell_left_toggle, .theme_oranger .ReactVirtualized__Grid__innerScrollContainer .col-md-2 .input-content:after, .theme_oranger .product_upload_image_child0_avatar, .theme_oranger .bpos_box_sell_right_box_payment .form-group .input-content:after, .theme_oranger .bpos_box_sell_left_action_list li:hover, .theme_oranger .bpos_box_sell_right_list_table tbody :nth-child(4) .form-group .input-content:after, .theme_oranger .bpos_list_pr_box_img p, .theme_oranger .bpos_box_sell_left_action:after, .theme_oranger .bpos_box_sell_right_box_title h2, .theme_oranger .label_checkbox input:checked ~ .checkmark,
.theme_oranger .product_sort .product_sort_child, .theme_oranger .popconfirm_content_btn button.primary,
.theme_oranger .setting_on_off .setting_on_off_ctrol.active, .theme_oranger .btn.btn-primary, .theme_oranger table thead, .theme_oranger .content_menu_item_child_item.active, .theme_oranger .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item:hover, .theme_oranger .panel_modal_close, .theme_oranger .navigation,
.theme_oranger .navigation .navigation_menu ul .sub_menu, .theme_oranger .navigation .navigation_menu ul li.menu_item ul.sub_menu li a:hover,
.theme_oranger .navigation .navigation_setting .navigation_setting_sub,
.theme_oranger .order_save_check,
.theme_oranger .return_title,
.theme_oranger .open_panel_fixed,
.theme_oranger .add_customer_sell,
.theme_oranger .sc-bxivhb,
.theme_oranger .bnc_dashboard_aff .bpos_dashboard_current_balance {
  background: #E67E22 !important; }

.theme_oranger .order_transport_title {
  border-left-color: #E67E22 !important; }

.theme_oranger .ReactVirtualized__Grid__innerScrollContainer .active {
  background: rgba(245, 120, 0, 0.2) !important; }

.theme_oranger .bpos_box_sell_right_box_search input:hover, .theme_oranger .bpos_box_sell_right_box_price_table, .theme_oranger .bpos_list_pr_box_img, .theme_oranger .bpos_box_sell_left_box, .theme_oranger .bpos_box_sell_right_box, .theme_oranger .output_complete_search, .theme_oranger .slider2 .image-detail-small:hover, .theme_oranger .slider2 .slick-current .image-detail-small, .theme_oranger .navigation .navigation_menu ul .sub_menu li a,
.theme_oranger .product_sort_child.item_width button:not(:last-child) {
  border-color: #E67E22 !important; }

.theme_oranger .react-sweet-progress-symbol-absolute .react-sweet-progress-symbol, .theme_oranger .product_upload_image_child0_del, .theme_oranger .la-credit-card, .theme_oranger .color-green, .theme_oranger .cell_price_sell_bpos, .theme_oranger .la-success, .theme_oranger .navigation_icon_search_sell i, .theme_oranger .k-content .slick-dots li:hover button:before, .theme_oranger .k-content .slick-dots li.slick-active button:before, .theme_oranger .k-content .title, .theme_oranger .btn.btn-none:hover, .theme_oranger .btn.btn-none i, .theme_oranger .panel_modal_list li a:hover, .theme_oranger .bpos_dashboard_this_month_revenue .css-1492t68, .theme_oranger .bpos_dashboard_selling_title span, .theme_oranger .bpos_dashboard_recent_activities_list ul li a {
  color: #E67E22 !important; }

.theme_oranger .product_sort .product_sort_child .label_checkbox .checkmark, .theme_oranger .navigation .navigation_setting .navigation_setting_sub li:hover, .theme_oranger .navigation .navigation_menu ul li.menu_item:hover a:hover, .theme_oranger .navigation .navigation_menu ul li.menu_item:hover a {
  background: #e06f03 !important; }

.theme_oranger .btn.btn-success, .theme_oranger .pagination > li > a:hover, .theme_oranger .pagination > li > span:hover, .theme_oranger .pagination > li > a:focus,
.theme_oranger .pagination > li > span:focus, .theme_oranger .pagination > .active > a, .theme_oranger .pagination > .active > span,
.theme_oranger .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus,
.theme_oranger .pagination > .active > span:focus {
  background: #E67E22 !important;
  border-color: #E67E22 !important; }

.theme_oranger .navigation_list_invoice, .theme_oranger .bpos_sell_network_mode {
  background: #bf5e00; }

.theme_oranger .navigation_list_invoice.active button, .theme_oranger .navigation_list_invoice.active .la,
.theme_oranger .navigation_list_invoice:hover button, .theme_oranger .navigation_list_invoice:hover .la, .theme_oranger .navigation_list_invoice.active, .theme_oranger .navigation_list_invoice:hover {
  color: #bf5e00; }

.theme_oranger .bpos_price_list_time ul li:first-child a {
  color: #E67E22;
  border-color: #E67E22; }

.theme_oranger .bpos_price_list_time ul li.active a, .theme_oranger .bpos_price_list_time ul li:hover a {
  background: #E67E22; }

.theme_oranger .bpos_price_list_time ul li:last-child a {
  color: #E67E22;
  border-color: #E67E22; }

.theme_oranger .bpos_price_list_box_name {
  background: linear-gradient(45deg, #E67E22, #fda650); }

.theme_oranger .bpos_price_list_box_price {
  background: #f67c00; }

.theme_oranger .label_checkbox .checkmark {
  border-color: #E67E22; }

.theme_oranger .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input .form-group-sale-box-btn a.active {
  background: #E67E22 !important; }

/*-- Themes Dark ---*/
.theme_dark .navigation .navigation_setting .navigation_setting_sub li, .theme_dark .navigation .navigation_menu ul li.menu_item a, .theme_dark .navigation .navigation_setting .navigation_setting_sub a, .theme_dark .navigation .navigation_change_color, .theme_dark .navigation .navigation_notification,
.theme_dark .depots {
  border-color: #3f4a5a; }

.theme_dark .ReactVirtualized__Grid__innerScrollContainer .active {
  background: rgba(74, 86, 104, 0.2) !important; }

.theme_dark .bpos_box_sell_right_box .form-group-sale-box-btn a.active, .theme_dark .bpos_box_sell_right_box .form-group-sale-box-btn a:hover, .theme_dark .bpos_box_sell_left_toggle, .theme_dark .ReactVirtualized__Grid__innerScrollContainer .col-md-2 .input-content:after, .theme_dark .navigation .navigation_menu ul li.menu_item ul.sub_menu li a:hover, .theme_dark .product_upload_image_child0_avatar, .theme_dark .bpos_box_sell_right_box_payment .form-group .input-content:after, .theme_dark .bpos_box_sell_left_action_list li:hover,
.theme_dark .bpos_box_sell_right_list_table tbody :nth-child(4) .form-group .input-content:after, .theme_dark .bpos_list_pr_box_img p, .theme_dark .bpos_box_sell_left_action:after, .theme_dark .bpos_box_sell_right_box_title h2, .theme_dark .label_checkbox input:checked ~ .checkmark,
.theme_dark .product_sort .product_sort_child, .theme_dark .popconfirm_content_btn button.primary, .theme_dark .setting_on_off .setting_on_off_ctrol.active, .theme_dark .btn.btn-primary, .theme_dark table thead, .theme_dark .content_menu_item_child_item.active,
.theme_dark .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item:hover, .theme_dark .panel_modal_close, .theme_dark .navigation, .theme_dark .navigation .navigation_menu ul .sub_menu,
.theme_dark .navigation .navigation_setting .navigation_setting_sub,
.theme_dark .order_save_check,
.theme_dark .return_title,
.theme_dark .open_panel_fixed,
.theme_dark .add_customer_sell,
.theme_dark .sc-bxivhb,
.theme_dark .bnc_dashboard_aff .bpos_dashboard_current_balance {
  background: #4c5667 !important; }

.theme_dark .order_transport_title {
  border-left-color: #4c5667 !important; }

.theme_dark .bpos_box_sell_right_box_search input:hover, .theme_dark .bpos_box_sell_right_box_price_table, .theme_dark .bpos_list_pr_box_img, .theme_dark .bpos_box_sell_left_box, .theme_dark .bpos_box_sell_right_box, .theme_dark .output_complete_search, .theme_dark .slider2 .image-detail-small:hover, .theme_dark .slider2 .slick-current .image-detail-small, .theme_dark .navigation .navigation_menu ul .sub_menu li a,
.theme_dark .product_sort_child.item_width button:not(:last-child) {
  border-color: #4c5667 !important; }

.theme_dark .react-sweet-progress-symbol-absolute .react-sweet-progress-symbol, .theme_dark .product_upload_image_child0_del, .theme_dark .la-credit-card, .theme_dark .color-green, .theme_dark .cell_price_sell_bpos, .theme_dark .la-success, .theme_dark .navigation_icon_search_sell i, .theme_dark .k-content .slick-dots li:hover button:before, .theme_dark .k-content .slick-dots li.slick-active button:before, .theme_dark .k-content .title, .theme_dark .btn.btn-none:hover, .theme_dark .btn.btn-none i, .theme_dark .panel_modal_list li a:hover, .theme_dark .bpos_dashboard_this_month_revenue .css-1492t68, .theme_dark .bpos_dashboard_selling_title span, .theme_dark .bpos_dashboard_recent_activities_list ul li a {
  color: #4c5667 !important; }

.theme_dark .product_sort .product_sort_child .label_checkbox .checkmark, .theme_dark .navigation .navigation_setting .navigation_setting_sub li:hover, .theme_dark .navigation .navigation_menu ul li.menu_item:hover a:hover, .theme_dark .navigation .navigation_menu ul li.menu_item:hover a {
  background: #3f4a5a !important; }

.theme_dark .btn.btn-success, .theme_dark .pagination > li > a:hover, .theme_dark .pagination > li > span:hover, .theme_dark .pagination > li > a:focus,
.theme_dark .pagination > li > span:focus, .theme_dark .pagination > .active > a, .theme_dark .pagination > .active > span,
.theme_dark .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus,
.theme_dark .pagination > .active > span:focus {
  background: #4c5667 !important;
  border-color: #4c5667 !important; }

.theme_dark .navigation_list_invoice, .theme_dark .bpos_sell_network_mode {
  background: #313e50; }

.theme_dark .navigation_list_invoice.active button, .theme_dark .navigation_list_invoice.active .la,
.theme_dark .navigation_list_invoice:hover button, .theme_dark .navigation_list_invoice:hover .la, .theme_dark .navigation_list_invoice.active, .theme_dark .navigation_list_invoice:hover {
  color: #313e50; }

.theme_dark .bpos_price_list_time ul li:first-child a {
  color: #4c5667;
  border-color: #4c5667; }

.theme_dark .bpos_price_list_time ul li.active a, .theme_dark .bpos_price_list_time ul li:hover a {
  background: #4c5667; }

.theme_dark .bpos_price_list_time ul li:last-child a {
  color: #4c5667;
  border-color: #4c5667; }

.theme_dark .bpos_price_list_box_name {
  background: linear-gradient(45deg, #4c5667, #616d84); }

.theme_dark .bpos_price_list_box_price {
  background: #5a677f; }

.theme_dark .label_checkbox .checkmark {
  border-color: #4c5667; }

.theme_dark .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input .form-group-sale-box-btn a.active {
  background: #4c5667 !important; }

/*---- Themes Dark Mode ----*/
.theme_dark_mode .react-datepicker select {
  background: unset;
  border-color: #a6a6a6; }

.theme_dark_mode .title_menu .title a, .theme_dark_mode .btn.btn-none:hover, .theme_dark_mode a {
  color: #888888; }

.theme_dark_mode .btn:hover, .theme_dark_mode .la:hover {
  opacity: .8; }

.theme_dark_mode .personnel_list_btn button.btn.btn-primary:hover {
  opacity: 1; }

.theme_dark_mode .st_body .st_left_menu,
.theme_dark_mode tr.list_product.tab td,
.theme_dark_mode .bnc_dashboard_aff .bpos_dashboard_current_balance,
.theme_dark_mode .bnc_dashboard_aff .bpos_dashboard_selling_today,
.theme_dark_mode .bnc_dashboard_aff .bpos_dashboard_recent_activities,
.theme_dark_mode .bnc_help_aff_list_tab,
.theme_dark_mode .bnc_help_aff_info {
  background: #212527 !important; }

.theme_dark_mode .nav_stocktakes.nav-tabs > li.active > a,
.theme_dark_mode .nav_stocktakes.nav-tabs > li.active > a:hover,
.theme_dark_mode .nav_stocktakes.nav-tabs > li.active > a:focus,
.theme_dark_mode .nav_stocktakes.nav-tabs > li.active > a:focus,
.theme_dark_mode .nav_stocktakes.nav-tabs > li > a:hover,
.theme_dark_mode .nav_stocktakes.nav-tabs > li > a:focus {
  background-color: #2c3134;
  border: 1px solid #212527;
  border-bottom: 1px solid #2c3134; }

.theme_dark_mode .nav_stocktakes.nav-tabs {
  border-color: #212527; }

.theme_dark_mode .bnc_dashboard_aff .bpos_dashboard_recent_activities h2,
.theme_dark_mode .bpos_dashboard_recent_activities.bpos_dashboard_news_hot .bpos_dashboard_news_hot_box, .theme_dark_mode .bnc_dashboard_aff .bpos_dashboard_recent_activities ul,
.theme_dark_mode .bpos_dashboard_statistical_list ul li,
.theme_dark_mode .bpos_application_box.bpos_aff_box .bpos_application_field {
  border-color: #2c3134 !important; }

.theme_dark_mode .bnc_help_aff_list_tab ul li {
  border-bottom-color: #2c3134 !important; }

.theme_dark_mode, .theme_dark_mode .order_transport_title, .theme_dark_mode .order_transport_modal table thead,
.theme_dark_mode .order_transport_modal table thead tr,
.theme_dark_mode .list_customer .btn.btn-primary {
  background: #2c3134 !important; }

.theme_dark_mode .order_transport_title {
  border-left-color: #404040;
  color: #fff; }

.theme_dark_mode .table > thead > tr > th, .theme_dark_mode .table > tbody > tr > th,
.theme_dark_mode .table > tfoot > tr > th,
.theme_dark_mode .table > thead > tr > td, .theme_dark_mode .table > tbody > tr > td,
.theme_dark_mode .table > tfoot > tr > td,
.theme_dark_mode .inventory-list .table > tbody > tr > td,
.theme_dark_mode .product-detail.form-wrapper.content-list td
.theme_dark_mode .navigation .navigation_menu ul .sub_menu li a,
.theme_dark_mode .k-content .form-group {
  border-color: #353738; }

.theme_dark_mode .navigation_list_invoice, .theme_dark_mode .bpos_sell_network_mode,
.theme_dark_mode .sc-bxivhb {
  background: #2c3134; }

.theme_dark_mode .navigation_list_invoice.active .la,
.theme_dark_mode .navigation_list_invoice:hover button,
.theme_dark_mode .navigation_list_invoice:hover .la,
.theme_dark_mode .navigation_list_invoice.active,
.theme_dark_mode .navigation_list_invoice:hover {
  color: #2c3134; }

.theme_dark_mode .cke_reset_all table tr td:nth-child(1),
.theme_dark_mode .sk-fading-circle .sk-circle:before, .theme_dark_mode .cke_reset_all table tr {
  background: #fff !important; }

.theme_dark_mode .setting_general_email_chart_title, .theme_dark_mode .panel_modal_list li a:hover, .theme_dark_mode .autocomplete_content_item, .theme_dark_mode .nav-tabs > li > a, .theme_dark_mode .la, .theme_dark_mode a:hover,
.theme_dark_mode .k-content .title, .theme_dark_mode,
.theme_dark_mode .k-content .slick-dots li button:before,
.theme_dark_mode .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item,
.theme_dark_mode .account_list_brands a, .theme_dark_mode .dragUploadExcelRoot .react-sweet-progress-symbol,
.theme_dark_mode .w-barcode-title h2, .theme_dark_mode .spin .overlay .overlay_icon .txt,
.theme_dark_mode .navigation_icon_search_sell i,
.theme_dark_mode .bpos_help_version a {
  color: #fff !important; }

.theme_dark_mode .st_body .st_left_menu .content_menu .content_menu_title,
.theme_dark_mode .navigation .navigation_setting .navigation_setting_sub li,
.theme_dark_mode .navigation .navigation_setting .navigation_setting_sub a,
.theme_dark_mode .navigation .navigation_change_color, .theme_dark_mode .navigation .navigation_notification,
.theme_dark_mode .depots, .theme_dark_mode .discount_hr, .theme_dark_mode .discount_hr:nth-child(1),
.theme_dark_mode .discount_add_quanity, .theme_dark_mode .discount_add_quanity_hr {
  border-color: #2c3134; }

.theme_dark_mode .content_menu_item_child_item.active, .theme_dark_mode .st_body_content.scroll_bar, .theme_dark_mode .table, .theme_dark_mode .setting_on_off .setting_on_off_ctrol, .theme_dark_mode .setting_on_off .setting_on_off_ctrol.active,
.theme_dark_mode .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item:hover,
.theme_dark_mode .setting_general .btn.btn-primary, .theme_dark_mode .k-content,
.theme_dark_mode .label_checkbox input:checked ~ .checkmark,
.theme_dark_mode .order_save_check,
.theme_dark_mode .return_title {
  background: #2c3134 !important; }

.theme_dark_mode .role_permission table tr td[colspan], .theme_dark_mode .autocomplete .autocomplete_content
, .theme_dark_mode .table-hover > tbody > tr:hover, .theme_dark_mode table tr, .theme_dark_mode table tr:nth-child(2n), .theme_dark_mode .bpos_box_sell_right_box .form-group-sale-box-btn a.active, .theme_dark_mode .bpos_box_sell_right_box .form-group-sale-box-btn a:hover, .theme_dark_mode .bpos_box_sell_left_toggle, .theme_dark_mode .ReactVirtualized__Grid__innerScrollContainer .col-md-2 .input-content:after, .theme_dark_mode .navigation .navigation_menu ul li.menu_item ul.sub_menu li a:hover, .theme_dark_mode .product_upload_image_child0_avatar, .theme_dark_mode .bpos_box_sell_right_box_payment .form-group .input-content:after, .theme_dark_mode .bpos_box_sell_left_action_list li:hover,
.theme_dark_mode .bpos_box_sell_right_list_table tbody :nth-child(4) .form-group .input-content:after, .theme_dark_mode .bpos_list_pr_box_img p, .theme_dark_mode .bpos_box_sell_left_action:after, .theme_dark_mode .bpos_box_sell_right_box_title h2,
.theme_dark_mode .product_sort .product_sort_child,
.theme_dark_mode table thead,
.theme_dark_mode .panel_modal_close, .theme_dark_mode .navigation, .theme_dark_mode .navigation .navigation_menu ul .sub_menu,
.theme_dark_mode .navigation .navigation_setting .navigation_setting_sub,
.theme_dark_mode tr.customer_view td,
.theme_dark_mode .btn.btn-primary, .theme_dark_mode .setting_general .printed_form_design .btn.btn-primary,
.theme_dark_mode .bpos_box_sell .bpos_list_pr_box_img,
.theme_dark_mode .open_panel_fixed {
  background: #212527 !important; }

.theme_dark_mode .bpos_box_sell_right_box_search input:hover,
.theme_dark_mode .bpos_list_pr_box_img, .theme_dark_mode .bpos_box_sell_left_box,
.theme_dark_mode .bpos_box_sell_right_box, .theme_dark_mode .output_complete_search,
.theme_dark_mode .slider2 .image-detail-small:hover, .theme_dark_mode .slider2 .slick-current .image-detail-small,
.theme_dark_mode .navigation .navigation_menu ul li.menu_item a,
.theme_dark_mode hr,
.theme_dark_mode .bpos_help .col-md-10,
.theme_dark_mode .bpos_help_title,
.theme_dark_mode .bpos_sell_left_box {
  border-color: #212527 !important; }

.theme_dark_mode .react-sweet-progress-symbol-absolute .react-sweet-progress-symbol,
.theme_dark_mode .product_upload_image_child0_del,
.theme_dark_mode .la-success,
.theme_dark_mode .k-content .slick-dots li:hover button:before,
.theme_dark_mode .k-content .slick-dots li.slick-active button:before {
  color: #212527 !important; }

.theme_dark_mode .product_sort .product_sort_child .label_checkbox .checkmark,
.theme_dark_mode .navigation .navigation_setting .navigation_setting_sub li:hover,
.theme_dark_mode .navigation .navigation_menu ul li.menu_item:hover a:hover,
.theme_dark_mode .navigation .navigation_menu ul li.menu_item:hover a,
.theme_dark_mode .customer_tabs_sub, .theme_dark_mode .bpos_box_sell_right_box_content .form-group-btn-sell .btn,
.theme_dark_mode .spin .overlay .overlay_icon,
.theme_dark_mode .btn.btn-primary.btn-properties {
  background: #2c3134 !important; }

.theme_dark_mode .product_sort_child.item_width button:not(:last-child),
.theme_dark_mode .sms_before_customer_birthday_item {
  border-color: #2c3134 !important; }

.theme_dark_mode .btn.btn-success {
  background: #2c3134 !important;
  border-color: #2c3134 !important; }

.theme_dark_mode .k-content .btn.btn-success {
  background: #202527 !important;
  border-color: #202527 !important; }

.theme_dark_mode .setting_general .setting_general_item .setting_general_content {
  border-color: #212527;
  background: #212527; }

.theme_dark_mode .setting_general .setting_general_item .setting_general_header {
  background: #212527;
  border-bottom: 1px solid #2c3134 !important;
  border: none; }

.theme_dark_mode .setting_general .setting_general_item .setting_general_content .setting_general_content_item.on_off,
.theme_dark_mode .setting_general .setting_general_item .setting_general_content .setting_general_content_item {
  border-color: #2c3134; }

.theme_dark_mode .setting_general .printed_form .printed_form_design .printed_form_view iframe {
  background: #fff; }

.theme_dark_mode .modal_tags .modal_tags-input .modal_tags-input-input {
  background: #212527; }
  .theme_dark_mode .modal_tags .modal_tags-input .modal_tags-input-input input {
    background: #111111; }

.theme_dark_mode .modal_tags .modal_tags-input {
  border-color: #2c3134; }

.theme_dark_mode .modal_tags .modal_tags-list {
  border-color: #2c3134; }
  .theme_dark_mode .modal_tags .modal_tags-list .modal_tags-list-item {
    border-color: #2c3134; }
    .theme_dark_mode .modal_tags .modal_tags-list .modal_tags-list-item .modal_tags-list-item-select .menu_color {
      background: #212527; }
      .theme_dark_mode .modal_tags .modal_tags-list .modal_tags-list-item .modal_tags-list-item-select .menu_color .menu_color-list:before {
        border-bottom: 8px solid #212527; }
    .theme_dark_mode .modal_tags .modal_tags-list .modal_tags-list-item:hover {
      background: #111111; }
      .theme_dark_mode .modal_tags .modal_tags-list .modal_tags-list-item:hover .modal_tags-list-item-act {
        color: #fff; }

.theme_dark_mode .la-credit-card, .theme_dark_mode .bpos_box_sell_right_box_content .color-green {
  color: #fff; }

.theme_dark_mode .bpos_box_sell {
  background: #2c3134 !important; }
  .theme_dark_mode .bpos_box_sell_left_box {
    background: #2c3134; }
  .theme_dark_mode .bpos_box_sell_right_box {
    background: #212527; }
    .theme_dark_mode .bpos_box_sell_right_box_search .add_customer_sell {
      color: #fff;
      background: #2c3134 !important; }
    .theme_dark_mode .bpos_box_sell_right_box .css-19bqh2r, .theme_dark_mode .bpos_box_sell_right_box .css-1492t68 {
      color: #fff; }
    .theme_dark_mode .bpos_box_sell_right_box_payment label {
      color: #fff; }
      .theme_dark_mode .bpos_box_sell_right_box_payment label .badge {
        color: #fff; }
    .theme_dark_mode .bpos_box_sell_right_box_content .form-group-note-sell .form-group textarea {
      background: #2c3134;
      border-color: #232323; }
  .theme_dark_mode .bpos_box_sell_right_list_table .table-striped > tbody > tr:nth-of-type(odd) {
    background: #232323; }
  .theme_dark_mode .bpos_box_sell .table > thead > tr > th, .theme_dark_mode .bpos_box_sell .table > tbody > tr > th, .theme_dark_mode .bpos_box_sell .table > tfoot > tr > th, .theme_dark_mode .bpos_box_sell .table > thead > tr > td, .theme_dark_mode .bpos_box_sell .table > tbody > tr > td, .theme_dark_mode .bpos_box_sell .table > tfoot > tr > td {
    border-color: #2c3134; }

.theme_dark_mode .cell_price_sell_bpos, .theme_dark_mode .react-datepicker-wrapper:before, .theme_dark_mode .la-actions {
  color: #fff !important; }

.theme_dark_mode .price_sell_bpos, .theme_dark_mode .number_sell_bpos {
  background: #2c3134;
  border: none;
  padding: 5px; }

.theme_dark_mode .bnc_product_banner_aff_list_tab .nav-tabs {
  border-color: #212527; }

.theme_dark_mode .css-vj8t7z, .theme_dark_mode .input-group-addon {
  background: #2c3134;
  border-color: #404040; }

.theme_dark_mode .css-xp4uvy {
  color: #fff; }

.theme_dark_mode .product_add_category_btn_add .btn.btn-primary {
  background: #2c3134 !important; }

.theme_dark_mode .pagination > li > a, .theme_dark_mode .pagination > li > span, .theme_dark_mode .pagination > .active > a {
  background: #212527;
  color: #fff;
  border: 1px solid #212527; }

.theme_dark_mode .pagination > li > a:hover, .theme_dark_mode .pagination > li > span:hover,
.theme_dark_mode .pagination > li > a:focus, .theme_dark_mode .pagination > .active > a,
.theme_dark_mode .pagination > .active > span, .theme_dark_mode .pagination > .active > a:hover,
.theme_dark_mode .pagination > .active > span:hover, .theme_dark_mode .pagination > .active > a:focus, .theme_dark_mode .pagination > .active > span:focus {
  background: #2c3134 !important; }

.theme_dark_mode .nav-tabs > li.active > a, .theme_dark_mode .nav-tabs > li.active > a:hover {
  color: #fff !important; }

.theme_dark_mode .nav-tabs > li.active > a, .theme_dark_mode .nav-tabs > li.active > a:hover, .theme_dark_mode .nav-tabs > li.active > a:focus,
.theme_dark_mode .nav-tabs > li.active > a:focus, .theme_dark_mode .nav > li > a:hover, .theme_dark_mode .nav > li > a:focus {
  background-color: #2c3134;
  border: 1px solid #2c3134; }

.theme_dark_mode .nav-tabs {
  border-bottom: 1px solid #2c3134; }

.theme_dark_mode select {
  background: #2c3134;
  border: 1px solid #404040; }

.theme_dark_mode .form-control, .theme_dark_mode .css-162g8z5 {
  background: #2c3134;
  border: 1px solid #404040;
  color: #fff; }

.theme_dark_mode .css-10nd86i {
  color: #2c3134 !important; }

.theme_dark_mode .css-1aya2g8, .theme_dark_mode .bpos_box_sell_right_box .css-1aya2g8, .theme_dark_mode .bpos_box_sell_right_box_user time {
  background: #2c3134;
  border-color: #404040; }

.theme_dark_mode .dragUploadExcelRoot .dragUploadExcel .uploadZone, .theme_dark_mode .dragUploadExcelRoot .dragUploadExcel .info .info_file .info_file_table tr {
  background: #2c3134 !important;
  border-color: #212527; }

.theme_dark_mode .dragUploadExcelRoot .rowInfoExcel .table-container {
  border: 1px solid #2c3134 !important; }

.theme_dark_mode .dragUploadExcelRoot .rowInfoExcel .table-container tbody tr {
  background: #2c3134 !important; }
  .theme_dark_mode .dragUploadExcelRoot .rowInfoExcel .table-container tbody tr:nth-child(2n) {
    background: #212527 !important; }

.theme_dark_mode .css-va7pk8, .theme_dark_mode .css-rsyb7x, .theme_dark_mode .css-10odw4q, .theme_dark_mode .css-1alnv5e {
  color: #fff; }

.theme_dark_mode .css-2o5izw, .theme_dark_mode .css-2o5izw:hover {
  background: #2c3134;
  border-color: #404040 !important; }

.theme_dark_mode .css-xwjg1b {
  background: #212527; }

.theme_dark_mode .add_customer_address_bpos .product_upload_image_child0 img {
  border-color: #404040 !important; }

.theme_dark_mode .bpos_dashboard_selling_today {
  background: #212527; }

.theme_dark_mode .bpos_dashboard_selling_title span {
  color: #fff; }

.theme_dark_mode .bpos_dashboard_this_month_revenue .css-1492t68 {
  color: #fff; }

.theme_dark_mode .bpos_dashboard_recent_activities_list ul li a {
  color: #fff; }

.theme_dark_mode .bpos_dashboard_recent_activities {
  background: #212527; }

.theme_dark_mode .bpos_dashboard_selling_today_list ul li + li, .theme_dark_mode .bpos_dashboard_recent_activities_list ul,
.theme_dark_mode .bpos_dashboard_recent_activities h2 {
  border-color: #2c3134; }

.theme_dark_mode .popconfirm .popconfirm_content_root::after {
  border-top: 8px solid #212527; }

.theme_dark_mode .popconfirm .popconfirm_content_root .popconfirm_content {
  background: #212527;
  color: #fff; }
  .theme_dark_mode .popconfirm .popconfirm_content_root .popconfirm_content .popconfirm_content_btn button.primary {
    background: #2c3134; }

.theme_dark_mode .panel_modal.show {
  background: #212527;
  border-left: 1px solid #212527; }

.theme_dark_mode .panel_modal_list {
  background: #2c3134;
  color: #fff; }
  .theme_dark_mode .panel_modal_list li {
    border-color: #212527; }
    .theme_dark_mode .panel_modal_list li a {
      color: #fff; }
  .theme_dark_mode .panel_modal_list .price_modal span {
    color: #fff; }

.theme_dark_mode .modal-content {
  background: #212527; }

.theme_dark_mode .modal-header .close {
  color: #fff; }

.theme_dark_mode .modal .modal-dialog .modal-content .modal-footer .btn.btn-primary, .theme_dark_mode .modal-content .btn.btn-primary,
.theme_dark_mode .modal .label_checkbox input:checked ~ .checkmark {
  background: #2c3134 !important; }

.theme_dark_mode .modal .modal-dialog .modal-content .modal-footer .btn.btn-default {
  color: #212527; }

.theme_dark_mode .modal-header, .theme_dark_mode .modal-footer, .theme_dark_mode .debt_list_item_box_modal .row:not(:last-child) {
  border-color: #2c3134; }

.theme_dark_mode .navigation .navigation_menu ul .sub_menu, .theme_dark_mode .navigation .navigation_setting .navigation_setting_sub {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25); }

.theme_dark_mode .navigation .navigation_change_color_box {
  background: #212527;
  color: #fff;
  border-color: #2c3134; }
  .theme_dark_mode .navigation .navigation_change_color_box:before {
    border-bottom: 10px solid #2c3134; }
  .theme_dark_mode .navigation .navigation_change_color_box hr {
    border-color: #2c3134; }
  .theme_dark_mode .navigation .navigation_change_color_box .navigation_themes_color_box_list li span {
    border-color: #2c3134; }

.theme_dark_mode .navigation .navigation_notification_list {
  background: #212527;
  color: #fff;
  border-color: #2c3134; }
  .theme_dark_mode .navigation .navigation_notification_list > p {
    color: #fff;
    border-bottom: 1px solid #2c3134; }
  .theme_dark_mode .navigation .navigation_notification_list_main li {
    border-bottom: 1px solid #2c3134; }
    .theme_dark_mode .navigation .navigation_notification_list_main li:hover {
      background: #2c3134; }
    .theme_dark_mode .navigation .navigation_notification_list_main li:nth-child(even) {
      background: #212527; }
      .theme_dark_mode .navigation .navigation_notification_list_main li:nth-child(even):hover {
        background: #2c3134; }
  .theme_dark_mode .navigation .navigation_notification_list_see_all:hover {
    background: #2c3134; }
  .theme_dark_mode .navigation .navigation_notification_list:before {
    border-bottom-color: #2c3134; }

.theme_dark_mode .navigation .navigation_notification_list_main a {
  color: #fff; }

.theme_dark_mode .navigation .navigation_notification_list > p a {
  color: #fff; }

.theme_dark_mode .container_login .form_login .form_login_content,
.theme_dark_mode .container_login .login_process .form_login_content, .theme_dark_mode .form_line span {
  background: #2c3134; }

.theme_dark_mode .container_login .form-group .input-content .form-control {
  border-color: #4c4c4c !important; }

.theme_dark_mode .ReactVirtualized__Grid__innerScrollContainer .row:nth-child(odd),
.theme_dark_mode .ReactVirtualized__Grid__innerScrollContainer .number_sell_bpos,
.theme_dark_mode .ReactVirtualized__Grid__innerScrollContainer .row:hover {
  background: #212527;
  border-color: #2c3134; }

.theme_dark_mode .ReactVirtualized__Grid__innerScrollContainer .row, .theme_dark_mode .navigation_list_invoice.active button,
.theme_dark_mode .navigation_list_invoice button:hover {
  color: #fff; }

.theme_dark_mode .ReactVirtualized__Grid__innerScrollContainer .la-actions,
.theme_dark_mode .ReactVirtualized__Grid__innerScrollContainer .bpos_sell_quantity .la,
.theme_dark_mode .navigation_list_invoice.active, .theme_dark_mode .navigation_list_invoice:hover,
.theme_dark_mode .bpos_box_sell_right_box_title h2 {
  background: #2c3134 !important; }

.theme_dark_mode .ReactVirtualized__Grid__innerScrollContainer .outstock {
  background: #c33b3b !important; }

.theme_dark_mode .ReactVirtualized__Grid__innerScrollContainer .active.outstock {
  background: #212527 !important; }

.theme_dark_mode .bpos_box_sell_left_action_list li {
  background: #1f2527;
  border-color: #33393c; }

.theme_dark_mode .bpos_box_sell_right_box_price_table {
  border-color: #2c3134 !important; }

.theme_dark_mode .ReactVirtualized__Grid__innerScrollContainer .active {
  background: #33393c !important; }

.theme_dark_mode .navigation_setting_exclamation_list .la {
  color: #000 !important; }

.theme_dark_mode .bpos_application_box {
  border-color: #212527; }

.theme_dark_mode .bpos_application_field, .theme_dark_mode .bpos_application_hover_body {
  border-color: #2c3134; }

.theme_dark_mode .bpos_application_hover {
  background: #2c3134; }

.theme_dark_mode .bpos_application_hover_header, .theme_dark_mode .bpos_application_hover_footer, .theme_dark_mode .bpos_application_box, .theme_dark_mode .total_receipts {
  background: #212527; }

.theme_dark_mode .table_return thead tr {
  background: #2c3134 !important; }

.theme_dark_mode .table_report thead tr th {
  border-color: #2c3134 !important; }

.theme_dark_mode .pagination_return li a {
  background: #2c3134 !important; }

.theme_dark_mode .pagination_return li.active a, .theme_dark_mode .pagination_return li a:hover {
  background: #212527 !important; }

.theme_dark_mode .customer_tabs .customer_tabs_upload_image .avatar {
  background: #2c3134 !important; }

.theme_dark_mode .setting_general_content_item_content_sub .table thead, .theme_dark_mode .setting_general_content_item_content_sub .table thead tr,
.theme_dark_mode .setting_general_content_item_content .table thead, .theme_dark_mode .setting_general_content_item_content .table thead tr {
  background: #2c3134 !important; }

.theme_dark_mode .load_type_receipts, .theme_dark_mode .load_type_receipts_1 {
  background: #2b3133; }

.theme_dark_mode .bpos_dashboard_right_top {
  background: #1f2426; }

.theme_dark_mode .bpos_dashboard_count_number_span {
  background: #2c3134; }

.theme_dark_mode .bpos_dashboard_choose_btn .btn {
  background: #2c3134 !important; }

.theme_dark_mode .bpos_price_list_box {
  box-shadow: none; }

.theme_dark_mode .bpos_price_list_title h1 {
  color: #fff; }

.theme_dark_mode .bpos_price_list_box_feature .btn {
  background: #2c3134 !important; }

.theme_dark_mode .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item:hover img {
  filter: invert(0);
  -webkit-filter: invert(0); }

.theme_dark_mode .label_checkbox .checkmark {
  border-color: #ddd; }

.theme_dark_mode .properties_images_box_hr {
  border-color: #2c3134 !important; }

.theme_dark_mode .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input .form-group-sale-box-btn a.active {
  background: #2c3134 !important; }

.theme_dark_mode .ReactVirtualized__Grid__innerScrollContainer .row {
  border-color: #2c3134 !important; }

.theme_dark_mode .buy-person .css-vj8t7z {
  background: none; }

.theme_dark_mode .buy-person .css-xp4uvy {
  color: #fff; }

.code_embed {
  position: relative; }
  .code_embed button {
    position: absolute;
    top: 5px;
    right: 5px;
    opacity: 0;
    transition: all 0.167s cubic-bezier(0.1, 0.25, 0.75, 0.9); }
  .code_embed pre {
    border-radius: 0px;
    background-color: #f8f8f8; }
  .code_embed:hover button {
    opacity: 1;
    transition: all 0.167s cubic-bezier(0.1, 0.25, 0.75, 0.9); }

.st_body {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
  top: 50px; }
  .st_body .st_left_menu {
    display: flex;
    width: 240px;
    background-color: #eeeeee;
    position: fixed;
    height: 100%; }
    .st_body .st_left_menu .content_menu {
      width: 100%; }
      .st_body .st_left_menu .content_menu .content_menu_title {
        font-size: 18px;
        height: 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 15px;
        border-bottom: 1px solid #e6e6e6; }
      .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item {
        margin-bottom: 15px; }
        .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_title {
          font-weight: 500;
          user-select: none;
          padding: 5px 0;
          cursor: pointer; }
          .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_title .ic_menu::before {
            transition: all 100ms; }
          .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_title .ic_menu.drop_down::before {
            transform: rotate(90deg);
            transition: all 100ms; }
          .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_title .content_menu_item_title_txt {
            padding: 10px 15px; }
        .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child {
          max-height: 0;
          transition: max-height 0.167s cubic-bezier(0.1, 0.25, 0.75, 0.9);
          overflow: hidden;
          user-select: none; }
          .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child.show {
            max-height: 500px;
            transition: max-height 0.167s cubic-bezier(0.1, 0.25, 0.75, 0.9); }
          .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item {
            padding: 10px 0px 10px 30px;
            cursor: pointer;
            transition: all ease 100ms;
            color: #000; }
            .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item.active {
              background-color: #28af6b;
              color: #fff; }
              .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item.active:hover img {
                filter: invert(0);
                -webkit-filter: invert(0); }
            .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item:hover {
              background-color: #28af6b;
              transition: all ease 100ms;
              color: #fff; }
              .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item:hover img {
                filter: invert(100%);
                -webkit-filter: invert(100%); }
            .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item img {
              width: 20px;
              height: 20px;
              display: inline-block;
              margin: 0 10px 0 0;
              transition: all ease .0s; }
          .st_body .st_left_menu .content_menu .content_menu_list .content_menu_item .content_menu_item_child .content_menu_item_child_item.item3 {
            padding-left: 45px; }
  .st_body .st_body_content {
    background-color: #fff;
    *box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 4px 24px 2px rgba(0, 0, 0, 0.1);
    position: absolute;
    margin-left: 240px;
    transition: all 0.167s cubic-bezier(0.1, 0.25, 0.75, 0.9);
    height: 100% !important;
    overflow-y: scroll;
    float: left;
    max-width: calc(100% - 240px);
    width: 100%;
    padding: 0 15px;
    min-height: 100vh; }
    .st_body .st_body_content .personnel_list {
      margin: 0 0 80px; }
    .st_body .st_body_content .scroll_bar {
      height: auto !important; }
    .st_body .st_body_content.st_body_content_animation_pop {
      margin-left: 500px;
      opacity: 0;
      transition: all 0.167s cubic-bezier(0.1, 0.25, 0.75, 0.9); }
    .st_body .st_body_content.st_body_content_animation_push {
      margin-left: 240px;
      opacity: 1;
      transition: all 0.367s cubic-bezier(0.1, 0.9, 0.2, 1); }
    .st_body .st_body_content.full {
      margin-left: 0px;
      max-width: 100%;
      transition: all 0.367s cubic-bezier(0.1, 0.9, 0.2, 1); }
    .st_body .st_body_content .st_body_content_child {
      *max-width: calc(960px - 240px); }
      .st_body .st_body_content .st_body_content_child.full {
        *max-width: calc(100% - 240px); }
    .st_body .st_body_content .title_menu {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 18px;
      height: 52px;
      align-content: center; }
      .st_body .st_body_content .title_menu .icon {
        padding-right: 15px;
        font-size: 24px;
        cursor: pointer; }
        .st_body .st_body_content .title_menu .icon:hover {
          opacity: .5; }
        .st_body .st_body_content .title_menu .icon .ic_menu_toggle::before {
          transform: rotate(180deg); }
        .st_body .st_body_content .title_menu .icon .ic_menu_toggle.ic_menu_toggle_back::before {
          transform: rotate(360deg); }
      .st_body .st_body_content .title_menu .title {
        user-select: none; }

.design_container {
  padding: 15px; }
  .design_container .design_data {
    width: 50%; }
  .design_container .design_bar_item img {
    width: 50px;
    max-height: 50px;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 2px;
    margin-right: 20px;
    float: left; }
  .design_container .design_bar_item .upload_image {
    background-color: #55b989;
    color: #fff;
    border-color: #08c136;
    border: 0;
    padding: 8px 15px;
    margin: 5px 0;
    border-radius: 2px;
    width: 70%; }
  .design_container .design_bar {
    width: 40%;
    border: 1px solid #bdbdbd;
    border-radius: 5px; }
    .design_container .design_bar .design_save {
      background: #d4d4d4;
      padding: 5px 0;
      border-radius: 0 0 5px 5px; }
      .design_container .design_bar .design_save button {
        margin: 0px auto; }
  .design_container .design_view {
    width: 58%;
    margin-left: 2%;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    padding: 10px; }

.setting_audio {
  margin-bottom: 15px; }
  .setting_audio .setting_audio_ctrol {
    width: 48px;
    background: #bfbfbf;
    height: 22px;
    border-radius: 20px;
    position: relative;
    cursor: pointer; }
    .setting_audio .setting_audio_ctrol.active {
      background: #2ea84c; }
      .setting_audio .setting_audio_ctrol.active:after {
        left: 28px; }
      .setting_audio .setting_audio_ctrol.active .setting_audio_status.on {
        opacity: 1; }
      .setting_audio .setting_audio_ctrol.active .setting_audio_status.off {
        opacity: 0; }
    .setting_audio .setting_audio_ctrol .setting_audio_status {
      color: white;
      position: absolute;
      top: 3px;
      font-size: 11px; }
      .setting_audio .setting_audio_ctrol .setting_audio_status.on {
        left: 7px;
        opacity: 0; }
      .setting_audio .setting_audio_ctrol .setting_audio_status.off {
        right: 8px; }
    .setting_audio .setting_audio_ctrol:after {
      content: '';
      width: 18px;
      height: 18px;
      background: white;
      border-radius: 50%;
      position: absolute;
      left: 2px;
      top: 2px;
      transition: all 0.5s ease; }

.role_permission table tr .nxt_checkbox {
  display: inline-block;
  height: 20px; }

.role_permission table tr td:nth-child(2) {
  padding-left: 40px; }

.role_permission table tr td[colspan] {
  padding-left: 75px;
  background-color: #ececec;
  text-align: left; }

.table-promotion .wp30 {
  width: 30%; }

.table-promotion .wp35 {
  width: 35%;
  text-align: left; }

.table-promotion .wp5 {
  width: 5%;
  vertical-align: middle; }

.table-promotion .btndelete button {
  min-width: auto;
  width: 100%; }

.table-promotion tr th {
  text-align: center; }

.table_permission {
  width: 100%; }
  .table_permission table th, .table_permission table td {
    white-space: pre; }

.setting_general.no-margin {
  margin: 0px !important; }

.ecommerce-tabs ul.nav {
  padding: none; }

.Synchronized button, .sync_lazada .close, .sync_lazada .btn-default {
  display: none; }

.Synchronized .btn-primary {
  display: inline;
  margin-right: 79px; }

.setting_general.margin-bottom-300 {
  margin-bottom: 300px !important; }

.setting_general.setting_earn_points .setting_general_item .is_input .form-group {
  top: 8px; }

.setting_general.setting_earn_points .setting_general_item .setting_general_content
.setting_general_content_item.item_height .setting_general_edit_item {
  top: 8px; }

.setting_general_content_item .navigation_search_sell {
  width: 100%;
  margin: 0; }

.setting_general_content_item .navigation_search_sell input {
  border: 1px solid #ddd !important; }

.setting_general_content_item .output_complete_search ul {
  padding: 0; }

.viettel_info p {
  line-height: 25px; }

.keyword_list_used label {
  margin: 0 0 10px 0;
  line-height: 20px; }

.form_textarea_main .form-group {
  margin: 0; }

.form_search_tab_setting .navigation_search_sell {
  width: 100%;
  margin: 0; }
  .form_search_tab_setting .navigation_search_sell input {
    background: #ececec; }

.form_search_tab_setting .output_complete_search ul {
  padding: 0; }

/*-----Modules Customer-------*/
.container_customer_online {
  position: fixed;
  margin-left: 70px;
  width: calc(100% - 70px);
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: row; }
  .container_customer_online .ant-table-wrapper {
    width: 100%; }

.add_customer_address_bpos .product_upload_image_child0 img {
  border: 1px solid #ddd;
  padding: 5px; }

.customer_tabs_sub {
  background: #fff;
  width: 100%;
  overflow: hidden; }
  .customer_tabs_sub label {
    display: block;
    border-bottom: 1px solid #f5f5f5;
    padding: 5px 0 10px;
    font-weight: 200;
    margin: 10px 0; }

.customer_tabs ul.nav {
  padding: 0 20px; }

.customer_tabs_upload_image .avatar {
  display: block;
  border-radius: 0; }

.customer_tabs .customer_tabs_upload_image .avatar {
  width: 100%;
  height: 125px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #f5f5f5;
  padding: 2px; }
  .customer_tabs .customer_tabs_upload_image .avatar i {
    font-size: 80px; }
  .customer_tabs .customer_tabs_upload_image .avatar img {
    object-fit: scale-down; }

.personnel_list_search .btn-search {
  position: absolute;
  background: none;
  border: none;
  top: 59%;
  margin: 0;
  transform: translateY(-50%) rotate(-90deg);
  right: -2px;
  font-size: 20px;
  color: #b3b3b3; }

.personnel_list_search .load_address_item_order {
  position: relative;
  float: left;
  width: 316px;
  margin: 0px 16px 0 0; }

.personnel_list_search .btn-danger {
  margin: 23px 0 0; }

.no_margin_customer .form-group {
  margin: 0 0 15px; }

.list_customer .row11 {
  width: 200px; }

.list_customer div {
  margin: 0 0 5px;
  font-size: 13px; }
  .list_customer div .la {
    font-size: 15px;
    margin: 0 5px 0 0; }

.add_customer_info_bpos .css-1aya2g8, .add_customer_info_bpos .css-vj8t7z, .add_customer_info_bpos .css-1wy0on6, .add_customer_info_bpos .css-162g8z5 {
  min-height: 30px !important; }

/*-----Modules Conversation-------*/
.modal_tag .ant-modal-header {
  background: #f6f7f9; }

.modal_tags {
  display: flex;
  flex-direction: column; }
  .modal_tags .modal_tags-desc {
    font-size: 13px;
    color: #90949c;
    margin-bottom: 24px; }
  .modal_tags .modal_tags-input {
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 52px;
    align-items: center;
    border-radius: 5px;
    padding: 15px;
    border: solid 1px #e9eef2; }
    .modal_tags .modal_tags-input:focus {
      border-color: #FF5630;
      outline: none; }
    .modal_tags .modal_tags-input .modal_tags-select {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: auto;
      margin-right: 10px;
      position: relative;
      cursor: pointer; }
      .modal_tags .modal_tags-input .modal_tags-select .modal_tags-arrow {
        padding: 10px; }
      .modal_tags .modal_tags-input .modal_tags-select .modal_tags-color {
        border-radius: 50%;
        height: 16px;
        width: 16px;
        margin-right: 5px;
        border: 1px solid #dddfe2; }
      .modal_tags .modal_tags-input .modal_tags-select .menu_color {
        display: none;
        position: absolute;
        background-color: #fff;
        height: 52px;
        padding: 15px;
        top: 35px;
        left: 15px;
        border: 0;
        border-radius: 2px;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 10px rgba(0, 0, 0, 0.35);
        z-index: 1; }
        .modal_tags .modal_tags-input .modal_tags-select .menu_color.show {
          display: block; }
        .modal_tags .modal_tags-input .modal_tags-select .menu_color .menu_color-list {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: relative; }
          .modal_tags .modal_tags-input .modal_tags-select .menu_color .menu_color-list::before {
            position: absolute;
            content: " ";
            width: 0px;
            height: 0px;
            border-left: 8px solid transparent;
            border-right: 8px solid #0c0c0c00;
            border-bottom: 8px solid #ccc;
            bottom: 40px;
            left: 0px; }
          .modal_tags .modal_tags-input .modal_tags-select .menu_color .menu_color-list .modal_tags-color {
            border-radius: 50%;
            border: 2px solid transparent;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 10px rgba(0, 0, 0, 0.35);
            padding: 10px;
            margin: 0 5px; }
            .modal_tags .modal_tags-input .modal_tags-select .menu_color .menu_color-list .modal_tags-color:hover {
              border: 2px solid #dddfe2; }
    .modal_tags .modal_tags-input .modal_tags-input-input {
      display: flex;
      flex: 1;
      width: 100%; }
      .modal_tags .modal_tags-input .modal_tags-input-input input {
        width: 100%;
        border: none;
        height: 32px;
        padding: 0 10px; }
        .modal_tags .modal_tags-input .modal_tags-input-input input::placeholder {
          color: #cecece; }
        .modal_tags .modal_tags-input .modal_tags-input-input input:focus {
          outline: none; }
  .modal_tags .modal_tags-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 200px;
    overflow: auto;
    border: solid 1px #e9eef2;
    border-radius: 5px; }
    .modal_tags .modal_tags-list .modal_tags-list-item {
      display: flex;
      flex-direction: row;
      min-height: 52px;
      border-bottom: solid 1px #e9eef2;
      align-items: center;
      padding: 0 15px;
      justify-content: space-between;
      cursor: pointer; }
      .modal_tags .modal_tags-list .modal_tags-list-item:last-child {
        border-bottom: none; }
      .modal_tags .modal_tags-list .modal_tags-list-item:hover {
        background-color: #f6f7f9; }
        .modal_tags .modal_tags-list .modal_tags-list-item:hover .modal_tags-list-item-select .modal_tags-arrow {
          padding: 10px;
          display: block; }
        .modal_tags .modal_tags-list .modal_tags-list-item:hover .modal_tags-list-item-act {
          color: #000000; }
      .modal_tags .modal_tags-list .modal_tags-list-item .modal_tags-list-item-select {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: auto;
        margin-right: 10px;
        position: relative;
        cursor: pointer; }
        .modal_tags .modal_tags-list .modal_tags-list-item .modal_tags-list-item-select:hover .menu_color {
          display: block; }
        .modal_tags .modal_tags-list .modal_tags-list-item .modal_tags-list-item-select .modal_tags-color {
          border-radius: 50%;
          height: 16px;
          width: 16px;
          margin-right: 5px;
          border: 1px solid #dddfe2;
          background-color: red; }
        .modal_tags .modal_tags-list .modal_tags-list-item .modal_tags-list-item-select .menu_color {
          display: none;
          position: absolute;
          background-color: #fff;
          height: 52px;
          padding: 15px;
          top: 35px;
          left: 15px;
          border: 0;
          border-radius: 2px;
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 10px rgba(0, 0, 0, 0.35);
          z-index: 1; }
          .modal_tags .modal_tags-list .modal_tags-list-item .modal_tags-list-item-select .menu_color .menu_color-list {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: relative; }
            .modal_tags .modal_tags-list .modal_tags-list-item .modal_tags-list-item-select .menu_color .menu_color-list::before {
              position: absolute;
              content: " ";
              width: 0px;
              height: 0px;
              border-left: 8px solid transparent;
              border-right: 8px solid #0c0c0c00;
              border-bottom: 8px solid #ccc;
              bottom: 39px;
              left: 0px; }
            .modal_tags .modal_tags-list .modal_tags-list-item .modal_tags-list-item-select .menu_color .menu_color-list .modal_tags-color {
              border-radius: 50%;
              border: 2px solid transparent;
              box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 10px rgba(0, 0, 0, 0.35);
              padding: 10px;
              margin: 0 5px; }
              .modal_tags .modal_tags-list .modal_tags-list-item .modal_tags-list-item-select .menu_color .menu_color-list .modal_tags-color:hover {
                border: 2px solid #dddfe2; }
        .modal_tags .modal_tags-list .modal_tags-list-item .modal_tags-list-item-select .modal_tags-arrow {
          display: none; }
      .modal_tags .modal_tags-list .modal_tags-list-item .modal_tags-list-item-name {
        display: flex;
        flex: 1;
        width: 100%; }
      .modal_tags .modal_tags-list .modal_tags-list-item .modal_tags-list-item-act {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #90949c; }
        .modal_tags .modal_tags-list .modal_tags-list-item .modal_tags-list-item-act .act_total {
          font-size: 16px;
          padding: 15px; }
        .modal_tags .modal_tags-list .modal_tags-list-item .modal_tags-list-item-act .act_remove {
          font-size: 20px; }

.modal_tags-color {
  border-radius: 50%;
  height: 16px;
  width: 16px;
  margin-right: 5px;
  border: 1px solid #dddfe2; }

/*---------------------------Cuộc chat------------------------*/
.container_conversation {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: row; }
  .container_conversation .left {
    background-color: white;
    flex: 0 0 25%;
    max-width: 420px;
    min-width: 240px; }
    .container_conversation .left .filter {
      position: relative; }
      .container_conversation .left .filter .left-filter {
        height: 56px;
        border-bottom: 1px solid #e9ebee;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0px 20px 0px 16px;
        justify-content: space-between;
        color: #90949c;
        font-size: 14px;
        font-weight: 550; }
        .container_conversation .left .filter .left-filter .left-filter-icon-menu {
          user-select: none;
          display: flex;
          flex-direction: row;
          cursor: pointer;
          align-items: center; }
          .container_conversation .left .filter .left-filter .left-filter-icon-menu i {
            font-size: 24px;
            margin-right: 5px; }
          .container_conversation .left .filter .left-filter .left-filter-icon-menu:hover {
            color: #4b4f56; }
        .container_conversation .left .filter .left-filter .left-filter-input-menu {
          display: none;
          flex: 1;
          padding: 0px 5px 0px 5px; }
          .container_conversation .left .filter .left-filter .left-filter-input-menu .form-group {
            margin-bottom: 0; }
          .container_conversation .left .filter .left-filter .left-filter-input-menu.show {
            display: block; }
          .container_conversation .left .filter .left-filter .left-filter-input-menu input {
            width: 100%;
            border: none; }
            .container_conversation .left .filter .left-filter .left-filter-input-menu input::placeholder {
              color: #cecece; }
            .container_conversation .left .filter .left-filter .left-filter-input-menu input:focus {
              outline: none; }
        .container_conversation .left .filter .left-filter .left-filter-icon-more {
          user-select: none;
          cursor: pointer; }
          .container_conversation .left .filter .left-filter .left-filter-icon-more.hide {
            display: none; }
          .container_conversation .left .filter .left-filter .left-filter-icon-more i {
            font-size: 24px; }
          .container_conversation .left .filter .left-filter .left-filter-icon-more:hover {
            color: #4b4f56; }
      .container_conversation .left .filter .filter-menu {
        position: absolute;
        width: 100%;
        background-color: #fff;
        border-bottom: 1px solid #dddfe2;
        box-shadow: 0 4px 4px 1px rgba(206, 208, 212, 0.6);
        box-sizing: border-box;
        display: none;
        z-index: 1; }
        .container_conversation .left .filter .filter-menu.show {
          display: block; }
        .container_conversation .left .filter .filter-menu .filter-menu-item {
          cursor: pointer;
          height: 56px;
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 0px 20px 0px 16px;
          color: #90949c;
          font-size: 14px;
          font-weight: 500;
          flex-direction: row;
          align-items: center; }
          .container_conversation .left .filter .filter-menu .filter-menu-item i {
            font-size: 24px;
            margin-right: 16px; }
          .container_conversation .left .filter .filter-menu .filter-menu-item:hover {
            background-color: #f6f7f9; }
          .container_conversation .left .filter .filter-menu .filter-menu-item.active {
            background-color: #f6f7f9; }
            .container_conversation .left .filter .filter-menu .filter-menu-item.active i {
              color: #42b72a; }
    .container_conversation .left .left-content {
      max-height: 550px;
      overflow-y: scroll; }
      .container_conversation .left .left-content .left-content-empty {
        background-image: url("/static/media/icon/empty-inbox.svg");
        width: 150px;
        height: 150px;
        background-size: cover;
        display: flex;
        align-self: center;
        margin-top: 40%;
        justify-content: center; }
        .container_conversation .left .left-content .left-content-empty .txt {
          display: flex;
          flex-direction: column-reverse;
          color: #b7b7b7;
          font-weight: 500;
          margin-top: 15px; }
      .container_conversation .left .left-content .left-content-item {
        border-bottom: 1px solid #e9ebee;
        cursor: pointer;
        height: 72px;
        overflow: hidden;
        position: relative;
        transition: height 500ms;
        transition-delay: 500ms;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 0px 0px 16px;
        justify-content: space-between; }
        .container_conversation .left .left-content .left-content-item .avatar {
          border: 0;
          background: none; }
          .container_conversation .left .left-content .left-content-item .avatar img {
            max-width: 40px;
            max-height: 40px; }
        .container_conversation .left .left-content .left-content-item:hover, .container_conversation .left .left-content .left-content-item.active {
          background-color: #f6f7f9; }
        .container_conversation .left .left-content .left-content-item .preview {
          display: flex;
          flex-direction: column;
          padding-left: 10px;
          flex: 1; }
          .container_conversation .left .left-content .left-content-item .preview .desc {
            font-size: 12px; }
          .container_conversation .left .left-content .left-content-item .preview .name {
            font-weight: 500; }
          .container_conversation .left .left-content .left-content-item .preview .tag {
            display: flex;
            font-size: 12px; }
          .container_conversation .left .left-content .left-content-item .preview .staff {
            display: flex;
            flex-direction: row;
            align-items: center; }
            .container_conversation .left .left-content .left-content-item .preview .staff .staff-avatar {
              margin-right: 5px; }
            .container_conversation .left .left-content .left-content-item .preview .staff .staff-desc {
              font-size: 12px; }
        .container_conversation .left .left-content .left-content-item .act {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          height: 100%; }
          .container_conversation .left .left-content .left-content-item .act:hover .done {
            background-color: #42b72a; }
            .container_conversation .left .left-content .left-content-item .act:hover .done i {
              color: white; }
          .container_conversation .left .left-content .left-content-item .act .active {
            background-color: #42b72a; }
            .container_conversation .left .left-content .left-content-item .act .active i {
              color: white !important; }
          .container_conversation .left .left-content .left-content-item .act .done {
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: background-color .2s; }
            .container_conversation .left .left-content .left-content-item .act .done i {
              font-size: 24px;
              color: #abadb3;
              transition: color .2s; }
  .container_conversation .body {
    display: flex;
    flex: 3;
    flex-direction: row;
    min-width: 0;
    border-left: solid 1px #dddfe2; }
    .container_conversation .body .container_x {
      flex: 2 0 0%;
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .container_conversation .body .container_x .container_x-head {
        display: flex;
        height: 56px;
        background-color: #fff;
        border-bottom: 1px solid #dddfe2;
        align-items: center;
        justify-content: space-between;
        padding-left: 16px; }
        .container_conversation .body .container_x .container_x-head .container_x-head-profile {
          display: flex;
          flex-direction: row; }
          .container_conversation .body .container_x .container_x-head .container_x-head-profile .profile-avatar {
            display: flex;
            justify-content: center;
            align-self: center;
            margin-right: 15px;
            width: 40px;
            height: 40px; }
          .container_conversation .body .container_x .container_x-head .container_x-head-profile .profile-desc {
            display: flex;
            flex-direction: column; }
            .container_conversation .body .container_x .container_x-head .container_x-head-profile .profile-desc .profile-desc-name {
              font-weight: 500; }
            .container_conversation .body .container_x .container_x-head .container_x-head-profile .profile-desc .profile-desc-staff {
              font-size: 13px; }
        .container_conversation .body .container_x .container_x-head .container_x-head-act-btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center; }
          .container_conversation .body .container_x .container_x-head .container_x-head-act-btn i {
            font-size: 24px;
            font-weight: 500;
            color: #90949c; }
          .container_conversation .body .container_x .container_x-head .container_x-head-act-btn .container_x-head-act-btn-spam,
          .container_conversation .body .container_x .container_x-head .container_x-head-act-btn .container_x-head-act-btn-unread {
            height: 100%;
            margin-right: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer; }
            .container_conversation .body .container_x .container_x-head .container_x-head-act-btn .container_x-head-act-btn-spam:hover i,
            .container_conversation .body .container_x .container_x-head .container_x-head-act-btn .container_x-head-act-btn-unread:hover i {
              color: #4b4f56; }
        .container_conversation .body .container_x .container_x-head .container_x-head-act {
          display: flex;
          flex-direction: row;
          height: 100%; }
          .container_conversation .body .container_x .container_x-head .container_x-head-act .container_x-head-act-item {
            width: 130px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-weight: 500;
            position: relative;
            user-select: none;
            color: white;
            transition: all 200ms;
            background: #bfbfbf; }
            .container_conversation .body .container_x .container_x-head .container_x-head-act .container_x-head-act-item i {
              font-size: 18px;
              margin-right: 5px; }
            .container_conversation .body .container_x .container_x-head .container_x-head-act .container_x-head-act-item:hover, .container_conversation .body .container_x .container_x-head .container_x-head-act .container_x-head-act-item.active {
              background-color: #2d8a2d;
              color: white;
              transition: all 200ms; }
              .container_conversation .body .container_x .container_x-head .container_x-head-act .container_x-head-act-item:hover::before, .container_conversation .body .container_x .container_x-head .container_x-head-act .container_x-head-act-item.active::before {
                width: 0px; }
            .container_conversation .body .container_x .container_x-head .container_x-head-act .container_x-head-act-item.spam {
              background: none;
              width: 80px; }
              .container_conversation .body .container_x .container_x-head .container_x-head-act .container_x-head-act-item.spam i {
                color: #333333;
                font-size: 30px;
                margin: 0; }
                .container_conversation .body .container_x .container_x-head .container_x-head-act .container_x-head-act-item.spam i:hover {
                  color: REd; }
      .container_conversation .body .container_x .container_x-body {
        display: flex;
        flex: 1;
        padding: 15px;
        position: relative; }
        .container_conversation .body .container_x .container_x-body::after {
          content: " ";
          background-image: url(/static/media/bg/app-bg.jpg);
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: -1;
          opacity: 0.8;
          padding: inherit;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0; }
        .container_conversation .body .container_x .container_x-body .container_x-body-list {
          width: 100%;
          max-height: 425px;
          overflow-y: scroll;
          overflow-x: hidden;
          padding-right: 5px; }
          .container_conversation .body .container_x .container_x-body .container_x-body-list .container_x-body-list-time {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px; }
            .container_conversation .body .container_x .container_x-body .container_x-body-list .container_x-body-list-time .line-body {
              position: relative;
              display: flex;
              justify-content: center;
              padding: 0 15px;
              font-weight: 500;
              color: #FF5630; }
            .container_conversation .body .container_x .container_x-body .container_x-body-list .container_x-body-list-time .line-after,
            .container_conversation .body .container_x .container_x-body .container_x-body-list .container_x-body-list-time .line-before {
              display: flex;
              position: relative;
              flex: 1; }
              .container_conversation .body .container_x .container_x-body .container_x-body-list .container_x-body-list-time .line-after::before,
              .container_conversation .body .container_x .container_x-body .container_x-body-list .container_x-body-list-time .line-before::before {
                position: absolute;
                content: " ";
                width: 100%;
                height: 1px;
                background-color: #cacaca; }
          .container_conversation .body .container_x .container_x-body .container_x-body-list .container_x-body-list-item-timeago {
            margin-top: 10px;
            font-size: 13px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            color: #90949c; }
          .container_conversation .body .container_x .container_x-body .container_x-body-list .container_x-body-list-item {
            margin-bottom: 15px;
            display: flex; }
          .container_conversation .body .container_x .container_x-body .container_x-body-list .container_x-body-list-item-content {
            max-width: 70%;
            padding: 15px;
            border-radius: 8px;
            position: relative; }
          .container_conversation .body .container_x .container_x-body .container_x-body-list .container_x-body-list-item-client {
            flex-direction: row; }
            .container_conversation .body .container_x .container_x-body .container_x-body-list .container_x-body-list-item-client .container_x-body-list-item-client-avatar {
              display: flex;
              flex-direction: column-reverse; }
            .container_conversation .body .container_x .container_x-body .container_x-body-list .container_x-body-list-item-client .container_x-body-list-item-client-content {
              background: #ececec;
              margin-left: 20px; }
              .container_conversation .body .container_x .container_x-body .container_x-body-list .container_x-body-list-item-client .container_x-body-list-item-client-content::before {
                content: " ";
                background-image: url(/static/media/icon/leftArrow.png);
                position: absolute;
                left: -18px;
                bottom: 12px;
                width: 20px;
                height: 20px;
                background-size: cover; }
          .container_conversation .body .container_x .container_x-body .container_x-body-list .container_x-body-list-item-staff {
            flex-direction: row-reverse; }
            .container_conversation .body .container_x .container_x-body .container_x-body-list .container_x-body-list-item-staff .container_x-body-list-item-staff-content {
              background: #ddf8c6;
              margin-left: 10px; }
              .container_conversation .body .container_x .container_x-body .container_x-body-list .container_x-body-list-item-staff .container_x-body-list-item-staff-content::after {
                content: " ";
                background-image: url(/static/media/icon/rightArrow.png);
                position: absolute;
                width: 20px;
                height: 20px;
                background-size: cover;
                right: -20px;
                bottom: 8px; }
      .container_conversation .body .container_x .container_x-footer {
        display: flex;
        height: 150px;
        border-top: solid 1px #dddfe2;
        flex-direction: column; }
        .container_conversation .body .container_x .container_x-footer .container_x-footer-notify {
          display: flex;
          flex-direction: row;
          height: 20px;
          bottom: 5px;
          padding: 0 10px; }
          .container_conversation .body .container_x .container_x-footer .container_x-footer-notify .notify {
            font-size: 13px;
            color: #90949c; }
            .container_conversation .body .container_x .container_x-footer .container_x-footer-notify .notify i {
              font-size: 16px; }
        .container_conversation .body .container_x .container_x-footer .container_x-footer-text {
          display: flex;
          min-height: 60px;
          flex-direction: row;
          justify-content: space-between;
          align-items: center; }
          .container_conversation .body .container_x .container_x-footer .container_x-footer-text .container_x-footer-text-avatar {
            padding: 0 8px;
            width: 40px;
            height: 40px; }
            .container_conversation .body .container_x .container_x-footer .container_x-footer-text .container_x-footer-text-avatar .avatar {
              border-radius: 0px;
              width: 32px;
              height: 32px; }
          .container_conversation .body .container_x .container_x-footer .container_x-footer-text .container_x-footer-text-input {
            display: flex;
            flex: 1; }
            .container_conversation .body .container_x .container_x-footer .container_x-footer-text .container_x-footer-text-input .form-group {
              width: 100%;
              height: 100%; }
            .container_conversation .body .container_x .container_x-footer .container_x-footer-text .container_x-footer-text-input textarea {
              border: none;
              resize: none; }
              .container_conversation .body .container_x .container_x-footer .container_x-footer-text .container_x-footer-text-input textarea:focus {
                outline: none;
                border: none;
                box-shadow: none; }
          .container_conversation .body .container_x .container_x-footer .container_x-footer-text .container_x-footer-text-act {
            display: flex;
            color: #90949c;
            font-weight: 500;
            align-content: center;
            align-items: center; }
            .container_conversation .body .container_x .container_x-footer .container_x-footer-text .container_x-footer-text-act i {
              font-size: 24px; }
            .container_conversation .body .container_x .container_x-footer .container_x-footer-text .container_x-footer-text-act div {
              padding: 0 8px; }
    .container_conversation .body .right {
      max-height: 600px;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      flex: 0 2 33.33%;
      max-width: 420px;
      min-width: 200px;
      flex-direction: column;
      overflow: hidden;
      overflow-y: scroll; }
      .container_conversation .body .right .right-col {
        display: flex;
        padding: 10px 24px;
        flex-direction: column;
        min-height: 130px; }
        .container_conversation .body .right .right-col:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
      .container_conversation .body .right .chat_info input {
        border: 0;
        border-bottom: 1px solid transparent; }
        .container_conversation .body .right .chat_info input:hover, .container_conversation .body .right .chat_info input:focus, .container_conversation .body .right .chat_info input:active {
          border-bottom: 1px solid #2d8a2d; }
      .container_conversation .body .right .chat_info .form-group {
        margin-bottom: 0; }
        .container_conversation .body .right .chat_info .form-group .icon_input_left i {
          color: black; }
        .container_conversation .body .right .chat_info .form-group .icon_input_left {
          width: 15px; }
      .container_conversation .body .right .chat_info .right-item {
        margin-bottom: 0; }
        .container_conversation .body .right .chat_info .right-item:hover .edit {
          opacity: 1;
          visibility: inherit; }
        .container_conversation .body .right .chat_info .right-item .edit {
          position: absolute;
          right: 0;
          top: 0;
          opacity: 0;
          visibility: hidden;
          transition: all 0.5s; }
          .container_conversation .body .right .chat_info .right-item .edit i {
            padding: 10px; }
      .container_conversation .body .right .right-two,
      .container_conversation .body .right .right-three,
      .container_conversation .body .right .right-four {
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
        .container_conversation .body .right .right-two .right-two-tag,
        .container_conversation .body .right .right-three .right-two-tag,
        .container_conversation .body .right .right-four .right-two-tag {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          align-items: center; }
          .container_conversation .body .right .right-two .right-two-tag .right-two-tag-title,
          .container_conversation .body .right .right-three .right-two-tag .right-two-tag-title,
          .container_conversation .body .right .right-four .right-two-tag .right-two-tag-title {
            font-weight: 500; }
        .container_conversation .body .right .right-two .right-two-tag-list,
        .container_conversation .body .right .right-three .right-two-tag-list,
        .container_conversation .body .right .right-four .right-two-tag-list {
          display: flex;
          flex: 1;
          flex-wrap: wrap; }
          .container_conversation .body .right .right-two .right-two-tag-list .ant-tag,
          .container_conversation .body .right .right-three .right-two-tag-list .ant-tag,
          .container_conversation .body .right .right-four .right-two-tag-list .ant-tag {
            margin-bottom: 5px; }
        .container_conversation .body .right .right-two .right-two-tag-input,
        .container_conversation .body .right .right-three .right-two-tag-input,
        .container_conversation .body .right .right-four .right-two-tag-input {
          display: flex; }
          .container_conversation .body .right .right-two .right-two-tag-input .ant-select-show-search,
          .container_conversation .body .right .right-three .right-two-tag-input .ant-select-show-search,
          .container_conversation .body .right .right-four .right-two-tag-input .ant-select-show-search {
            width: 100%; }
            .container_conversation .body .right .right-two .right-two-tag-input .ant-select-show-search input,
            .container_conversation .body .right .right-three .right-two-tag-input .ant-select-show-search input,
            .container_conversation .body .right .right-four .right-two-tag-input .ant-select-show-search input {
              width: 100%; }
              .container_conversation .body .right .right-two .right-two-tag-input .ant-select-show-search input:hover,
              .container_conversation .body .right .right-three .right-two-tag-input .ant-select-show-search input:hover,
              .container_conversation .body .right .right-four .right-two-tag-input .ant-select-show-search input:hover {
                border-color: #cecece; }
              .container_conversation .body .right .right-two .right-two-tag-input .ant-select-show-search input::placeholder,
              .container_conversation .body .right .right-three .right-two-tag-input .ant-select-show-search input::placeholder,
              .container_conversation .body .right .right-four .right-two-tag-input .ant-select-show-search input::placeholder {
                color: #cecece; }
              .container_conversation .body .right .right-two .right-two-tag-input .ant-select-show-search input:focus,
              .container_conversation .body .right .right-three .right-two-tag-input .ant-select-show-search input:focus,
              .container_conversation .body .right .right-four .right-two-tag-input .ant-select-show-search input:focus {
                outline: none;
                border-color: #cecece;
                box-shadow: none; }
        .container_conversation .body .right .right-two .right-three_content,
        .container_conversation .body .right .right-three .right-three_content,
        .container_conversation .body .right .right-four .right-three_content {
          display: none;
          flex: 1;
          font-size: 13px; }
        .container_conversation .body .right .right-two .right-three_textarea,
        .container_conversation .body .right .right-three .right-three_textarea,
        .container_conversation .body .right .right-four .right-three_textarea {
          display: block; }
          .container_conversation .body .right .right-two .right-three_textarea .note,
          .container_conversation .body .right .right-three .right-three_textarea .note,
          .container_conversation .body .right .right-four .right-three_textarea .note {
            display: flex;
            flex-direction: column;
            justify-content: space-between; }
            .container_conversation .body .right .right-two .right-three_textarea .note .text-area,
            .container_conversation .body .right .right-three .right-three_textarea .note .text-area,
            .container_conversation .body .right .right-four .right-three_textarea .note .text-area {
              width: 100%; }
              .container_conversation .body .right .right-two .right-three_textarea .note .text-area textarea,
              .container_conversation .body .right .right-three .right-three_textarea .note .text-area textarea,
              .container_conversation .body .right .right-four .right-three_textarea .note .text-area textarea {
                width: 100%; }
                .container_conversation .body .right .right-two .right-three_textarea .note .text-area textarea:hover,
                .container_conversation .body .right .right-three .right-three_textarea .note .text-area textarea:hover,
                .container_conversation .body .right .right-four .right-three_textarea .note .text-area textarea:hover {
                  border-color: #cecece; }
                .container_conversation .body .right .right-two .right-three_textarea .note .text-area textarea::placeholder,
                .container_conversation .body .right .right-three .right-three_textarea .note .text-area textarea::placeholder,
                .container_conversation .body .right .right-four .right-three_textarea .note .text-area textarea::placeholder {
                  color: #cecece; }
                .container_conversation .body .right .right-two .right-three_textarea .note .text-area textarea:focus,
                .container_conversation .body .right .right-three .right-three_textarea .note .text-area textarea:focus,
                .container_conversation .body .right .right-four .right-three_textarea .note .text-area textarea:focus {
                  outline: none !important;
                  border-color: #cecece;
                  box-shadow: none; }
            .container_conversation .body .right .right-two .right-three_textarea .note .btn-s,
            .container_conversation .body .right .right-three .right-three_textarea .note .btn-s,
            .container_conversation .body .right .right-four .right-three_textarea .note .btn-s {
              display: flex;
              margin-bottom: 5px;
              flex-direction: row;
              justify-content: space-between; }
        .container_conversation .body .right .right-two .right-four_content,
        .container_conversation .body .right .right-three .right-four_content,
        .container_conversation .body .right .right-four .right-four_content {
          display: flex;
          flex: 1;
          flex-direction: column; }
          .container_conversation .body .right .right-two .right-four_content .right-four-item,
          .container_conversation .body .right .right-three .right-four_content .right-four-item,
          .container_conversation .body .right .right-four .right-four_content .right-four-item {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px; }
            .container_conversation .body .right .right-two .right-four_content .right-four-item .value,
            .container_conversation .body .right .right-three .right-four_content .right-four-item .value,
            .container_conversation .body .right .right-four .right-four_content .right-four-item .value {
              font-size: 13px;
              color: #90949c; }
      .container_conversation .body .right .right-item {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        justify-content: space-between;
        position: relative; }
        .container_conversation .body .right .right-item .icon {
          width: 30px; }
          .container_conversation .body .right .right-item .icon i {
            font-size: 16px; }
        .container_conversation .body .right .right-item .value {
          display: flex;
          flex: 1; }
      .container_conversation .body .right .right-title {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 10px;
        color: #1d374b; }
    .container_conversation .body .tag_root {
      flex: 1; }
      .container_conversation .body .tag_root .tag {
        width: 49%;
        margin-right: 1%; }
      .container_conversation .body .tag_root .tag:nth-child(2n) {
        margin-right: 0; }

/*-----Modules Products-------*/
.preview_appearance_wel {
  width: 100%; }
  .preview_appearance_wel img {
    width: 100%; }

.preview_appearance {
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 32px 0 rgba(34, 34, 34, 0.14), 0 1px 6px 0 rgba(34, 34, 34, 0.06);
  border-radius: 8px;
  border: 1px solid #eee; }
  .preview_appearance .header {
    height: 57px;
    border-radius: 8px 8px 0px 0px;
    transition: background-color .2s;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px; }
    .preview_appearance .header .icon i {
      font-weight: bold; }
    .preview_appearance .header .profile {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 15px; }
      .preview_appearance .header .profile .avatar {
        margin-right: 10px; }
      .preview_appearance .header .profile .info .desc {
        font-size: 12px; }
  .preview_appearance .body {
    height: 339px;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    padding: 15px; }
    .preview_appearance .body.bg0 {
      background-color: white; }
    .preview_appearance .body.bg1 {
      background-image: url(/static/media/bg/1.png);
      background-size: contain; }
    .preview_appearance .body.bg2 {
      background-image: url(/static/media/bg/2.png);
      background-size: contain; }
    .preview_appearance .body.bg3 {
      background-image: url(/static/media/bg/3.png);
      background-size: contain; }
    .preview_appearance .body.bg4 {
      background-image: url(/static/media/bg/4.png);
      background-size: contain; }
    .preview_appearance .body.bg5 {
      background-image: url(/static/media/bg/5.png);
      background-size: contain; }
    .preview_appearance .body.bg6 {
      background-image: url(/static/media/bg/6.png);
      background-size: contain; }
    .preview_appearance .body.bg7 {
      background-image: url(/static/media/bg/7.png); }
    .preview_appearance .body.bg8 {
      background-image: url(/static/media/bg/8.png);
      background-size: contain; }
    .preview_appearance .body .time {
      color: #ccc;
      font-size: 12px;
      text-align: center;
      padding: 10px; }
    .preview_appearance .body .from {
      display: flex; }
      .preview_appearance .body .from .msg {
        color: #6E7A89;
        padding: 15px;
        border-radius: 6px;
        transition: background-color .2s;
        background-color: #EFF3F6;
        max-width: 80%; }
    .preview_appearance .body .client {
      display: flex;
      flex-direction: column; }
      .preview_appearance .body .client .msg1 {
        display: flex;
        flex-direction: row-reverse; }
        .preview_appearance .body .client .msg1 .msg {
          color: #fff;
          padding: 15px;
          border-radius: 6px;
          transition: background-color .2s;
          max-width: 80%; }
      .preview_appearance .body .client .time_ago {
        display: flex;
        justify-content: flex-end;
        color: #ccc;
        font-size: 12px;
        margin-top: 5px; }
  .preview_appearance .footer {
    height: 40px;
    background-color: #f6f6f6;
    border-radius: 0px 0px 8px 8px;
    display: flex;
    color: #888;
    justify-content: space-around;
    align-items: center; }
    .preview_appearance .footer .input {
      font-size: 12px; }

/*-- Fix all --*/
/*-- Fix Width,Height Menu ---*/
/*-- Fix ---*/
/*-- Bpos ---*/
/*-- Themes Color ---*/
/*-- Themes Background ---*/
.cke_dialog.cke_browser_webkit.cke_ltr {
  max-width: 500px !important; }

.setting_general {
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 80px !important; }
  .setting_general_header_line canvas {
    width: 100% !important;
    height: auto !important; }
  .setting_general_email_chart .setting_general_header {
    background: #eeeeee;
    border: 1px solid #d6d6d6; }
    .setting_general_email_chart .setting_general_header label {
      margin: 0;
      padding: 10px 15px; }
  .setting_general_email_chart_title {
    font-size: 18px;
    color: #000;
    margin: 15px 0;
    text-transform: capitalize;
    width: 100%;
    overflow: hidden; }
  .setting_general_email_chart_caption {
    float: left; }
    .setting_general_email_chart_caption p:nth-child(1) span {
      background: #ee2633; }
    .setting_general_email_chart_caption p:nth-child(2) span {
      background: #a5cad9; }
    .setting_general_email_chart_caption p span {
      width: 10px;
      height: 10px;
      display: inline-block; }
  .setting_general_email_chart_caption_circle {
    margin: 15px 0 0;
    display: flex;
    justify-content: center;
    text-align: center;
    float: none; }
    .setting_general_email_chart_caption_circle p {
      padding: 0 10px;
      display: inline-block; }
  .setting_general .printed_form {
    margin: 0 -15px; }
    .setting_general .printed_form .printed_form_header h3 {
      text-align: center;
      margin: 0 0 13px 0; }
    .setting_general .printed_form .printed_form_design {
      margin-top: 15px; }
      .setting_general .printed_form .printed_form_design .printed_form_title {
        background-color: #fafafa;
        border: 1px solid #cccccc;
        height: 32px;
        border-radius: 4px;
        line-height: 30px; }
      .setting_general .printed_form .printed_form_design button.btn-primary {
        margin-top: 15px;
        margin-right: 15px;
        float: left; }
      .setting_general .printed_form .printed_form_design .printed_form_view {
        margin-top: 15px; }
        @media print {
          .setting_general .printed_form .printed_form_design .printed_form_view html, .setting_general .printed_form .printed_form_design .printed_form_view body, .setting_general .printed_form .printed_form_design .printed_form_view table {
            font-family: monospace !important; } }
        .setting_general .printed_form .printed_form_design .printed_form_view .printed_form_view_content {
          width: 100%;
          max-width: 100%;
          margin: 0 auto;
          display: block;
          min-height: 400px;
          max-height: 400px;
          box-shadow: 0px 0px 4px #b5b5b5;
          overflow-y: auto;
          padding: 15px;
          font-family: 'Starjout'; }
          .setting_general .printed_form .printed_form_design .printed_form_view .printed_form_view_content table tr td:nth-child(1) {
            text-align: left; }
        .setting_general .printed_form .printed_form_design .printed_form_view iframe {
          width: 100%;
          max-width: 100%;
          margin: 0 auto;
          display: block;
          min-height: 400px;
          box-shadow: 0px 0px 4px #b5b5b5;
          overflow-y: scroll; }
  .setting_general .setting_general_item {
    margin-bottom: 15px; }
    .setting_general .setting_general_item:last-child {
      margin: 0; }
    .setting_general .setting_general_item p {
      margin: 0; }
    .setting_general .setting_general_item .setting_general_header {
      background: #eeeeee;
      border: 1px solid #d6d6d6; }
      .setting_general .setting_general_item .setting_general_header label {
        margin: 0;
        padding: 10px 15px; }
        .setting_general .setting_general_item .setting_general_header label i {
          font-weight: normal; }
    .setting_general .setting_general_item .setting_general_content {
      border: 1px solid #d6d6d6;
      border-top: 0;
      background: #fff;
      display: flex;
      flex-direction: column;
      position: relative; }
      .setting_general .setting_general_item .setting_general_content .setting_general_content_item {
        display: flex;
        flex: 1;
        padding: 15px;
        border-bottom: 1px solid #d6d6d6;
        position: relative; }
        .setting_general .setting_general_item .setting_general_content .setting_general_content_item:last-child {
          border: 0; }
        .setting_general .setting_general_item .setting_general_content .setting_general_content_item.on_off {
          flex-direction: row; }
          .setting_general .setting_general_item .setting_general_content .setting_general_content_item.on_off .setting_audio {
            display: flex;
            flex: 0;
            margin: 0; }
          .setting_general .setting_general_item .setting_general_content .setting_general_content_item.on_off p {
            display: flex;
            flex: 9; }
          .setting_general .setting_general_item .setting_general_content .setting_general_content_item.on_off .on_off_content {
            padding-left: 15px; }
        .setting_general .setting_general_item .setting_general_content .setting_general_content_item.item_height .setting_general_edit_item {
          top: 20px; }
        .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_icon {
          display: flex;
          flex: 0.5 1;
          float: left;
          min-width: 50px; }
          .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_icon i {
            font-size: 30px; }
        .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content {
          display: flex;
          flex: 9.5;
          flex-direction: column;
          padding-right: 70px;
          position: relative; }
          .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content.not_p label {
            margin-top: 13px; }
          .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content .form-group:last-child {
            margin-bottom: 15px; }
        .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content_sub {
          display: none;
          transition: max-height .5s ease-in;
          margin-top: 10px; }
          .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content_sub.show {
            display: block; }
          .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content_sub .note_sdt {
            font-size: 13px;
            color: #8a8a8a;
            margin-bottom: 15px; }
          .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content_sub select {
            width: 100%; }
          .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content_sub .list_content_title {
            display: table;
            width: 100%;
            margin: 15px 0;
            font-weight: bold; }
            .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content_sub .list_content_title p:nth-child(1) {
              width: 30%;
              float: left; }
            .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content_sub .list_content_title p:nth-child(2) {
              width: 70%;
              float: left;
              padding-left: 15px; }
          .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content_sub.sms_on_customer_birthday .col_right .form-group:last-child {
            margin-top: 30px; }
          .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content_sub.sms_on_customer_birthday .sms_on_customer_birthday_note {
            margin-bottom: 15px;
            border: 1px solid #efe3a5; }
            .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content_sub.sms_on_customer_birthday .sms_on_customer_birthday_note p {
              color: #8a6d3b;
              background-color: #fcf8e3;
              border-color: #faebcc;
              padding: 10px; }
              .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content_sub.sms_on_customer_birthday .sms_on_customer_birthday_note p span {
                color: #a94442;
                font-weight: bold; }
          .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content_sub.sms_before_customer_birthday .sms_before_customer_birthday_item {
            display: table;
            width: 100%;
            padding: 10px 0;
            border-top: 1px dashed #d2d2d2; }
            .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content_sub.sms_before_customer_birthday .sms_before_customer_birthday_item .sms_before_customer_birthday_item_day {
              width: 30%;
              float: left; }
            .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content_sub.sms_before_customer_birthday .sms_before_customer_birthday_item .sms_before_customer_birthday_item_content {
              width: 60%;
              float: left;
              padding: 0 15px; }
            .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content_sub.sms_before_customer_birthday .sms_before_customer_birthday_item .sms_before_customer_birthday_item_del {
              width: 10%;
              float: left; }
              .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content_sub.sms_before_customer_birthday .sms_before_customer_birthday_item .sms_before_customer_birthday_item_del button {
                min-width: auto;
                width: 100%;
                margin-top: 3px; }
          .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content_sub.sms_before_customer_birthday .block_action {
            display: flex;
            flex: 1;
            width: 200px;
            margin-top: 15px; }
            .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content_sub.sms_before_customer_birthday .block_action button {
              flex: 5;
              margin-right: 15px; }
          .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content_sub.create_order_retail .manager:hover .showList {
            opacity: 1;
            visibility: visible; }
      .setting_general .setting_general_item .setting_general_content .setting_general_edit_item {
        position: absolute;
        right: 15px;
        top: 10px; }
        .setting_general .setting_general_item .setting_general_content .setting_general_edit_item button {
          width: 60px;
          min-width: 0;
          font-size: 12px; }
          .setting_general .setting_general_item .setting_general_content .setting_general_edit_item button i {
            font-size: 12px;
            padding-right: 5px; }
    .setting_general .setting_general_item .is_input .form-group {
      position: absolute;
      width: 50px;
      right: 90px;
      top: 21px; }
      .setting_general .setting_general_item .is_input .form-group input {
        text-align: center; }
    .setting_general .setting_general_item .is_input.is_select .form-group {
      width: auto; }
    .setting_general .setting_general_item .is_input.status_sell .form-group {
      width: 200px;
      margin: 0 !important;
      position: static; }
    .setting_general .setting_general_item.info {
      width: 60%; }

.setting_general_content_info {
  width: 100%; }
  .setting_general_content_info_box .form-group:last-child {
    margin-bottom: 15px; }

.setting_on_off.disabled {
  pointer-events: none; }
  .setting_on_off.disabled .setting_on_off_ctrol {
    opacity: 0.5; }

.setting_on_off .setting_on_off_ctrol {
  max-width: 95px !important;
  background: #bfbfbf;
  height: 22px;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  user-select: none; }
  .setting_on_off .setting_on_off_ctrol.active {
    background: #28af6b; }
    .setting_on_off .setting_on_off_ctrol.active:after {
      left: calc(100% - 20px); }
    .setting_on_off .setting_on_off_ctrol.active .setting_on_off_status.on {
      opacity: 1; }
    .setting_on_off .setting_on_off_ctrol.active .setting_on_off_status.off {
      opacity: 0; }
  .setting_on_off .setting_on_off_ctrol .setting_on_off_status {
    color: white;
    position: absolute;
    top: 3px;
    font-size: 11px;
    background: none !important; }
    .setting_on_off .setting_on_off_ctrol .setting_on_off_status.on {
      left: 7px;
      opacity: 0; }
    .setting_on_off .setting_on_off_ctrol .setting_on_off_status.off {
      right: 8px; }
  .setting_on_off .setting_on_off_ctrol:after {
    content: '';
    width: 18px;
    height: 18px;
    background: white;
    border-radius: 50%;
    position: absolute;
    left: 2px;
    top: 2px;
    transition: all 160ms ease; }

/*-- Fix all --*/
/*-- Fix Width,Height Menu ---*/
/*-- Fix ---*/
/*-- Bpos ---*/
/*-- Themes Color ---*/
/*-- Themes Background ---*/
.general_product_filter {
  padding: 10px;
  background: #eeeeee;
  border: 1px solid #d6d6d6; }

/*----- Add Products ------*/
.list_of_products_modal .modal-dialog {
  max-height: 650px; }
  .list_of_products_modal .modal-dialog .modal .modal-footer button:nth-child(3) {
    display: none; }

.product_add_advanced .form-group:last-child {
  margin-bottom: 15px; }

.product_add.row {
  margin: 15px -15px 0; }

.product_add .product_upload_image {
  display: flex; }
  .product_add .product_upload_image_avatar {
    width: 180px;
    height: 180px;
    border: dashed 1px #ccc;
    border-radius: 5px;
    padding: 1px; }
    .product_add .product_upload_image_avatar img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      cursor: move; }

.product_add .product_add_category {
  display: flex;
  margin: 0 0 15px; }
  .product_add .product_add_category_btn_add {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 23px 0 0px 15px; }
  .product_add .product_add_category .product_add_category_select {
    display: flex;
    flex: 1; }

.product_upload_image_child.row {
  margin: 0 -10px; }

.product_upload_image_child ul {
  overflow: hidden;
  margin: 0 -10px; }

.product_upload_image_child0 {
  padding: 0 10px;
  margin: 0 0 15px;
  z-index: 9999; }
  .product_upload_image_child0_text {
    text-align: center; }
    .product_upload_image_child0_text p {
      margin: 3px 0 0 0;
      color: #b3b3b3; }
    .product_upload_image_child0_text i {
      font-size: 65px;
      margin: auto;
      color: #f5f5f5; }
  .product_upload_image_child0.avatar {
    display: block;
    background: none;
    width: 33.33%; }
  .product_upload_image_child0.avatar .product_upload_image_child0_img {
    border-color: red; }
  .product_upload_image_child0:hover .product_upload_image_child0_action {
    opacity: 1; }
  .product_upload_image_child0:hover .product_upload_image_child0_avatar, .product_upload_image_child0:hover .product_upload_image_child0_del {
    transform: translateY(0); }
  .product_upload_image_child0 img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    cursor: move;
    object-fit: scale-down; }
  .product_upload_image_child0_img {
    position: relative;
    height: 94px;
    border: dashed 1px #ccc;
    border-radius: 5px;
    cursor: move;
    overflow: hidden;
    background: #fff; }
    .product_upload_image_child0_img .spin {
      height: 94px;
      padding: 5px; }
  .product_upload_image_child0_action {
    text-align: center;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    opacity: 0;
    z-index: 2; }
  .product_upload_image_child0_avatar {
    background: #28af6b;
    padding: 5px;
    width: 100%;
    margin: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0 0 6px 6px;
    z-index: 2;
    transition: all ease .3s;
    transform: translateY(100px); }
    .product_upload_image_child0_avatar:hover {
      opacity: .9; }
  .product_upload_image_child0_del {
    font-size: 20px;
    position: absolute;
    top: 0px;
    right: 3px;
    color: #28af6b;
    z-index: 2;
    transition: all ease .3s;
    transform: translateY(-100px); }
    .product_upload_image_child0_del:hover {
      opacity: .9; }

.drap_and_drop_img_pr {
  border: 2px dashed #e6e6e6;
  cursor: pointer;
  margin-bottom: 1rem;
  border-radius: 2px;
  user-select: none; }
  .drap_and_drop_img_pr i {
    font-size: 40px;
    color: #a6b6c2; }
  .drap_and_drop_img_pr p {
    color: #677683; }

.right-two {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.right-two .right-two-tag {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center; }

.right-two-tag .right-two-tag-title {
  font-weight: 600; }

.right .right-two .right-two-tag-list {
  display: flex;
  flex: 1;
  flex-wrap: wrap; }

.right .right-two .right-two-tag-list .ant-tag {
  margin-bottom: 5px; }

.right .right-two .right-two-tag-input {
  display: flex; }

.right .right-two .right-two-tag-input .ant-select-show-search {
  width: 100%; }

.right .right-two .right-two-tag-input .ant-select-show-search input {
  width: 100%; }

.right .right-two .right-two-tag-input .ant-select-show-search input:hover {
  border-color: #cecece; }

.right .right-two .right-two-tag-input .ant-select-show-search input::placeholder {
  color: #cecece; }

.right .right-two .right-two-tag-input .ant-select-show-search input:focus {
  outline: none;
  border-color: #cecece;
  box-shadow: none; }

.load_type_receipts {
  font-weight: 600;
  cursor: pointer;
  background: #f2f2f2;
  height: 32px;
  border-radius: 2px;
  text-align: center;
  line-height: 32px; }
  .load_type_receipts_1 {
    position: absolute;
    right: 0;
    background: #f2f2f2;
    padding: 5px 10px;
    top: -9px;
    border-radius: 2px;
    font-weight: 600;
    cursor: pointer;
    z-index: 2; }

.load_type_receipts_btn {
  position: absolute;
  right: 0;
  top: -10px;
  height: 29px;
  line-height: 29px;
  padding: 0 25px;
  cursor: pointer;
  z-index: 1; }

.product_sort {
  display: none;
  position: absolute;
  text-align: left;
  left: 200px;
  top: 0; }
  .product_sort .show {
    display: block;
    z-index: 9999; }
  .product_sort .product_sort_child {
    position: absolute;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    right: 0;
    border: none;
    color: #fff;
    top: 35px;
    min-width: 200px;
    overflow: hidden !important;
    padding: 7px;
    background: #1cb467;
    z-index: 99; }
    .product_sort .product_sort_child:hover {
      opacity: 1; }
    .product_sort .product_sort_child .checkmark {
      border: none;
      background-color: #259c60 !important;
      border-radius: 3px; }
    .product_sort .product_sort_child.item_width button {
      min-width: 0;
      width: 100%;
      justify-content: left;
      margin-left: 0 !important; }
      .product_sort .product_sort_child.item_width button:not(:last-child) {
        border-bottom: 1px solid #28af6b;
        border-radius: 0; }

.table_product > tbody > .list_product:hover {
  background: #eaeaea !important;
  cursor: pointer;
  color: #000 !important; }
  .table_product > tbody > .list_product:hover .row9.text-right strong {
    color: red !important; }

.table_product > tbody > .list_product.tab:hover {
  background: none !important; }

.table_product thead tr th {
  border: none !important;
  border-bottom-width: 0; }

.content-list table {
  border-collapse: unset;
  overflow: unset; }

.content-list tbody {
  border: 1px solid #ffffff !important; }

.table_product_sub > tr:nth-child(odd) {
  background: #fff; }

.table_product_sub th {
  padding: 0 8px; }

.table_product_sub td {
  padding: 7px 0;
  padding-left: 0; }

tr.list_product .nxt_checkbox label {
  margin-bottom: 0; }

.list_product ul li a {
  margin: 0 10px; }

.list_product th .hidden {
  display: none; }

.list_product .row1 {
  width: 2%; }

.list_product .row2 {
  width: 10%; }

.list_product .row3 {
  width: 20%; }

.list_product .row4 {
  width: 5%; }

.list_product .row5 {
  width: 5%; }

.list_product .row6 {
  width: 5%; }

.list_product .row7 {
  width: 5%; }

.list_product .row8 {
  width: 5%; }

.list_product .row9 {
  width: 10%; }

.list_product .row10 {
  width: 13%; }

.k-content {
  border: none;
  padding: 15px;
  background: #fff;
  margin: 0;
  position: relative;
  overflow: visible;
  text-align: left;
  border-top: none; }
  .k-content.list-discount-tab .row {
    *border-bottom: 1px dashed #464646;
    *padding: 15px 0; }
  .k-content label {
    vertical-align: unset;
    margin-right: 10px; }
  .k-content .form-group {
    border-bottom: 1px solid #f5f5f5;
    padding: 7px 0; }
  .k-content .form-control-static {
    display: inline-block;
    min-height: auto;
    padding: 0; }
  .k-content .title {
    font-size: 18px;
    font-weight: 700;
    color: #28af6b;
    margin: 0 0 20px; }
  .k-content .d-flex {
    display: flex; }
  .k-content .product-detail-width {
    width: 330px; }
  .k-content .slick-slider {
    width: 100%; }
    .k-content .slick-slider div {
      outline: none; }
  .k-content .slick-dots {
    z-index: 1;
    position: static; }
    .k-content .slick-dots li, .k-content .slick-dots button {
      width: 10px;
      height: 10px; }
    .k-content .slick-dots li button:before {
      opacity: .25;
      color: black; }
    .k-content .slick-dots li:hover button:before, .k-content .slick-dots li.slick-active button:before {
      opacity: 1;
      color: #28af6b; }
  .k-content .slider1 .slick-prev, .k-content .slider1 .slick-next {
    z-index: 1; }
  .k-content .slider1 .slick-prev {
    left: 25px; }
    .k-content .slider1 .slick-prev:before {
      color: #a2a2a2; }
  .k-content .slider1 .slick-next {
    right: 25px; }
    .k-content .slider1 .slick-next:before {
      color: #a2a2a2; }
  .k-content .slider1 .image-detail-small {
    height: 230px;
    border: 1px solid #f5f5f5;
    margin: 0 !important;
    overflow: hidden; }
  .k-content .slider1 .col-md-12 {
    margin-bottom: 30px; }
  .k-content .slider2 .slick-current .image-detail-small {
    *border-color: #28af6b; }
  .k-content .slider2 .image-detail-small:hover {
    border-color: #28af6b; }
  .k-content .slider2 .slick-arrow {
    display: none !important; }
  .k-content .image-detail-small {
    border: 1px solid #f5f5f5;
    height: 65px;
    overflow: hidden; }

.rowgroup td {
  text-align: left;
  padding-left: 10px; }

.inventory-list td {
  text-align: right; }

.inventory-list table tr td:nth-child(1) {
  text-align: right; }

.print_select_option .form-group {
  margin-bottom: 21px; }
  .print_select_option .form-group:last-child {
    margin-bottom: 0; }

.w-barcode-right .row {
  margin: 0 -5px; }

.w-barcode-right [class*="col-"] {
  padding: 0 5px; }

.w-barcode-right .btn {
  min-width: 100%; }

.w-barcode-img {
  border: 1px solid #ddd;
  padding: 5px;
  height: 85px;
  border-radius: 6px; }

.w-barcode-title h2 {
  text-align: left;
  margin: 0 0 15px;
  font-size: 14px;
  color: #000;
  line-height: 20px;
  height: 35px; }

.w-barcode-btn {
  width: 100%;
  min-width: 100%; }

.modal_barcode .modal-title {
  font-weight: bold; }

.barcode_demo {
  border: 1px solid #f5f5f5;
  padding: 5px;
  text-align: center; }
  .barcode_demo svg {
    width: 160px !important; }

.barcode_item {
  float: 'left';
  width: '31%';
  padding: '7px';
  text-align: 'center';
  margin-right: "10px";
  height: "83px"; }
  .barcode_item .barcode_span {
    font-size: '17px';
    font-family: 'Tahoma';
    width: '100%';
    text-align: 'center';
    font-weight: "600"; }
  .barcode_item .barcode {
    justify-content: 'center';
    width: "100%";
    display: "flex"; }

.img_test {
  height: 100px;
  width: 100px; }

.btn-refesh i {
  width: 18px;
  height: 18px; }

/*----- Transfer_depot And Warehouse And StockTakes ------*/
.bpos_actions_main > .spin {
  height: 100vh; }

.transfer_add .number_sell_bpos {
  width: 50px; }

.transfer_bar input {
  width: 130px !important; }

.transfer_load_depot .css-1rtrksz {
  padding: 3px 8px !important; }

.transfer_load_depot .css-1ep9fjw, .transfer_load_depot .css-1sjym57 {
  padding: 4px 8px !important; }

.transfer_payment {
  min-height: calc(100% - 200px) !important; }

.transfer_action {
  height: 200px !important; }

.stocktakes_payment {
  min-height: calc(100% - 200px) !important; }

.stocktakes_action {
  height: 200px !important; }

.bpos-transfer .form-control-static {
  padding: 0; }

.table_transfer .nav-tabs {
  line-height: 0;
  display: flex; }

.warehouse_box_right .bpos_box_sell_right_box_payment {
  min-height: calc(100% - 200px); }

.warehouse_box_right .bpos_box_sell_right_box_content .form-group-btn-sell {
  height: 200px; }

.customer.warehouse .css-10nd86i {
  width: 200px; }

.customer.warehouse .css-1rtrksz {
  padding: 2px 0; }

table.table.table-result {
  font-weight: bold; }
  table.table.table-result thead {
    opacity: 0; }
  table.table.table-result tr:nth-child(2n) {
    background-color: #fff; }
  table.table.table-result tr td {
    border: none; }

.report_title {
  text-align: center;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 500; }

.report_date {
  text-align: center;
  margin: 5px 0 30px;
  font-style: italic;
  color: #c3c3c3;
  font-size: 13px; }

.table_report thead tr th {
  text-align: center;
  vertical-align: middle;
  border: 1px solid #ddd !important; }

.total_receipts {
  display: flex;
  justify-content: flex-end;
  background: #f3f3f3;
  padding: 10px 15px;
  margin: 0 0 15px; }
  .total_receipts div {
    padding: 0 0 0 20px;
    font-size: 14px;
    text-align: right; }
    .total_receipts div span {
      display: block;
      font-weight: 600;
      margin: 7px 0 0 0; }

/*== Modal Images ==*/
.modal_image_box {
  height: 90px;
  margin: 0 0 10px;
  border: dashed 1px #ccc;
  border-radius: 5px;
  overflow: hidden; }
  .modal_image_box:hover {
    border-color: red; }
  .modal_image_box_small {
    border: dashed 1px #ccc;
    border-radius: 5px;
    height: 60px;
    padding: 5px;
    position: relative;
    overflow: hidden;
    width: 100%; }
    .modal_image_box_small:hover .btn-delete-img {
      transform: translateY(0); }

.padding-5 {
  padding: 0 5px; }

.row_modal_images_choose {
  max-height: 200px;
  overflow-y: auto; }

.properties_active {
  color: red; }
  .properties_active:hover {
    color: red; }

.btn-delete-img {
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  top: 3px;
  right: 5px;
  color: #28af6b;
  z-index: 2;
  transition: all ease .3s;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  transform: translateY(-100px); }

.properties_images_box_row {
  width: 100%;
  margin: 0 -5px;
  overflow-x: auto !important;
  max-height: 90px;
  display: flex;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch; }
  .properties_images_box_row .col-md-2 {
    width: 70px;
    flex: 0 0 auto; }

.quick-edit button {
  width: 100%;
  min-width: 100%;
  padding: 6px 8px;
  border-radius: 4px; }

.quick-edit .row {
  margin: 0 -2px; }

.quick-edit [class*="col-"] {
  padding: 0 2px; }

.personnel_list_form.personnel_list_form_1.show {
  display: none !important; }

.personnel_list_form.personnel_list_form_1.hidden {
  display: block !important;
  max-height: 500px; }

.check_all_cart .nxt_checkbox {
  width: 20px;
  display: block;
  margin: auto; }
  .check_all_cart .nxt_checkbox .label_checkbox {
    margin: 0 auto; }
    .check_all_cart .nxt_checkbox .label_checkbox .title {
      display: none; }

.product_sort_options select {
  color: #000;
  position: absolute;
  bottom: 27px;
  left: 0;
  width: 100%;
  right: 0;
  height: 30px;
  -webkit-transition: all ease .3s;
  transition: all ease .3s;
  -webkit-transform: translateY(200px);
  transform: translateY(200px);
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  padding: 0px 12px;
  font-size: 12px !important; }

.product_upload_image_child0_img:hover .product_sort_options select {
  -webkit-transform: translateY(0);
  transform: translateY(0); }

/*-- Fix all --*/
/*-- Fix Width,Height Menu ---*/
/*-- Fix ---*/
/*-- Bpos ---*/
/*-- Themes Color ---*/
/*-- Themes Background ---*/
.general_branchs_content .btn_add_branchs {
  margin-bottom: 15px; }

.general_branchs_content .branch_item td:first-child {
  text-align: center; }

.load_address {
  float: left;
  width: 50%;
  padding-left: 7.5px; }
  .load_address .load_address_item {
    display: block;
    width: 100%; }
    .load_address .load_address_item .form-group {
      margin: 0px 0 15px; }
  .load_address .load_address_item_order {
    display: block; }

.input_info {
  float: left;
  width: 50%;
  padding-right: 7.5px; }
  .input_info .form-group {
    display: block;
    width: 100%; }

.search_form_1 {
  float: left;
  width: 20%;
  padding-right: 7.5px; }
  .search_form_1 .form-group {
    display: block;
    width: 100%; }

.search_form_2 {
  float: left;
  width: 20%;
  padding-right: 7.5px; }
  .search_form_2 .form-group {
    display: block;
    width: 100%; }

.search_form_3 {
  float: left;
  width: 20%;
  padding-right: 7.5px; }
  .search_form_3 .form-group {
    display: block;
    width: 100%; }

.search_form_4 {
  float: left;
  width: 20%;
  padding-right: 7.5px; }
  .search_form_4 .form-group {
    display: block;
    width: 100%; }

.search_form_5 {
  float: left;
  width: 20%;
  padding-right: 7.5px; }
  .search_form_5 .form-group {
    display: block;
    width: 100%; }

.search_form_receipts {
  float: left;
  padding-right: 7.5px; }
  .search_form_receipts .form-group {
    display: block;
    width: 100%; }

.debt_list_item {
  padding: 15px 0;
  border-bottom: 1px dashed #eaeaea;
  float: left;
  width: 100%; }
  .debt_list_item button {
    min-width: auto;
    margin-top: 23px;
    z-index: 1; }
  .debt_list_item.item_old {
    position: relative; }
    .debt_list_item.item_old:after {
      content: "";
      height: 165px;
      width: 100%;
      background: #ff000000;
      top: 0;
      left: 0;
      position: absolute; }
  .debt_list_item:last-child {
    border-bottom: none; }
  .debt_list_item_box_modal .row:not(:last-child) {
    border-bottom: 1px dashed #f5f5f5;
    margin-bottom: 15px;
    padding: 0 0 15px; }

.btn-view-debt {
  min-width: 100%; }

.link_sendo {
  border: #c2c2c2 dashed 1px;
  border-radius: 2px;
  padding: 10px;
  height: 197px; }
  .link_sendo a {
    color: #28af6b; }

.bpos_dashboard {
  top: 50px;
  position: absolute;
  height: 100% !important;
  overflow-y: auto;
  float: left;
  width: 100%;
  min-height: 100vh;
  margin: 0 0 30px; }
  .bpos_dashboard .col-md-8 {
    margin: 0 0 50px; }
  .bpos_dashboard_right_top {
    background: #fff;
    margin: 30px 0;
    height: 130px;
    padding: 10px; }
  .bpos_dashboard_count_number {
    width: 100%;
    float: left; }
    .bpos_dashboard_count_number .row {
      margin: 0 -5px; }
    .bpos_dashboard_count_number .col-xl-20 {
      padding: 0 5px; }
    .bpos_dashboard_count_number_span {
      width: 100%;
      height: 60px;
      background: #eee;
      float: left;
      text-align: center;
      border-radius: 0.1875rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center; }
      .bpos_dashboard_count_number_span p {
        margin: 0; }
        .bpos_dashboard_count_number_span p:nth-child(1) {
          font-weight: 500;
          font-size: 18px;
          margin: 0; }
  .bpos_dashboard_choose_btn {
    width: 100%;
    overflow: hidden; }
    .bpos_dashboard_choose_btn .btn {
      display: inline-block;
      min-width: 100%;
      padding: 11px 10px;
      margin: 10px 0; }
  .bpos_dashboard_selling_today {
    background: #fff;
    padding: 15px;
    margin: 30px 0;
    overflow: hidden;
    width: 100%; }
    .bpos_dashboard_selling_today_list ul {
      padding: 0; }
      .bpos_dashboard_selling_today_list ul li {
        float: left;
        list-style: none;
        padding: 0 30px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .bpos_dashboard_selling_today_list ul li:nth-child(1) {
          padding-left: 0; }
          .bpos_dashboard_selling_today_list ul li:nth-child(1) .la {
            background: #28af6b; }
          .bpos_dashboard_selling_today_list ul li:nth-child(1) span {
            color: #28af6b; }
        .bpos_dashboard_selling_today_list ul li:nth-child(2) .la {
          background: #ff8030; }
        .bpos_dashboard_selling_today_list ul li:nth-child(2) span {
          color: #ff8030; }
        .bpos_dashboard_selling_today_list ul li.customer.down .la {
          background: red; }
        .bpos_dashboard_selling_today_list ul li.customer.down span {
          color: red; }
        .bpos_dashboard_selling_today_list ul li.customer.up .la {
          background: #28af6b; }
        .bpos_dashboard_selling_today_list ul li.customer.up span {
          color: #28af6b; }
        .bpos_dashboard_selling_today_list ul li + li {
          border-left: 1px solid #d6d6d6; }
        .bpos_dashboard_selling_today_list ul li .la {
          width: 30px;
          height: 30px;
          background: red;
          line-height: 30px;
          text-align: center;
          color: #fff;
          font-size: 20px;
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
          border-radius: 100px;
          float: left;
          margin: 0 10px 0 0;
          font-weight: 600; }
    .bpos_dashboard_selling_today_box {
      display: table; }
      .bpos_dashboard_selling_today_box p {
        font-weight: 700;
        color: #666;
        margin: 0 0 5px; }
      .bpos_dashboard_selling_today_box span {
        font-size: 22px;
        display: block; }
      .bpos_dashboard_selling_today_box h3 {
        color: #999;
        font-size: 12px;
        margin: 0; }
  .bpos_dashboard_recent_activities {
    background: #fff;
    margin: 0 0 30px; }
    .bpos_dashboard_recent_activities h2 {
      padding: 15px;
      border-bottom: 1px solid #efefef;
      margin-bottom: 18px; }
    .bpos_dashboard_recent_activities_list {
      max-height: 802px;
      overflow: auto; }
      .bpos_dashboard_recent_activities_list ul {
        margin: 0 20px 0 35px;
        padding: 0 0 10px 32px;
        border-left: 1px solid #e9e9e9; }
        .bpos_dashboard_recent_activities_list ul li.purchase_order .la {
          background: #e2d752; }
        .bpos_dashboard_recent_activities_list ul li.invoices .la {
          background: #5ec6f2; }
        .bpos_dashboard_recent_activities_list ul li.returns .la {
          background: #ff8030; }
        .bpos_dashboard_recent_activities_list ul li.damage_items .la {
          background: #ed5c6a; }
        .bpos_dashboard_recent_activities_list ul li {
          position: relative;
          color: #696969;
          line-height: 21px;
          list-style: none; }
          .bpos_dashboard_recent_activities_list ul li + li {
            margin: 20px 0 0; }
          .bpos_dashboard_recent_activities_list ul li .la {
            display: inline-block;
            width: 28px;
            height: 28px;
            position: absolute;
            top: 5px;
            left: -46px;
            border-radius: 100%;
            text-align: center;
            line-height: 28px;
            background: #28af6b;
            color: #fff;
            font-size: 15px; }
          .bpos_dashboard_recent_activities_list ul li a {
            color: #28af6b;
            margin: 0 5px 0 0; }
          .bpos_dashboard_recent_activities_list ul li strong {
            font-size: 12px;
            font-weight: 700 !important; }
          .bpos_dashboard_recent_activities_list ul li span {
            padding: 0 5px 0 0; }
          .bpos_dashboard_recent_activities_list ul li time {
            color: #b1b1b1;
            font-size: 11px;
            display: block; }
  .bpos_dashboard_selling_title .la {
    color: #B6B6B6;
    margin: 0 10px;
    font-weight: 600; }
  .bpos_dashboard_selling_title span {
    color: #28af6b; }
  .bpos_dashboard_selling_title h2 {
    font-size: 14px;
    text-transform: uppercase;
    overflow: hidden;
    margin: 0 0 20px; }
  .bpos_dashboard_selling_title .form-group {
    width: 200px;
    float: right; }
  .bpos_dashboard_this_month_revenue h2 {
    float: left;
    line-height: 32px;
    margin: 0; }
  .bpos_dashboard_this_month_revenue span {
    font-size: 14px;
    font-weight: 700;
    overflow: hidden;
    float: left;
    line-height: 32px; }
  .bpos_dashboard_this_month_revenue .css-1aya2g8 {
    *border: none;
    background: none;
    height: 26px;
    min-height: auto;
    font-size: 13px;
    overflow: hidden;
    color: #000; }
  .bpos_dashboard_this_month_revenue .css-d8oujb {
    display: none; }
  .bpos_dashboard_this_month_revenue .css-1492t68 {
    *color: #00b85e;
    *right: 0; }
  .bpos_dashboard_this_month_revenue {
    overflow: unset;
    float: left;
    margin: 0 0 30px; }
  .bpos_dashboard_chart_box {
    width: 100%;
    float: left; }

.firstLogin button {
  display: none; }

.firstLogin button.btn-primary {
  display: block; }

.filter_form {
  max-height: 0;
  height: 0;
  transition: all 0.5s ease;
  opacity: 0; }
  .filter_form.show {
    max-height: 400px;
    height: auto;
    opacity: 1;
    margin-bottom: 15px; }

.guaranteeHistory_list .guaranteeHistory_status {
  min-width: 120px; }

.modal_guarantee .form-group {
  margin-bottom: 15px; }

.modal_guarantee .navigation_search_sell {
  margin: 0;
  width: 100%;
  border: 1px solid #1cb467;
  border-radius: 0 0 3px 3px;
  margin-bottom: 15px; }
  .modal_guarantee .navigation_search_sell .output_complete_search {
    width: calc(100% + 2px); }
    .modal_guarantee .navigation_search_sell .output_complete_search ul {
      margin: 0;
      padding: 0; }
      .modal_guarantee .navigation_search_sell .output_complete_search ul .output_complete_search_info_bottom {
        display: none; }

.modal_guarantee_history .khach_hang > div {
  width: 90%; }

.modal_guarantee_history .add_customer {
  position: absolute;
  top: 22px;
  right: 15px;
  z-index: 9;
  background: #fafafa;
  border: 1px solid #dadada;
  width: 32px;
  height: 33px;
  text-align: center; }
  .modal_guarantee_history .add_customer i {
    margin-right: 0; }

.order_transport_title {
  background: #c7c7c7;
  padding: 10px;
  margin: 0 0 15px;
  color: #000;
  border-left: 7px solid #1cb467; }
  .order_transport_title h3 {
    margin: 0;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase; }

.order_transport_modal hr {
  margin: 20px 0; }

.order_transport_modal .order_radio_select > div {
  display: inline-block;
  margin-right: 10px; }

.order_transport_info {
  font-size: 14px; }
  .order_transport_info div {
    margin: 0 0 15px; }
    .order_transport_info div:last-child {
      margin: 0; }

.order_transport_right .form-group {
  margin: 0 0 15px; }

.order_transport_right .row {
  margin: 0 -5px; }

.order_transport_right [class*="col-"] {
  padding: 0 5px; }

.order_transport_right .no-margin .form-group {
  margin: 0; }

.order_transport_left:after {
  content: "\f121";
  position: absolute;
  font: normal normal normal 20px/1 LineAwesome;
  font-weight: 900;
  top: 7%;
  right: -11px;
  transform: translateY(-50%); }

.order_transport_list {
  line-height: 30px;
  font-size: 14px; }

.buy-person .select_custom .css-vj8t7z, .buy-person .css-2o5izw {
  border: none !important; }

.buy-person .form-group {
  padding: 0;
  border-bottom: 0px dashed #f1f1f1 !important; }

.buy-person .css-vj8t7z {
  min-height: 20px !important; }

.buy-person .css-1ep9fjw, .buy-person .css-1sjym57, .buy-person .css-1uq0kb5, .buy-person .css-1hwfws3 {
  padding: 0 !important; }

.buy-person .css-1aya2g8, .buy-person .css-vj8t7z, .buy-person .css-1wy0on6, .buy-person .css-162g8z5, .buy-person .css-2o5izw {
  min-height: 20px !important; }

.buy-person .css-1wy0on6 {
  display: none; }

.buy-person .css-xp4uvy {
  border-bottom: dashed 1px #8484ff; }

.tb-order tr td {
  vertical-align: top !important; }

.customer_view .spin .overlay {
  background: #ffffff87;
  width: 100%;
  height: 500px;
  top: 0;
  cursor: progress;
  right: 0;
  left: 0;
  bottom: 0;
  position: relative; }

.customer_view .order_transport_modal .spin .overlay {
  height: 100%; }

.collect_enough_select {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 9999; }

.tab_order_list_main {
  margin: 0 0 15px;
  border-bottom: 1px solid #ddd; }
  .tab_order_list_main .item.active a {
    color: red; }
  .tab_order_list_main .item a {
    cursor: pointer; }
    .tab_order_list_main .item a span {
      margin: 0 0 0 5px; }

.filter_products_title h3 {
  font-size: 15px;
  font-weight: 600;
  margin: 0 0 10px; }

.filter_products_box .row {
  margin: 0 -7.5px; }

.filter_products_box [class*="col-"] {
  padding: 0 7.5px; }

ul.filter-list {
  padding: 0;
  margin: 0; }
  ul.filter-list li {
    list-style: none;
    border-top: 1px solid #eaeaea;
    padding: 15px 0; }
    ul.filter-list li:last-child {
      border-bottom: 1px solid #eaeaea;
      margin: 0 0 15px; }
  ul.filter-list .btn {
    min-width: 100%; }
  ul.filter-list .row {
    margin: 0 -7.5px; }
  ul.filter-list [class*="col-"] {
    padding: 0 7.5px; }
  ul.filter-list .col-md-9 .form-group {
    width: 25%;
    float: left;
    margin: 0 15px 0 0; }
  ul.filter-list .col-md-9 label {
    margin: 9px 0; }
    ul.filter-list .col-md-9 label input {
      margin: 0 7px 0 0; }
  ul.filter-list .col-md-9 input[type=date] {
    box-shadow: none;
    border: 1px solid #e5e5e5;
    height: 34px;
    border-radius: 6px !important;
    padding: 0 10px !important;
    font-size: 13px;
    width: 25%;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    display: inline-block; }

/*-----Modules Bpos-------*/
/*-----Modules Sell-------*/
.navigation_search_sell {
  width: 350px;
  position: relative;
  margin: 8px 8px 8px 15px; }
  .navigation_search_sell input {
    border-radius: 3px !important;
    border: none !important;
    font-size: 13px; }

.react-autosuggest__container--open .output_complete_search {
  display: block; }

.output_complete_search {
  position: absolute;
  z-index: 9;
  background: #fff;
  top: 32px;
  width: 509px;
  max-height: 274px;
  overflow-y: auto;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #1cb467;
  border-radius: 0 0 3px 3px;
  display: none; }
  .output_complete_search::-webkit-scrollbar {
    width: 8px;
    height: 60px; }
  .output_complete_search::-webkit-scrollbar-track {
    background: none;
    border-radius: 0px;
    height: 60px; }
  .output_complete_search::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background: #e8e8e8;
    height: 60px; }
  .output_complete_search ul {
    display: block; }
    .output_complete_search ul li {
      width: 100%;
      float: left;
      display: flex;
      cursor: pointer; }
      .output_complete_search ul li:not(:last-child) {
        border-bottom: 1px solid #ddd; }
      .output_complete_search ul li:hover {
        background: #f3f3f3; }
  .output_complete_search_img {
    width: 60px;
    float: left;
    margin: 0 10px 0 0;
    height: 70px;
    padding: 5px;
    border-right: 1px solid #ddd; }
  .output_complete_search_info {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px 0 0; }
    .output_complete_search_info_top {
      float: left;
      width: 100%; }
      .output_complete_search_info_top p {
        float: right;
        margin: 0px 0 2px;
        text-align: right; }
    .output_complete_search_info_bottom {
      width: 100%;
      float: left; }
      .output_complete_search_info_bottom p {
        float: left;
        margin: 0; }
        .output_complete_search_info_bottom p:last-child {
          float: right; }
    .output_complete_search_info h2, .output_complete_search_info h2 a {
      font-size: 14px;
      color: #000;
      padding: 0;
      font-weight: 600;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis; }
    .output_complete_search_info h2 {
      margin: 0;
      max-width: 300px;
      float: left;
      padding: 0 0 3px; }
    .output_complete_search_info p span {
      font-size: 13px;
      color: #949494; }

.navigation_icon_search_sell {
  position: absolute;
  top: 4px;
  right: 0;
  background: none;
  border: none; }
  .navigation_icon_search_sell i {
    color: #1cb467;
    font-size: 20px;
    font-weight: 600;
    transform: rotate(-90deg); }

.navigation_sales_number_sell {
  margin: 8px;
  margin-left: 8px !important; }
  .navigation_sales_number_sell input {
    border-radius: 3px !important;
    border: none !important;
    font-size: 13px; }

.navigation_setting_right {
  justify-content: flex-end;
  flex: 1;
  padding: 0 !important; }

.navigation_setting_actions {
  justify-content: center;
  align-items: center; }
  .navigation_setting_actions li i {
    font-size: 20px;
    cursor: pointer;
    margin: 0 5px; }
    .navigation_setting_actions li i:hover {
      opacity: .4; }

.navigation_setting_exclamation:hover ul.navigation_setting_exclamation_list {
  transform: translateY(0);
  opacity: 1;
  visibility: visible; }

.navigation_setting_exclamation_list {
  top: 36px;
  z-index: 99;
  background: #fff;
  min-width: 400px;
  padding: 0 0 5px;
  right: -91px;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  /* -webkit-transform: translateY(100px); */
  -ms-transform: translateY(100px);
  /* transform: translateY(100px); */
  opacity: 0;
  visibility: hidden;
  position: absolute;
  display: block !important;
  font-size: 14px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  color: #000; }
  .navigation_setting_exclamation_list li {
    padding: 5px 10px;
    margin: 0; }
    .navigation_setting_exclamation_list li span {
      width: 70px;
      display: inline-block;
      font-size: 16px;
      font-weight: 600; }
      .navigation_setting_exclamation_list li span i {
        color: #000;
        margin: 0; }
  .navigation_setting_exclamation_list h2 {
    padding: 10px;
    font-size: 15px;
    font-weight: 600;
    border-bottom: 1px solid #e4e4e4;
    margin: 0 0 10px;
    color: #000; }
  .navigation_setting_exclamation_list:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
    top: -8px;
    right: 98px; }

.slider_invoice {
  width: 500px;
  margin: 0 30px; }
  .slider_invoice .slick-track {
    margin: 0; }
  .slider_invoice div:focus {
    outline: none; }
  .slider_invoice .slick-prev:before, .slider_invoice .slick-next:before {
    font-size: 18px; }

.navigation_list_invoice {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #018a47;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  opacity: 0.67;
  width: 120px !important; }
  .navigation_list_invoice button {
    background: none !important;
    border: none;
    color: #fff;
    padding: 0; }
  .navigation_list_invoice .la {
    font-size: 20px;
    cursor: pointer;
    float: right;
    line-height: 50px; }

.navigation_list_invoice.active button, .navigation_list_invoice.active .la,
.navigation_list_invoice:hover button, .navigation_list_invoice:hover .la {
  color: #018a47; }

.navigation_list_invoice.active, .navigation_list_invoice:hover {
  background: #fff;
  color: #018a47;
  opacity: 1; }

.btn-add-invoice {
  min-width: auto !important;
  background: none;
  cursor: pointer; }
  .btn-add-invoice i {
    font-size: 20px; }
  .btn-add-invoice:hover {
    opacity: .7; }

.navigation_search_sendo .output_complete_search ul {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0; }

.navigation_search_sendo .navigation_search_sell {
  width: 100%;
  position: relative;
  margin: 0;
  border: 1px solid #ddd; }

.bpos_box_sell_right_list_header {
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  padding: 10px 0;
  width: 100%;
  overflow: hidden;
  color: #000; }
  .bpos_box_sell_right_list_header .row {
    margin: 0 -5px; }
  .bpos_box_sell_right_list_header [class*="col-"] {
    padding: 0 5px !important; }

.la-actions {
  cursor: pointer; }

.ReactVirtualized__Table__Grid {
  outline: none;
  width: 101% !important;
  overflow: hidden !important; }

.ReactVirtualized__Grid__innerScrollContainer {
  width: 100% !important;
  font-size: 14px;
  max-width: 100% !important;
  overflow-y: auto !important; }
  .ReactVirtualized__Grid__innerScrollContainer .active {
    background: rgba(0, 184, 94, 0.2) !important;
    cursor: pointer; }
  .ReactVirtualized__Grid__innerScrollContainer .outstock {
    background: #ffd0d0;
    cursor: not-allowed; }
  .ReactVirtualized__Grid__innerScrollContainer .row {
    padding: 15px 0 !important;
    margin: 0 0px;
    color: #000;
    font-weight: 500;
    overflow: unset !important;
    width: 100% !important;
    height: auto !important;
    border-bottom: 1px solid #ddd;
    user-select: none; }
    .ReactVirtualized__Grid__innerScrollContainer .row:hover {
      background: #ececec; }
    .ReactVirtualized__Grid__innerScrollContainer .row [class*="col-"] {
      padding: 0 5px !important; }
    .ReactVirtualized__Grid__innerScrollContainer .row .form-group {
      margin: 0; }
  .ReactVirtualized__Grid__innerScrollContainer .la-actions {
    width: 20px;
    height: 20px;
    background: #fff;
    display: block;
    line-height: 20px;
    font-weight: 600;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 10px; }
  .ReactVirtualized__Grid__innerScrollContainer .name_sell_bpos {
    overflow: hidden;
    margin: 0 0 2px;
    max-height: 53px; }
  .ReactVirtualized__Grid__innerScrollContainer .product_price {
    position: relative; }
    .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box {
      position: absolute;
      background: #fff !important;
      z-index: 9999;
      width: 250px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 2px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      top: 15px;
      left: -215px;
      padding: 10px;
      transition: all ease .2s;
      opacity: 0;
      visibility: hidden;
      -webkit-transform: translateY(50px);
      transform: translateY(50px); }
      .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box-table:before {
        top: 23% !important; }
      .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box:before {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        content: "";
        border-width: 6px;
        right: -6px;
        border-right-width: 0;
        border-left-color: #fff;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%); }
      .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box.active {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateY(0);
        transform: translateY(0); }
      .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input {
        display: flex; }
        .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input label {
          width: 70px;
          height: 20px;
          line-height: 20px;
          float: left;
          font-size: 13px;
          color: #000;
          font-weight: 200; }
        .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input .form-group {
          float: left;
          text-align: right;
          width: auto; }
        .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input .input-content {
          position: relative; }
        .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input .form-group .input-content input {
          border-radius: 0px;
          padding-left: 5px; }
        .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input input {
          border-radius: 0px;
          padding-left: 5px;
          border: none;
          outline: none;
          border-bottom: 1px solid #ddd;
          background: none;
          text-align: right;
          width: 100px; }
        .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input .form-group-sale-box-btn {
          float: right;
          margin: 2px 0; }
          .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input .form-group-sale-box-btn a.active {
            background: #28af6b !important;
            color: #fff !important; }
          .ReactVirtualized__Grid__innerScrollContainer .product_price .form-group-sale-box .form-group-sale-box-input .form-group-sale-box-btn a {
            background: #bdbdbd;
            margin: 0 3px;
            color: #fff;
            padding: 1px 5px;
            border-radius: 2px; }
  .ReactVirtualized__Grid__innerScrollContainer .bpos_sell_quantity {
    display: flex;
    justify-content: center; }
    .ReactVirtualized__Grid__innerScrollContainer .bpos_sell_quantity .form-group {
      width: auto;
      float: left; }
    .ReactVirtualized__Grid__innerScrollContainer .bpos_sell_quantity .la {
      float: left;
      height: 24px;
      background: #e8e8e8;
      color: #151515;
      line-height: 24px;
      padding: 0 5px;
      cursor: pointer;
      transition: all ease .2s; }
      .ReactVirtualized__Grid__innerScrollContainer .bpos_sell_quantity .la:hover {
        background: #8a8a8a;
        color: #fff; }
  .ReactVirtualized__Grid__innerScrollContainer .gift_sell_bpos {
    color: #efad4e;
    font-size: 11px;
    cursor: pointer; }
  .ReactVirtualized__Grid__innerScrollContainer .list_gift_sell_bpos {
    color: #02e61e;
    font-size: 11px;
    cursor: pointer; }
  .ReactVirtualized__Grid__innerScrollContainer .price_sell_bpos {
    border: none;
    background: none;
    text-align: right;
    width: 100%;
    border-bottom: 1px solid #ddd; }
  .ReactVirtualized__Grid__innerScrollContainer .number_sell_bpos {
    background: #fff;
    width: 50px;
    text-align: center;
    border: 1px solid #e8e8e8;
    height: 24px; }
  .ReactVirtualized__Grid__innerScrollContainer .cell_price_sell_bpos {
    color: #28af6b;
    font-size: 16px; }
  .ReactVirtualized__Grid__innerScrollContainer .sales_price_bpos {
    text-align: right;
    float: right;
    display: block;
    width: 100%;
    font-size: 11px;
    color: red;
    position: relative;
    top: 5px; }
  .ReactVirtualized__Grid__innerScrollContainer .col-md-2 .input-content:after {
    content: "";
    width: 100%;
    height: 1px;
    background: #28af6b;
    bottom: 0;
    position: absolute;
    left: 0;
    -webkit-transition: all ease .5s;
    -o-transition: all ease .5s;
    transition: all ease .5s;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0); }
  .ReactVirtualized__Grid__innerScrollContainer .col-md-2 .input-content:hover:after {
    transform: scale(1); }
  .ReactVirtualized__Grid__innerScrollContainer .col-md-4:hover .notes_sell_bpos {
    display: inline-block;
    cursor: pointer; }
  .ReactVirtualized__Grid__innerScrollContainer .notes_sell_bpos {
    color: #666666;
    font-size: 11px;
    position: relative;
    padding: 0 10px 0 0;
    display: none; }
    .ReactVirtualized__Grid__innerScrollContainer .notes_sell_bpos:hover .notes_sell_bpos_popover {
      display: block; }
  .ReactVirtualized__Grid__innerScrollContainer .notes_sell_bpos_popover {
    position: absolute;
    top: 27px;
    width: 250px;
    display: none;
    z-index: 3; }
    .ReactVirtualized__Grid__innerScrollContainer .notes_sell_bpos_popover textarea {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      max-width: 300px;
      padding: 10px 10px;
      border: 1px solid #ddd;
      z-index: 3;
      width: 100%;
      height: 80px;
      outline: none; }
    .ReactVirtualized__Grid__innerScrollContainer .notes_sell_bpos_popover:before {
      content: "";
      border-width: 7px;
      position: absolute;
      right: auto;
      margin: 0;
      bottom: auto;
      top: -14px;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-bottom: 7px solid #ddd;
      left: 10px; }
    .ReactVirtualized__Grid__innerScrollContainer .notes_sell_bpos_popover:after {
      content: "";
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      top: -13px;
      margin-left: 10px;
      border-top-width: 0px;
      border-bottom-color: #fff;
      border-width: 7px; }
    .ReactVirtualized__Grid__innerScrollContainer .notes_sell_bpos_popover::-webkit-input-placeholder {
      color: #999 !important; }

.bpos_sell_main {
  height: 100vh; }
  .bpos_sell_main .navigation {
    position: static; }

.bpos_sell_network_mode {
  font-size: 15px;
  display: table;
  margin: auto;
  padding: 10px;
  background: #018a47;
  width: 100%;
  color: #fff; }
  .bpos_sell_network_mode img {
    display: inline-block;
    margin: 0 5px; }
  .bpos_sell_network_mode p {
    margin: 0; }
  .bpos_sell_network_mode span {
    color: red; }

.bpos_box_sell_left_action {
  position: relative;
  padding: 10px 0px;
  height: 100%; }

.bpos_sell_left_box {
  border-top: 1px solid #ddd;
  position: absolute;
  bottom: 0;
  z-index: 1;
  height: 346px; }
  .bpos_sell_left_box.hide_box {
    transition: all 146ms;
    height: 0px; }

.bpos_box_sell {
  position: relative;
  overflow: hidden;
  background: #fff;
  height: calc(100% - 50px);
  display: flex; }
  .bpos_box_sell .bpos_box_sell_left_box .bpos_sell_right_list {
    height: 100%; }
  .bpos_box_sell_left_toggle {
    width: 40px;
    height: 20px;
    background: #ddd;
    line-height: 30px;
    color: #fff;
    position: absolute;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    z-index: 9;
    cursor: pointer;
    transition: all ease .3s; }
    .bpos_box_sell_left_toggle .la {
      font-size: 17px; }
  .bpos_box_sell_left_box {
    background: #fff;
    border: 1px solid #28af6b;
    border-radius: 3px;
    height: 100%;
    overflow: hidden;
    position: relative; }
    .bpos_box_sell_left_box .row,
    .bpos_box_sell_left_box .col-md-3.bpos_sell_left_box,
    .bpos_box_sell_left_box .col-md-9.bpos_sell_right_list {
      height: 100%;
      transition: all ease .3s; }
    .bpos_box_sell_left_box.active .bpos_box_sell_left_action {
      transform: translateY(-1000%);
      position: absolute;
      opacity: 0;
      visibility: hidden; }
    .bpos_box_sell_left_box.active .col-md-12.bpos_sell_right_list {
      width: 100%; }
    .bpos_box_sell_left_box.active .bpos_box_sell_left_toggle {
      top: -20px; }
      .bpos_box_sell_left_box.active .bpos_box_sell_left_toggle .la-angle-down:before {
        content: "\f113"; }
    .bpos_box_sell_left_box.active .ReactVirtualized__Table__Grid {
      height: 680px !important; }
  .bpos_box_sell_left_action_pagination {
    user-select: none;
    float: right; }
    .bpos_box_sell_left_action_pagination ul {
      margin: 10px 0; }
  .bpos_box_sell_left_action_list {
    padding: 0;
    float: left;
    margin: 10px 0;
    z-index: 8;
    position: relative; }
    .bpos_box_sell_left_action_list li {
      list-style: none;
      margin: 0 4px 0 0;
      float: left;
      padding: 2px;
      border: 1px solid #c2c2c2;
      border-radius: 2px;
      background: #e4e4e4; }
      .bpos_box_sell_left_action_list li:hover {
        background: #28af6b;
        color: #fff;
        cursor: pointer; }
      .bpos_box_sell_left_action_list li:hover .la {
        color: #fff; }
      .bpos_box_sell_left_action_list li .la {
        width: 14px;
        height: 14px;
        line-height: 14px;
        font-size: 15px;
        text-align: center;
        color: #666666;
        font-weight: 700;
        display: block; }
  .bpos_box_sell .bpos_list_pr_inner {
    width: 100%;
    float: left;
    height: 260px;
    overflow: hidden; }
  .bpos_box_sell .bpos_list_pr_box {
    cursor: pointer;
    user-select: none; }
    .bpos_box_sell .bpos_list_pr_box:hover .bpos_list_pr_box_name h2 {
      color: red; }
  .bpos_box_sell .bpos_list_pr_vitual_root {
    width: 100% !important;
    overflow: auto !important; }
    .bpos_box_sell .bpos_list_pr_vitual_root > div {
      width: 100% !important;
      height: 151px !important;
      display: flex; }
    .bpos_box_sell .bpos_list_pr_vitual_root:focus {
      outline: none; }
    .bpos_box_sell .bpos_list_pr_vitual_root .col-md-6 {
      width: 100% !important;
      position: static !important;
      height: auto !important; }
  .bpos_box_sell .bpos_list_pr_box_img {
    border: 1px solid #28af6b;
    border-radius: 3px;
    padding: 5px;
    position: relative;
    height: 80px;
    width: 100%; }
    .bpos_box_sell .bpos_list_pr_box_img p {
      background: #28af6b;
      text-align: center;
      color: #fff;
      position: absolute;
      padding: 2px;
      bottom: 0px;
      left: 0; }
  .bpos_box_sell .bpos_list_pr_box_name h2 {
    margin: 5px 0;
    font-size: 13px;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 41px;
    line-height: 20px;
    user-select: none; }
    .bpos_box_sell .bpos_list_pr_box_name h2:hover {
      color: red; }
  .bpos_box_sell_right_box {
    border: 1px solid #28af6b;
    border-radius: 3px;
    overflow: hidden;
    height: 100%;
    position: relative;
    background: #fff; }
    .bpos_box_sell_right_box .modal-content .form-control {
      border: 1px solid #e5e5e5 !important;
      border-radius: 4px !important; }
    .bpos_box_sell_right_box .modal-content .css-1aya2g8 {
      min-height: 32px !important;
      height: 32px;
      border: 1px solid #e5e5e5; }
    .bpos_box_sell_right_box .row {
      margin: 0 -5px; }
    .bpos_box_sell_right_box [class*="col-"] {
      padding: 0 5px; }
    .bpos_box_sell_right_box .la {
      margin-right: 5px; }
    .bpos_box_sell_right_box .btn-w100 {
      min-width: 100%; }
    .bpos_box_sell_right_box .css-1aya2g8 {
      border-color: #efefef;
      font-size: 13px;
      overflow: hidden;
      color: #000; }
    .bpos_box_sell_right_box .css-d8oujb {
      display: none; }
    .bpos_box_sell_right_box .css-19bqh2r {
      color: #000;
      font-size: 11px !important;
      width: 15px; }
    .bpos_box_sell_right_box .css-1aya2g8, .bpos_box_sell_right_box .css-vj8t7z, .bpos_box_sell_right_box .css-162g8z5, .bpos_box_sell_right_box .css-2o5izw {
      border-bottom: 1px solid #efefef !important;
      border: none;
      border-radius: 0;
      padding: 0 !important;
      background: none; }
    .bpos_box_sell_right_box .css-1hwfws3 {
      padding-left: 0; }
    .bpos_box_sell_right_box_price_table {
      border-bottom: 1px solid #28af6b;
      margin: 0 0 5px;
      width: 100%;
      float: left;
      padding: 0 5px 5px; }
      .bpos_box_sell_right_box_price_table_left .la {
        font-size: 18px;
        color: #999;
        transform: rotate(-90deg);
        float: left;
        top: 7px;
        position: relative; }
      .bpos_box_sell_right_box_price_table_right .la {
        font-size: 18px;
        color: #999;
        float: left;
        position: relative;
        top: 7px; }
    .bpos_box_sell_right_box_search {
      position: relative;
      padding: 0 5px 5px; }
      .bpos_box_sell_right_box_search input {
        border: none;
        outline: none;
        *border-bottom: 1px solid #ddd;
        background: none;
        border-radius: 0 !important;
        *padding: 0 25px !important; }
        .bpos_box_sell_right_box_search input:hover {
          border-color: #28af6b !important; }
      .bpos_box_sell_right_box_search .la-search {
        font-size: 18px;
        color: #999;
        transform: rotate(-90deg);
        float: left;
        margin: 8px 5px 8px 0; }
      .bpos_box_sell_right_box_search .add_customer_sell {
        background: #28af6b;
        font-size: 16px;
        color: #fff;
        font-weight: bold;
        cursor: pointer;
        z-index: 9;
        width: 100%;
        text-align: center;
        height: 32px;
        line-height: 32px;
        border-radius: 3px;
        border: none; }
      .bpos_box_sell_right_box_search .output_complete_search_customer {
        width: 95%;
        left: 50%;
        transform: translateX(-50%);
        top: 31px; }
        .bpos_box_sell_right_box_search .output_complete_search_customer ul {
          padding: 0; }
          .bpos_box_sell_right_box_search .output_complete_search_customer ul li {
            padding: 0 10px; }
      .bpos_box_sell_right_box_search:hover .output_complete_search_customer {
        display: block; }
    .bpos_box_sell_right_box_user {
      padding: 5px;
      width: 100%;
      float: left; }
      .bpos_box_sell_right_box_user time {
        text-align: right;
        float: right;
        color: #999999;
        width: 100%;
        height: 32px;
        line-height: 32px; }
      .bpos_box_sell_right_box_user .la-user {
        font-size: 18px;
        color: #999;
        float: left;
        position: relative;
        top: 7px; }
      .bpos_box_sell_right_box_user .form-group {
        max-width: 150px;
        float: left;
        margin: 0; }
      .bpos_box_sell_right_box_user .react-datepicker-popper {
        right: 0px !important;
        left: unset !important; }
      .bpos_box_sell_right_box_user .react-datepicker__triangle {
        left: unset;
        right: 80px; }
    .bpos_box_sell_right_box_content {
      position: relative;
      height: calc(100% - 48px);
      width: 100%;
      float: left; }
      .bpos_box_sell_right_box_content .cutomer_support_phone {
        margin: 20px 0;
        text-align: center;
        font-size: 13px; }
      .bpos_box_sell_right_box_content .color-green {
        color: #28af6b;
        font-size: 18px; }
      .bpos_box_sell_right_box_content .form-group-price-all {
        width: 100%;
        float: left;
        position: relative;
        margin: 0 0 5px; }
        .bpos_box_sell_right_box_content .form-group-price-all .la-credit-card {
          font-size: 23px;
          position: absolute;
          top: 2px;
          left: 150px;
          z-index: 0;
          cursor: pointer;
          color: #28af6b; }
      .bpos_box_sell_right_box_content .sub_label {
        display: block;
        font-size: 12px;
        margin: 10px 0 0;
        color: #666666; }
      .bpos_box_sell_right_box_content .form-group-note-sell .form-group {
        width: 100%;
        margin: 0 0 15px; }
        .bpos_box_sell_right_box_content .form-group-note-sell .form-group textarea {
          width: 100%;
          border: 1px solid #ddd;
          padding: 5px 10px;
          border-radius: 3px;
          font-size: 13px;
          color: #666666; }
      .bpos_box_sell_right_box_content .form-group-btn-sell {
        width: 100%;
        height: 320px;
        overflow: hidden;
        padding: 0 10px; }
        .bpos_box_sell_right_box_content .form-group-btn-sell .row {
          margin: 0 -5px; }
        .bpos_box_sell_right_box_content .form-group-btn-sell [class*="col-"] {
          padding: 0 5px; }
        .bpos_box_sell_right_box_content .form-group-btn-sell .btn {
          width: 100%;
          min-width: 100%;
          padding: 13px 8px;
          font-size: 15px;
          text-transform: uppercase; }
    .bpos_box_sell_right_box_title h2 {
      margin: 0;
      background: #28af6b;
      text-align: center;
      color: #fff;
      font-size: 15px;
      font-weight: 600;
      padding: 13px 10px; }
    .bpos_box_sell_right_box .form-group-sale-box {
      position: absolute;
      background: #fff;
      z-index: 9999;
      width: 250px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 2px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      top: -50px;
      left: 0;
      padding: 10px;
      transition: all ease .2s;
      opacity: 0;
      visibility: hidden;
      transform: translateY(50px); }
      .bpos_box_sell_right_box .form-group-sale-box.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0); }
      .bpos_box_sell_right_box .form-group-sale-box:before {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        content: "";
        border-width: 6px;
        right: -6px;
        border-right-width: 0;
        border-left-color: #fff;
        top: 50%;
        transform: translateY(-50%); }
      .bpos_box_sell_right_box .form-group-sale-box label {
        width: 70px;
        height: 20px;
        line-height: 20px; }
      .bpos_box_sell_right_box .form-group-sale-box-input {
        display: flex; }
        .bpos_box_sell_right_box .form-group-sale-box-input .form-group {
          float: left; }
      .bpos_box_sell_right_box .form-group-sale-box-btn {
        float: right;
        margin: 2px 0; }
        .bpos_box_sell_right_box .form-group-sale-box-btn a {
          background: #bdbdbd;
          margin: 0 3px;
          color: #fff;
          padding: 1px 5px;
          border-radius: 2px; }
          .bpos_box_sell_right_box .form-group-sale-box-btn a.active, .bpos_box_sell_right_box .form-group-sale-box-btn a:hover {
            background: #28af6b;
            color: #fff !important; }
      .bpos_box_sell_right_box .form-group-sale-box-promotion {
        width: 100%;
        float: left;
        border-bottom: 1px dashed #ddd;
        margin: 0 0 10px; }
        .bpos_box_sell_right_box .form-group-sale-box-promotion .form-group-sale-box-promotion-view {
          float: right; }
        .bpos_box_sell_right_box .form-group-sale-box-promotion label {
          min-width: 100px; }
        .bpos_box_sell_right_box .form-group-sale-box-promotion-total-discount div {
          float: right; }
        .bpos_box_sell_right_box .form-group-sale-box-promotion-total-discount label {
          min-width: 100px; }
    .bpos_box_sell_right_box_payment {
      padding: 0 10px;
      width: 100%;
      float: left;
      min-height: calc(100% - 320px); }
      .bpos_box_sell_right_box_payment label {
        width: 60%;
        float: left;
        font-size: 13px;
        color: #000;
        font-weight: 500; }
        .bpos_box_sell_right_box_payment label .badge {
          border-radius: 2px;
          background: none;
          border: 1px solid #000;
          padding: 3px 5px;
          color: #000;
          font-weight: 200;
          border: 1px solid #e1e1e1;
          margin: 0 5px; }
      .bpos_box_sell_right_box_payment .form-group {
        float: right;
        text-align: right;
        width: auto; }
        .bpos_box_sell_right_box_payment .form-group .input-content:after {
          content: "";
          width: 100%;
          height: 1px;
          background: #28af6b;
          bottom: 0;
          position: absolute;
          left: 0;
          transition: all ease .5s;
          transform: scale(0); }
        .bpos_box_sell_right_box_payment .form-group:hover .input-content:after {
          transform: scale(1); }
      .bpos_box_sell_right_box_payment input {
        border: none;
        outline: none;
        border-bottom: 1px solid #ddd;
        background: none;
        text-align: right;
        width: 100px; }

/*== Produtcs Return  ==*/
.return_title {
  background: #1cb467;
  padding: 12px 10px;
  margin: 0 0 10px; }
  .return_title h2 {
    margin: 0;
    font-size: 13px;
    color: #fff;
    font-weight: 600; }

.table.table_return > tbody > tr > td {
  vertical-align: middle; }

.transfer_bar label {
  width: 50%; }

.bpos_box_sell .bpos_box_sell_left, .bpos_box_sell .bpos_box_sell_right {
  height: 100%;
  overflow: hidden;
  position: relative; }

/*=== Modal Type Payment ==*/
.modal_type_payment_price {
  margin: 0 -15px 15px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .modal_type_payment_price input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #ddd;
    padding: 7px 0 !important;
    font-size: 14px !important;
    font-weight: 600; }
    .modal_type_payment_price input:focus {
      font-size: 14px !important; }

.modal_type_payment_btn.row {
  margin: 0 -7.5px 15px; }
  .modal_type_payment_btn.row .col-xl-20 {
    padding: 0 7.5px; }
    .modal_type_payment_btn.row .col-xl-20 .btn {
      min-width: 100%; }

.modal_type_payment_pay {
  margin: 15px 0 0;
  font-size: 14px; }
  .modal_type_payment_pay strong {
    padding: 0 0 7px;
    display: block; }

.bpos_box_sell_right_box_modal .add_customer_info_bpos .css-1aya2g8,
.bpos_box_sell_right_box_modal .add_customer_info_bpos .css-vj8t7z,
.bpos_box_sell_right_box_modal .add_customer_info_bpos .css-162g8z5,
.bpos_box_sell_right_box_modal .add_customer_info_bpos .css-2o5izw, .bpos_box_sell_right_box_modal .add_customer_address_bpos .css-1aya2g8,
.bpos_box_sell_right_box_modal .add_customer_address_bpos .css-vj8t7z,
.bpos_box_sell_right_box_modal .add_customer_address_bpos .css-162g8z5,
.bpos_box_sell_right_box_modal .add_customer_address_bpos .css-2o5izw {
  border: 1px solid #e5e5e5 !important;
  border-radius: 4px; }

.bpos_box_sell_right_box_modal .add_customer_info_bpos .css-1hwfws3, .bpos_box_sell_right_box_modal .add_customer_address_bpos .css-1hwfws3 {
  padding: 0px 8px; }

.loadingFull {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.3); }

.modal-body .table-sale-promotion thead,
.table-sale-promotion,
.table-sale-promotion tr {
  background: none !important; }

.table-sale-promotion thead {
  color: #000; }

.table-sale-promotion tr td:nth-child(1) {
  width: 40px; }

.table-sale-promotion tr td:nth-child(1) .nxt_checkbox label {
  margin: 0; }

.table-sale-promotion tr td:nth-child(2) {
  width: 30%; }

.table-sale-promotion tr td:nth-child(3) {
  width: 20%; }

.table-sale-promotion tr td {
  vertical-align: top !important; }

.modal-gift.output_complete_search,
.table-sale-promotion .output_complete_search {
  display: block;
  position: relative;
  top: 0;
  border: none; }

.modal-gift.output_complete_search ul,
.table-sale-promotion .output_complete_search ul {
  padding: 0; }

.modal-gift.output_complete_search {
  width: 100%;
  height: 212px; }

.modal-gift .output_complete_search_img {
  position: relative; }

.modal-gift .li_active .output_complete_search_img .la {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #28bd16;
  font-weight: bold;
  font-size: 20px; }

.modal-gift .li_active .output_complete_search_img img {
  opacity: .2 !important; }

.modal-gift.output_complete_search .li_active {
  background: #f3f3f3; }

.md_overlay:nth-child(2) {
  z-index: 999; }

.md_overlay:nth-child(2) + div {
  z-index: 999; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  display: none; }

/*-----Modules Discount-------*/
.select_range_discount > div > label {
  margin: 0 10px 10px 0; }
  .select_range_discount > div > label:nth-child(1) {
    min-width: 120px; }

.iradio_flat-grey {
  margin: 0 5px 0 0; }

.discount_changer {
  height: 32px;
  line-height: 32px;
  color: #fff;
  cursor: pointer; }

.discount_vnd {
  border-radius: 6px 0 0 6px; }

.discount_percent {
  border-radius: 0px 6px 6px 0px; }

.discount_hr {
  padding: 15px 15px;
  border-bottom: 1px solid #e5e5e5;
  margin-top: 15px !important; }
  .discount_hr:nth-child(1) {
    border-top: 1px solid #e5e5e5; }

.discount_apply {
  margin-top: 15px; }

.discount_add_quanity {
  padding: 15px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd; }
  .discount_add_quanity_hr {
    border-bottom: 1px solid #ddd;
    padding: 0 15px 15px;
    margin: 0 0 15px;
    float: left;
    width: 100%; }

.fa-discount-close {
  height: 32px;
  line-height: 32px;
  font-size: 20px;
  cursor: pointer; }
  .fa-discount-close:hover {
    opacity: .6; }

.discount_select_condition .nxt_checkbox {
  position: absolute;
  right: 15px;
  margin: -6px 0;
  top: 0;
  z-index: 9; }
  .discount_select_condition .nxt_checkbox .label_checkbox .title {
    top: 3px;
    position: relative; }

.col-md-9.discount_goods > div {
  width: 40%;
  float: left;
  margin: 0 15px; }
  .col-md-9.discount_goods > div:nth-child(2) {
    margin: 23px 15px; }

/*-----Modules Help-------*/
.bpos_help {
  top: 50px;
  position: absolute;
  height: 100% !important;
  overflow-y: auto;
  float: left;
  width: 100%;
  min-height: 100vh;
  margin: 0 0 30px; }
  .bpos_help .container {
    margin-bottom: 50px; }
  .bpos_help .container-fluid {
    margin-bottom: 80px; }
  .bpos_help .col-md-10 {
    border-left: 1px solid #ddd; }
  .bpos_help .col-md-2, .bpos_help .col-md-10 {
    padding: 20px 15px 0; }
  .bpos_help ul {
    padding: 0; }
    .bpos_help ul li {
      list-style-position: inside;
      padding: 0 0 5px; }
  .bpos_help_title {
    border-bottom: 1px solid #ddd;
    padding: 0 0 15px;
    width: 100%;
    float: left;
    margin-top: 20px; }
    .bpos_help_title h1 {
      font-size: 20px;
      float: left;
      margin: 0; }
  .bpos_help_version a {
    font-size: 15px;
    color: #000; }
  .bpos_help_title_box h2 a, .bpos_help_title_box h2 {
    color: #1cb467;
    font-size: 18px;
    margin: 0 0 10px; }
  .bpos_help_latest_release a {
    display: inline-block;
    padding: 3px 4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    border-radius: 2px;
    color: #1cb467;
    border: 1px solid #1cb467;
    margin: 0px 0 10px; }
    .bpos_help_latest_release a:hover {
      border-color: #000; }

/*-----Modules Price List-------*/
.bpos_price_list_title {
  margin: 0 0 60px; }
  .bpos_price_list_title h1 {
    font-size: 29px;
    font-weight: 300;
    color: #42464e;
    margin: 40px 0 5px;
    line-height: 52px; }
  .bpos_price_list_title p {
    font-size: 16px;
    font-weight: 100;
    color: #7d8492; }

.bpos_price_list_time {
  text-align: center;
  margin: 30px 0; }
  .bpos_price_list_time ul {
    display: inline-block;
    list-style: none;
    padding: 0; }
    .bpos_price_list_time ul li {
      float: left; }
      .bpos_price_list_time ul li:first-child a {
        display: block;
        font-size: 14px;
        color: #28af6b;
        padding: 0 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 15px 0 0 15px;
        border: 1px solid #28af6b; }
      .bpos_price_list_time ul li:last-child a {
        display: block;
        font-size: 14px;
        color: #28af6b;
        padding: 0 15px;
        height: 30px;
        line-height: 30px;
        border-radius: 0 15px 15px 0;
        border: 1px solid #28af6b; }
      .bpos_price_list_time ul li.active a, .bpos_price_list_time ul li:hover a {
        background: #28af6b;
        color: #fff !important; }

.bpos_price_list_box {
  box-shadow: #f1f2f5 0 10px 25px 15px;
  margin: 0 0 30px; }
  .bpos_price_list_box_omnichannel hgroup {
    background: linear-gradient(45deg, #5c77eb, #ad59f4) !important;
    position: relative; }
  .bpos_price_list_box_omnichannel .bpos_price_list_box_price {
    background: #857cfd; }
  .bpos_price_list_box_omnichannel .bpos_price_list_box_old_price {
    top: 8px;
    font-size: 20px;
    position: relative;
    line-height: 30px;
    height: auto;
    font-weight: 500; }
    .bpos_price_list_box_omnichannel .bpos_price_list_box_old_price:before {
      content: "";
      display: block;
      height: 1px;
      width: 140px;
      border-bottom: 1px solid #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -70px; }
  .bpos_price_list_box_omnichannel .icon_start {
    display: block;
    background: url(/image/star-omnichannel.png) no-repeat center center;
    width: 76px;
    height: 74px;
    position: absolute;
    top: -37px;
    left: 50%;
    margin-left: -38px;
    z-index: 1; }
  .bpos_price_list_box_web hgroup {
    background: linear-gradient(45deg, #fd6793, #ffa36e) !important;
    overflow: hidden;
    position: relative;
    padding: 35px 0; }
    .bpos_price_list_box_web hgroup p {
      margin: 0; }
  .bpos_price_list_box_web .bpos_price_list_box_price {
    background: #ff6b92; }
  .bpos_price_list_box_web .flag {
    font-size: 14px;
    color: #fff;
    position: absolute;
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 25px;
    left: -45px;
    background: #ec5980;
    padding: 6px 50px; }
  .bpos_price_list_box_web_chanel ul {
    margin: 0; }
    .bpos_price_list_box_web_chanel ul li {
      display: inline-block;
      background: transparent;
      font-size: 14px;
      color: #fff;
      width: 120px;
      text-align: center;
      height: 33px;
      line-height: 33px;
      border-radius: 3px 0 0 3px;
      border: 1px solid #fff;
      cursor: pointer; }
      .bpos_price_list_box_web_chanel ul li:last-of-type {
        border-left: none;
        border-radius: 0 3px 3px 0; }
  .bpos_price_list_box_web_chanel ul li.active, .bpos_price_list_box_web_chanel ul li:hover {
    background-color: rgba(255, 255, 255, 0.6);
    color: #434343; }
  .bpos_price_list_box_name {
    color: #fff;
    padding: 35px 0;
    background: linear-gradient(45deg, #00b75e, #0dd280);
    border-radius: 5px 5px 0 0; }
    .bpos_price_list_box_name h2 {
      font-weight: 900;
      font-size: 35px;
      margin: 0 0 8px;
      line-height: 35px;
      color: #fff; }
    .bpos_price_list_box_name p {
      margin-bottom: 0;
      font-size: 14px;
      color: #fff; }
  .bpos_price_list_box_price {
    color: #fff;
    background: #0ad06f;
    height: 80px;
    line-height: 80px;
    font-size: 35px;
    position: relative;
    font-weight: 600; }
    .bpos_price_list_box_price i {
      font-style: normal;
      font-size: 20px;
      position: relative;
      top: -11px;
      font-weight: 500;
      left: 5px; }
    .bpos_price_list_box_price span {
      font-size: 20px;
      font-weight: 300;
      display: inline-block;
      margin-left: 6px; }
  .bpos_price_list_box_feature {
    padding: 0 30px;
    background: #fff;
    position: relative; }
    .bpos_price_list_box_feature ul li {
      list-style: none;
      font-size: 15px;
      padding: 15px 0;
      color: #7d8492;
      border-bottom: 1px solid #ddd; }
    .bpos_price_list_box_feature .btn {
      display: inline-block;
      margin: 0 0 10px 0;
      border-radius: 30px;
      min-width: 180px;
      font-size: 15px; }
    .bpos_price_list_box_feature .btn-position {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%); }

/*-----Modules Application-------*/
.bpos_application_bg {
  height: 116px;
  overflow: hidden; }

.bpos_application_box {
  border: 1px solid #ddd;
  background: #fff;
  position: relative;
  height: 264px;
  overflow: hidden;
  border-radius: 6px;
  transition: all ease .5s; }
  .bpos_application_box p {
    margin: 0;
    line-height: 20px; }
  .bpos_application_box_first {
    transition: all ease .5s; }
  .bpos_application_box:hover .bpos_application_hover {
    left: 0; }
  .bpos_application_box:hover .bpos_application_box_first {
    transform: translateX(100%); }

.bpos_application_info {
  padding: 15px; }
  .bpos_application_info h2 {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 10px; }
  .bpos_application_info p {
    font-size: 13px;
    line-height: 20px;
    color: #7b7b7b;
    margin: 0;
    height: 40px;
    overflow: hidden; }

.bpos_application_field {
  border-bottom: 1px solid #ddd;
  padding: 15px 15px; }

.bpos_application_hover {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  left: -100%;
  transition: all ease .5s; }
  .bpos_application_hover_body {
    padding: 15px;
    height: 48px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    display: none; }

.bpos_application_hover_header, .bpos_application_hover_footer {
  background: #fff;
  padding: 15px; }

.bpos_application_hover_header {
  height: 76px;
  overflow: hidden;
  display: none; }
  .bpos_application_hover_header p strong {
    margin: 0 0 10px;
    display: block; }

.bpos_application_hover_footer {
  height: 100%;
  *height: calc(100% - 124px);
  line-height: 20px;
  *display: flex;
  *justify-content: center;
  *align-items: center;
  overflow: hidden;
  background: #fff; }

/*-----Modules Affilinate-------*/
.color-gray {
  color: gray; }

.bpos_application_box.bpos_aff_box {
  height: auto; }
  .bpos_application_box.bpos_aff_box:hover .bpos_application_box_first {
    transform: translateX(0); }
  .bpos_application_box.bpos_aff_box:hover .bpos_aff_hover_readmore {
    transform: scale(1); }
  .bpos_application_box.bpos_aff_box .bpos_application_field {
    border-top: 1px solid #ddd;
    border-bottom: none; }

.bpos_aff_hover_readmore {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease .5s;
  transform: scale(0); }

.tracking_list_link input.form-control.input-link-aff {
  border-radius: 0 !important;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: .5rem .75rem !important;
  font-size: 1rem !important;
  line-height: 1.25;
  color: #464a4c;
  background-color: #eceeef;
  height: 37px;
  border-right: 0; }

.tracking_list_link .input-content {
  margin: 0 !important; }

.tracking_list_link .input-group-addon {
  text-align: center; }
  .tracking_list_link .input-group-addon:last-child {
    border-radius: 0; }
  .tracking_list_link .input-group-addon .la {
    margin: 0 !important; }

p.link-aff-target {
  font-size: 12px;
  padding: 6px 0 0;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
  p.link-aff-target a {
    color: #337ab7; }

.bnc_dashboard_aff .bpos_dashboard_selling_today {
  background: #eeeeee;
  margin: 0 0 30px;
  padding: 0; }

.bnc_dashboard_aff .bpos_dashboard_current_balance {
  padding: 15px;
  overflow: hidden;
  height: 108px;
  text-align: center;
  margin: 0 0 30px;
  background: #00b75e;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .bnc_dashboard_aff .bpos_dashboard_current_balance span {
    display: block;
    font-size: 19px;
    color: #fff; }
  .bnc_dashboard_aff .bpos_dashboard_current_balance p {
    text-transform: uppercase;
    color: #fff;
    font-size: 14px; }

.bnc_dashboard_aff .bpos_dashboard_recent_activities {
  background: #eeeeee; }
  .bnc_dashboard_aff .bpos_dashboard_recent_activities h2 {
    border-bottom: 1px solid #fff;
    margin: 0; }
  .bnc_dashboard_aff .bpos_dashboard_recent_activities ul {
    border-left: 1px solid #fff;
    padding: 10px 0 10px 32px; }
  @media only screen and (min-width: 991px) {
    .bnc_dashboard_aff .bpos_dashboard_recent_activities .bpos_dashboard_recent_activities_list {
      max-height: 447px; } }

.bpos_dashboard_recent_activities.bpos_dashboard_news_hot {
  width: 100%;
  float: left;
  margin: 0 0 70px 0; }
  .bpos_dashboard_recent_activities.bpos_dashboard_news_hot .bpos_dashboard_news_hot_box {
    border-bottom: 1px solid #fff;
    padding: 15px 0; }
    .bpos_dashboard_recent_activities.bpos_dashboard_news_hot .bpos_dashboard_news_hot_box:last-child {
      border: none; }
  .bpos_dashboard_recent_activities.bpos_dashboard_news_hot .bpos_dashboard_news_hot_max {
    padding: 0 15px; }
  .bpos_dashboard_recent_activities.bpos_dashboard_news_hot .bpos_dashboard_news_hot_img {
    height: 150px; }
  .bpos_dashboard_recent_activities.bpos_dashboard_news_hot .bpos_dashboard_news_hot_info h3 {
    margin: 0 0 10px;
    font-size: 14px;
    line-height: 25px;
    font-weight: 600; }
    .bpos_dashboard_recent_activities.bpos_dashboard_news_hot .bpos_dashboard_news_hot_info h3 a:hover {
      color: #337ab7 !important; }
  .bpos_dashboard_recent_activities.bpos_dashboard_news_hot .bpos_dashboard_news_hot_info p {
    color: #808080;
    line-height: 20px;
    font-size: 13px;
    height: 60px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: horizontal;
    -webkit-line-clamp: 2; }

.bpos_dashboard_guide_list ul {
  border: none !important; }
  .bpos_dashboard_guide_list ul li {
    margin: 0 0 5px; }
    .bpos_dashboard_guide_list ul li a:hover {
      text-decoration: underline !important; }

.bpos_dashboard_aff_title {
  margin: 0 0 15px; }
  .bpos_dashboard_aff_title h2 {
    float: left;
    margin: 0;
    font-size: 18px;
    flex: 1; }

.bpos_dashboard_statistical_list ul {
  padding: 0;
  margin: 0; }
  .bpos_dashboard_statistical_list ul li {
    padding: 15px;
    list-style: none;
    border-right: 1px solid #fff; }
    .bpos_dashboard_statistical_list ul li:last-child {
      border: none; }
  .bpos_dashboard_statistical_list ul span {
    font-size: 19px;
    color: red; }

.bpos_dashboard_statistical_list_box p {
  text-transform: uppercase;
  margin: 0; }

.bpos_dashboard_statistical_list_box h3 {
  margin: 10px 0;
  color: #a9a9a9 !important;
  text-transform: uppercase;
  font-size: 12px; }

.bpos_dashboard_chart_aff {
  height: 493px; }

.table-bordered a {
  color: #337ab7; }

.btn-register {
  background: #3597f1;
  border-color: #3597f1;
  color: #fff; }
  .btn-register:hover {
    color: #fff;
    background: #337ab7; }

.bnc_help_aff_list_tab {
  background: #eeeeee; }
  .bnc_help_aff_list_tab ul {
    padding: 0;
    border: none; }
    .bnc_help_aff_list_tab ul li {
      list-style: none;
      padding: 10px 15px;
      border-bottom: 1px solid #e6e6e6;
      border-left: 4px solid transparent;
      float: none;
      margin: 0; }
      .bnc_help_aff_list_tab ul li a {
        color: #757575;
        font-size: 15px;
        font-weight: 400;
        padding: 0;
        margin: 0;
        border-radius: 0;
        border: none; }
        .bnc_help_aff_list_tab ul li a:hover, .bnc_help_aff_list_tab ul li a:focus {
          border: none;
          background: none;
          color: unset !important; }
      .bnc_help_aff_list_tab ul li.active, .bnc_help_aff_list_tab ul li:hover, .bnc_help_aff_list_tab ul li:focus {
        border-left: 4px solid #00b75e !important;
        background: none; }
      .bnc_help_aff_list_tab ul li.active > a, .bnc_help_aff_list_tab ul li.active > a:hover, .bnc_help_aff_list_tab ul li.active > a:focus {
        border: none;
        background: none; }
      .bnc_help_aff_list_tab ul li:last-child {
        border-bottom: none; }

.bnc_help_aff_info {
  background: #eeeeee;
  padding: 15px;
  margin: 0 0 70px; }

.bnc_help_aff_content strong {
  font-weight: 600 !important; }

.bnc_help_aff_content p {
  line-height: 20px;
  font-size: 14px; }

.bnc_help_aff_content h3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 25px; }

@media only screen and (min-width: 768px) {
  .bnc_help_aff_content img {
    width: 800px;
    margin: auto; } }

.bnc_help_aff_title h2 {
  margin: 0 0 15px;
  text-transform: uppercase;
  font-size: 16px;
  color: #00b75e;
  font-weight: 600;
  line-height: 23px;
  line-height: 25px; }

.bnc_product_banner_aff_list_tab ul {
  border-bottom: 1px solid #ddd;
  padding: 0 20px;
  position: relative;
  margin: 0 0 30px; }
  .bnc_product_banner_aff_list_tab ul li {
    display: inline-block;
    padding: 10px;
    cursor: pointer;
    border: 1px solid transparent;
    margin: 0;
    float: none; }
    .bnc_product_banner_aff_list_tab ul li a {
      padding: 0;
      margin: 0;
      border: none; }
    .bnc_product_banner_aff_list_tab ul li.active > a, .bnc_product_banner_aff_list_tab ul li.active > a:hover, .bnc_product_banner_aff_list_tab ul li.active > a:focus, .bnc_product_banner_aff_list_tab ul li a:hover, .bnc_product_banner_aff_list_tab ul li a:focus {
      border: none;
      background: none; }
    .bnc_product_banner_aff_list_tab ul li.active, .bnc_product_banner_aff_list_tab ul li:hover, .bnc_product_banner_aff_list_tab ul li:focus {
      border-bottom: 2px solid #00b75e;
      background: none; }

.bpos_product_banner_aff_bg {
  height: 200px; }

/*-----Modules Register Aff-------*/
.container_login_bg.container_bg.container_aff_bg {
  background: #FFF url("/image/bg_register_aff.png") center bottom no-repeat; }

.container_login .container_aff_box .power-text {
  color: #777;
  padding: 20px 0 0;
  margin: 0; }

.container_login .container_aff_box .title_form h2 {
  margin: 0 0 30px;
  text-transform: uppercase;
  font-weight: 600; }

.container_login .container_aff_box .form_login_content {
  border-radius: 10px; }

.container_login .container_aff_box label {
  display: none; }

.container_login .container_aff_box .form_btn.no-margin-bottom {
  margin-bottom: 0 !important; }

.container_login .container_aff_box .form_btn .btn {
  border-radius: 999px !important;
  font-weight: 600 !important; }

.container_login .container_aff_box .icon_input_left {
  display: block !important; }
  .container_login .container_aff_box .icon_input_left .la {
    position: absolute;
    top: 8px;
    font-size: 20px;
    display: block !important; }

.container_login .container_aff_box .form-group .input-content .form-control {
  border: none !important;
  padding: 0 30px !important;
  border-bottom: 1px solid #cccccc !important;
  border-radius: 0 !important; }

.container_login .container_aff_box .form-group .input-content:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  -moz-transition: all .4s;
  transition: all .4s;
  background: #3597f1; }

.container_login .container_aff_box .form-group .input-content:hover:after {
  width: 100%; }

/*----- Responsive ----- */
@media only screen and (min-width: 1200px) {
  .bpos_sell_mobile {
    margin-top: 50px; }
    .bpos_sell_mobile .navigation_sales_btn {
      margin: 8px 0; }
    .bpos_sell_mobile .navigation.navigation_sell {
      display: block; }
      .bpos_sell_mobile .navigation.navigation_sell .btn {
        min-width: 100%; }
    .bpos_sell_mobile .navigation .full-xs {
      padding: 0 8px; }
    .bpos_sell_mobile .navigation .col-md-6 {
      width: 41.66666667%; }
    .bpos_sell_mobile .navigation_search_sell {
      width: 100%;
      margin: 8px 0; }
    .bpos_sell_mobile .col-md-8 {
      width: 75%; }
    .bpos_sell_mobile .col-md-4 {
      width: 25%; }
  .bpos_dashboard_m {
    top: 50px;
    position: absolute;
    height: 100% !important;
    overflow-y: auto;
    float: left;
    width: 100%;
    min-height: 100vh;
    margin: 0 0 30px; }
  .bpos_dashboard_top_ten_revenue {
    margin: 0 0 80px; } }

@media only screen and (max-width: 1200px) {
  html {
    overflow-x: hidden;
    background: #fff; }
  body {
    overflow: auto !important;
    overflow-x: hidden !important;
    background: #eee !important; }
  ::-webkit-input-placeholder, ::placeholder {
    font-size: 13px !important; }
  select,
  textarea,
  input {
    font-size: 13px !important; }
  input {
    /* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    box-shadow: none !important;
    font-size: 13px !important; }
  #menu_mobile {
    display: block !important;
    float: left;
    width: 33.33333333%;
    padding: 0 15px; }
  ul.sub_menu.show div {
    position: relative; }
  .bm-cross-button {
    background: #373a48;
    right: -30px !important;
    top: 0 !important;
    height: 46px !important;
    width: 30px !important;
    text-align: center;
    border-left: 1px solid #4a4c58; }
  li.menu_item .icons8-drop-down-arrow {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    width: 152px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding-left: 100px; }
  .bm-cross-button > span {
    top: 40% !important; }
  .bm-overlay {
    transition: opacity 0.5s ease 0s !important; }
  li.depots_mobile {
    margin: 45px 0px 0;
    border-top: 1px solid #484a56;
    border-bottom: 1px solid #484a56;
    padding: 15px; }
  nav.bm-item-list li ul {
    display: none;
    margin: 0 20px; }
  nav.bm-item-list li:not(:last-child) a {
    border-bottom: 1px solid #51535d; }
  nav.bm-item-list li a {
    padding: 15px; }
  #slide .bm-menu, #stack .bm-menu, #reveal .bm-menu {
    padding: 0; }
  .bm-menu-wrap {
    transform: translate3d(0, 0px, 0px) !important;
    left: -200%; }
  .menu_mobile_active .bm-menu-wrap {
    transform: translate3d(0px, 0px, 0px) !important;
    left: 0; }
  .bm-burger-button {
    position: relative;
    width: 70px;
    height: 50px; }
    .bm-burger-button > span {
      position: relative;
      width: 20px;
      height: 20px;
      top: 16px;
      left: 15px;
      display: block; }
  #menu_mobile {
    padding: 0 15px;
    display: block;
    height: 100%; }
  .bm-cross {
    background: #fff; }
  .bm-burger-bars {
    background: #ffff;
    height: 1px !important; }
  .bm-morph-shape {
    fill: #373a47; }
  .bm-menu {
    background: #373a47; }
  .bm-menu a {
    color: #b8b7ad; }
  .bm-menu a:hover,
  .bm-menu a:focus {
    color: #c94e50; }
  .bm-item-list a {
    padding: 0.8em; }
  .bm-item-list a span {
    margin-left: 10px;
    font-weight: 700; }
  #slide .bm-cross,
  #stack .bm-cross,
  #reveal .bm-cross {
    background: #bdc3c7; }
  #slide .bm-menu,
  #stack .bm-menu,
  #reveal .bm-menu {
    font-size: 1.15em; }
  #bubble .bm-cross,
  #push .bm-cross {
    background: #999; }
  #bubble .bm-menu,
  #push .bm-menu {
    padding: 3.4em 1em 0;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    position: fixed;
    transition: opacity 0s cubic-bezier(0.17, 0.67, 0.1, 1.27) 0.3s, transform 0s cubic-bezier(0.17, 0.67, 0.1, 1.27) 0.3s;
    opacity: 0; }
  #bubble a,
  #push a {
    padding: 1em; }
  #bubble i,
  #push i {
    font-size: 1.7em;
    vertical-align: middle;
    color: #282a35; }
  #elastic .bm-cross {
    background: #888; }
  #elastic .bm-menu {
    padding: 2em 1em;
    font-size: 1.15em; }
  #elastic i {
    opacity: 0.5; }
  #elastic span {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.75em; }
  #scaleDown .bm-cross,
  #scaleRotate .bm-cross,
  #pushRotate .bm-cross,
  #fallDown .bm-cross {
    background: #888; }
  #scaleDown h2,
  #scaleRotate h2,
  #pushRotate h2,
  #fallDown h2 {
    margin: 0 auto;
    padding: 2em 1em;
    color: rgba(0, 0, 0, 0.4);
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2); }
  #scaleDown h2 i,
  #scaleRotate h2 i,
  #pushRotate h2 i,
  #fallDown h2 i {
    margin-left: 0.2em; }
  #scaleDown h2 span,
  #scaleRotate h2 span,
  #pushRotate h2 span,
  #fallDown h2 span {
    font-size: 1.6em;
    font-weight: 700; }
  #scaleDown a,
  #scaleRotate a,
  #pushRotate a,
  #fallDown a {
    padding: 1em;
    text-transform: uppercase;
    transition: background 0.3s, box-shadow 0.3s;
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2); }
  #scaleDown a span,
  #scaleRotate a span,
  #pushRotate a span,
  #fallDown a span {
    letter-spacing: 1px;
    font-weight: 400; }
  #scaleDown a:hover,
  #scaleRotate a:hover,
  #pushRotate a:hover,
  #fallDown a:hover,
  #scaleDown a:focus,
  #scaleRotate a:focus,
  #pushRotate a:focus,
  #fallDown a:focus {
    background: rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
    color: #b8b7ad; }
  .panel_modal {
    width: 100%;
    right: -200%; }
    .panel_modal.show .btn {
      margin: 0 0 10px; }
    .panel_modal_list {
      height: calc(100% - 84px - 49px - 30px) !important; }
    .panel_modal_close {
      padding: 12px 15px; }
    .panel_modal .row {
      padding: 0 15px !important; }
  .open_panel_fixed {
    right: 0;
    display: none; }
  .modal-dialog {
    padding: 0 15px; }
  .bpos_dashboard_recent_activities_list ul {
    margin: 0 20px 0 25px; }
  .bpos_dashboard_recent_activities_list {
    max-height: 870px;
    overflow: auto; }
  .bpos_dashboard_selling_title .form-group {
    width: 150px; }
  .navigation_themes_color_box_list li, .navigation .navigation_change_color_box_list li {
    width: 50%;
    float: left; }
  .navigation {
    position: relative;
    justify-content: space-between; }
    .navigation .navigation_menu {
      display: none; }
  .btn {
    min-width: unset; }
  label {
    line-height: 20px; }
  .personnel_list_table, .personnel_list_btn {
    overflow-y: auto; }
  .total_receipts {
    margin: 15px 0; }
  .setting_general .form-group:last-child {
    margin-bottom: 15px; }
  .setting_general .setting_general_item .setting_general_header label i {
    line-height: 25px; }
  .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content.not_p label {
    margin-top: 0;
    line-height: 20px; }
  .setting_general, .setting_general.margin-bottom-300 {
    margin: 0 0 20px !important; }
  .setting_general .setting_general_item .is_input .form-group {
    position: absolute;
    right: 15px;
    top: 58px;
    width: 60px; }
  .setting_general .printed_form .printed_form_design .printed_form_view iframe {
    width: 100% !important;
    margin: 15px 0;
    float: left; }
  .st_body .st_body_content .scroll_bar {
    margin: 15px 0;
    overflow-x: auto; }
  .st_body {
    top: 0; }
    .st_body .st_left_menu {
      display: none; }
    .st_body .st_body_content {
      position: relative;
      margin: 0;
      max-width: 100%; }
      .st_body .st_body_content .scroll_product {
        overflow-x: auto;
        margin: 0 0 15px 0; }
      .st_body .st_body_content .personnel_list {
        margin: 0; }
        .st_body .st_body_content .personnel_list table > tbody > tr > td, .st_body .st_body_content .personnel_list table > tbody > tr > th, .st_body .st_body_content .personnel_list table > tfoot > tr > td, .st_body .st_body_content .personnel_list table > tfoot > tr > th, .st_body .st_body_content .personnel_list table > thead > tr > td,
        .st_body .st_body_content .personnel_list table > thead > tr > th {
          white-space: nowrap; }
  .table_transfer {
    margin: 15px 0; }
  .personnel_list_form.show {
    margin-top: 0; }
  .report_title {
    font-size: 17px; }
  .product_sort.show, .personnel_list .personnel_list_btn, .k-content div {
    overflow: inherit !important; }
  .personnel_list_btn.personnel_list_btn_receipts {
    overflow-x: auto !important;
    margin: 0; }
  .personnel_list_products {
    margin: 0 0 15px 0 !important; }
  .bpos_price_list_title {
    margin: 0 0 30px; }
    .bpos_price_list_title h1 {
      margin: 30px 0 5px; }
  .bpos_help {
    position: static;
    margin: 0;
    background: #fff;
    min-height: 100%; }
    .bpos_help .container {
      margin: 0 auto; }
  .list_order_shipping {
    margin: 0 0 15px; }
  .no-margin-table {
    margin: 0 !important; }
  .navigation_mobile, .depots {
    display: none; }
  .navigation {
    position: relative;
    display: block;
    margin: 0 -15px; }
    .navigation .navigation_logo {
      width: 33.33333333%;
      float: left;
      padding: 0;
      text-align: center;
      margin: auto; }
      .navigation .navigation_logo a {
        width: 110px;
        display: block;
        margin: 10px auto; }
    .navigation .navigation_setting {
      width: 33.33333333%;
      display: block;
      padding: 0 15px;
      float: left; }
      .navigation .navigation_setting ul {
        display: block;
        float: right;
        margin: 0 15px; }
      .navigation .navigation_setting li {
        width: auto; }
  .setting_general_email_chart .form-group {
    margin: 0 0 15px; }
  .btn_add_box, .personnel_list_btn {
    overflow: inherit !important; }
    .btn_add_box div, .personnel_list_btn div {
      overflow: inherit !important; }
  .bpos_sell_main .navigation {
    margin: 0; }
  .bpos_sell_main .bpos_box_sell {
    height: 100vh; }
  .bpos_sell_main .scroll-content-inner {
    overflow-x: auto; }
  .bpos_sell_main .navigation_search_sell {
    width: 100%;
    margin: 8px 0; }
  .bpos_sell_main .navigation_sales_btn {
    margin: 8px 0; }
  .bpos_sell_main .navigation .full-xs {
    padding: 0 8px; }
  .bpos_sell_main .navigation_sell .col-md-2 {
    width: auto; }
  .bpos_sell_main .navigation_sell .row {
    margin: 0 -8px; } }

@media only screen and (max-width: 1024px) {
  .react-datepicker-wrapper {
    display: block !important; }
  .filter-item div {
    overflow: inherit !important; }
  .personnel_list_form.show {
    max-height: unset; }
  .bpos_box_sell_right_box_content .color-green {
    font-size: 13px; } }

@media only screen and (max-width: 991px) {
  .container_login {
    position: static;
    padding: 30px; }
  label, .container_login .form_login .form_login_content .form_btn .btn, .container_login .login_process .form_login_content .form_btn .btn {
    font-weight: 400; }
  .bpos_dashboard_right_top {
    width: 100%;
    overflow: hidden; }
  .bpos_dashboard_count_number .col-xl-20 {
    width: 20%;
    float: left; }
  .bpos_dashboard_recent_activities_list {
    max-height: 300px; }
  .filter_products_list_btn {
    margin: 15px 0 0; }
  .date_and_time_box strong, .date_and_time_box b {
    padding: 5px 0;
    display: block; }
  .nav > li > a {
    padding: 10px 5px; }
  .order_transport_left:after {
    position: relative;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(90deg);
    transform: translateX(-50%) rotate(90deg);
    margin: 20px 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    right: unset; }
  .personnel_list_btn_discount .form-group:last-child {
    margin-bottom: 15px; }
  .product_add .product_add_category_btn_add {
    margin: 28px 0 0px 15px; }
  .margin-top-15-m {
    margin-top: 15px !important; }
  .filter_products_box_btn .btn {
    min-width: 100%;
    width: 100%; }
  .bpos_sell_main {
    height: unset; }
    .bpos_sell_main > .spin {
      height: unset; }
    .bpos_sell_main .bpos_box_sell {
      display: block;
      height: unset; }
      .bpos_sell_main .bpos_box_sell_left, .bpos_sell_main .bpos_box_sell_right {
        padding: 0 15px !important;
        overflow: unset; }
      .bpos_sell_main .bpos_box_sell_left {
        margin: 0 0 15px; }
      .bpos_sell_main .bpos_box_sell_right_box_user {
        padding: 10px; }
      .bpos_sell_main .bpos_box_sell_left_box {
        overflow: inherit;
        float: left;
        width: 100%; }
    .bpos_sell_main .warehouse_box_right .bpos_box_sell_right_box_content .form-group-btn-sell {
      height: auto; }
    .bpos_sell_main .bpos_box_sell_right_box_content .cutomer_support_phone {
      margin: 0 0 15px; }
    .bpos_sell_main .bpos_box_sell_right_box {
      overflow: unset;
      float: left; }
  .padding-15-sm {
    padding: 0 15px !important; }
  .no-padding-xs {
    padding: 0 !important; }
  .discount_add_quanity_hr {
    padding: 0 0 15px; }
  .discount_action_xs {
    position: absolute;
    right: 20px;
    top: 11px;
    z-index: 0; }
    .discount_action_xs i {
      position: absolute;
      top: 0;
      right: 0; }
  .discount_goods .condition_first_select {
    margin: 30px 10px 10px !important;
    width: 44% !important; }
  .setting_general_content_item_content_sub.order_online .alert {
    height: auto !important; }
  .bpos_dashboard_recent_activities.bpos_dashboard_news_hot {
    margin: 0 0 30px 0; } }

@media only screen and (max-width: 767px) {
  .quick-edit .col-md-8 {
    margin: 0 0 4px; }
  .collect_enough_select {
    top: 0px;
    right: 0px; }
  .margin-top-15-xs {
    margin-top: 15px !important; }
  .setting_profile button {
    right: 63px; }
  .filter-item .form-group {
    margin: 0 0 15px; }
  .remove-button {
    margin: 0px 0 15px; }
  ul.filter-list .col-md-9 .form-group {
    width: 100%;
    margin: 0 0 15px; }
    ul.filter-list .col-md-9 .form-group:last-child {
      margin: 0; }
  .total_receipts {
    margin: 0 0 15px;
    display: block;
    background: none;
    padding: 0; }
    .total_receipts div {
      background: #f3f3f3;
      text-align: left;
      padding: 10px;
      margin: 0 0 15px;
      font-size: 15px; }
      .total_receipts div:last-child {
        margin: 0; }
  .depots {
    display: none; }
  .navigation .navigation_setting_user span.navigation_setting_info h3, .navigation .navigation_setting_user span.navigation_setting_info p {
    display: none; }
  .navigation .navigation_setting_user, .navigation .navigation_setting {
    padding: 0; }
  .navigation .navigation_setting_user span {
    display: block; }
    .navigation .navigation_setting_user span i {
      position: relative; }
  .navigation_setting_user_info {
    display: none; }
  .navigation_setting_user_name {
    display: block; }
  .navigation_mobile {
    display: block; }
  .bnc_dashboard_aff .bpos_dashboard_selling_today_list ul li, .bnc_dashboard_aff .bpos_dashboard_selling_today_list ul li:last-child {
    width: 50%; }
  .bpos_dashboard_selling_today_list ul li {
    width: 100%;
    padding: 10px;
    margin: 0 0 10px;
    justify-content: flex-start;
    background: #eeeeee; }
  .bpos_dashboard_selling_today_list ul li:nth-child(1) {
    padding: 10px; }
  .bpos_dashboard_selling_today_list ul li + li {
    border: none; }
  .bpos_dashboard_selling_today_list ul li:last-child {
    width: 100%;
    border: none;
    padding: 10px;
    margin: 0; }
  .bpos_dashboard_selling_today {
    margin: 20px 0 0; }
  .bpos_dashboard_selling_title .form-group {
    float: left;
    width: 100%;
    margin: 10px 0; }
  .bpos_dashboard_right_top {
    margin: 20px 0; }
  .bpos_dashboard_recent_activities {
    margin: 20px 0; }
  hgroup.bpos_dashboard_selling_title .margin-left-10 {
    margin: 0 !important; }
  .bpos_dashboard {
    position: static;
    min-height: auto;
    padding: 0;
    margin: 0; }
    .bpos_dashboard .col-md-8 {
      width: 100%;
      overflow: hidden;
      margin: 0; }
  .navigation .navigation_notification_list {
    left: -200px; }
    .navigation .navigation_notification_list:before {
      left: 208px; }
  .modal-dialog {
    height: unset;
    margin: 0 0 15px;
    max-height: 300px !important;
    overflow-y: auto; }
  .bpos_box_sell_right_box_content .form-group-btn-sell .btn-payment {
    position: fixed;
    bottom: 0;
    z-index: 9;
    left: 0;
    right: 0; }
  .btn-margin-payment {
    margin: 15px 0 0 !important; }
  .bpos_box_sell_right_box .form-group-sale-box {
    right: 105px;
    top: -21px;
    left: unset; }
  .bpos_sell_main .bpos_box_sell_right_box {
    margin: 0 0 50px; }
  .nav_stocktakes {
    width: 100%;
    float: left; }
  .st_body .st_body_content .title_menu {
    font-size: 15px; }
  .bpos_help .col-md-10 {
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid #ddd; }
  table.table.table-hover.table-bordered.table_product {
    white-space: pre; }
  .name_sell_bpos {
    margin: 0; }
  .setting_general_content_item_icon {
    display: none !important; }
  .padding-right-0 {
    padding: 0 !important; }
  .list_order_shipping {
    overflow-x: auto; }
  .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content {
    padding: 0; }
  .setting_general .setting_general_item .setting_general_content .setting_general_content_item.item_height .setting_general_edit_item {
    top: 9px; }
  .setting_general_content_item_content > label {
    padding-right: 70px;
    font-weight: 600; }
  .bnc_dashboard_aff .bpos_dashboard_selling_today, .bnc_help_aff_info {
    margin: 0 0 20px 0; }
  .bpos_dashboard_recent_activities.bpos_dashboard_news_hot, .bnc_dashboard_aff .bpos_dashboard_current_balance {
    margin: 0; }
  .bpos_dashboard_statistical_list ul li {
    border-bottom: 1px solid #fff; } }

@media only screen and (max-width: 480px) {
  .tracking_list_link .modal-dialog {
    max-height: inherit !important;
    min-height: auto !important;
    overflow-y: unset; }
  .container_login {
    padding: 0; }
    .container_login .container_aff_box .form_login_content {
      padding: 30px 20px 0; }
  .bpos_dashboard_statistical_list ul li {
    margin: 0 0 15px;
    background: #eeeeee;
    padding: 10px 15px; }
  .bnc_dashboard_aff .bpos_dashboard_selling_today {
    background: none;
    margin: 0; }
  .bpos_dashboard_recent_activities.bpos_dashboard_chart_aff {
    margin-bottom: 0; }
  .bpos_dashboard_m .personnel_list {
    height: 400px; }
    .bpos_dashboard_m .personnel_list canvas {
      height: 100%; }
  .list_receipts_modals .input_info {
    width: 100%;
    padding: 0; }
    .list_receipts_modals .input_info_2 {
      margin: 15px 0 0; }
  .modal-dialog {
    top: 50%;
    transform: translateY(-50%);
    max-height: 500px !important;
    overflow-y: auto; }
  .bpos_sell_main .navigation {
    height: auto;
    float: left;
    width: 100%; }
  .bpos_box_sell_right_list_table {
    width: 100%;
    overflow: hidden; }
  .bpos_box_sell_list_table_main, .bpos_box_sell_left_box {
    width: 100%;
    float: left; }
  .bpos_sell_main .navigation_sell .col-md-2 {
    width: 50% !important; }
    .bpos_sell_main .navigation_sell .col-md-2 .btn {
      width: 100%; }
  .bpos_sell_main .navigation_sell .col-md-2.full-btn {
    width: 100% !important; }
  .bpos_sell_main .navigation_sell_purchasereturn .navigation_search_sell {
    margin: 8px 0; }
  .bpos_sell_main .navigation_search_sell {
    margin: 8px 0 0; }
  .output_complete_search {
    z-index: 9999;
    overflow: auto;
    max-height: 200px;
    max-width: 350px; }
  .add_customer_info_bpos .input_info {
    width: 100%;
    padding: 0; }
  .add_customer_info_bpos .load_address {
    width: 100%;
    padding: 0;
    overflow: hidden; }
  .magin-bottom-port-15 {
    margin-bottom: 15px !important; }
  .setting_general_content_item .btn {
    min-width: 100%; }
  .hidden-port-xs {
    display: none; }
  .discount_action {
    position: absolute;
    right: 20px;
    top: 11px;
    z-index: 999; }
    .discount_action i {
      position: absolute;
      top: 0;
      right: 0; }
  .col-xs-port-2 {
    width: 20%; }
  .col-xs-port-6 {
    width: 60%; }
  .col-xs-port-5 {
    width: 50%; }
  .margin-top-15-por {
    margin-top: 15px !important; }
  .margin-top-10-por {
    margin-top: 10px !important; }
  .col-md-6.col-sm-6.col-xs-6.full-xs.magin-top-10-por {
    margin-top: 10px !important; }
  .output_complete_search {
    max-width: 100%; }
    .output_complete_search_info {
      display: inline-block; }
      .output_complete_search_info h2 {
        max-width: 100%;
        float: none;
        padding: 10px 0 5px; }
      .output_complete_search_info p {
        float: left;
        margin: 0 0 5px 0; }
    .output_complete_search_img {
      height: auto; }
  .hidden-port {
    display: none; }
  .sms_before_customer_birthday_item {
    position: relative; }
  .btn-delete-ab {
    position: absolute;
    min-width: auto;
    right: -75px;
    top: 50%;
    transform: translateY(-50%);
    width: unset !important;
    padding: 0; }
    .btn-delete-ab .btn {
      min-width: unset; }
  .bnc_dashboard_aff .bpos_dashboard_selling_today_list ul li, .bnc_dashboard_aff .bpos_dashboard_selling_today_list ul li:last-child {
    width: 100%; }
  .bnc_product_banner_aff_list_tab ul {
    display: flex;
    width: 400px;
    overflow-x: auto;
    padding: 0; }
    .bnc_product_banner_aff_list_tab ul li {
      float: left;
      white-space: pre; } }

@media only screen and (max-width: 360px) {
  .menu_mobile_active .bm-menu-wrap {
    width: 250px !important; } }

@media only screen and (max-width: 320px) {
  .nav_stocktakes {
    padding: 0 10px !important; }
    .nav_stocktakes.nav > li > a {
      font-size: 11px; } }

/*** Styles added to fix the issue with zoom in on iphone ***/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 13px !important; }
  ::-webkit-input-placeholder, ::-moz-input-placeholder {
    font-size: 13px !important; } }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 13px !important; }
  ::-webkit-input-placeholder, ::-moz-input-placeholder {
    font-size: 13px !important; } }

/* iPhone < 5: */
@media screen and (device-aspect-ratio: 2 / 3) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="url"] {
    font-size: 13px !important; }
  ::-webkit-input-placeholder, ::-moz-input-placeholder {
    font-size: 13px !important; } }

/* iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40 / 71) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="url"] {
    font-size: 13px !important; }
  ::-webkit-input-placeholder, ::-moz-input-placeholder {
    font-size: 13px !important; } }

/* iPhone 6, iPhone 6s, iPhone 7 portrait/landscape */
@media screen and (device-aspect-ratio: 375 / 667) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="url"] {
    font-size: 13px !important; }
  ::-webkit-input-placeholder, ::-moz-input-placeholder {
    font-size: 13px !important; } }

/* iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus portrait/landscape */
@media screen and (device-aspect-ratio: 9 / 16) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="url"] {
    font-size: 13px !important; }
  ::-webkit-input-placeholder, ::-moz-input-placeholder {
    font-size: 13px !important; } }

body {
  font-family: 'Roboto', sans-serif !important;
  font-size: 13px;
  background: #eee;
  overflow: hidden; }
  @media (max-width: 1024px) {
    body {
      background: #fff; } }

/*-------------------FIX SLIDE---------------------*/
/*-------------------LOAD IMAGE---------------------*/
*:focus {
  outline: none; }

.lazy-image {
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
  -webkit-transition: opacity 0.1s ease-in-out;
  -moz-transition: opacity 0.1s ease-in-out; }

.lazy-image.opacity {
  opacity: 1; }

/*-------------------LOAD DỮ LIỆU BAN ĐẦU---------------------*/
.loader {
  position: fixed;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1; }
  .loader .txt {
    margin-top: 10px; }

.sk-fading-circle {
  width: 40px;
  height: 40px;
  position: relative; }

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both; }

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg); }

.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg); }

.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg); }

.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg); }

.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg); }

.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg); }

.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg); }

.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg); }

.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg); }

.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s; }

.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s; }

.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s; }

.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s; }

.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s; }

.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s; }

.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s; }

.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s; }

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0; }
  40% {
    opacity: 1; } }

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0; }
  40% {
    opacity: 1; } }

/*----- Empty No Data -----*/
.bpos_no_data {
  margin: auto;
  user-select: none; }
  .bpos_no_data img {
    opacity: .7;
    margin: 100px auto 20px;
    width: 300px; }
  .bpos_no_data p {
    color: #a2a2a2;
    line-height: 25px; }
    .bpos_no_data p strong {
      font-size: 15px;
      font-weight: 100;
      color: #a2a2a2; }

/*== List Sned ==*/
.blockbuilder-preview.design-web.right.sc-kAzzGY.ebQxgL {
  overflow-x: hidden;
  padding-bottom: 0; }

.jseRlH {
  height: 100ch; }
  .jseRlH > iframe {
    min-height: 100% !important; }


.modal_tag {
    .ant-modal-header {
        background: #f6f7f9;
    }
}

.modal_tags {
    display: flex;
    flex-direction: column;
    .modal_tags-desc {
        font-size: 13px;
        color: $color_text_left;
        margin-bottom: 24px;
    }
    .modal_tags-input {
        margin-bottom: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 52px;
        align-items: center;
        border-radius: 5px;
        padding: 15px;
        border: solid 1px $color_border;
        &:focus {
            border-color: $color_error;
            outline: none;
        }
        .modal_tags-select {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: auto;
            margin-right: 10px;
            position: relative;
            cursor: pointer;
            .modal_tags-arrow {
                padding: 10px;
            }
            .modal_tags-color {
                border-radius: 50%;
                height: 16px;
                width: 16px;
                margin-right: 5px;
                border: 1px solid #dddfe2;
            }
            .menu_color {
                display: none;
                position: absolute;
                background-color: #fff;
                height: 52px;
                padding: 15px;
                top: 35px;
                left: 15px;
                border: 0;
                border-radius: 2px;
                box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 10px rgba(0, 0, 0, 0.35);
                z-index: 1;
                &.show {
                    display: block;
                }
                .menu_color-list {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    position: relative;
                    &::before {
                        position: absolute;
                        content: " ";
                        width: 0px;
                        height: 0px;
                        border-left: 8px solid transparent;
                        border-right: 8px solid #0c0c0c00;
                        border-bottom: 8px solid #ccc;
                        bottom: 40px;
                        left: 0px;
                    }
                    .modal_tags-color {
                        border-radius: 50%;
                        border: 2px solid transparent;
                        box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 1px 10px rgba(0, 0, 0, .35);
                        padding: 10px;
                        margin: 0 5px;
                        &:hover {
                            border: 2px solid #dddfe2;
                        }
                    }
                }
            }
        }
        .modal_tags-input-input {
            display: flex;
            flex: 1;
            width: 100%;
            input {
                width: 100%;
                border: none;
                height: 32px;
                padding: 0 10px;
                &::placeholder {
                    color: #cecece;
                }
                &:focus {
                    outline: none;
                }
            }
        }
    }
    .modal_tags-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: 200px;
        overflow: auto;
        border: solid 1px $color_border;
        border-radius: 5px;
        .modal_tags-list-item {
            display: flex;
            flex-direction: row;
            min-height: 52px;
            border-bottom: solid 1px $color_border;
            align-items: center;
            padding: 0 15px;
            justify-content: space-between;
            cursor: pointer;
            &:last-child {
                border-bottom: none;
            }
            &:hover {
                .modal_tags-list-item-select .modal_tags-arrow {
                    padding: 10px;
                    display: block;
                }
                background-color: #f6f7f9;
                .modal_tags-list-item-act {
                    color: #000000;
                }
            }
            .modal_tags-list-item-select {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: auto;
                margin-right: 10px;
                position: relative;
                cursor: pointer;
                &:hover {
                    .menu_color {
                        display: block;
                    }
                }
                .modal_tags-color {
                    border-radius: 50%;
                    height: 16px;
                    width: 16px;
                    margin-right: 5px;
                    border: 1px solid #dddfe2;
                    background-color: red;
                }
                .menu_color {
                    display: none;
                    position: absolute;
                    background-color: #fff;
                    height: 52px;
                    padding: 15px;
                    top: 35px;
                    left: 15px;
                    border: 0;
                    border-radius: 2px;
                    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 10px rgba(0, 0, 0, 0.35);
                    z-index: 1;
                    .menu_color-list {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        position: relative;
                        &::before {
                            position: absolute;
                            content: " ";
                            width: 0px;
                            height: 0px;
                            border-left: 8px solid transparent;
                            border-right: 8px solid #0c0c0c00;
                            border-bottom: 8px solid #ccc;
                            bottom: 39px;
                            left: 0px;
                        }
                        .modal_tags-color {
                            border-radius: 50%;
                            border: 2px solid transparent;
                            box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 1px 10px rgba(0, 0, 0, .35);
                            padding: 10px;
                            margin: 0 5px;
                            &:hover {
                                border: 2px solid #dddfe2;
                            }
                        }
                    }
                }
                .modal_tags-arrow {
                    display: none;
                }
            }
            .modal_tags-list-item-name {
                display: flex;
                flex: 1;
                width: 100%;
            }
            .modal_tags-list-item-act {
                display: flex;
                flex-direction: row;
                align-items: center;
                color: $color_text_left;
                .act_total {
                    font-size: 16px;
                    padding: 15px;
                }
                .act_remove {
                    font-size: 20px;
                }
            }
        }
    }
}
.modal_tags-color {
    border-radius: 50%;
    height: 16px;
    width: 16px;
    margin-right: 5px;
    border: 1px solid #dddfe2;
}

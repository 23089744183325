@import "../_variable.scss";
.general_product {
    &_filter {
        padding: 10px;
        background: $setting_header_bg;
        border: 1px solid $setting_header_border;
    }
}
/*----- Add Products ------*/
.list_of_products_modal .modal-dialog {
    max-height: 650px;
    .modal{// ẩn nút lưu và tiếp tục popup thêm danh mục sản phẩm
        .modal-footer{
            button:nth-child(3){
                display: none;
            }
        }

    }
}

.product_add {
    &_advanced{
        .form-group:last-child{
            margin-bottom:15px;
        }
    }
    &.row {
        margin: 15px -15px 0;
    }
    .product_upload_image {
        display: flex;
        &_avatar {
            width: 180px;
            height: 180px;
            border: dashed 1px #ccc;
            border-radius: 5px;
            padding: 1px;
            img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                cursor: move;
            }
        }
    }
    .product_add_category {
        &_btn_add {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 23px 0 0px 15px;
        }
        display: flex;
        margin: 0 0 15px;
        & .product_add_category_select {
            display: flex;
            flex: 1;
        }
    }
}
.product_upload_image_child {
    &.row {
        margin: 0 -10px;
    }
    ul{
        overflow:hidden;
        margin: 0 -10px;
    }
    
    &0 {
        padding: 0 10px;
        margin: 0 0 15px;
        z-index: 9999;
        &_text{
            text-align: center;
            p {
                margin: 3px 0 0 0;
                color: #b3b3b3;
            }
            i {
                font-size: 65px;
                margin: auto;
                color: #f5f5f5;
            }
        }
        &.avatar {
            display: block;
            background: none;
            width: 33.33%;
        }
        &.avatar .product_upload_image_child0_img {
            border-color: red;
        }
        &:hover .product_upload_image_child0_action{
            opacity:1;
        }
        &:hover .product_upload_image_child0_avatar,&:hover .product_upload_image_child0_del{
            transform: translateY(0);
        }
        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            cursor: move;
            object-fit: scale-down;
        }
        &_img {
            position: relative;
            height: 94px;
            border: dashed 1px #ccc;
            border-radius: 5px;
            cursor: move;
            overflow: hidden;
            background: #fff;
            & .spin {
                height: 94px;
                padding: 5px;
            }
        }
        &_action {
            text-align: center;
            font-size: 12px;
            color: #fff;
            cursor: pointer;
            opacity: 0;
            z-index: 2;
        }
        &_avatar {
            background: $themeNavBorder;
            padding: 5px;
            width: 100%;
            margin: auto;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 0 0 6px 6px;
            z-index: 2;
            transition: all ease .3s;
            transform: translateY(100px);
            &:hover{
                opacity:.9;
            }
        }
        &_del {
            font-size: 20px;
            position: absolute;
            top: 0px;
            right: 3px;
            color: #28af6b;
            z-index: 2;
            transition: all ease .3s;
            transform: translateY(-100px);
            &:hover{
                opacity:.9;
            }
        }
    }
}
.drap_and_drop_img_pr {
    border: 2px dashed #e6e6e6;
    cursor: pointer;
    margin-bottom: 1rem;
    border-radius: 2px;
    user-select: none;
    i {
        font-size: 40px;
        color:#a6b6c2;
    }
    p {
        color: #677683;
    }
}
.right-two {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.right-two .right-two-tag {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
}
.right-two-tag .right-two-tag-title,{
    font-weight: 600;
}
.right .right-two .right-two-tag-list {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
}
.right .right-two .right-two-tag-list .ant-tag,{
    margin-bottom: 5px;
}
.right .right-two .right-two-tag-input{
    display: flex;
}
.right .right-two .right-two-tag-input .ant-select-show-search {
    width: 100%;
}
.right .right-two .right-two-tag-input .ant-select-show-search input,{
    width: 100%;
}
.right .right-two .right-two-tag-input .ant-select-show-search input:hover,{
    border-color: #cecece;
}
.right .right-two .right-two-tag-input .ant-select-show-search input::placeholder,{
    color: #cecece;
}
.right .right-two .right-two-tag-input .ant-select-show-search input:focus,{
    outline: none;
    border-color: #cecece;
    box-shadow: none;
}
.load_type_receipts {
    font-weight: 600;
    cursor: pointer;
    background: #f2f2f2;
    height: 32px;
    border-radius: 2px;
    text-align: center;
    line-height: 32px;
    &_1 {
        position: absolute;
        right: 0;
        background: #f2f2f2;
        padding: 5px 10px;
        top: -9px;
        border-radius: 2px;
        font-weight: 600;
        cursor: pointer;
        z-index: 2;
    }
}
.load_type_receipts_btn{
    position: absolute;
    right: 0;
    top: -10px;
    height: 29px;
    line-height: 29px;
    padding: 0 25px;
    cursor: pointer;
    z-index: 1;
}
.product_sort{
    display:none;
    position: absolute;
    text-align: left;
    left: 200px;
    top: 0;
    .show{
        display:block;
        z-index: 9999;
    }
    .product_sort_child{
        position: absolute;
        font-size: 13px;
        font-family: Arial, Helvetica, sans-serif;
        right: 0;
        border: none;
        color: #fff;
        top: 35px;
        min-width: 200px;
        overflow: hidden !important;
        padding: 7px;
        background: $themePrimaryColor;
        z-index: 99;
        &:hover{
            opacity:1;
        }
        & .checkmark{
            border: none;
            background-color: #259c60 !important;
            border-radius: 3px;
        }
        &.item_width{
            button{
                min-width: 0;
                width:100%;
                justify-content: left; 
                margin-left: 0 !important;
                &:not(:last-child) {
                    border-bottom: 1px solid #28af6b;
                    border-radius: 0;
                }
            }
        }
    }
    
}
.table_product>tbody>.list_product:hover {
    background: #eaeaea !important;
    cursor: pointer;
    color:#000 !important;
    .row9.text-right{
        strong{
            color:red !important;
        }
    }
}
.table_product>tbody>.list_product.tab:hover{
    background: none!important;
}
.list_product .quick-edit .quick-input {

}
.list_product .quick-edit .quick-button {

}
.table_product thead tr th {
    border: none !important;
    border-bottom-width:0;
}
.content-list {
    table{
        border-collapse: unset;
        overflow:unset;
    }
    tbody {
        border: 1px solid #ffffff !important;
    }        
}
.table_product_sub{
    >tr:nth-child(odd) {
        background: #fff;
    }
    th{
        padding:0 8px;
    }
    td{
        padding: 7px 0;
        padding-left: 0;
    }
}
tr.list_product .nxt_checkbox label {
    margin-bottom: 0;
}
.list_product{
    ul{
        li{
            a{
                margin:0 10px;
            }
        }
    }
    th{
        .hidden{
            display:none;
        }
    }
    .row1{
        width: 2%;
    }
    .row2{
        width: 10%;
    }
    .row3{
        width: 20%;
    }
    .row4{
        width: 5%;
    }
    .row5{
        width: 5%;
    }
    .row6{
        width: 5%;
    }
    .row7{
        width: 5%;
    }
    .row8{
        width: 5%;
    }
    .row9{
        width: 10%;
    }
    .row10{
        width: 13%;
    }
}
.k-content{
    border: none;
    padding: 15px;
    background: #fff;
    margin: 0;
    position: relative;
    overflow: visible;
    text-align: left;
    border-top: none;
    &.list-discount-tab{
        .row{
            *border-bottom: 1px dashed #464646;
            *padding: 15px 0;
        }
    }
    // & svg {
    //     width: 100%;
    //     height: 116px;
    // }
    & label {
        vertical-align: unset;
        margin-right: 10px;
    }
    & .form-group {
        border-bottom: 1px solid #f5f5f5;
        padding: 7px 0;
    }
    & .form-control-static{
        display:inline-block;
        min-height:auto;
        padding: 0;
    }
    .title{
        font-size: 18px;
        font-weight: 700;
        color: $themeNavBorder;
        margin: 0 0 20px;
    }
    .d-flex{
        display:flex;
    }
    .product-detail-width{
        width:330px;
    }
    .slick-slider{
        width:100%;
        div{
            outline: none;
        }
    }
    .slick-dots{
        z-index: 1;
        position:static;
        & li,& button{
            width: 10px;
            height: 10px;
        }
        & li button:before{
            opacity: .25;
            color: black;
        }
        & li:hover button:before,& li.slick-active button:before{
            opacity: 1;
            color: $themeNavBorder;
        }
    }
    .slider1{
        & .slick-prev,& .slick-next{
            z-index:1;
        }
        .slick-prev {
            left:25px;
            &:before{
                color: #a2a2a2;
            }
        }
        .slick-next {
            right:25px;
            &:before{
                color: #a2a2a2;
            }
        }
        .image-detail-small {
            height: 230px;
            border: 1px solid #f5f5f5;
            margin: 0!important;
            overflow:hidden;
        }
        .col-md-12{
            margin-bottom:30px;
        }
    }
    .slider2{
        .slick-current .image-detail-small{
            *border-color:$themeNavBorder;
        }
        & .image-detail-small{
            &:hover{
                border-color:$themeNavBorder; 
            }
        }
        & .slick-arrow{
            display:none !important;
        }
    }
    & .image-detail-small {
        border: 1px solid #f5f5f5;
        height: 65px;
        overflow:hidden;
    }
}
.rowgroup{
    td{
        text-align: left;
        padding-left: 10px;
    }
}
.inventory-list{
    td{
        text-align: right;
    }
    table tr td:nth-child(1){
        text-align: right;
    }
}
.print_select_option {
    .form-group{
        margin-bottom: 21px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}
.w-barcode{
    &-right{
        .row {
            margin: 0 -5px;
        }
        [class*="col-"] {
            padding: 0 5px;
        }
        & .btn {
            min-width: 100%;
        }
    }
    &-img {
        border: 1px solid #ddd;
        padding: 5px;
        height: 85px;
        border-radius: 6px;
    }
    &-title h2 {
        text-align: left;
        margin: 0 0 15px;
        font-size: 14px;
        color: #000;
        line-height: 20px;
        height: 35px;
    }
    &-btn {
        width: 100%;
        min-width: 100%;
    }
}
.modal_barcode{
    .modal-title{
        font-weight:bold;
    }
}
.barcode_demo{
    border: 1px solid #f5f5f5;
    padding: 5px;
    text-align:center;
    svg{
        width: 160px !important;
    }
}
.barcode_item{
    float:'left';
    width:'31%';
    padding:'7px'; 
    text-align:'center';
    margin-right:"10px";
    height:"83px";
    .barcode_span{
        font-size:'17px';
        font-family:'Tahoma';
        width:'100%';
        text-align:'center';
        font-weight:"600";
    }
    .barcode{
        justify-content:'center';
        width:"100%";
        display:"flex"
    }
}
.img_test{
    height: 100px; 
    width:100px;
}
.btn-refesh i{
    width: 18px;
    height: 18px;
}
/*----- Transfer_depot And Warehouse And StockTakes ------*/
.bpos_actions_main {
    & > .spin{
        height: 100vh;
    }
}
.transfer{
    &_add{
        .number_sell_bpos{
            width:50px;
        }
    }
    &_bar{
        input{
            width:130px !important;
        }
    }
    &_load_depot{
        .css-1rtrksz{
            padding:3px 8px !important;
        }
        .css-1ep9fjw, & .css-1sjym57 {
            padding: 4px 8px!important;
        }
    }
    &_payment{
        min-height: calc(100% - 200px) !important;
    }
    &_action{
        height: 200px !important;
    }
}
.stocktakes{
    &_payment{
        min-height: calc(100% - 200px) !important;
    }
    &_action{
        height: 200px !important;
    }
}
.bpos-transfer{
    .form-control-static{
        padding:0;
    }
}
.table_transfer .nav-tabs{
    line-height: 0;
    display: flex;
}
.warehouse_box_right{
    .bpos_box_sell_right_box_payment{
        min-height: calc(100% - 200px);
    }
    .bpos_box_sell_right_box_content .form-group-btn-sell{
        height: 200px;
    }
}
.customer.warehouse {
    & .css-10nd86i {
        width: 200px;
    }
    & .css-1rtrksz {
        padding: 2px 0;
    }
}
table.table.table-result{
    font-weight: bold;
    thead{
        opacity: 0;
    }
    tr{
        &:nth-child(2n) {
            background-color: #fff;
        }
        td {
            border: none;
        }
    }
}
.report{
    &_title {
        text-align: center;
        font-size: 20px;
        text-transform: capitalize;
        font-weight: 500;
    }
    &_date {
        text-align: center;
        margin: 5px 0 30px;
        font-style: italic;
        color: #c3c3c3;
        font-size: 13px;
    }
}
.table_report{
    thead{
        tr{
           th{
             text-align: center;
             vertical-align: middle;
             border:1px solid #ddd !important;
           }
        }
    }
}
.total_receipts {
    display: flex;
    justify-content: flex-end;
    background: #f3f3f3;
    padding: 10px 15px;
    margin: 0 0 15px;
    & div {
        padding: 0 0 0 20px;
        font-size: 14px;
        text-align:right;
        & span{
            display:block;
            font-weight: 600;
            margin:7px 0 0 0;
        }
    }
}
/*== Modal Images ==*/
.modal_image_box{
    height: 90px;
    margin: 0 0 10px;
    border: dashed 1px #ccc;
    border-radius: 5px;
    overflow: hidden;
    &:hover {
        border-color: red;
    }
    &_small {
        border: dashed 1px #ccc;
        border-radius: 5px;
        height: 60px;
        padding: 5px;
        position: relative;
        overflow: hidden;
        width: 100%;
        &:hover .btn-delete-img{
            transform: translateY(0);
        }
    }
}
.padding-5{
    padding:0 5px;
}
.row_modal_images_choose {
    max-height: 200px;
    overflow-y: auto;
}
.properties_active {
    color: red;
    &:hover {
        color: red;
    }
}
.btn-delete-img {
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    top: 3px;
    right: 5px;
    color: #28af6b;
    z-index: 2;
    transition: all ease .3s;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    transform: translateY(-100px);
}
.properties_images_box_row{
    width: 100%;
    margin: 0 -5px;
    overflow-x: auto !important;
    max-height: 90px;
    display: flex;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        //display: none;
        //padding:10px 0 0 0;
      }
    & .col-md-2 {
        width: 70px;
        flex: 0 0 auto;
    }
}
.quick-edit {
    button{
        width: 100%;
        min-width: 100%;
        padding: 6px 8px;
        border-radius:4px;
    }
    .row {
        margin: 0 -2px;
    }
    [class*="col-"] {
        padding: 0 2px;
    }
    
}
.personnel_list_form.personnel_list_form_1{
    &.show {
        display: none !important;
    }
    &.hidden {
        display: block !important;
        max-height: 500px;
    }
}
.check_all_cart {
    .nxt_checkbox{
        width: 20px;
        display: block;
        margin: auto;
        .label_checkbox{
            margin: 0 auto;
            .title{
                display:none;
            }
        }
    }   
}
.product_sort_options{
    select {
        color: #000;
        position: absolute;
        bottom: 27px;
        left: 0;
        width: 100%;
        right: 0;
        height: 30px;
        -webkit-transition: all ease .3s;
        transition: all ease .3s;
        -webkit-transform: translateY(200px);
        transform: translateY(200px);
        background-color: rgba(255, 255, 255, .8);
        border: none;
        padding: 0px 12px;
        font-size: 12px !important;
    }
}
.product_upload_image_child0_img:hover .product_sort_options select {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}


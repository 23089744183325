.order_transport{
    &_title {
        background: #c7c7c7;
        padding: 10px;
        margin: 0 0 15px;
        color: #000;
        border-left: 7px solid $themePrimaryColor;
        h3 {
            margin: 0;
            font-size: 15px;
            font-weight: 600;
            text-transform: uppercase;
        }
    }
    &_modal{
        hr{
            margin:20px 0;
        }
        .label_checkbox{
            //margin:0;
        }
        .order_radio_select{
            &>div{
                display: inline-block;
                margin-right: 10px;
            }
        }
    }
    &_info {
        font-size: 14px;
        div {
            margin: 0 0 15px;
            &:last-child{
                margin: 0 ;
            }
        }
    }
    &_right{
        .form-group {
            margin: 0 0 15px;
        }
        .row {
            margin: 0 -5px;
        }
        [class*="col-"]{
            padding:0 5px;
        }
        .css-1492t68,& input {
            //font-size: 12px;
        }
        .no-margin .form-group{
            margin:0;
        }
    }
    &_left{
        &:after{
            content: "\f121";
            position: absolute;
            font: normal normal normal 20px/1 LineAwesome;
            font-weight: 900;
            top: 7%;
            right: -11px;
            transform: translateY(-50%);
        }
    }
    &_list {
        line-height: 30px;
        font-size: 14px;
    }
}
.buy-person{
    .select_custom .css-vj8t7z,& .css-2o5izw {
        border: none !important;
    }
    .form-group{
        padding: 0;
        border-bottom: 0px dashed #f1f1f1 !important;
    }
    .css-vj8t7z {
        min-height: 20px !important;
    }
    .css-1ep9fjw, & .css-1sjym57, & .css-1uq0kb5, & .css-1hwfws3 {
        padding: 0 !important;
    }
    .css-1aya2g8, & .css-vj8t7z, & .css-1wy0on6, & .css-162g8z5,& .css-2o5izw{
        min-height: 20px !important;
    }
    .css-1wy0on6 {
        display: none;
    }
    .css-xp4uvy {
        border-bottom: dashed 1px #8484ff;
    }
}
.tb-order tr td {
    vertical-align: top !important;
}
.customer_view{
    .spin .overlay {
        background: #ffffff87;
        width: 100%;
        height: 500px;
        top: 0;
        cursor: progress;
        right: 0;
        left: 0;
        bottom: 0;
        position: relative;
    }
    .order_transport_modal{
        .spin .overlay {
            height: 100%; 
        }
    }
}
.collect_enough_select{
    position:absolute;
    top:0px;
    right:0px;
    z-index:9999;
}
.tab_order_list_main{
    margin: 0 0 15px;
    border-bottom: 1px solid #ddd;
    .item {
        &.active {
            a{
                color:red;
            }
        }
        a {
            cursor:pointer;
            span {
                margin: 0 0 0 5px;
            }
        }
    }
    // & .slick-prev,&  .slick-next {
    //     z-index: 9;
    // }
    // & .slick-prev {
    //     left: 10px;
    // }
    // & .slick-next {
    //     right: 10px;
    // }
    // & .slick-prev:before,& .slick-next:before {
    //     color: #000;
    // }
    // .slider_order_list {
    //     padding: 0 40px;
    //     margin: 0 0 -1px;
    //     .slick-track{
    //         margin:0;
    //     }
    //     .item {
    //         margin-bottom:-1px;
    //         cursor: pointer;
    //         outline: none;
    //         &.active {
    //             a{
    //                 border: 1px solid #ddd;
    //                 border-bottom: 1px solid #fff;
    //             }
    //         }
    //         a {
    //             display: flex;
    //             align-items: center;
    //             justify-content: center;
    //             padding: 10px 3px;
    //             border-radius: 4px 4px 0 0;
    //             margin: 0 0 1px 0;
    //             white-space: pre;
    //             span {
    //                 margin: 0 0 0 5px;
    //             }
    //         }
    //     }
    // }
}
@import "../_variable.scss";
.nxt_radio {
    label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 5px;
        font-weight: normal;
    }
    .label_radio {
        display: block;
        position: relative;
        margin-bottom: 12px;
        cursor: pointer;
        user-select: none;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }
        .checkmark_radio {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            border: solid 1px #5454544a;
            border-radius: 50%;
            background-color: #fff;
        }
        .title {
            margin-left: 25px;
        }
    }
}

.label_radio:hover input~.checkmark_radio {
    border-color: $themePrimaryColor;
}

.label_radio input:checked~.checkmark_radio {
    background-color: $themePrimaryColor;
}

.checkmark_radio:after {
    content: "";
    position: absolute;
    display: none;
}

.label_radio input:checked~.checkmark_radio:after {
    display: block;
}

.label_radio .checkmark_radio:after {
    left: 5px;
    top: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
}
.bpos_price_list_title{
    margin: 0 0 60px;
    h1 {
        font-size: 29px;
        font-weight: 300;
        color: #42464e;
        margin: 40px 0 5px;
        line-height: 52px;
    }
    p {
        font-size: 16px;
        font-weight: 100;
        color: #7d8492;
    }
}
.bpos_price_list_time {
    text-align: center;
    margin:30px 0;
    ul {
        li {
            &:first-child a {
                display: block;
                font-size: 14px;
                color: $theme-default;
                padding: 0 30px;
                height: 30px;
                line-height: 30px;
                border-radius: 15px 0 0 15px;
                border: 1px solid $theme-default;
            }
            &:last-child a{
                display: block;
                font-size: 14px;
                color: $theme-default;
                padding: 0 15px;
                height: 30px;
                line-height: 30px;
                border-radius: 0 15px 15px 0;
                border: 1px solid $theme-default;
            }
            &.active a,&:hover a {
                background: $theme-default;
                color: #fff !important;
            }
            float: left;
        }
        display: inline-block;
        list-style: none;
        padding: 0;
    }
}
.bpos_price_list_box{
    box-shadow: #f1f2f5 0 10px 25px 15px;
    margin: 0 0 30px;
    &_omnichannel{
        // position: relative;
        // top: -23px;
        hgroup {
            background: linear-gradient(45deg,#5c77eb,#ad59f4) !important;
            //padding: 60px 0 34px;
            position: relative;
        }
        .bpos_price_list_box_price {
            background: #857cfd;
        }
        .bpos_price_list_box_old_price {
            top: 8px;
            font-size: 20px;
            position: relative;
            line-height: 30px;
            height: auto;
            font-weight: 500;
            &:before { 
                content: "";
                display: block;
                height: 1px;
                width: 140px;
                border-bottom: 1px solid #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -70px;
            }
        }
        .icon_start{
            display: block;
            background: url(/image/star-omnichannel.png) no-repeat center center;
            width: 76px;
            height: 74px;
            position: absolute;
            top: -37px;
            left: 50%;
            margin-left: -38px;
            z-index: 1;
        }
    }
    &_web {
        hgroup {
            background: linear-gradient(45deg,#fd6793,#ffa36e) !important;
            overflow: hidden;
            position: relative;
            padding: 35px 0;
            p {
                margin: 0;
            }
        }
        .bpos_price_list_box_price{
            background: #ff6b92;
        }
        .flag {
            font-size: 14px;
            color: #fff;
            position: absolute;
            -ms-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 25px;
            left: -45px;
            background: #ec5980;
            padding: 6px 50px;
        }
        &_chanel{
            ul{
                margin: 0;
                li {
                    display: inline-block;
                    background: transparent;
                    font-size: 14px;
                    color: #fff;
                    width: 120px;
                    text-align: center;
                    height: 33px;
                    line-height: 33px;
                    border-radius: 3px 0 0 3px;
                    border: 1px solid #fff;
                    cursor: pointer;
                    &:last-of-type {
                        border-left: none;
                        border-radius: 0 3px 3px 0;
                    }
                }
            }
            ul li.active,& ul li:hover{
                background-color: rgba(255,255,255,.6);
                color: #434343;
            }
        }
    }
    &_name {
        color: #fff;
        padding: 35px 0;
        background: linear-gradient(45deg,#00b75e,#0dd280);
        border-radius: 5px 5px 0 0;
        h2 {
            font-weight: 900;
            font-size: 35px;
            margin: 0 0 8px;
            line-height: 35px;
            color: #fff;
        }
        p {
            margin-bottom: 0;
            font-size: 14px;
            color: #fff;
        }
    }
    &_price {
        color: #fff;
        background: #0ad06f;
        height: 80px;
        line-height: 80px;
        font-size: 35px;
        position: relative;
        font-weight: 600;
        i {
            font-style: normal;
            font-size: 20px;
            position: relative;
            top: -11px;
            font-weight: 500;
            left: 5px;
        }
        span {
            font-size: 20px;
            font-weight: 300;
            display: inline-block;
            margin-left: 6px;
        }
    }
    &_feature {
        padding: 0 30px;
        background: #fff;
        //height: 419px;
        position: relative;
        ul{
            li {
                list-style: none;
                font-size: 15px;
                padding: 15px 0;
                color: #7d8492;
                border-bottom: 1px solid #ddd;
                &:not(:last-child) {
                    //border-bottom: 1px solid #ddd;
                }
            }
        }
        .btn {
            display: inline-block;
            margin: 0 0 10px 0;
            border-radius: 30px;
            min-width: 180px;
            font-size: 15px;
        }
        .btn-position{
            position: absolute;
            bottom:30px;
            left:50%;
            transform: translateX(-50%)
        }
    }
}
.personnel_list {
    display: flex;
    flex-direction: column;
    &_btn {
        display: flex;
        margin-bottom: 15px;
        & .form-group {
            margin: 0 0 15px;
        }
        button{
            margin:0 15px 0 0;
        }
    }
    &_form {
        max-height: 0;
        -webkit-transition: max-height .2s ease-in;
        -o-transition: max-height .2s ease-in;
        transition: max-height .2s ease;
        margin-top: 10px;
        display: none;
        &.show {
            max-height: 500px;
            display:block;
        }
    }
}
.personnel_list_content{
    &.scroll_bar{
        max-height: 407px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}
.add_customer{
    &_info_bpos{
        & .form-group {
            margin: 15px 0;
        }
    }
    &_address_bpos{
        & label {
            margin: 15px 0;
        }
        & .form-group {
            margin: 0; 
        }
        .edit_avatar_upload_image {
            margin: 0;
        }
        .product_upload_image_child {
            width: 100%;
        }
        .product_upload_image_child0 {
            padding: 0 !important;
            height: 185px;
            width: 100%;
            &_img {
                height: 182px !important;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        & .modal{
            overflow: inherit;
        }
        .right-two-tag-title {
            cursor: pointer;
            margin:15px 0 7px;
            font-weight:bold;
        }
        .right-two-tag-input {
            margin: 3px 0;
        }
        .tag_root {
           .tag{
                margin: 10px 5px 5px 0;
           }
            & .tag span{
                float:none;
                display:inline;
            }
        }
        .right-two-tag-input .form-group {
            margin: 0;
        }
    }
}
.promissory_note_refesh{
    & .form-group:last-child {
        margin: 0 0 15px;
    }
}
@import "../_variable.scss";
.autocomplete {
    position: relative;
    width: 100%;
    .form-group {
        margin-bottom: 0px;
    }
    .autocomplete_content {
        // min-height: 100px; // background: #cccc;
        display: flex;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        z-index: 9;
        position: absolute;
        width: 100%;
        background-color: #ffff; // height: 0; // display: none;
        // &.show {
        // display: block;
        // height: 250px;
        // transition:
        // }
        .autocomplete_content_list {
            position: relative;
            width: 100%;
            max-height: 200px;
            overflow: auto;
            height: 0px;
            transition: height 200ms ease-out;
            &.show {
                height: 200px;
                transition: height 200ms ease-in;
            }
            .autocomplete_content_item {
                display: flex;
                padding: 12px;
                align-items: center;
                cursor: pointer;
                transition: all 200ms;
                color: $primaryTextColor;
                &:hover {
                    background-color: $theme-dark-mode-bg;
                    transition: all 200ms;
                    color: white;
                }
                .tag_color {
                    border-radius: 50%;
                    height: 16px;
                    width: 16px;
                    margin-right: 5px;
                    border: 1px solid #dddfe2;
                    margin-right: 5px;
                }
                .tag_name {
                    user-select: none;
                }
            }
        }
    }
}
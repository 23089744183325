@import "../_variable.scss";
.body_container {
    top: 50px;
    position: relative;
    .body_container_head {
        border-bottom: solid 0.5px $neutralLightColor;
        height: 40px;
    }
    .body_container_side_left {
        width: 240px;
        height: 100%;
        position: fixed;
        border-right: solid 0.5px $neutralLightColor;
        background-color: #ffffff;
        .body_container_side_left_item {
            cursor: pointer;
            display: flex;
            flex: 1;
            position: relative;
            flex-direction: column;
            a {
                padding: 10px 0;
                color: $primaryTextColor;
                display: flex;
                &:hover {
                    background: $neutralLighterColor;
                    color: $primaryTextColor;
                }
                .body_container_side_left_item_icon {
                    width: 36px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                }
            }
            .body_container_side_left_item_child {
                display: flex;
                flex-direction: column;
                .body_container_side_left_item_child_sub {
                    position: relative;
                    display: inherit;
                    padding-left: 50px;
                    &:hover {
                        background: $neutralLighterColor;
                    }
                    &.active {
                        a {
                            &::after {
                                content: " ";
                                height: 100%;
                                position: absolute;
                                top: 0px;
                                right: 0px;
                                bottom: 0px;
                                left: 1px;
                                border-left: 2px solid $themeDarkAltColor;
                            }
                        }
                        background: $neutralLighterColor;
                    }
                }
            }
            &.active {
                a {
                    &::after {
                        content: " ";
                        height: 100%;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        bottom: 0px;
                        left: 1px;
                        border-left: 2px solid $themeDarkAltColor;
                    }
                }
                background: $neutralLighterColor;
            }
        }
    }
}
.bpos_dashboard{
    top: 50px;
    position: absolute;
    height: 100% !important;
    overflow-y: auto;
    float: left;
    width: 100%;
    min-height: 100vh;
    margin: 0 0 30px;
    .col-md-8 {
        margin: 0 0 50px;
    }
    &_right_top {
        background: #fff;
        margin: 30px 0;
        height: 130px;
        padding: 10px;
    }
    &_count_number {
        width: 100%;
        float: left;
        & .row{
            margin:0 -5px;
        }
        .col-xl-20{
            padding:0 5px;
        }
        &_span {
            width: 100%;
            height: 60px;
            background: #eee;
            float: left;
            text-align: center;
            border-radius: 0.1875rem;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            & p {
                &:nth-child(1) {
                    font-weight: 500;
                    font-size: 18px;
                    margin: 0;
                }
                margin: 0;
            }
        }
    }
    &_choose_btn {
        width: 100%;
        overflow: hidden;
        & .btn {
            display: inline-block;
            min-width: 100%;
            padding: 11px 10px;
            margin: 10px 0;
        }
    }
    &_selling_today {
        background: #fff;
        padding: 15px;
        margin: 30px 0;
        overflow: hidden;
        width: 100%;
        &_list{
            & ul {
                padding: 0;
                & li {
                    float: left;
                    list-style: none;
                    padding: 0 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:nth-child(1) {
                        padding-left: 0;
                        .la {
                            background: $themeNavBorder;
                        }
                        span {
                            color: $themeNavBorder;
                        }
                    }
                    &:nth-child(2) {
                        .la {
                            background: #ff8030;
                        }
                        span {
                            color: #ff8030;
                        }
                    }
                    &.customer {
                        &.down{
                            .la {
                                background: red;
                            }
                            span {
                                color: red;
                            }
                        }
                        &.up{
                            .la {
                                background: $themeNavBorder;
                            }
                            span {
                                color: $themeNavBorder;
                            }
                        }
                    }
                    &+li {
                        border-left: 1px solid #d6d6d6;
                    }
                    & .la {
                        width: 30px;
                        height: 30px;
                        background: red;
                        line-height: 30px;
                        text-align: center;
                        color: #fff;
                        font-size: 20px;
                        text-shadow: 1px 1px 1px rgba(0,0,0,.3);
                        border-radius: 100px;
                        float: left;
                        margin: 0 10px 0 0;
                        font-weight: 600;
                    }
                }
            }
        }
        &_box {
            display: table;
            & p {
                font-weight: 700;
                color: #666;
                margin: 0 0 5px;
            }
            & span {
                font-size: 22px;
                display: block;
            }
            & h3 {
                color: #999;
                font-size: 12px;
                margin: 0;
            }
        }
        
    }
    &_recent_activities {
        background: #fff;
        margin: 0 0 30px;
        & h2 {
            padding: 15px;
            border-bottom: 1px solid #efefef;
            margin-bottom: 18px;
        }
        &_list {
            max-height: 802px;
            overflow: auto;
            & ul {
                margin: 0 20px 0 35px;
                padding: 0 0 10px 32px;
                border-left: 1px solid #e9e9e9;
                li.purchase_order .la {
                    background: #e2d752;
                }
                li.invoices .la {
                    background: #5ec6f2;
                }
                li.returns .la {
                    background: #ff8030;
                }
                li.damage_items .la {
                    background: #ed5c6a;
                }
                & li {
                    position: relative;
                    color: #696969;
                    line-height: 21px;
                    list-style: none;
                    &+li {
                        margin: 20px 0 0;
                    }
                    & .la {
                        display: inline-block;
                        width: 28px;
                        height: 28px;
                        position: absolute;
                        top: 5px;
                        left: -46px;
                        border-radius: 100%;
                        text-align: center;
                        line-height: 28px;
                        background: $themeNavBorder;
                        color: #fff;
                        font-size: 15px;
                    }
                    & a {
                        color: $themeNavBorder;
                        margin: 0 5px 0 0;
                    }
                    & strong {
                        font-size: 12px;
                        font-weight: 700!important;
                    }
                    span {
                        padding: 0 5px 0 0;
                    }
                    time {
                        color: #b1b1b1;
                        font-size: 11px;
                        display: block;
                    }
                }
            }
        }
    }
    &_selling_title{
        & .la {
            color: #B6B6B6;
            margin: 0 10px ;
            font-weight: 600;
        }
        & span {
            color: $themeNavBorder;
        }
        & h2 {
            font-size: 14px;
            //font-weight: 700;
            text-transform: uppercase;
            overflow: hidden;
            margin: 0 0 20px;
        }
        & .form-group {
            width: 200px;
            float: right;
        }
    }
    &_this_month_revenue{
        h2 {
            float: left;
            line-height: 32px;
            margin: 0;
        }
        span{
            font-size: 14px;
            font-weight: 700;
            overflow: hidden;
            float: left;
            line-height: 32px;
        }
        .css-1aya2g8 {
            *border: none;
            background: none;
            height: 26px;
            min-height: auto;
            font-size: 13px;
            overflow: hidden;
            color: #000;
        }
        .css-d8oujb {
            display: none;
        }
        .css-1492t68 {
            *color: #00b85e;
            *right: 0;
        }
    }
    &_this_month_revenue{
        overflow: unset;
        float: left;
        margin: 0 0 30px;
    }
    &_chart_box{
       width: 100%;
       float:left;
    }
}
.firstLogin{
    button{ 
        display: none
    }
    button.btn-primary{
        display: block;
    }
}
@media only screen and (min-width: 1200px){
    .bpos_sell_mobile {
        margin-top: 50px;
        .navigation_sales_btn {
            margin: 8px 0;
        }
        .navigation.navigation_sell {
            display: block;
            .btn{
                min-width: 100%;
            }
        }
        .navigation{
            .full-xs {
                padding: 0 8px;
            }
            .col-md-6{
                width: 41.66666667%;
            }
        }
        .navigation_search_sell {
            width: 100%;
            margin: 8px 0;
        }
        .col-md-8{
            width: 75%;
        }
        .col-md-4{
            width: 25%;
        }
    }
    .bpos_dashboard_m {
        top: 50px;
        position: absolute;
        height: 100% !important;
        overflow-y: auto;
        float: left;
        width: 100%;
        min-height: 100vh;
        margin: 0 0 30px;
    }
    .bpos_dashboard_top_ten_revenue{
        margin:0 0 80px;
    }
}
@media only screen and (max-width: 1200px){
    html {
        overflow-x: hidden;
        background:#fff;
    }
    body {
        overflow: auto !important;
        overflow-x:hidden !important;
        background: #eee !important;
    }
    ::-webkit-input-placeholder,::placeholder{
        font-size: 13px !important;
    }
    select,
    textarea,
    input{   
        font-size: 13px !important;   
    }
    input{   
        /* Remove First */
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none; 
        box-shadow: none !important;
        font-size: 13px !important;   
    }
    // Menu Mobile //
    #menu_mobile{
        display:block !important;
        float:left;
        width: 33.33333333%;
        padding:0 15px;
    }
    ul.sub_menu.show div {
        position: relative;
    }
    .bm-cross-button {
        background: #373a48;
        right: -30px !important;
        top: 0 !important;
        height: 46px !important;
        width: 30px !important;
        text-align: center;
        border-left: 1px solid #4a4c58;
    }
    li.menu_item .icons8-drop-down-arrow {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 999;
        width: 152px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        padding-left: 100px;
    }
    .bm-cross-button > span {
        top: 40% !important;
    }
    .bm-overlay {
        transition: opacity 0.5s ease 0s !important;
    }
    li.depots_mobile {
        margin: 45px 0px 0;
        border-top: 1px solid #484a56;
        border-bottom: 1px solid #484a56;
        padding:15px;
    }
    nav.bm-item-list li ul {
        // display: block;
        display: none;
        margin: 0 20px;
    }
    nav.bm-item-list li:not(:last-child) a {
        border-bottom: 1px solid #51535d;
    }
    nav.bm-item-list li a {
        padding: 15px;
    }
    #slide .bm-menu, #stack .bm-menu, #reveal .bm-menu {
        padding: 0;
    }
    .bm-menu-wrap{
        transform: translate3d(0, 0px, 0px) !important;
        left: -200%;
    }
    .menu_mobile_active .bm-menu-wrap{
        transform: translate3d(0px, 0px, 0px) !important;
        left:0;
    }
    .bm-burger-button {
        position: relative;
        width: 70px;
        height: 50px;
        > span {
            position: relative;
            width: 20px;
            height: 20px;
            top: 16px;
            left: 15px;
            display: block;
        }
    }
    #menu_mobile {
        padding: 0 15px;
        display: block;
        height: 100%;
    }
    .bm-cross {
        background: #fff;
    }
    .bm-burger-bars {
      background: #ffff;
      height:1px !important;
    }
    .bm-morph-shape {
      fill: #373a47;
    }
    .bm-menu {
      background: #373a47;
    }
    .bm-menu a {
      color: #b8b7ad;
    }
    .bm-menu a:hover,
    .bm-menu a:focus {
      color: #c94e50;
    }
    .bm-item-list a {
      padding: 0.8em;
    }
    .bm-item-list a span {
      margin-left: 10px;
      font-weight: 700;
    }
    #slide .bm-cross,
    #stack .bm-cross,
    #reveal .bm-cross {
      background: #bdc3c7;
    }
    #slide .bm-menu,
    #stack .bm-menu,
    #reveal .bm-menu {
      //padding: 2.5em 1.5em 0;
      font-size: 1.15em;
    }
    #bubble .bm-cross,
    #push .bm-cross {
      background: #999;
    }
    #bubble .bm-menu,
    #push .bm-menu {
      padding: 3.4em 1em 0;
      height: 100%;
      box-sizing: border-box;
      overflow: auto;
      position: fixed;
      transition: opacity 0s cubic-bezier(0.17, 0.67, 0.1, 1.27) 0.3s, transform 0s cubic-bezier(0.17, 0.67, 0.1, 1.27) 0.3s;
      opacity: 0;
    }
    #bubble a,
    #push a {
      padding: 1em;
    }
    #bubble i,
    #push i {
      font-size: 1.7em;
      vertical-align: middle;
      color: #282a35;
    }
    #elastic .bm-cross {
      background: #888;
    }
    #elastic .bm-menu {
      padding: 2em 1em;
      font-size: 1.15em;
    }
    #elastic i {
      opacity: 0.5;
    }
    #elastic span {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 0.75em;
    }
    #scaleDown .bm-cross,
    #scaleRotate .bm-cross,
    #pushRotate .bm-cross,
    #fallDown .bm-cross {
      background: #888;
    }
    #scaleDown h2,
    #scaleRotate h2,
    #pushRotate h2,
    #fallDown h2 {
      margin: 0 auto;
      padding: 2em 1em;
      color: rgba(0, 0, 0, 0.4);
      box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
    }
    #scaleDown h2 i,
    #scaleRotate h2 i,
    #pushRotate h2 i,
    #fallDown h2 i {
      margin-left: 0.2em;
    }
    #scaleDown h2 span,
    #scaleRotate h2 span,
    #pushRotate h2 span,
    #fallDown h2 span {
      font-size: 1.6em;
      font-weight: 700;
    }
    #scaleDown a,
    #scaleRotate a,
    #pushRotate a,
    #fallDown a {
      padding: 1em;
      text-transform: uppercase;
      transition: background 0.3s, box-shadow 0.3s;
      box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
    }
    #scaleDown a span,
    #scaleRotate a span,
    #pushRotate a span,
    #fallDown a span {
      letter-spacing: 1px;
      font-weight: 400;
    }
    #scaleDown a:hover,
    #scaleRotate a:hover,
    #pushRotate a:hover,
    #fallDown a:hover,
    #scaleDown a:focus,
    #scaleRotate a:focus,
    #pushRotate a:focus,
    #fallDown a:focus {
      background: rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
      color: #b8b7ad;
    }
    // End Menu Mobile //
    .panel_modal {
        width: 100%;
        right: -200%;
        &.show .btn {
            margin: 0 0 10px;
        }
        &_list {
            height: calc(100% - 84px - 49px - 30px) !important;
        }
        &_close {
            padding: 12px 15px;
        }
        .row {
            padding: 0 15px !important;
        }
    }
    .open_panel_fixed{
        right:0;
        display:none;
    }
    .modal-dialog {
        padding: 0 15px;
    }
    .bpos_dashboard_recent_activities_list ul {
        margin: 0 20px 0 25px;
    }
    .bpos_dashboard_recent_activities_list {
        max-height: 870px;
        overflow: auto;
    }
    .bpos_dashboard_selling_title .form-group {
        width: 150px;
    }
    .navigation_themes_color_box_list li,.navigation .navigation_change_color_box_list li {
        width: 50%;
        float: left;
    }
    .navigation {
        position: relative;
        justify-content: space-between;
        & .navigation_menu{
            display:none;
        }
    }
    .btn{
        min-width:unset;
    }
    label {
        line-height: 20px;
    }
    .personnel_list_table,.personnel_list_btn {
        overflow-y: auto;
    }
    .total_receipts{
        margin:15px 0;
    }
    .setting_general .form-group:last-child {
        margin-bottom: 15px;
    }
    .setting_general .setting_general_item .setting_general_header label i {
        line-height: 25px;
    }
    .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content.not_p label {
        margin-top: 0;
        line-height: 20px;
    }
    .setting_general,& .setting_general.margin-bottom-300{
        margin:0 0 20px !important;
    }
    .setting_general .setting_general_item .is_input .form-group {
        position: absolute;
        right: 15px;
        top: 58px;
        width: 60px;
    }
    .setting_general .printed_form .printed_form_design .printed_form_view iframe {
        width: 100% !important;
        margin: 15px 0;
        float: left;
    }
    .st_body .st_body_content .scroll_bar {
        margin: 15px 0;
        overflow-x: auto;
    }
    .st_body {
        top: 0;
        .st_left_menu {
            display: none;
        }
        .st_body_content {
            position: relative;
            margin: 0;
            max-width: 100%;
            & .scroll_product{
                overflow-x:auto; 
                margin:0 0 15px 0;
            }
            & .personnel_list {
                margin: 0;
                &  div:nth-child(2){
                    //overflow-x:auto;
                }
                table>tbody>tr>td, table>tbody>tr>th, table>tfoot>tr>td,table>tfoot>tr>th,table>thead>tr>td,
                table>thead>tr>th {
                    white-space: nowrap;
                }
            }
        }
    }
    .table_transfer{
        margin: 15px 0;
    }
    .personnel_list_form.show{
        margin-top: 0;
    }
    .report_title {
        font-size: 17px;
    }
    .product_sort.show,.personnel_list .personnel_list_btn,.k-content div {
        overflow: inherit !important;
    }
    .personnel_list_btn.personnel_list_btn_receipts {
        overflow-x: auto !important;
        margin: 0;
    }
    .personnel_list_products{
        margin:0 0 15px 0 !important;
    }
    .bpos_price_list_title {
        margin: 0 0 30px;
        h1 {
            margin: 30px 0 5px;
        }
    }
    .bpos_help {
        position: static;
        margin: 0;
        background: $primaryBackgroundColor;
        min-height: 100%;
        & .container {
            margin: 0 auto;
        }
    }
    .list_order_shipping{
        margin:0 0 15px;
    }
    .no-margin-table{
        margin:0 !important;
    }
    .navigation_mobile,.depots{
        display:none;
    }
    .navigation {
        position: relative;
        display: block;
        margin: 0 -15px;
        & .navigation_logo {
            width: 33.33333333%;
            float: left;
            padding:0;
            text-align: center;
            margin: auto;
            a {
                width: 110px;
                display: block;
                margin: 10px auto;
            }
        }
        & .navigation_setting {
            width: 33.33333333%;
            display: block;
            padding:0 15px;
            float: left;
            ul {
                display: block;
                float: right;
                margin: 0 15px;
            }
            li {
                width: auto;
            }
        }
    }
    .setting_general_email_chart .form-group {
        margin: 0 0 15px;
    }
    .btn_add_box,& .personnel_list_btn{
        overflow:inherit !important;
        div{
            overflow:inherit !important; 
        }
    }
    .bpos_sell_main{
        .navigation {
            margin: 0;
        }
        .bpos_box_sell{
            height: 100vh;
        }
        .scroll-content-inner{
            overflow-x: auto;
        }
        .navigation_search_sell {
            width: 100%;
            margin: 8px 0;
        }
        .navigation_sales_btn {
            margin: 8px 0;
        }
        .navigation .full-xs {
            padding: 0 8px;
        }
        .navigation_sell{
            .col-md-2 {
                width: auto;
            }
            .row {
                margin: 0 -8px;
            }
        }
    }
   
}
@media only screen and (max-width: 1024px){
    .react-datepicker-wrapper {
        display: block !important;
    }
    .filter-item {
        div{
            overflow: inherit !important;
        }
    }
    .personnel_list_form.show{
        max-height:unset;
    }
    .bpos_box_sell_right_box_content .color-green {
        font-size: 13px;
    }
}
@media only screen and (max-width: 991px){
    .container_login{
        position:static;
        padding: 30px;
    }
    label,.container_login .form_login .form_login_content .form_btn .btn, .container_login .login_process .form_login_content .form_btn .btn{
        font-weight:400;
    }
    .bpos_dashboard_right_top{
        width: 100%;
        overflow: hidden;
    }
    .bpos_dashboard_count_number .col-xl-20{
        width: 20%;
        float: left;
    }
    .bpos_dashboard_recent_activities_list {
        max-height: 300px;
    }
    .filter_products_list_btn {
        margin: 15px 0 0;
    }
    .date_and_time_box strong,.date_and_time_box b{
        padding:5px 0;
        display:block;
    }
    .nav>li>a {
        padding: 10px 5px;
    }
    .order_transport_left:after {
        position: relative;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateX(-50%) rotate(90deg);
        transform: translateX(-50%) rotate(90deg);
        margin: 20px 0;
        display: inline-block;
        width: 20px;
        height: 20px;
        right: unset;
    }
    .personnel_list_btn_discount {
        .form-group:last-child{
            margin-bottom: 15px;
        }
    }
    .product_add .product_add_category_btn_add {
        margin: 28px 0 0px 15px;
    }
    .margin-top-15-m{
        margin-top:15px !important;
    }
    .filter_products_box_btn{
        .btn{
            min-width: 100%;
            width: 100%;
        }
    }
    .bpos_sell_main {
        height: unset;
        & > .spin {
            height: unset;
        }
        .bpos_box_sell {
            display: block;
            height: unset;
            &_left,&_right{
                padding: 0 15px !important; 
                overflow: unset;
            }
           &_left {
                margin: 0 0 15px;
            }
            &_right_box_user {
                padding: 10px;
            }
            &_left_box {
                overflow: inherit;
                float:left;
                width: 100%;
            }
        }
        .warehouse_box_right .bpos_box_sell_right_box_content .form-group-btn-sell {
            height: auto;
        }
        .bpos_box_sell_right_box_content .cutomer_support_phone {
            margin: 0 0 15px;
        }
        .bpos_box_sell_right_box {
            overflow: unset;
            float: left;
        }
    }
    .padding-15-sm {
        padding: 0 15px !important;
    }
    .no-padding-xs{
        padding:0 !important;
    }
    .discount_add_quanity_hr{
        padding:0 0 15px;
    }
    .discount_action_xs {
        position: absolute;
        right: 20px;
        top: 11px;
        z-index: 0;
        & i {
            position: absolute;
            top: 0;
            right: 0;
            //color: red;
        }
    }
    .discount_goods .condition_first_select {
        margin: 30px 10px 10px !important;
        width: 44% !important;
    }
    .setting_general_content_item_content_sub.order_online{
        .alert{
            height:auto !important;
        }
    }
    .bpos_dashboard_recent_activities.bpos_dashboard_news_hot {
        margin: 0 0 30px 0;
    }
}
@media only screen and (max-width: 767px){
    .quick-edit .col-md-8{
        margin:0 0 4px;
    }
    .collect_enough_select{
        top:0px;
        right:0px;
    }
    .margin-top-15-xs{
        margin-top:15px !important;
    }
    .setting_profile button {
        right: 63px;
    }
    .filter-item {
        .form-group {
            margin: 0 0 15px;
        }
    }
    .remove-button{
        margin: 0px 0 15px;
    }
    ul.filter-list .col-md-9 .form-group {
        width: 100%;
        margin: 0 0 15px;
        &:last-child {
            margin: 0;
        }
    }
    .total_receipts{
        margin:0 0 15px;
        display:block;
        background:none;
        padding:0;
        div {
            background: #f3f3f3;
            text-align: left;
            padding: 10px;
            margin: 0 0 15px;
            font-size: 15px;
            &:last-child {
                margin: 0;
            }
        }
    }
    .depots{
        display:none;
    }
    .navigation .navigation_setting_user span.navigation_setting_info h3, & 
    .navigation .navigation_setting_user span.navigation_setting_info p{
        display:none;
    }
    .navigation .navigation_setting_user,.navigation .navigation_setting{
        padding: 0;
    }
    .navigation .navigation_setting_user span{
        display:block;
        &  i {
            position: relative;
        }
    }
    .navigation_setting_user_info{
        display:none;
    }
    .navigation_setting_user_name{
        display:block;
    }
    .navigation_mobile {
       display:block;
    }
    .bnc_dashboard_aff{
        .bpos_dashboard_selling_today_list ul li,& .bpos_dashboard_selling_today_list ul li:last-child  {
            width: 50%;
        } 
    }
    .bpos_dashboard_selling_today_list ul li {
        width: 100%;
        padding: 10px;
        margin:0 0 10px;
        justify-content: flex-start;
        background: #eeeeee;
    }
    .bpos_dashboard_selling_today_list ul li:nth-child(1) {
        padding: 10px;
    }
    .bpos_dashboard_selling_today_list ul li + li{
        border:none;
    }
    .bpos_dashboard_selling_today_list ul li:last-child {
        width: 100%;
        border: none;
        padding: 10px;
        margin: 0;
    }
    .bpos_dashboard_selling_today {
        margin: 20px 0 0;
    }
    .bpos_dashboard_selling_title .form-group {
        float: left;
        width: 100%;
        margin: 10px 0;
    }
    .bpos_dashboard_right_top {
        margin:20px 0;
    }
    .bpos_dashboard_recent_activities {
        margin: 20px 0;
    }
    hgroup.bpos_dashboard_selling_title .margin-left-10 {
        margin: 0 !important;
    }
    .bpos_dashboard {
        position: static;
        min-height: auto;
        padding: 0;
        margin: 0;
        .col-md-8 {
            width: 100%;
            overflow: hidden;
            margin: 0;
        }
    }
    .navigation .navigation_notification_list {
        left: -200px;
        &:before {
            left: 208px;
        }
    }
    .modal-dialog {
        height: unset;
        margin: 0 0 15px;
        max-height: 300px !important;
        overflow-y: auto;
    }
    .bpos_box_sell_right_box_content .form-group-btn-sell .btn-payment{
        position: fixed;
        bottom: 0;
        z-index: 9;
        left: 0;
        right: 0;
    }
    .btn-margin-payment{
        margin:15px 0 0 !important;
    }
    .bpos_box_sell_right_box .form-group-sale-box{
        right: 105px;
        top: -21px;
        left:unset;
    }
    .bpos_sell_main .bpos_box_sell_right_box {
        margin: 0 0 50px;
    }
    .nav_stocktakes{
        width: 100%;
        float: left;
    }
    .st_body .st_body_content .title_menu{
        font-size: 15px;
    }
    .bpos_help .col-md-10{
        padding:10px 0;
        border:none;
        border-bottom:1px solid #ddd;
    }
    table.table.table-hover.table-bordered.table_product {
        white-space: pre;
    }
    .name_sell_bpos{
        margin:0;
    }
    .setting_general_content_item_icon {
        display: none !important;
    }
    .padding-right-0{
        padding:0 !important;
    }
    .list_order_shipping{
        overflow-x: auto;
    }
    .setting_general .setting_general_item .setting_general_content .setting_general_content_item .setting_general_content_item_content{
        padding:0;
    }
    .setting_general .setting_general_item .setting_general_content .setting_general_content_item.item_height .setting_general_edit_item {
        top: 9px;
    }
    .setting_general_content_item_content > label {
        padding-right: 70px;
        font-weight:600;
    }
    .bnc_dashboard_aff .bpos_dashboard_selling_today,.bnc_help_aff_info{
        margin: 0 0 20px 0;
    }
    .bpos_dashboard_recent_activities.bpos_dashboard_news_hot,.bnc_dashboard_aff .bpos_dashboard_current_balance{
        margin:0;
    }
    .bpos_dashboard_statistical_list ul li {
        border-bottom: 1px solid #fff;
    }
}
@media only screen and (max-width: 480px){
    .tracking_list_link{
        .modal-dialog{
            max-height: inherit !important;
            min-height: auto !important;
            overflow-y: unset;
        }
    }
    .container_login{
        padding:0;
        .container_aff_box .form_login_content{
            padding: 30px 20px 0;
        }
    }
    .bpos_dashboard_statistical_list ul li {
        margin: 0 0 15px;
        background: #eeeeee;
        padding: 10px 15px;
        &:last-child{
            //margin:0 0 5px 0; 
        }
    }
    .bnc_dashboard_aff .bpos_dashboard_selling_today {
        background: none;
        margin:0;
    }
    .bpos_dashboard_recent_activities.bpos_dashboard_chart_aff{
        margin-bottom:0;
    }
    .bpos_dashboard_m .personnel_list {
        height: 400px;
        canvas{
            height: 100%;
        }
    }
    .list_receipts_modals{
        .input_info {
            width: 100%;
            padding: 0;
            &_2 {
                margin: 15px 0 0;
            }
        }
    }
    .modal-dialog {
        top: 50%;
        transform: translateY(-50%);
        max-height: 500px !important;
        overflow-y: auto;
    }
    .bpos_sell_main .navigation {
        height: auto;
        float: left;
        width: 100%;
    }
    .bpos_box_sell_right_list_table {
        width: 100%;
        overflow: hidden;
    }
    .bpos_box_sell_list_table_main,.bpos_box_sell_left_box {
        width: 100%;
        float: left;
    }
    .bpos_sell_main .navigation_sell{
        & .col-md-2 {
            width: 50% !important;
            & .btn {
                width: 100%;
            }
        }
        & .col-md-2.full-btn{
            width: 100% !important;
        }
    }
    .bpos_sell_main .navigation_sell_purchasereturn{
        .navigation_search_sell {
            margin: 8px 0;
        }
    }
    .bpos_sell_main .navigation_search_sell {
        margin: 8px 0 0;
    }
    .output_complete_search {
        z-index: 9999;
        overflow: auto;
        max-height: 200px;
        max-width: 350px;
    }
    .add_customer_info_bpos{
        .input_info {
            width: 100%;
            padding: 0;
        }
        .load_address {
            width: 100%;
            padding: 0;
            overflow: hidden;
        }
    }
    .magin-bottom-port-15{
        margin-bottom: 15px !important;
    }
    .setting_general_content_item .btn {
        min-width: 100%;
    } 
    .hidden-port-xs{
        display:none;
    }
    .discount_action {
        position: absolute;
        right: 20px;
        top: 11px;
        z-index: 999;
        & i {
            position: absolute;
            top: 0;
            right: 0;
            //color: red;
        }
    }
    .col-xs-port{
        &-2{
            width: 20%;
        }
        &-6{
            width: 60%;
        }
        &-5{
            width: 50%;
        }
    }
    .margin-top-15-por{
        margin-top:15px !important;
    }
    .margin-top-10-por{
        margin-top:10px !important;
    }
    .col-md-6.col-sm-6.col-xs-6.full-xs.magin-top-10-por {
        margin-top: 10px !important;
    }
    .output_complete_search{
        max-width: 100%;
        &_info {
            display: inline-block;
            h2 {
                max-width: 100%;
                float: none;
                padding: 10px 0 5px;
            }
            p {
                float: left;
                margin: 0 0 5px 0;
            }
        }
        &_img{
            height: auto;
        }
    }
    .hidden-port{
        display:none;
    }
    .sms_before_customer_birthday_item{
        position:relative;
    }
    .btn-delete-ab{
        position: absolute;
        min-width: auto;
        right: -75px;
        top: 50%;
        transform: translateY(-50%);
        width:unset !important;
        padding:0;
        .btn{
            min-width: unset;
        }
    }
    .bnc_dashboard_aff{
        .bpos_dashboard_selling_today_list ul li,& .bpos_dashboard_selling_today_list ul li:last-child  {
            width: 100%;
        } 
    }
    .bnc_product_banner_aff_list_tab ul {
        display: flex;
        width: 400px;
        overflow-x: auto;
        padding: 0;
        li {
            float: left;
            white-space: pre;
        }
    }
}
@media only screen and (max-width: 360px){
    .menu_mobile_active .bm-menu-wrap {
        width: 250px !important;
    }
}
@media only screen and (max-width: 320px){
    .nav_stocktakes {
        padding: 0 10px !important;
        &.nav > li > a{
            font-size:11px;
        }
    }
}
/*** Styles added to fix the issue with zoom in on iphone ***/
@media screen and (-webkit-min-device-pixel-ratio:0) { 
    select,
    textarea,
    input {
      font-size: 13px !important;
    }
    ::-webkit-input-placeholder,::-moz-input-placeholder {
        font-size: 13px !important;
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio:0) { 
    select:focus,
    textarea:focus,
    input:focus {
      font-size: 13px !important;
    }
    ::-webkit-input-placeholder,::-moz-input-placeholder {
        font-size: 13px !important;
    }
  }
/* iPhone < 5: */
@media screen and (device-aspect-ratio: 2/3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 13px !important; }
    ::-webkit-input-placeholder,::-moz-input-placeholder {
        font-size: 13px !important;
    }
}

/* iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 13px !important; }
    ::-webkit-input-placeholder,::-moz-input-placeholder {
        font-size: 13px !important;
    }
}

/* iPhone 6, iPhone 6s, iPhone 7 portrait/landscape */
@media screen and (device-aspect-ratio: 375/667) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 13px !important; }
    ::-webkit-input-placeholder,::-moz-input-placeholder {
        font-size: 13px !important;
    }
}

/* iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus portrait/landscape */
@media screen and (device-aspect-ratio: 9/16) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 13px !important; }
    ::-webkit-input-placeholder,::-moz-input-placeholder {
        font-size: 13px !important;
    }
}
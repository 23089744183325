 /* 
    Document   : reset css
    Created on : 6-6-2018, 12:00:00
    Author     : DTM
    Description: Code reset SASS Main
*/
.text{
    &-primary{
        color:#1b64d2;
    }
    &-success{
        color:#00b75e;
    }
    &-danger{
        color:#f30d0a;
    }
}
strong,.btn,.container_login label{
    font-weight: 500 !important;
}
button[disabled], html input[disabled] {
    cursor: no-drop !important;
}
input,textarea{
    outline: none;
}
a,img{
    -webkit-transition: all ease .5s;
    -moz-transition: all ease .5s;
    -ms-transition: all ease .5s;
    -o-transition: all ease .5s;
    transition: all ease .5s;
    text-decoration: none !important;
}
.img_cover{
    width: 100% ;
    height: 100% !important;
    object-fit: cover !important;
}
.img_scale_down{
    width: 100% ;
    height: 100% !important;
    object-fit: scale-down !important;
    &.max100{
        max-width: 100px;
    }
}
.no-drop {
    cursor: no-drop;
}
/*=== Btn Width-full ===*/
.btn-w100 {
    width: 100%;
}
.btn-min-w{
    min-width: 100%;
}
/*=== Misc tools ===*/
.clearfix {
    clear: both;
}
.margin-auto{
    margin:auto;
}
.no{
    &-margin{
        margin: 0px !important;
    }	
    &-margin-right{
        margin-right: 0 !important;
    }
    &-margin-left{
        margin-left: 0 !important;
    }
    &-padding-right{
        padding-right: 0 !important;
    }
    &-padding-left{
        padding-left: 0 !important;
    }
    &-padding{
        padding: 0px !important;
    }
    &-bottom-space{
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
    }
    &-top-space{
        padding-top: 0px !important;
        margin-top: 0px !important;
    }
    &-text-shadow {
        text-shadow: none !important;
    }
    &-border{
        border:none !important;
    }
    &-border-right{
        border-right: none !important;
    }
    &-border-left{
        border-left: none !important;
    }
}
$numbersmarginPX:
  "10" 10px,
  "15" 15px,
  "20" 20px,
  "25" 25px,
  "30" 30px,
  "300" 300px;

// Margin //
@each $i in $numbersmarginPX{
  .margin-#{nth($i, 1)} {
    margin: nth($i, 2) !important;
  }
  .margin-top-#{nth($i, 1)} {
    margin-top: nth($i, 2) !important;
  }
  .margin-bottom-#{nth($i, 1)} {
    margin-bottom: nth($i, 2) !important;
  }
  .margin-right-#{nth($i, 1)} {
    margin-right: nth($i, 2) !important;
  }
  .margin-left-#{nth($i, 1)} {
    margin-left: nth($i, 2) !important;
  }
}
$numbersPX:
  "10" 10px,
  "15" 15px,
  "20" 20px,
  "25" 25px,
  "30" 30px;

// Padding //
@each $i in $numbersPX{
  .padding-#{nth($i, 1)} {
    padding: nth($i, 2) !important;
  }
  .padding-top-#{nth($i, 1)} {
    padding-top: nth($i, 2) !important;
  }
  .padding-bottom-#{nth($i, 1)} {
    padding-bottom: nth($i, 2) !important;
  }
  .padding-right-#{nth($i, 1)} {
    padding-right: nth($i, 2) !important;
  }
  .padding-left-#{nth($i, 1)} {
    padding-left: nth($i, 2) !important;
  }
}
/*=== Reset Mobile Col ==*/	
@media only screen and (max-width: 480px){
    .full-xs{
        width: 100% !important;
        float:left;
    } 
}
@media only screen and (min-width: 1024px){
    .col-xl-20{
        width: 20% !important;
        float:left;
        padding: 0 15px;
    } 
    .col-xl-7{
		//width: 14.2% !important;
	} 
}
@media (min-width: 1332px){
    .modal-xl {
        width: 100%;
        padding:0 30px;
    }
}

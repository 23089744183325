/*----- Notify -----*/
#notification-box {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50px;
    right: 0px;
    z-index:9999;
    .crm_notification {
        display: flex;
        position: relative; 
        color: white;
        width: 380px;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
        padding: 24px;
        margin: 0 0 6px;
        overflow: hidden;
        color: #262626;
        font-size: 13px; 
        &.success {
            background: $themeNavBorder;
            background-position: 15px center;
            background-repeat: no-repeat;
            color: white;
        }
        &.info {
            background: #fff;
            background-position: 15px center;
            background-repeat: no-repeat;
        }
        &.warning {
            background: $warningBackgroundColor;
            background-position: 15px center;
            background-repeat: no-repeat;
        }
        &.error {
            background: $errorBackgroundColor;
            background-position: 15px center;
            background-repeat: no-repeat;
        }
        .crm_notification_content {
            display: flex;
            flex: 1;
            flex-direction: column;
            height: 100%;
            .crm_notification_content_title {
                font-weight: 700;
            }
            .crm_notification_content_desc {
                font-weight: normal;
            }
        }
        .crm_notification_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 60px;
            flex: 1;
            cursor: pointer;
            user-select: none;
            top: 24px;
            position: absolute;
            right: 24px;
            &:hover {
                font-weight: 700;
            }
        }
    }
}
.headerForm-enter {
    animation: 0.8s flipInX ease;
}
.headerForm-leave {
    animation: 0.5s flipOutX ease;
}
// .notifi-appear {
//     right: -380px;
// }

// .notifi-appear.notifi-appear-active {
//     right: 15px;
//     transition: opacity .2s;
// }
@import "../_variable.scss";
.dragUploadExcelRoot {
    // padding-left: 0px; // padding-right: 0px;
    // position: relative;
    .dragUploadExcel {
        position: relative;
        .uploadZone {
            background: white;
            height: 141px;
            border: dashed 2px #c2c2c2;
            border-radius: 2px;
            cursor: pointer;
            user-select: none;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 15px;
            span {
                z-index: 1;
            }
            input[type=file] {
                display: none;
            }
            &::after {
                background-size: contain;
                position: absolute;
                content: " ";
                background-image: url("../style/scss/common/image/xls.svg");
                width: 100px;
                height: 100px;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                left: 0;
                opacity: 0.3;
                background-repeat: no-repeat;
            }
            &:hover {
                background-color: $neutralLightColor;
            }
            &.uploadZone_hover {
                background-color: $neutralLightColor;
            }
            &.uploadZone_reject {
                background-color: $errorTextColor;
            }
            &.uploadZone_accept {
                background-color: $successTextColor;
            }
        }
        .info {
            .info_file {
                .info_file_table {
                    tr{
                        background: white !important;
                        td{
                            word-break: break-word;
                            &:nth-child(1) {
                                word-break: unset;
                                text-align: left;
                                font-weight: bold;
                            }
                            .react-sweet-progress-symbol{
                                width:40px;
                            }
                        }
                    }
                }
                .info_file_table_opt_insert {
                    td:nth-child(1) {
                        width: 150px;
                        text-align: left;
                        font-weight: bold;
                        line-height: 32px;
                    }
                    select.form-control {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
    .rowInfoExcel {
        // overflow: auto;
        // max-height: 200px;
        // margin-top: 15px;
        .rowInfoExcel_title {
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            margin: 15px 0;
        }
        select.form-control {
            margin-bottom: 0px;
        }
        .table-container {
            height: 305px;

            &.table tr>td:nth-child(2) {
                width: 30px;
            }
            &.table tr>td:nth-child(6) {
                width: 100px;
            }
            &.table tr>td:nth-child(5) {
                width: 100px;
            }
            &.table tr>td:nth-child(4) {
                width: 100px;
            }
            & tbody tr {
                display: block;
                padding: 9px;
            }
        }
        table {
            display: flex;
            flex-flow: column;
            height: 100%;
            width: 100%;
        }
        table thead {
            /* head takes the height it requires, 
            and it's not scaled when table is resized */
            flex: 0 0 auto;
        }
        table tbody {
            /* body takes all the remaining available space */
            flex: 1 1 auto;
            display: block;
            overflow-y: auto;
        }
        table tbody tr {
            width: 100%;
        }
        table thead,
        table tbody tr {
            display: table;
            table-layout: fixed;
        }
    }
}
/*== Sms Excel ==*/
.account_list_brands{
    padding:0;
    li{
        list-style-position: inside;
    }
}
.info_file_table {
    height: 141px;
    overflow-y: auto;
}
textarea.form-control.content_sms_excel{
    height: 108px;
}
.filter_form{
    max-height: 0;
    height: 0;
    transition: all 0.5s ease;
    opacity: 0;
    &.show{
        max-height: 400px;
        height: auto;
        opacity: 1;
        margin-bottom: 15px;
    }
}
.guaranteeHistory_list{
    .guaranteeHistory_status{
        min-width: 120px;
    }
}

.modal_guarantee{
    .form-group{
        margin-bottom: 15px;
    }
    .navigation_search_sell{
         margin: 0;
         width: 100%;
         border: 1px solid #1cb467;
         border-radius: 0 0 3px 3px;
         margin-bottom: 15px;
        .output_complete_search{
            width: calc(100% + 2px);
            ul{
                margin:0;
                padding:0;
                .output_complete_search_info_bottom{
                    display: none;
                }
            }
        }
    }
}
.modal_guarantee_history{
    .khach_hang{
        >div{
            width: 90%;
        }
    }
    .add_customer{
        position: absolute;
        top: 22px;
        right: 15px;
        z-index: 9;
        background: #fafafa;
        border: 1px solid #dadada;
        width: 32px;
        height: 33px;
        text-align: center;
        i{
            margin-right: 0;
        }
    }
}
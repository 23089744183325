@import "./_variable.scss";
.animated {
    animation-duration: 500ms;
}
.modal-dialog{
    top:50%;
    transform: translateY(-50%);
    margin: 0 auto;
    max-height: 650px;
}
.modal-title{
    font-size: 16px;
}
.alert {
    border-radius: 0px;
    user-select: none;
    padding: 10px 15px;
    margin-bottom: 15px;
}
.alert>p+p {
    line-height: 25px;
}
.modal {
    &_logout{
        .modal-body{
            display:none;
        }
        .modal-header{
            border:none;
        }
    }
    display: block; //visibility: hidden; // transition: all 200ms;
    // background-color: rgba(0, 0, 0, 0.4);
    &.show_md {
        visibility: visible; // zoom: 1;
        // transition: opacity 200ms;
        overflow-x: hidden;
        overflow-y: auto;
    }
    &.hide_md {
        visibility: hidden;
    }
    .modal-dialog {
        .modal-content {
            border-radius: 0px;
            border: none;
            .modal-footer {
                position: relative;
                display: flex;
                justify-content: flex-end;
                button {
                    float: left;
                }
            }
        }
    }
}
.md_overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
}
button {
    &:focus {
        outline: none;
    }
}
.no-resize {
    resize: none;
}
a {
    color: $primaryTextColor;
    text-decoration: none;
    &:focus,
    &:hover {
        color: darken($primaryTextColor, 15%) !important;
        text-decoration: none;
        outline: none;
    }
    &.btn:hover {
        color: #fff !important;
    }
}
hr{
    margin: 10px 0; 
}
.form-group .input-content {
    outline: none;
}
select {
    height: 32px;
    padding: 6px 15px;
    text-transform: capitalize;
}
select:not([multiple]) {
    appearance: none; //background-position: right 50%;
    background-position: calc(100% - 5px) 50%;
    background-repeat: no-repeat;
    background-image: url("/image/icon/drop-down-arrow.svg");
    padding: .5em;
    padding-right: 1.5em;
    border-radius: 0px;
}
select.form-control {
    margin-bottom: 15px;
    height: 32px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.btn:focus,
.btn:active,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
    outline: none;
    box-shadow: none;
}
a.btn.btn-primary{
    color:#fff;
}
.btn {
    min-width: 108px;
    border: none;
    font-weight: bold;
    outline: transparent;
    user-select: none;
    text-decoration: none;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: $font-size-body;
    position: relative;
    padding: 7px 8px;
    font-weight: 600;
    border-radius: 0.1875rem;
    height: auto;
    i {
        font-size: 18px;
        margin-right: 5px;
    }
    &.button_min_width_none{
        min-width: auto;
        max-width: 100%;
    }
    &.btn-primary {
        background-color: $themePrimaryColor;
        &:hover {
            background-color: darken($themePrimaryColor, 2%);
        }
        &:active {
            background-color: darken($themePrimaryColor, 6%) !important;
        }
    }
    &.btn-default {
        background-color: $neutralTertiaryColor;
        color: $whiteColor;
        &:hover {
            background-color: darken($neutralTertiaryColor, 2%);
        }
        &:active {
            background-color: darken($neutralTertiaryColor, 6%) !important;
        }
    }
    &.btn-success {
        color: #fff;
        background-color: #00bc52;
        border-color: #00bc52;
    }
    &.btn-none {
        background-color: transparent;
        min-width: none;
        background-color: transparent;
        width: auto;
        min-width: auto;
        height: auto;
        min-height: auto;
        padding: 0px;
        margin: 0px;
        display: inline-flex;
        &:hover {
            color: $themePrimaryColor;
        }
        i {
            color: $themePrimaryColor;
        }
    }
    .btn-slash-root {
        position: absolute;
        right: 0;
        .btn-slash {
            position: relative;
            width: 38px;
            margin-left: 10px;
            height: 100%;
            &::before {
                position: absolute;
                content: " ";
                width: 1px;
                height: 100%;
                background-color: white;
                left: 0px;
                top: 0px;
            }
        }
    }
    &.btn-menu {
        padding-right: 50px;
    }
    .btn-menu-list {
        position: absolute;
        left: 0;
        top: 100%;
        display: block;
        width: 100%;
        background: none;
        text-align: left;
        visibility: hidden;
        opacity: 0;
        transition: all 160ms;
        ul {
            padding: 0;
            list-style: none;
            margin: 0;
            width: 100%;
            margin-top: 11px;
            box-shadow: 0px 0px 3px 1px #bdbdbd;
            background: white;
            position: relative;
            li {
                padding: 5px 10px;
                &:hover {
                    background: #f1f1f1;
                }
            }
            &:after {
                content: '';
                position: absolute;
                right: 15px;
                top: -8px;
                border-right: 14px solid transparent;
                border-top: 14px solid #c2c2c2;
                transform: rotate(44deg);
            }
        }
        &.btn-menu-list-show {
            visibility: visible;
            opacity: 1;
            transition: all 200ms;
        }
    }
}
.require {
    color: red;
}
.form-control {
    box-shadow: none;
    border: 1px solid #e5e5e5;
    height: 32px;
    border-radius: 6px !important;
    padding: 0 10px !important;
    font-size:$font-size-body;
    &:focus {
        box-shadow: none;
        translate: all 200ms;
        border-color:#b5b5b5;
        outline:none;
    }
}
.form-group {
    position: relative;
    width: 100%;
    &:last-child {
        margin: 0;
    }
    .show-error {
        color: $errorTextColor;
    }
    .input-content {
        position: relative;
        input {
            border-radius: 0px;
            padding-left: 5px;
        }
        .icon_input_left {
            position: absolute;
            display: none;
            width: 30px;
            top: 0;
            justify-content: center;
            align-content: center;
            height: 100%;
            .icon {
                height: 100%;
                font-size: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                color: $disabledTextColor;
                cursor: pointer;
                &.icon_right {
                    right: 0;
                    &:hover {
                        color: $bodyTextColor;
                    }
                }
                &.icon_left {
                    left: 0;
                    &:hover {
                        color: $bodyTextColor;
                    }
                }
            }
        }
        .form-control {
            &:focus {
                border-color:#b5b5b5;
            }
            &:hover {
                border-color: #b5b5b5;
                &:focus {
                    border-color: #b5b5b5;
                }
            }
            //height: 34px;
            &.ic_left {
                padding-left: 30px;
            }
            &.ic_right {
                padding-right: 30px;
            }
            &.error {
                border-color: $errorBackgroundColor !important;
            }
        }
    }
}
.form-control-feedback {
    top: 27px;
    display: none;
}
.has-feedback {
    .form-control-feedback {
        display: block;
        top: 27px !important;
    }
}
// body span.cke_top, body span.cke_top span{background: white !important;}
label {
    position: relative;
    font-size: $font-size-body;
    line-height: 1;
    vertical-align: top;
    display: inline-block;
    margin-bottom: 1rem;
    .color_require {
        background: none !important;
    }
}
body label span.color_require{background: none !important;}
textarea.form-control {
    padding: 10px !important;
}
/*--- Amination ----*/
.spiner {
    animation: spin 1500ms linear infinite;
}
@keyframes spin {
    to {
        transform: rotate(359deg);
    }
}
/*-------- Fix color ------*/
.colorPrimary {
    color: $themePrimaryColor;
}
.color_require {
    color: $color_error;
}
/*----------Fix Select--------*/
.css-2o5izw {
    box-shadow: none !important;
    border-color: #eaeaea !important;
    min-height: 32px !important;
}
.css-1aya2g8,.css-vj8t7z,.css-1wy0on6,.css-162g8z5{
    min-height: 32px !important;
}
.css-1ep9fjw,.css-1sjym57,.css-1uq0kb5 {
    padding: 4px !important;
}
// .css-1aya2g8 {
//     border-radius: 0px !important;
//     height: 32px !important;
//     min-height: 32px !important;
// }

// .css-2o5izw {
//     border-radius: 0px !important;
//     height: 32px !important;
//     min-height: 32px !important;
//     &:hover {
//         border-color: red !important;
//     }
//     border-color: red !important;
//     box-shadow: none !important;
// }

// .css-15k3avv {
//     border-radius: 0px !important;
//     margin-top: 0px !important;
// }

/*----------Fix Datepicker--------*/
.react-datepicker-wrapper {
    position: relative;
    &:before {
        content: "\f15c";
        position: absolute;
        font: normal normal normal 19px/1 LineAwesome;
        display: block;
        color: #000;
        top: 50%;
        z-index: 2;
        transform: translateY(-50%);
        right: 10px;
    }
}
.react-datepicker-wrapper,.react-datepicker-wrapper input {
    height: 32px !important;
}
.react-datepicker {
    font-size: 1em !important;
    display: flex !important;
}
.react-datepicker__header {
    padding-top: 0.8em !important
}
.react-datepicker__month {
    margin: 0.4em 1em !important
}
.react-datepicker__day-name,
.react-datepicker__day {
    width: 1.9em !important;
    line-height: 1.9em !important;
    margin: 0.166em !important;
}
.react-datepicker__current-month {
    font-size: 1em !important
}
.react-datepicker__navigation {
    top: 1em !important;
    line-height: 1.7em !important;
    border: 0.45em solid transparent !important;
}
.react-datepicker__navigation--previous {
    border-right-color: #ccc !important;
    left: 1em !important
}
.react-datepicker__navigation--next {
    border-left-color: #ccc !important;
    right: 1em !important;
    left: 220px !important
}
.react-datepicker__time-container {
    width: 6em !important
}
.react-datepicker-time__header {
    font-size: 1em !important
}
.react-datepicker-time__header--time {
    padding-left: 0px !important;
    padding-right: 0px !important
}
.react-datepicker__time-box {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important
}
.react-datepicker__time-list {
    padding: 0 !important;
}
.react-datepicker__input-container {
    width: 100%;
}
.react-datepicker-wrapper {
    width: 100%;
}
/*----------Fix Pagination--------*/
.pagination {
    margin: 15px 0 10px;
}
.pagination>li>a:hover, .pagination>li>span:hover, .pagination>li>a:focus, .pagination>li>span:focus,.pagination>.active>a, .pagination>.active>span, .pagination>.active>a:hover, .pagination>.active>span:hover, .pagination>.active>a:focus, .pagination>.active>span:focus {
    background-color: $themeNavBorder;
    border-color: $themeNavBorder;
    color:#fff !important;
    cursor: pointer;
}
.pagination>li>a, .pagination>li>span{
    color:#000;
    margin: 0 !important;
}
/*----------Fix Table--------*/
table {
    width:100% !important;
    margin:0 !important;
    border-collapse: collapse;
    border-radius: 3px;
    background: #f9f9f9;
    border:none !important;
    thead {
        background: #1cb467;
        color: #fff;
        tr{
            th {
                border: none !important;
                border-bottom-width: 0;
                white-space: pre;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
    tr {
        &:nth-child(2n) {
            background: #f1f1f1;
        }
        th {
            &:nth-child(1) {
                text-align: center;
            }
        }
        td {
            vertical-align: middle !important;
            &:nth-child(1) {
                text-align: center;
            }
        }
    }
    .sub_category {
        font-family: monospace;
    }
}
.table>thead>tr>th{
    padding: 12px 8px;
}
.table-padding-th-5>thead>tr>th{
    padding: 5px !important;
}
.table-align-left {
    th {
        &:nth-child(1) {
            text-align: left !important;
        }
    }
    td {
        vertical-align: middle !important;
        &:nth-child(1) {
            text-align: left !important;
        }
    }
}
/*---SMOOTH-SCROLLBAR-REACT---*/
.scrollbar-thumb-y, .scrollbar-track-y {
    width: 5px!important;
}
.scrollbar-thumb-x, .scrollbar-track-x {
    height: 5px !important;
}
.scroll-content,[data-scrollbar]{
    width: 100% !important;
    table th,table td {
        white-space: pre;
    }
}
.scroll-content-inner{
    max-height: 450px;
    overflow-y:auto;
}
/*-- CkEditer --*/
.cke_reset_all{
    table{
        width: auto !important;
        margin: 0px !important;
        border-collapse: collapse;
        border-radius: 0;
        background: none;
        border: none !important;
        tr td:nth-child(1) {
            text-align: unset;
        }
        tr:nth-child(2n){
            background:none;
        }
    }
    .cke_dialog_footer {
        table{
            margin: 5px 10px !important;
        }
    }
    .cke_dialog_ui_vbox table, .cke_dialog_ui_hbox table{
        margin: auto !important;
    }
    .cke_dialog_contents_body table{
        width: 100% !important;
    }
    .cke_dialog_tabs {
        position: static;
        margin: 10px 0;
        width: 100%;
        border-bottom: 1px solid #ddd;
        a {
            margin: 0 5px;
        }
    }
  

}
.blockbuilder-branding{
    display:none  !important;
}
.btn_add_box{
    .row{
        margin:0 -7.5px;
    }
    [class*="col-"]{
        padding:0 7.5px;
    }
    .btn{
        min-width: 100%;
        margin-bottom:15px;
    }
}
@media only screen and (min-width: 1024px){
    .btn_add_box > .row > .col-md-2 {
        width: auto;
    }
}
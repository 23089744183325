.navigation{
    background: $themePrimaryColor;
    display: flex;
    flex: 1;
    flex-direction: row;
    box-shadow: 0 1px 1px rgba(0,0,0,.15);
    box-sizing: border-box;
    height:50px;
    position:fixed;
    top:0;
    left:0;
    right:0;
    z-index:10;
    &_mobile {
        display: flex;
        flex: 7 1;
    }
    &_setting_user_name{
        display:none;
    }
    &_sell{
        position:static;
    }
    &_themes_color_box_list{
        display: block !important;
        margin: 0 0px 10px !important;
        li {
            padding:0 7px;
            span {
                border: 1px solid #ddd;
                width: 100%;
                float: left;
                text-align: center;
                padding: 8px 0;
                border-radius: 100px;
            }
            &.active span,span:hover{
                background:#121212;
                color:#fff;
                border-color:#121212;
            }
        }
    }
    ul{
        list-style: none;
        list-style: none;
        display: flex;
        flex-direction: row;
        margin: 0;
        padding:0;
    }
    i{
        color: white;
    }
    li {
        position: relative;
        a{
            color: $whiteColor; 
            padding: 16px 15px; 
            display: block;  
         }
    }
    .navigation_logo {
        width: 110px;
        margin: 10px 15px;
    }
    .navigation_master{
        display: flex;
        position: relative;
        flex: 1;
        border-right: 1px solid $themeNavBorder;
        button{
            flex: 1;
            background: none;
            border:0;
            color:white;
        }
        .navigation_master_content{
            display: none;
            position: absolute;
            left: 0;
            top: 50px;
            width: 718px;
            height: auto;
            z-index: 123;
            background: #2b2b2b;
            overflow: hidden;
            ul{
                display: table;
                width: 100%;
                li{
                  display: block;
                  float: left;
                  width: 25%; 
                  border-right: 1px solid $themeNavBorder;
                  border-bottom: 1px solid $themeNavBorder;
                  a{
                    text-align: center;
                    i{
                        clear: both;
                        display: block;
                    }      
                  }
                }
                li:nth-child(5){
                    clear: both;
                }
            }
        }
    }
    .navigation_menu{
        display: flex;
        flex: 7;
        ul{
            li.menu_item{
                position: relative;
                a{    
                   padding: 16px 15px;
                   *border-right: 1px solid $themeNavBorder;
                   transition: all 0.2s ease;
                }
                span {
                    padding: 16px 15px;
                    display: block;
                    color: #fff;
                    cursor: pointer;
                    i{
                        color: white;
                        font-style: normal;
                    }
                }
                &:hover{
                    a{
                        color:white !important; 
                        background: $hover_color_bg_nav;
                        &:hover{
                            background: $hover_color_bg_nav;  
                        }
                    }
                    .sub_menu{
                        opacity: 1;
                        visibility: visible;
                        z-index: 9;
                        transform: translateY(0);
                    }
                }
            }
            li.menu_item_master{
                i{
                    position: relative;
                    top:auto;
                    right:auto;
                    padding: 15px;
                }
                &:hover{
                    a{
                        background: black;  
                    }
                    i{
                        color:white;
                    }
                }
            }
            .sub_menu{
                position: absolute;
                left: 0;
                top: 50px;
                background: #1cb467;
                min-width: 200px;
                transition: all 0.2s ease;	
                opacity: 0;
                visibility: hidden;
                z-index: 9;
                display:block;
                // display:none;
                transform: translateY(50px);
                &.show{
                    display: block;
                }
                li{
                    a{
                        border-bottom: 1px solid rgba(28, 180, 103, .5);
                        &:hover{
                            background:$themePrimaryColor !important;
                        }
                    }
                }
                
            }
        }
    }
    .navigation_notification{
        display: flex;
        border-right: 1px solid $themeNavBorder;
        justify-content: flex-end;
        align-items: center;
        &_box {
            float: right;
            padding: 0 10px;
            position: relative;
            cursor: pointer;
            & i {
                font-size: 20px;
            }
            & .badge {
                position: absolute;
                top: -9px;
                width: 15px;
                height: 15px;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                right: 3px;
                font-size: 9px;
                padding: 0;
                min-width: 15px;
                background: #FF0017;
            }
        }
        &_list {
            position: absolute;
            top: 36px;
            background: #fff;
            width: 300px;
            left: -120px;
            border: 1px solid rgba(100, 100, 100, .4);
            border-radius: 0 0 2px 2px;
            box-shadow: 0 3px 8px rgba(0, 0, 0, .25);
            color: #1d2129;
            -webkit-transition: all 0.2s ease;
            -o-transition: all 0.2s ease;
            transition: all 0.2s ease;
            opacity: 0;
            visibility: hidden;
            transform: translateY(0);
            z-index: 2;
            &:before {
                content: "";
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #fff;
                position:absolute;
                top:-10px;
                left: 128px;
            }
            & > p {
                border-bottom: 1px solid #ddd;
                padding: 10px;
                font-weight: 600;
                font-size: 13px;
                color: #000;
                margin: 0;
            }
            &_see_all{
                padding: 10px 0!important;
                width: 100%;
                float: left;
                border: none !important;
                &:hover{
                    background: #efefef;
                }
            }
            &_main {
                display: block !important;
                max-height: 400px;
                overflow-y: auto;
                & a {
                    padding: 0;
                    color: #000;
                }
                li {
                    padding: 10px 0;
                    display: block;
                    float: left;
                    width: 100%;
                    border-bottom: 1px solid #f7f7f7;
                    &:nth-child(even){
                        background-color: #f3f3f34d;
                    }
                    &:hover {
                        background: #efefef;
                    }
                }
            }
            &_ava {
                width: 50px;
                height: 50px;
                float: left;
                margin: 0 10px;
                & img {
                    border-radius: 50px;
                }
            }
            &_info {
                display: table;
                & p {
                    margin: 0 0 5px;
                    max-height:36px;
                    overflow:hidden;
                    font-size: 12px;
                }
                & time,& .la {
                    font-size: 12px;
                    color: #90949c;
                }
            }
        }
        &_box:hover .navigation_notification_list{
            opacity:1;
            visibility: visible;
            transform: translateY(0);
        }
    }
    .navigation_change_color {
        position: relative;
        display: flex;
        justify-content: flex-end;
        color: #fff;
        align-items: center;
        border-right: 1px solid #28af6b;
        padding: 0 10px;
        cursor: pointer;
        & img{
            width:18px;
            height:18px;
        }
        & .la {
            font-size: 18px;
            margin: 0 5px 0 0;
        }
        &:hover .navigation_change_color_box {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
        &_box {
            position: absolute;
            top: 50px;
            background: #fff;
            width: 200px;
            left: -59px;
            border: 1px solid rgba(100, 100, 100, 0.4);
            border-radius: 0 0 2px 2px;
            -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
            box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
            color: #1d2129;
            -webkit-transition: all 0.2s ease;
            -o-transition: all 0.2s ease;
            transition: all 0.2s ease;
            opacity: 0;
            visibility: hidden;
            -webkit-transform: translateY(0);
            -ms-transform: translateY(0);
            transform: translateY(0);
            z-index: 3;
            &:before {
                content: "";
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #fff;
                position: absolute;
                top: -10px;
                left: 67px;
            }
            p {
                padding: 10px;
                font-weight: 600;
                margin: 0;
            }
            &_list {
                display: block !important;
                margin:0 5px !important;
                li{
                    padding:0 5px;
                    margin:0 0 10px;
                    &.active:before {
                        content: "\f17b";
                        font: normal normal normal 16px/1 LineAwesome;
                        font-weight:bold;
                        font-size: 20px;
                        line-height: 50px;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        color: #fff;
                        text-align: center;
                    }
                    span {
                        height: 50px;
                        background: red;
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
    .navigation_setting{
        display: flex;
        padding: 0 15px 0 0;
        color:#fff;
        &_user{
            display: flex;
            padding: 0 15px 0 0;
            position: relative;
            & span {
                display: table;
                & h3 {
                    margin: 10px 22px 3px 0;
                    color: #fff;
                    font-size: $font-size-body;
                    font-weight: 600;
                    text-transform: capitalize;
                    white-space: pre;
                    max-width: 100px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                & p{
                    font-size:10px;
                    margin: 0 10px 0 0;
                }
                & i {
                    position: absolute;
                    top: 50%;
                    right: 5px;
                    transform: translateY(-50%);
                    font-size: 20px;
                }
            }
        }
        .navigation_setting_sub{
            position: absolute;
            z-index: 10;
            right: 0;
            top: 50px;
            visibility: hidden;
            opacity: 0;
            background: $themePrimaryColor;
            display: table;
            min-width: 200px;
            transition: all 0.2s ease;
            transform: translateY(50px);
            a{
                &:hover{
                    color:#fff !important;
                }
            }
            i{
                padding-right: 8px;
            }
            li{
                border-top: 1px solid $themeNavBorder; 
                .btn.btn-none{
                    color: #fff !important;
                    padding: 15px 0 15px 10px;
                    font-weight:400;
                    i{
                        margin-right: 0;
                        padding-right: 8px;
                        color: #fff !important;
                    }
                }
                &:hover{
                    background:$hover_color_bg_nav;
                }
            }
        }
        li{
            width: 100%;
            &:hover{
                .navigation_setting_sub{
                    visibility: inherit;
                    opacity: 1;
                    transform: translateY(0);
                }
            }
            .navigation_setting_img{
                float: left;
                width: 35px;
                height: 35px;
                padding: 0;
                margin: 7px 7px 7px 10px;
                img{
                    max-width: 35px;
                    max-height: 35px;
                    border-radius: 50%;
                    object-fit: scale-down;
                }
            }
        }
    }
}
.setting_profile{
    padding:15px 0 0 0;
    position: relative;
    button{
        position: absolute;
        bottom: -63px;
        right: 125px;
        z-index: 999;
    }
    &.file{
        button{
            bottom: -63px;
        }
    }
    &_tab2{
        & .form-group:nth-child(3) {
            margin: 0;
        }
    }
    &_tab3{
        .form-group:first-child {
            margin: 0;
        }
    }
}
.depots {
    width: 200px;
    border-right: 1px solid #28af6b;
    padding: 8px 10px;
    .css-1aya2g8{
        border: none !important;
    }
}
.panel_modal {
    width: 400px;
    box-shadow: -30px 0px 30px -30px rgba(0,0,0,0.2);
    border-left: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    top: 0;
    right: -100%;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 11;
    transition: all ease .3s;
    line-height: 25px;
    &.show {
        right: -1px;
    }
    &_close {
        width: 100%;
        overflow: hidden;
        background: #00b85e;
        padding: 12px 30px;
        span{
            float:left;
            color: #fff;
        }
        i {
            float:right;
            color: #fff;
            font-size: 20px;
            cursor: pointer;
            &:hover{
                color:#eaeaea;
            }
        }
    }
    &_list {
        display: block !important;
        padding: 0 30px 0px !important;
        margin:0 0 30px !important;
        overflow-y:auto;
        li{
            border-bottom: 1px solid #f5f5f5;
            position: relative;
            list-style: none;
            a {
                color: #000;
                padding: 10px 0;
                overflow: hidden;
                display: table;
                &:hover {
                    color: $themeNavBorder !important;
                }
            }
        }
        span {
            padding: 0 0 0 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 200px;
            display: block;
        }
        & .price_modal {
            color: red;
            padding: 0 0 0 10px;
            margin: 0;
            span {
                display: inline;
                padding: 0 3px!important;
                color: #000;
            }
        }
        & .delete_item {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            & .la {
                color: #6f6f6f;
                font-size: 20px;
                &:hover {
                    opacity: .5;
                }
            }
        }
        & .form-group {
            margin: 0 10px;
            width: 90px;
            input {
                height: 25px !important;
            }
        }
        &_ava {
            width: 50px;
            height: 50px;
            display: table-cell;
            margin: 0 10px 0 0;
            vertical-align: middle;
            img {
                border-radius: 50px;
            }
        }
        .delete-list {
            position: absolute;
            right: 0;
            top: 20px;
            color: red;
            font-size: 16px;
            cursor: pointer;
        }
    }
}
.open_panel_fixed {
    position: fixed;
    top: 60px;
    z-index: 3;
    right: 15px;
    background: #1eb367;
    color: #fff;
    padding: 20px 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    border-radius: 6px 0px 0 6px;
    & .la {
        font-size: 24px;
    }
    & span{
        position: absolute;
        top: 15%;
        width: 17px;
        height: 17px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 3px;
        font-size: 9px;
        padding: 0;
        min-width: 15px;
        background: #FF0017;
    }
}
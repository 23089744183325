@import "../_variable.scss";
.general_branchs_content {
    .btn_add_branchs {
        margin-bottom: 15px;
    }
    .branch_item {
        td {
            &:first-child {
                text-align: center;
            }
        }
    }
}

.load_address {
    float: left;
    width: 50%;
    padding-left: 7.5px;
    .load_address_item {
        display: block;
        width: 100%;
        & .form-group {
            margin: 0px 0 15px;
        }
    }
    .load_address_item_order {
        display: block;
    }
}

.input_info {
    float: left;
    width: 50%;
    padding-right: 7.5px;
    .form-group {
        display: block;
        width: 100%;
    }
}

.search_form_1 {
    float: left;
    width: 20%;
    padding-right: 7.5px;
    .form-group {
        display: block;
        width: 100%;
    }
}
.search_form_2 {
    float: left;
    width: 20%;
    padding-right: 7.5px;
    .form-group {
        display: block;
        width: 100%;
    }
}
.search_form_3 {
    float: left;
    width: 20%;
    padding-right: 7.5px;
    .form-group {
        display: block;
        width: 100%;
    }
}

.search_form_4 {
    float: left;
    width: 20%;
    padding-right: 7.5px;
    .form-group {
        display: block;
        width: 100%;
    }
}
.search_form_5 {
    float: left;
    width: 20%;
    padding-right: 7.5px;
    .form-group {
        display: block;
        width: 100%;
    }
}

.search_form_receipts {
    float: left;
    padding-right: 7.5px;
    .form-group {
        display: block;
        width: 100%;
    }
}
.debt_list_item{
    padding:15px 0;
    border-bottom: 1px dashed #eaeaea;
    float: left;
    width: 100%;
    button{
        min-width: auto;
        margin-top: 23px;
        z-index: 1;
    }
    &.item_old{
        position: relative;
        &:after{
            content:"";
            height: 165px;
            width: 100%;
            background: #ff000000;
            top: 0;
            left: 0;
            position: absolute;
        }
    }
    &:last-child{
        border-bottom:none;
    }
    &_box_modal{
        & .row{
            &:not(:last-child){
                border-bottom: 1px dashed #f5f5f5;
                margin-bottom: 15px;
                padding: 0 0 15px;
            }
        }
    }
}
.btn-view-debt{
    min-width: 100%;
}
.link_sendo{
    border: #c2c2c2 dashed 1px;
    border-radius: 2px;
    padding: 10px;
    //margin-bottom: 15px;
    height: 197px;
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // justify-content: center;
    a {
        color: $themeNavBorder;
    }
}

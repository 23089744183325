.preview_appearance_wel {
    width: 100%;
    img{
        width: 100%;
    }
    // display: flex;
    // flex-direction: column;
    // box-shadow: 0 2px 32px 0 rgba(34, 34, 34, .14), 0 1px 6px 0 rgba(34, 34, 34, .06);
    // border-radius: 8px;
    // border: 1px solid #eee;
}

.preview_appearance {
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 32px 0 rgba(34, 34, 34, .14), 0 1px 6px 0 rgba(34, 34, 34, .06);
    border-radius: 8px;
    border: 1px solid #eee;
    .header {
        height: 57px;
        border-radius: 8px 8px 0px 0px; // background-color: #0071b2;
        transition: background-color .2s;
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-left: 15px;
        padding-right: 15px;
        .icon {
            i {
                font-weight: bold;
            }
        }
        .profile {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-left: 15px;
            .avatar {
                margin-right: 10px;
            }
            .info {
                .desc {
                    font-size: 12px;
                }
            }
        }
    }
    .body {
        height: 339px;
        display: flex;
        flex-direction: column;
        font-size: 13px;
        padding: 15px;
        &.bg0 {
            background-color: white;
        }
        &.bg1 {
            background-image: url(/static/media/bg/1.png);
            background-size: contain;
        }
        &.bg2 {
            background-image: url(/static/media/bg/2.png);
            background-size: contain;
        }
        &.bg3 {
            background-image: url(/static/media/bg/3.png);
            background-size: contain;
        }
        &.bg4 {
            background-image: url(/static/media/bg/4.png);
            background-size: contain;
        }
        &.bg5 {
            background-image: url(/static/media/bg/5.png);
            background-size: contain;
        }
        &.bg6 {
            background-image: url(/static/media/bg/6.png);
            background-size: contain;
        }
        &.bg7 {
            background-image: url(/static/media/bg/7.png);
        }
        &.bg8 {
            background-image: url(/static/media/bg/8.png);
            background-size: contain;
        }
        .time {
            color: #ccc;
            font-size: 12px;
            text-align: center;
            padding: 10px;
        }
        .from {
            display: flex;
            .msg {
                color: #6E7A89;
                padding: 15px;
                border-radius: 6px;
                transition: background-color .2s;
                background-color: #EFF3F6;
                max-width: 80%;
            }
        }
        .client {
            display: flex;
            flex-direction: column;
            .msg1 {
                display: flex;
                flex-direction: row-reverse;
                .msg {
                    color: #fff;
                    padding: 15px;
                    border-radius: 6px;
                    transition: background-color .2s; // background-color: #0071b2;
                    max-width: 80%;
                }
            }
            .time_ago {
                display: flex;
                justify-content: flex-end;
                color: #ccc;
                font-size: 12px;
                margin-top: 5px;
            }
        }
    }
    .footer {
        height: 40px;
        background-color: #f6f6f6;
        border-radius: 0px 0px 8px 8px;
        display: flex;
        color: #888;
        justify-content: space-around;
        align-items: center;
        .input {
            font-size: 12px;
        }
    }
}
.select_range_discount > div > label {
    margin: 0 10px 10px 0;
    &:nth-child(1) {
        min-width: 120px;
    }
}
.iradio_flat-grey {
    margin: 0 5px 0 0;
}
.discount{
    &_changer {
        height: 32px;
        line-height: 32px;
        color: #fff;
        cursor: pointer;
    }
    &_vnd{
        border-radius: 6px 0 0 6px;
    }
    &_percent{
        border-radius: 0px 6px 6px 0px;
    }
    &_hr {
        padding: 15px 15px;
        border-bottom: 1px solid #e5e5e5;
        margin-top: 15px !important;
        &:nth-child(1){
            border-top: 1px solid #e5e5e5; 
         }
    }
    &_apply{
        margin-top:15px;
    }
    &_add_quanity{
        padding:15px 0;
        border-top:1px solid #ddd;
        border-bottom:1px solid #ddd;
        &_hr {
            border-bottom: 1px solid #ddd;
            padding: 0 15px 15px;
            margin: 0 0 15px;
            float:left;
            width: 100%;
        }
    }
}
.fa-discount-close {
    height: 32px;
    line-height: 32px;
    font-size: 20px;
    cursor: pointer;
    &:hover {
        opacity: .6;
    }
}
.discount_select_condition .nxt_checkbox {
    position: absolute;
    right: 15px;
    margin: -6px 0;
    top: 0;
    z-index:9;
    .label_checkbox .title {
        top: 3px;
        position: relative;
    }
}
.col-md-9.discount_goods > div {
    width: 40%;
    float: left;
    margin: 0 15px;
    &:nth-child(2) {
        margin: 23px 15px;
    }
}

.code_embed {
    position: relative;
    button {
        position: absolute;
        top: 5px;
        right: 5px;
        opacity: 0;
        transition: all 0.167s cubic-bezier(0.1, 0.25, 0.75, 0.9);
    }
    pre {
        border-radius: 0px;
        background-color: $neutralLighterAltColor;
    }
    &:hover {
        button {
            opacity: 1;
            transition: all 0.167s cubic-bezier(0.1, 0.25, 0.75, 0.9);
        }
    }
}

.st_body {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #eeeeee;
    top: 50px;
    .st_left_menu {
        display: flex;
        width: $setting_menu;
        background-color: #eeeeee;
        position: fixed;
        height: 100%;
        .content_menu {
            width: 100%;
            .content_menu_title {
                font-size: 18px;
                height: 60px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0 15px;
                border-bottom: 1px solid #e6e6e6;
            }
            .content_menu_list {
                .content_menu_item {
                    margin-bottom: 15px;
                    .content_menu_item_title {
                        font-weight: 500;
                        user-select: none;
                        padding: 5px 0;
                        cursor: pointer;
                        .ic_menu {
                            &::before {
                                transition: all 100ms;
                            }
                            &.drop_down {
                                &::before {
                                    transform: rotate(90deg);
                                    transition: all 100ms;
                                }
                            }
                        }
                        .content_menu_item_title_txt {
                            padding: 10px 15px;
                        }
                    }
                    .content_menu_item_child {
                        max-height: 0;
                        transition: max-height 0.167s cubic-bezier(0.1, 0.25, 0.75, 0.9);
                        overflow: hidden;
                        user-select: none;
                        &.show {
                            max-height: 500px;
                            transition: max-height 0.167s cubic-bezier(0.1, 0.25, 0.75, 0.9);
                        }
                        .content_menu_item_child_item {
                            padding: 10px 0px 10px 30px;
                            cursor: pointer;
                            transition: all ease 100ms;
                            color:#000;
                            &.active {
                                background-color: $themeNavBorder;
                                color:#fff;
                                &:hover {
                                    & img{
                                        filter: invert(0);
                                        -webkit-filter: invert(0);
                                    }
                                }
                            }
                            &:hover {
                                background-color: $themeNavBorder;
                                transition: all ease 100ms;
                                color:#fff;
                                & img{
                                    filter: invert(100%);
                                    -webkit-filter: invert(100%);
                                }
                            }
                            & img{
                                width: 20px;
                                height: 20px;
                                display: inline-block;
                                margin: 0 10px 0 0;
                                transition:all ease .0s;
                            }
                        }
                        .content_menu_item_child_item.item3 {
                            padding-left: 45px;
                        }
                    }
                }
            }
        }
    }
    .st_body_content {
        .personnel_list{
            margin: 0 0 80px;
        }
        .scroll_bar {
            height: auto !important;
        }
        background-color: #fff;
        *box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 4px 24px 2px rgba(0, 0, 0, .1);
        position: absolute;
        margin-left: $setting_menu;
        transition: all 0.167s cubic-bezier(0.1, 0.25, 0.75, 0.9);
        height: 100% !important;
        overflow-y: scroll;
        float: left;
        max-width: calc(100% - #{$setting_menu});
        width: 100%;
        padding: 0 15px;
        min-height: 100vh;
        &.st_body_content_animation_pop {
            margin-left: 500px;
            opacity: 0;
            transition: all 0.167s cubic-bezier(0.1, 0.25, 0.75, 0.9);
        }
        &.st_body_content_animation_push {
            margin-left: $setting_menu;
            opacity: 1;
            transition: all 0.367s cubic-bezier(0.1, 0.9, 0.2, 1);
        }
        &.full {
            margin-left: 0px;
            max-width: 100%;
            transition: all 0.367s cubic-bezier(0.1, 0.9, 0.2, 1);
        }
        .st_body_content_child {
            *max-width: calc(960px - #{$setting_menu});
            &.full {
                *max-width: calc(100% - #{$setting_menu});
            }
        }
        .title_menu {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 18px;
            height: 52px;
            align-content: center;
            .icon {
                padding-right: 15px;
                font-size: 24px;
                cursor: pointer;
                &:hover {
                    opacity:.5;
                }
                .ic_menu_toggle {
                    &::before {
                        transform: rotate(180deg);
                    }
                    &.ic_menu_toggle_back {
                        &::before {
                            transform: rotate(360deg);
                        }
                    }
                }
            }
            .title {
                user-select: none;
            }
        }
    }
}

.design_container {
    padding: 15px;
    .design_data {
        width: 50%;
    }
    .design_bar_item {
        img {
            width: 50px;
            max-height: 50px;
            border: 1px solid #ccc;
            border-radius: 2px;
            padding: 2px;
            margin-right: 20px;
            float: left;
        }
        .upload_image {
            background-color: #55b989;
            color: #fff;
            border-color: #08c136;
            border: 0;
            padding: 8px 15px;
            margin: 5px 0;
            border-radius: 2px;
            width: 70%;
        }
    }
    .design_bar {
        width: 40%;
        border: 1px solid #bdbdbd;
        border-radius: 5px;
        .design_save {
            background: #d4d4d4;
            padding: 5px 0;
            border-radius: 0 0 5px 5px;
            button {
                margin: 0px auto;
            }
        }
    }
    .design_view {
        width: 58%;
        margin-left: 2%;
        border: 1px solid #bdbdbd;
        border-radius: 5px;
        padding: 10px;
    }
}

.setting_audio {
    margin-bottom: 15px;
    .setting_audio_ctrol {
        width: 48px;
        background: #bfbfbf;
        height: 22px;
        border-radius: 20px;
        position: relative;
        cursor: pointer;
        &.active {
            background: #2ea84c;
            &:after {
                left: 28px;
            }
            .setting_audio_status {
                &.on {
                    opacity: 1;
                }
                &.off {
                    opacity: 0;
                }
            }
        }
        .setting_audio_status {
            color: white;
            position: absolute;
            top: 3px;
            font-size: 11px;
            &.on {
                left: 7px;
                opacity: 0;
            }
            &.off {
                right: 8px;
            }
        }
        &:after {
            content: '';
            width: 18px;
            height: 18px;
            background: white;
            border-radius: 50%;
            position: absolute;
            left: 2px;
            top: 2px;
            transition: all 0.5s ease;
        }
    }
}

.role_permission {
    table {
        tr {
            .nxt_checkbox {
                display: inline-block;
                height: 20px;
            } 
            td:nth-child(2) {
                padding-left:40px;
            }
            td[colspan]{
                padding-left:75px;
                background-color: #ececec;
                text-align: left;
            }
        }
    }
}

.table-promotion {
    .wp30 {
        width: 30%;
    }
    .wp35 {
        width: 35%;
        text-align: left;
    }
    .wp5 {
        width: 5%;
        vertical-align: middle;
    }
    .btndelete button {
        min-width: auto;
        width: 100%;
    }
    tr th {
        text-align: center;
    }
}

.table_permission {
    width: 100%;
    table th,table td {
        white-space: pre;
    }
}

.setting_general.no-margin {
    margin: 0px !important;
}
.ecommerce-tabs{
    ul.nav{
        padding: none;
    }
}
.Synchronized button,.sync_lazada .close,.sync_lazada .btn-default{
    display: none,
}
.Synchronized .btn-primary{
    display: inline;
    margin-right: 79px
}
.setting_general.margin-bottom-300 {
    margin-bottom: 300px !important;
}
.setting_general.setting_earn_points .setting_general_item .is_input .form-group {
    top: 8px;
}
.setting_general.setting_earn_points .setting_general_item .setting_general_content
 .setting_general_content_item.item_height .setting_general_edit_item {
    top: 8px;
}
.setting_general_content_item{
    .navigation_search_sell {
        width: 100%;
        margin: 0;
    }
    .navigation_search_sell input {
        border: 1px solid #ddd !important;
    }
    .output_complete_search ul {
        padding: 0;
    }
}
.viettel_info {
    p{
        line-height: 25px;
    }
}
.keyword_list_used {
    label{
        margin:0 0 10px 0;
        line-height:20px;
    }
}
.form_textarea_main .form-group{
    margin:0;
}
.form_search_tab_setting{
    & .navigation_search_sell {
        width: 100%;
        margin: 0;
        input {
            background: #ececec;
        }
    }
    & .output_complete_search ul {
        padding: 0;
    }
}

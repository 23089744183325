.color-gray{
    color:gray;
}
.bpos_application_box.bpos_aff_box {
    height: auto;
    //border-radius: 0;
    &:hover .bpos_application_box_first{
        transform: translateX(0);
    }
    &:hover .bpos_aff_hover_readmore{
        transform:scale(1);
    }
    .bpos_application_field{
        border-top: 1px solid #ddd;
        border-bottom:none;
    }
}
.bpos_aff_hover_readmore {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease .5s;
    transform:scale(0);
}
.tracking_list_link{
    input.form-control.input-link-aff {
        border-radius: 0 !important;
        background: none;
        border: 1px solid rgba(0,0,0,.15);
        padding: .5rem .75rem !important;
        font-size: 1rem !important;
        line-height: 1.25;
        color: #464a4c;
        background-color: #eceeef;
        height: 37px;
        border-right: 0;
    }
    .input-content {
        margin: 0 !important;
    }
    .input-group-addon{
        text-align: center;
        &:last-child {
            border-radius: 0;
        }
        & .la {
            margin: 0 !important;
        }
    }
}
p.link-aff-target {
    font-size: 12px;
    padding: 6px 0 0;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    a {
        color: #337ab7;
    }
}
.bnc_dashboard_aff{
    .bpos_dashboard_selling_today {
        background: #eeeeee;
        margin: 0 0 30px;
        padding: 0;
    }
    .bpos_dashboard_current_balance {
        padding: 15px;
        overflow: hidden;
        height: 108px;
        text-align:center;
        margin:0 0 30px;
        background: #00b75e;
        display:flex;
        justify-content: center;
        align-items:center; 
        flex-direction: column;
        span {
            display: block;
            font-size: 19px;
            color:$color-white;
        }
         p {
            text-transform: uppercase;
            color: $color-white;
            font-size: 14px;
        }
    }
    .bpos_dashboard_recent_activities {
        background: #eeeeee;
        h2 {
            border-bottom: 1px solid $color-white;
            margin:0;
        }
        ul{
            border-left: 1px solid $color-white;
            padding:10px 0 10px 32px;
        }
        @media only screen and (min-width: 991px){
            .bpos_dashboard_recent_activities_list{
                max-height: 447px;
                //height: 447px;
            }
        }
    }
}
.bpos_dashboard_recent_activities.bpos_dashboard_news_hot {
    width: 100%;
    float: left;
    margin: 0 0 70px 0;
    .bpos_dashboard_news_hot_box {
        border-bottom: 1px solid $color-white;
        padding: 15px 0;
        &:last-child{
            border:none;
        }
    }
    .bpos_dashboard_news_hot_max {
        padding: 0 15px;
    }
    .bpos_dashboard_news_hot_img {
        height: 150px;
    }
    .bpos_dashboard_news_hot_info{
        h3 {
            margin: 0 0 10px;
            font-size: 14px;
            line-height: 25px;
            font-weight: 600;
            a:hover {
                color: #337ab7 !important;
            }            
        }
        p {
            color: #808080;
            line-height: 20px;
            font-size: 13px;
            height: 60px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: horizontal;
            -webkit-line-clamp: 2;
        }
    }
}
.bpos_dashboard_guide_list{
    ul {
        border: none !important;
        li {
            margin: 0 0 5px;
            a:hover {
                text-decoration: underline !important;
            }
        }
    }
}
.bpos_dashboard_aff_title{
    margin: 0 0 15px;
    // width: 100%;
    // overflow: hidden;
    // display: flex;
    // align-items: center;
    h2 {
        float: left;
        margin: 0;
        font-size: 18px;
        flex: 1;
    }
}
.bpos_dashboard_statistical_list {
    ul {
        padding: 0;
        margin: 0;
        li {
            padding:15px;
            list-style: none;
            border-right: 1px solid $color-white;
            &:last-child{
                border:none;
            }       
        }
        span {
            font-size: 19px;
            color: red;
        }
    }
    &_box{
        p {
            text-transform: uppercase;
            margin: 0;
        }
        h3 {
            margin: 10px 0;
            color: #a9a9a9!important;
            text-transform: uppercase;
            font-size: 12px;
        }
    }
}
.bpos_dashboard_chart_aff{
    height: 493px;
}
.table-bordered a{
    color: #337ab7;
}
.btn-register{
    background: #3597f1;
    border-color: #3597f1;
    color:#fff;
    &:hover{
        color:#fff;
        background: #337ab7;
    }
}
.filter_products_title{
    h3 {
        font-size: 15px;
        font-weight: 600;
        margin: 0 0 10px;
    }
}
.filter_products_box{
    .row {
        margin: 0 -7.5px;
    }
    [class*="col-"] {
        padding:0 7.5px
    }
}
ul.filter-list {
    padding: 0;
    margin:0;
    li {
        list-style: none;
        border-top: 1px solid #eaeaea;
        padding: 15px 0;
        &:last-child{
            border-bottom: 1px solid #eaeaea;
            margin: 0 0 15px;
        }
    }
    .btn {
        min-width: 100%;
    }
    .row {
        margin: 0 -7.5px;
    }
    [class*="col-"] {
        padding:0 7.5px
    }
    .col-md-9{
        .form-group {
            width: 25%;
            float: left;
            margin:0 15px 0 0
        }
        .multi-select .form-group{
            //width: 50%;
        }
        label{
            margin:9px 0;
            input{
                margin:0 7px 0 0;
            }
        }
        input[type=date]{
            box-shadow: none;
            border: 1px solid #e5e5e5;
            height: 34px;
            border-radius: 6px !important;
            padding: 0 10px !important;
            font-size: 13px;
            width:25%;
            line-height: 1.42857143;
            color: #555;
            background-color: #fff;
            display: inline-block;
        }
    }
}

/*---------------------------Cuộc chat------------------------*/
.container_conversation {
    position: fixed; // margin-left: $with_nav_left;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    .left {
        background-color: white;
        flex: 0 0 25%;
        max-width: 420px;
        min-width: 240px; // background: red;
        .filter {
            position: relative;
            .left-filter {
                height: $menu_height;
                border-bottom: 1px solid #e9ebee;
                width: 100%;
                display: flex;
                align-items: center;
                padding: 0px 20px 0px 16px;
                justify-content: space-between;
                color: #90949c;
                font-size: 14px;
                font-weight: 550;
                .left-filter-icon-menu {
                    user-select: none;
                    display: flex;
                    flex-direction: row;
                    cursor: pointer;
                    align-items: center;
                    i {
                        font-size: 24px;
                        margin-right: 5px;
                    }
                    &:hover {
                        color: #4b4f56;
                    }
                }
                .left-filter-input-menu {
                    display: none;
                    flex: 1;
                    padding: 0px 5px 0px 5px;
                    .form-group{
                        margin-bottom :0;
                    }
                    &.show {
                        display: block;
                    }
                    input {
                        width: 100%;
                        border: none;
                        &::placeholder {
                            color: #cecece;
                        }
                        &:focus {
                            outline: none;
                        }
                    }
                }
                .left-filter-icon-more {
                    &.hide {
                        display: none;
                    }
                    user-select: none;
                    cursor: pointer;
                    i {
                        font-size: 24px;
                    }
                    &:hover {
                        color: #4b4f56;
                    }
                }
            }
            .filter-menu {
                position: absolute;
                width: 100%;
                background-color: #fff;
                border-bottom: 1px solid #dddfe2;
                box-shadow: 0 4px 4px 1px rgba(206, 208, 212, .6);
                box-sizing: border-box;
                display: none;
                z-index: 1;
                &.show {
                    display: block;
                }
                .filter-menu-item {
                    cursor: pointer;
                    height: $menu_height; // border-bottom: 1px solid #e9ebee;
                    width: 100%;
                    display: flex;
                    flex-direction: column; // align-items: center;
                    padding: 0px 20px 0px 16px; // justify-content: space-between;
                    color: #90949c;
                    font-size: 14px;
                    font-weight: 500; // background-color: #f6f7f9;
                    flex-direction: row;
                    align-items: center;
                    i {
                        font-size: 24px;
                        margin-right: 16px;
                    }
                    &:hover {
                        background-color: #f6f7f9;
                    }
                    &.active {
                        background-color: #f6f7f9;
                        i {
                            color: $color_green;
                        }
                    }
                }
            }
        }
        .left-content {
            // display: flex;
            // flex-direction: column;
            max-height: 550px;
            overflow-y: scroll;
            .left-content-empty {
                background-image: url('/static/media/icon/empty-inbox.svg');
                width: 150px;
                height: 150px;
                background-size: cover;
                display: flex;
                align-self: center;
                margin-top: 40%;
                justify-content: center;
                .txt {
                    display: flex;
                    flex-direction: column-reverse;
                    color: #b7b7b7;
                    font-weight: 500;
                    margin-top: 15px;
                }
            }
            .left-content-item {
                border-bottom: 1px solid #e9ebee;
                cursor: pointer;
                height: 72px;
                overflow: hidden;
                position: relative;
                transition: height 500ms;
                transition-delay: 500ms;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px 0px 0px 16px;
                justify-content: space-between;
                .avatar
                {
                    border:0;
                    background: none;
                    img{
                        max-width: 40px;
                        max-height: 40px;
                    }
                }
                &:hover,&.active {
                    background-color: #f6f7f9;
                }
                .preview {
                    display: flex;
                    flex-direction: column;
                    padding-left: 10px;
                    flex: 1;
                    .desc {
                        font-size: 12px;
                    }
                    .name {
                        font-weight: 500;
                    }
                    .tag {
                        display: flex;
                        font-size: 12px;
                    }
                    .staff {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        .staff-avatar {
                            margin-right: 5px;
                        }
                        .staff-desc {
                            font-size: 12px;
                        }
                    }
                }
                .act {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    height: 100%;
                    &:hover {
                        .done{
                            background-color: $color_green;
                            i {
                                color: white;
                            }
                        }
                    }
                    & .active{
                        background-color: $color_green;
                        i {
                            color: white !important;
                        }
                    }
                    .done {
                        width: 36px;
                        height: 36px; // background-color: $color_green;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: background-color .2s;
                        i {
                            font-size: 24px;
                            color: #abadb3;
                            transition: color .2s;
                        }
                    }
                }
            }
        }
    }
    .body {
        // background: green;
        display: flex;
        flex: 3;
        flex-direction: row;
        min-width: 0;
        border-left: solid 1px #dddfe2;
        .container_x {
            flex: 2 0 0%;
            overflow: hidden;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .container_x-head {
                display: flex;
                height: $menu_height;
                background-color: #fff;
                border-bottom: 1px solid #dddfe2;
                align-items: center;
                justify-content: space-between;
                padding-left: 16px;
                .container_x-head-profile {
                    display: flex;
                    flex-direction: row;
                    .profile-avatar {
                        display: flex;
                        justify-content: center;
                        align-self: center;
                        margin-right: 15px;
                        width: 40px;
                        height: 40px;
                        img {}
                    }
                    .profile-desc {
                        display: flex;
                        flex-direction: column;
                        .profile-desc-name {
                            font-weight: 500;
                        }
                        .profile-desc-staff {
                            font-size: 13px;
                        }
                    }
                }
                .container_x-head-act-btn {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    i {
                        font-size: 24px;
                        font-weight: 500;
                        color: $color_text_left;
                    }
                    .container_x-head-act-btn-spam,
                    .container_x-head-act-btn-unread {
                        height: 100%;
                        margin-right: 15px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        &:hover {
                            i {
                                color: #4b4f56;
                            }
                        }
                    }
                }
                .container_x-head-act {
                    display: flex;
                    flex-direction: row;
                    height: 100%;
                    .container_x-head-act-item{
                        width: 130px;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        font-weight: 500;
                        position: relative;
                        user-select: none;
                        color: white;
                        transition: all 200ms;
                        background: #bfbfbf;
                        i {
                            font-size: 18px;
                            margin-right: 5px;
                        }
                        &:hover,&.active {
                            background-color: $themeDarkAltColor;
                            color: white;
                            transition: all 200ms;
                            &::before {
                                width: 0px;
                            }
                        }
                        &.spam{
                            background: none;
                            width: 80px;
                            i{
                                color: #333333;
                                font-size: 30px;
                                margin: 0;
                                &:hover{
                                    color:REd;
                                }
                            }
                        }
                    } 
                }
            }
            .container_x-body {
                display: flex;
                flex: 1;
                padding: 15px;
                position: relative;
                &::after {
                    content: " ";
                    background-image: url(/static/media/bg/app-bg.jpg);
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    opacity: 0.8;
                    padding: inherit;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
                .container_x-body-list {
                    // display: flex;
                    // flex-direction: column-reverse;
                    width: 100%;
                    max-height: 425px;
                    overflow-y: scroll;
                    overflow-x: hidden;
                    padding-right: 5px;
                    .container_x-body-list-time {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 15px;
                        .line-body {
                            position: relative;
                            display: flex;
                            justify-content: center;
                            padding: 0 15px;
                            font-weight: 500;
                            color: $color_error;
                        }
                        .line-after,
                        .line-before {
                            display: flex;
                            position: relative;
                            flex: 1;
                            &::before {
                                position: absolute;
                                content: " ";
                                width: 100%;
                                height: 1px;
                                background-color: #cacaca;
                            }
                        }
                    }
                    .container_x-body-list-item-timeago {
                        margin-top: 10px;
                        font-size: 13px;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        color: $color_text_left;
                    }
                    .container_x-body-list-item {
                        margin-bottom: 15px;
                        display: flex;
                    }
                    .container_x-body-list-item-content {
                        max-width: 70%;
                        padding: 15px;
                        border-radius: 8px;
                        position: relative;
                    }
                    .container_x-body-list-item-content-msg {}
                    .container_x-body-list-item-client {
                        flex-direction: row;
                        .container_x-body-list-item-client-avatar {
                            display: flex;
                            flex-direction: column-reverse;
                        }
                        .container_x-body-list-item-client-content {
                            background: #ececec;
                            margin-left: 20px;
                            &::before {
                                content: " ";
                                background-image: url(/static/media/icon/leftArrow.png);
                                position: absolute;
                                left: -18px;
                                bottom: 12px;
                                width: 20px;
                                height: 20px;
                                background-size: cover;
                            }
                        }
                    }
                    .container_x-body-list-item-staff {
                        flex-direction: row-reverse;
                        .container_x-body-list-item-staff-content {
                            background: #ddf8c6;
                            margin-left: 10px;
                            &::after {
                                content: " ";
                                background-image: url(/static/media/icon/rightArrow.png);
                                position: absolute;
                                width: 20px;
                                height: 20px;
                                background-size: cover;
                                right: -20px;
                                bottom: 8px;
                            }
                        }
                    }
                }
            }
            .container_x-footer {
                display: flex;
                height: 150px;
                border-top: solid 1px #dddfe2;
                flex-direction: column;
                .container_x-footer-notify {
                    display: flex;
                    flex-direction: row;
                    height: 20px; // position: absolute;
                    bottom: 5px;
                    padding: 0 10px;
                    .notify {
                        i {
                            font-size: 16px;
                        }
                        font-size: 13px;
                        color: $color_text_left;
                    }
                }
                .container_x-footer-text {
                    display: flex;
                    min-height: 60px;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    .container_x-footer-text-avatar {
                        padding: 0 8px;
                        width: 40px;
                        height: 40px;
                        .avatar {
                            border-radius: 0px;
                            width: 32px;
                            height: 32px;
                        }
                    }
                    .container_x-footer-text-input {
                        display: flex;
                        flex: 1;
                        .form-group {
                            width: 100%;
                            height: 100%;
                        }
                        textarea {
                            border: none;
                            resize: none;
                            &:focus {
                                outline: none;
                                border: none;
                                box-shadow: none;
                            }
                        }
                    }
                    .container_x-footer-text-act {
                        display: flex;
                        color: $color_text_left;
                        font-weight: 500;
                        align-content: center;
                        align-items: center;
                        i {
                            font-size: 24px;
                        }
                        div {
                            padding: 0 8px;
                        }
                    }
                }
            }
        }
        .right {
            max-height: 600px;
            // background: yellow;
            border-left: 1px solid rgba(0, 0, 0, .10);
            flex: 0 2 33.33%;
            max-width: 420px;
            min-width: 200px;
            flex-direction: column;
            overflow: hidden;
            overflow-y: scroll;
            .right-col {
                display: flex;
                padding: $margin_height 24px;
                flex-direction: column;
                &:not(:last-child) {
                    border-bottom: 1px solid rgba(0, 0, 0, .10);
                } // &:last-child{
                //     border-bottom: 1px solid rgba(0, 0, 0, .10);
                // }
                min-height: 130px;
            }
            .chat_info{
                input{
                    border: 0;
                    border-bottom: 1px solid transparent;
                    &:hover,&:focus,&:active{
                        border-bottom: 1px solid #2d8a2d;
                    }
                }
                .form-group{
                    margin-bottom: 0;
                    .icon_input_left{
                        i{
                            color:black;    
                        }
                    }
                    .icon_input_left{
                        width: 15px;
                    }
                }
                .right-item{
                    margin-bottom: 0;
                    .value{
                        
                    }
                    &:hover{
                        .edit{
                            opacity: 1;
                            visibility: inherit;
                        }
                    }
                    .edit{
                        position: absolute;
                        right: 0;
                        top: 0;
                        opacity: 0;
                        visibility: hidden;
                        transition: all 0.5s;
                        i{
                          padding:10px;      
                        }
                    }
                }
            }
            .right-one {}
            .right-two,
            .right-three,
            .right-four {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .right-two-tag {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: $margin_height;
                    align-items: center;
                    .right-two-tag-title {
                        font-weight: 500;
                    }
                }
                .right-two-tag-list {
                    display: flex;
                    flex: 1;
                    flex-wrap: wrap;
                    .ant-tag {
                        margin-bottom: 5px;
                    }
                }
                .right-two-tag-input {
                    display: flex;
                    .ant-select-show-search {
                        width: 100%;
                        input {
                            width: 100%;
                            &:hover {
                                border-color: #cecece;
                            }
                            &::placeholder {
                                color: #cecece;
                            }
                            &:focus {
                                outline: none;
                                border-color: #cecece;
                                box-shadow: none;
                            }
                        }
                    }
                }
                .right-three_content {
                    display: none; // display: flex;
                    flex: 1;
                    font-size: 13px;
                }
                .right-three_textarea {
                    display: block;
                    .note {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .text-area {
                            width: 100%;
                            textarea {
                                width: 100%;
                                &:hover {
                                    border-color: #cecece;
                                }
                                &::placeholder {
                                    color: #cecece;
                                }
                                &:focus {
                                    outline: none !important;
                                    border-color: #cecece;
                                    box-shadow: none;
                                }
                            }
                        }
                        .btn-s {
                            display: flex;
                            margin-bottom: 5px;
                            flex-direction: row;
                            justify-content: space-between;
                        }
                    }
                }
                .right-four_content {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    .right-four-item {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: $margin_height;
                        .name {}
                        .value {
                            font-size: 13px;
                            color: $color_text_left;
                        }
                    }
                }
            }
            .right-item {
                display: flex;
                flex-direction: row;
                margin-bottom: $margin_height;
                justify-content: space-between;
                position: relative;
                .icon {
                    width: 30px;
                    i {
                        font-size: 16px;
                    }
                }
                .value {
                    display: flex;
                    flex: 1;
                }
            }
            .right-title {
                font-size: 18px;
                font-weight: 500;
                text-align: center;
                margin-bottom: $margin_height;
                color: #1d374b;
            }
        }
        .tag_root{
            flex: 1;
            .tag{
                width: 49%;
                margin-right: 1%;
            }
            .tag:nth-child(2n){
                margin-right: 0;
            }
        } 
    } // .right {
    //     flex: 0 0 25%;
    //     max-width: 420px;
    //     min-width: 240px;
    //     background: red;
    // }
}
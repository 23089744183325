.edit_user_upload_image_box{
    height: 171px;
    border: dashed 1px #e5e5e5;
    border-radius: 6px;
    p{
        padding:10px;
    }
    & img {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
    }
}
$magenta:#ec3095;
$red:#f5222d;
$volcano:#fa541c;
$orange:#fa8c16;
$gold:#faad14;
$lime:#a0d911;
$green:#52c41a;
$cyan:#13c2c2;
$blue:#1890ff;
$geekblue:#2f54eb;
$purple:#722ed1;
.tag_root {
    position: relative;
    display: inline-block;
    .tag {
        float: left;
        padding: 3px;
        background: #c3c3c3;
        margin-right: 5px;
        margin-bottom: 5px;
        border-radius: 2px;
        color: white;
        user-select: none;
        transition: all 200ms;
        border: dashed 1px;
        span{
            overflow: hidden;
            white-space: nowrap;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            width: 89%;
            display: block;
            float: left;
        }
        i {
            cursor: pointer;
            &:hover {
                color: red;
            }
        }
        &.hidden {
            display: none;
        }
    }
}

.magenta {
    color: $magenta !important;
    background-color: lighten( $magenta, 40%) !important;
    border-color: darken( $magenta, 2%) !important;
}

.red {
    color: $red !important;
    background-color: lighten( $red, 40%) !important;
    border-color: darken( $red, 2%) !important;
}

.volcano {
    color: $volcano !important;
    background-color: lighten( $volcano, 40%) !important;
    border-color: darken( $volcano, 2%) !important;
}

.orange {
    color: $orange !important;
    background-color: lighten( $orange, 40%) !important;
    border-color: darken( $orange, 2%) !important;
}

.gold {
    color: $gold !important;
    background-color: lighten( $gold, 40%) !important;
    border-color: darken( $gold, 2%) !important;
}

.lime {
    color: $lime !important;
    background-color: lighten( $lime, 40%) !important;
    border-color: darken( $lime, 2%) !important;
}

.green {
    color: $green !important;
    background-color: lighten( $green, 40%) !important;
    border-color: darken( $green, 2%) !important;
}

.cyan {
    color: $cyan !important;
    background-color: lighten( $cyan, 40%) !important;
    border-color: darken( $cyan, 2%) !important;
}

.blue {
    color: $blue !important;
    background-color: lighten( $blue, 40%) !important;
    border-color: darken( $blue, 2%) !important;
}

.geekblue {
    color: $geekblue !important;
    background-color: lighten( $geekblue, 40%) !important;
    border-color: darken( $geekblue, 2%) !important;
}

.purple {
    color: $purple !important;
    background-color: lighten( $purple, 40%) !important;
    border-color: darken( $purple, 2%) !important;
}
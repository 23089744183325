.bpos_application{
    &_bg {
        height: 116px;
        overflow: hidden;
    }
    &_box {
        border: 1px solid #ddd;
        background: #fff;
        position: relative;
        height: 264px;
        overflow: hidden;
        border-radius: 6px;
        transition:all ease .5s;
        & p {
            margin: 0;
            line-height: 20px;
        }
        &_first{
           transition:all ease .5s;
        }
        &:hover .bpos_application_hover{
            left:0;
        }
        &:hover .bpos_application_box_first{
            transform: translateX(100%);
        }
    }
    &_info {
        padding: 15px;
        & h2 {
            font-size: 16px;
            font-weight: 600;
            margin: 0 0 10px;
        }
        & p {
            font-size: 13px;
            line-height: 20px;
            color: #7b7b7b;
            margin: 0;
            height: 40px;
            overflow: hidden;
        }
    }
    &_field {
        border-bottom: 1px solid #ddd;
        padding: 15px 15px;
    }
    &_hover {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        left: -100%;
        transition:all ease .5s;
        &_body {
            padding: 15px;
            height: 48px;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            display:none;
        }
    }
    &_hover_header,&_hover_footer {
        background: #fff;
        padding: 15px;
    }
    &_hover_header{
        height: 76px;
        overflow:hidden;
        display:none;
        p strong {
            margin: 0 0 10px;
            display: block;
        }
    }
    &_hover_footer {
        height: 100%;
        *height: calc(100% - 124px);
        line-height: 20px;
        *display: flex;
        *justify-content: center;
        *align-items: center;
        overflow:hidden;
        background: #fff;
    }
}
.container{
    &_bg {
        background-size: cover !important;
        position: fixed;
        width: 100%;
        height: 100%;
    }
    &_login_bg{
        background: url("/image/bg_login_1.jpg");
    }
    &_register_bg{
        background: url("/image/bg_register.jpg") center center no-repeat;
    }
}
// .form-group .input-content .form-control:focus, .form-group .input-content .form-control:hover, .form-group .input-content .form-control:hover:focus{
//     border:none !important;
// }
.power-text{
    color: rgba(255, 255, 255, 0.4);
    font-size: $font-size-body;
    padding: 20px 0;
}
.check_captcha{
    display: flex;
    flex: 1;
    img{
        height: 32px;
        display: flex;
        flex: 1;
    }
    .form-group{
        margin-right: 15px;
    }
    .show-error{
        margin: 10px 0 0 0;
    }
}
.title_form h2 {
    color: #000;
    margin: 20px 0;
    text-transform: capitalize;
    font-size: 20px;
}
.color-white h2{
    color:#fff;
}

.show-error {
    margin: 10px 0 0 !important;
    font-size: $font-size-body !important;
}
.fogot_password a:hover,.register_link a:hover,.more_link a:hover {
    color: $themePrimaryColor !important;
}
.check_captcha img{
    height: 40px;
}
@media (max-width:480px){
    .container_login_bg,.container_register_bg,.container_login  {
        background: #fff;
        position: relative !important;
    }
    .color-white h2,.power-text{
        color: #000;
    }
}
.container_login {
    display: flex;
    height: 100%;
    width: 100%;
    position: fixed;
    flex: 1;
    .form-group .input-content .form-control {
        border: 1px solid #e5e5e5;
        height: 40px;
        border-radius: 6px;
        padding: 0 10px!important;
    }
    .form_login, .login_process {
        @media (max-width:1024px){
           width:500px;
        }
        @media (max-width:767px){
            width:100%;
        }
        width: 33.33%;
        margin: auto;
        .input-content .icon_input_left .icon {
            display: none;
        }
        .form_login_content {
            @media (max-width:768px){
                padding: 20px 20px 10px;
            }
            @media (max-width:480px){
                padding: 0px 20px 10px;
                box-shadow: none;
            }
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            height: 100%;
            background: #ffffff;
            padding: 40px 40px 30px;
            border-radius: 4px;
            box-shadow: 0 -25px 37.7px 11.3px rgba(8, 143, 220, 0.07);
            z-index: 9;
            .logo {
                img {
                    width: 150px;
                }
            }
            .form_check_input {
                float: left;
            }
            .form_check_label .form-group {
                float: left;
                width: auto;
                margin: 0 10px 0 0;
            }
            .text-title {
                color: #404040;
                font-size: 1.5rem;
                font-weight: 600;
                padding: 0;
                margin-top: 12px;
                margin-bottom: 12px;
            }
            .form_btn {
                display: block;
                margin: 15px 0;
                .btn {
                    margin: 0;
                    width: 100%;
                    height: auto;
                    font-size: $font-size-body;
                    padding: 11px 8px;
                    font-weight: 600;
                    border-radius: 0.1875rem;
                }
                .btn.btn-default {
                    background-color: #c2c2c2;
                    margin: 0 0 10px;
                    color: #fff;
                    &:hover {
                        background: #a5a5a5;
                    }
                }
            }
            .more_link_root {
                display: flex;
                justify-content: space-between;
                margin:20px 0 0;
            }
        }
    } 
    .login_process {
        @media (max-width:1024px){
            position: fixed;
            top: 50%;
            left:50%;
            transform: translate(-50%,-50%);
        }
        &_box {
            width: 100%;
            margin: auto;
        }

    }
    .form_login_process{
        @media (max-width:767px){
            box-shadow: none !important;
        }
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        height: 100%;
        background: #fff;
        padding: 40px 40px 30px;
        border-radius: 4px;
        box-shadow: 0 -25px 37.7px 11.3px rgba(8, 143, 220, 0.07);
        text-align: center;
        .form_login_process_number{
            font-size: 25px;
            color: #fff;
            position: relative;
            overflow: hidden;
            height: 100px;
            z-index: 999;
            line-height:100px;
            z-index: 999;
            width: 100px;
            background: #ddd;
            margin: auto;
            border-radius: 100%;
        }
        .form_login_process_load{
            background: $themeNavBorder;
            *border-radius: 100%;
            position: absolute;
            left: 50%;
            top:100%;
            transform: translate(-50%,0%);
            height: 100px;
            z-index: -1;
            width:100px;
        }
        p{
            font-size: 18px;
            color:#000;
            margin-top: 10px;
        }
    } 
}

.form_line {
    position: relative;
    text-align: center;
    margin: 0 0 15px;
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background: #ddd;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
    }
    & span {
        position: relative;
        z-index: 0;
        background: #fff;
        padding: 0 20px;
        text-transform: capitalize;
    }
}

.btn{
    &.btn-login{
        font-size: 13px;
        padding: 11px 8px;
        font-weight: 600;
        border-radius: 0.1875rem;
        color: #fff;
    }
    &.btn-login-facebook {
        background: #3065cb;
        margin: 0 0 15px;
        &:hover{
            opacity:.9;
        }
    }
    &.btn-login-google {
        background: #e03325;
        &:hover{
            opacity:.9;
        }
    }
}
.react-sweet-progress-circle-outer{
    margin:auto;
}
.react-sweet-progress-symbol-absolute .react-sweet-progress-symbol{
    color:$themeNavBorder;
}
/*---- Clouds Loading ----*/
#clouds {
    top:40%;
    position: absolute;
    opacity:0;
    @media (max-width:767px){
       display:none;
    }
}
.cloud{
    width: 60px;
    height: 60px;
    animation-delay: 3.5s !important;
    position: relative;
}
/*Time to animate*/
.x1 {
    -webkit-animation: moveclouds 6s linear infinite;
    -moz-animation: moveclouds 6s linear infinite;
    -o-animation: moveclouds 6s linear infinite;
}
.x2 {
    left: 200px;
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 0.6;
    -webkit-animation: moveclouds 8s linear infinite;
    -moz-animation: moveclouds 8s linear infinite;
    -o-animation: moveclouds 8s linear infinite;
}
.x3 {
    left: -250px;
    top: -200px;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.8;
    -webkit-animation: moveclouds 10s linear infinite;
    -moz-animation: moveclouds 10s linear infinite;
    -o-animation: moveclouds 10s linear infinite;
}
.x4 {
    left: 470px;
    top: -250px;
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    transform: scale(0.75);
    opacity: 0.75;
    -webkit-animation: moveclouds 8s linear infinite;
    -moz-animation: moveclouds 8s linear infinite;
    -o-animation: moveclouds 8s linear infinite;
}
.x5 {
    left: -150px;
    top: -150px;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.8;
    -webkit-animation: moveclouds 20s linear infinite;
    -moz-animation: moveclouds 20s linear infinite;
    -o-animation: moveclouds 20s linear infinite;
}
.x6 {
    left: -150px;
    top: -130px;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.8;
    -webkit-animation: moveclouds 10s linear infinite;
    -moz-animation: moveclouds 10s linear infinite;
    -o-animation: moveclouds 10s linear infinite;
}
.x7 {
    left: -150px;
    top: -600px;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0.9;
    -webkit-animation: moveclouds 10s linear infinite;
    -moz-animation: moveclouds 10s linear infinite;
    -o-animation: moveclouds 10s linear infinite;
}
.x8 {
    left: -150px;
    top: -150px;
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 0.6;
    -webkit-animation: moveclouds 10s linear infinite;
    -moz-animation: moveclouds 10s linear infinite;
    -o-animation: moveclouds 10s linear infinite;
}
.x9 {
    left: -150px;
    bottom: 120px;
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 0.5;
    -webkit-animation: moveclouds 11s linear infinite;
    -moz-animation: moveclouds 11s linear infinite;
    -o-animation: moveclouds 11s linear infinite;
}
@-webkit-keyframes moveclouds {
    0% {
        left: 0px;
        fill:#fff;
   }
    20% {
        fill:#d1d1d1;
   }
    40% {
        fill:#fff;
   }
    50% {
        fill:#28af6b;
   }
    100% {
        left: 1366px;
        fill:#fff;
   }
}
@-moz-keyframes moveclouds {
   0% {
        left: 0px;
        color:#fff;
   }
    20% {
        color:#d1d1d1;
   }
    40% {
        color:#fff;
   }
    50% {
        color:#28af6b;
   }
    100% {
        left: 1366px;
        color:#fff;
   }
}
@-o-keyframes moveclouds {
    0% {
        left: 0px;
        color:#fff;
   }
    20% {
        color:#d1d1d1;
   }
    40% {
        color:#fff;
   }
    50% {
        color:#28af6b;
   }
    100% {
        left: 1366px;
        color:#fff;
   }
}

/*== Check User ==*/
.container_login_check{
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
    flex-direction: column;
    position: relative;
    width: auto;
    height: auto;
    &_box{
        display:flex;
        justify-content: 'center';
        align-items: 'center';
    }
}
.avatar_check{
    width:100px;
}
.cssload-loader {
    width: 97px;
    height: 19px;
    position: relative;
    margin: 20px 0 30px;
}
.cssload-loader > div {
	content: " ";
	width: 19px;
	height: 19px;
	background: rgb(33,150,243);
	border-radius: 100%;
	position: absolute;
	animation: cssload-shift 2.3s linear infinite;
		-o-animation: cssload-shift 2.3s linear infinite;
		-ms-animation: cssload-shift 2.3s linear infinite;
		-webkit-animation: cssload-shift 2.3s linear infinite;
		-moz-animation: cssload-shift 2.3s linear infinite;
}
.cssload-loader > div:nth-of-type(1) {
	animation-delay: -0.46s;
		-o-animation-delay: -0.46s;
		-ms-animation-delay: -0.46s;
		-webkit-animation-delay: -0.46s;
		-moz-animation-delay: -0.46s;
}
.cssload-loader > div:nth-of-type(2) {
	animation-delay: -0.92s;
		-o-animation-delay: -0.92s;
		-ms-animation-delay: -0.92s;
		-webkit-animation-delay: -0.92s;
		-moz-animation-delay: -0.92s;
}
.cssload-loader > div:nth-of-type(3) {
	animation-delay: -1.38s;
		-o-animation-delay: -1.38s;
		-ms-animation-delay: -1.38s;
		-webkit-animation-delay: -1.38s;
		-moz-animation-delay: -1.38s;
}
.cssload-loader > div:nth-of-type(4) {
	animation-delay: -1.84s;
		-o-animation-delay: -1.84s;
		-ms-animation-delay: -1.84s;
		-webkit-animation-delay: -1.84s;
		-moz-animation-delay: -1.84s;
}



@keyframes cssload-shift {
	0% {
		left: -58px;
		opacity: 0;
		background-color: rgb(255,255,0);
	}
	10% {
		left: 0;
		opacity: 1;
	}
	90% {
		left: 97px;
		opacity: 1;
	}
	100% {
		left: 156px;
		background-color: rgb(255,0,0);
		opacity: 0;
	}
}

@-o-keyframes cssload-shift {
	0% {
		left: -58px;
		opacity: 0;
		background-color: rgb(255,255,0);
	}
	10% {
		left: 0;
		opacity: 1;
	}
	90% {
		left: 97px;
		opacity: 1;
	}
	100% {
		left: 156px;
		background-color: rgb(255,0,0);
		opacity: 0;
	}
}

@-ms-keyframes cssload-shift {
	0% {
		left: -58px;
		opacity: 0;
		background-color: rgb(255,255,0);
	}
	10% {
		left: 0;
		opacity: 1;
	}
	90% {
		left: 97px;
		opacity: 1;
	}
	100% {
		left: 156px;
		background-color: rgb(255,0,0);
		opacity: 0;
	}
}

@-webkit-keyframes cssload-shift {
	0% {
		left: -58px;
		opacity: 0;
		background-color: rgb(255,255,0);
	}
	10% {
		left: 0;
		opacity: 1;
	}
	90% {
		left: 97px;
		opacity: 1;
	}
	100% {
		left: 156px;
		background-color: rgb(255,0,0);
		opacity: 0;
	}
}

@-moz-keyframes cssload-shift {
	0% {
		left: -58px;
		opacity: 0;
		background-color: rgb(255,255,0);
	}
	10% {
		left: 0;
		opacity: 1;
	}
	90% {
		left: 97px;
		opacity: 1;
	}
	100% {
		left: 156px;
		background-color: rgb(255,0,0);
		opacity: 0;
	}
}

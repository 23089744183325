
.container_login_bg.container_bg.container_aff_bg{
    background: #FFF url("/image/bg_register_aff.png") center bottom no-repeat;
}
.container_login .container_aff_box{
    .power-text {
        color: #777;
        padding: 20px 0 0;
        margin:0;
    }
    .title_form{
        h2{
            margin: 0 0 30px;
            text-transform: uppercase;
            font-weight: 600;
        }
    }
    .form_login_content{
        border-radius:10px;
    }
    label {
        display: none;
    }
    .form_btn{
       //margin: 30px 0 0 !important;
        &.no-margin-bottom{
            margin-bottom: 0 !important; 
        }
        .btn{
            border-radius:999px !important;
            font-weight: 600 !important;
        }
    }
    .icon_input_left {
        display:block !important;
        .la {
            position: absolute;
            top: 8px;
            font-size: 20px;
            display:block !important;
        }
    }
    .form-group .input-content {
        .form-control {
            border: none !important;
            padding: 0 30px !important;
            border-bottom: 1px solid #cccccc!important;
            border-radius: 0 !important;
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 1px;
            -webkit-transition: all .4s;
            -o-transition: all .4s;
            -moz-transition: all .4s;
            transition: all .4s;
            background: #3597f1;
        }
        &:hover:after{
            width: 100%;
        }
    }
}

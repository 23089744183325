.navigation_search_sell {
    width: 350px;
    position: relative;
    margin: 8px 8px 8px 15px;
    & input {
        border-radius: 3px !important;
        border: none !important;
        font-size: 13px;
    }
    &:hover .output_complete_search {
        //display:block;
    }
}
.react-autosuggest__container--open .output_complete_search {
    display:block;
}
.output_complete_search {
    position: absolute;
    z-index: 9;
    background: #fff;
    top: 32px;
    width: 509px;
    max-height: 274px;
    overflow-y: auto;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
    border: 1px solid #1cb467;
    border-radius: 0 0 3px 3px;
    display: none; 
    &::-webkit-scrollbar {
        width: 8px;
        height:60px;
    }
    &::-webkit-scrollbar-track {
        background: none;
        border-radius: 0px;
        height:60px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background:  #e8e8e8;
        height:60px;
    }
    & ul {
        display: block;
        & li {
            width: 100%;
            float: left;
            display: flex;
            cursor: pointer;
            &:not(:last-child) {
                border-bottom: 1px solid #ddd;
            }
            &:hover {
                background: #f3f3f3;
            }
        }
    }
    &_img {
        width: 60px;
        float: left;
        margin: 0 10px 0 0;
        height: 70px;
        padding: 5px;
        border-right: 1px solid #ddd;
    }
    &_info {
        float: left;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 10px 0 0;
        &_top {
            float: left;
            width: 100%;
            & p {
                float: right;
                margin: 0px 0 2px;
                text-align: right;
            }
        }
        &_bottom {
            width: 100%;
            float: left;
            p {
                float: left;
                margin:0;
                &:last-child {
                    float: right;
                }
            }
        }
        & h2, h2 a{
            font-size: 14px;
            color: #000;
            padding: 0;
            font-weight: 600;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        & h2 {
            margin: 0;
            max-width: 300px;
            float: left;
            padding: 0 0 3px;
        }
        & p {
            & span {
                font-size: 13px;
                color: #949494;
            }
        }
    }
}
.navigation_icon_search_sell {
    position: absolute;
    top: 4px;
    right: 0;
    background: none;
    border: none;
    & i {
        color: #1cb467;
        font-size: 20px;
        font-weight: 600;   
        transform: rotate(-90deg);
    }
}
.navigation_sales_number_sell{
    //width:90px;
    margin: 8px;
    margin-left:8px !important;
    & input{
        border-radius: 3px !important;
        border: none !important;
        font-size: 13px;
    }
}
.navigation_setting{
    &_right {
        justify-content: flex-end;
        flex:1;
        padding:0 !important;
    }
    &_actions {
        justify-content: center;
        align-items: center;
        li{
            i{
                font-size: 20px;
                cursor: pointer;
                margin: 0 5px;
                &:hover{
                    opacity: .4;
                }
            }
        }
    }
    &_exclamation:hover ul.navigation_setting_exclamation_list {
        transform: translateY(0);
        opacity:1;
        visibility:visible;
    }
    &_exclamation_list {
        top: 36px;
        z-index: 99;
        background: #fff;
        min-width: 400px;
        padding: 0 0 5px;
        right: -91px;
        -webkit-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
        /* -webkit-transform: translateY(100px); */
        -ms-transform: translateY(100px);
        /* transform: translateY(100px); */
        opacity:0;
        visibility:hidden;
        position: absolute;
        display: block !important;
        font-size: 14px;
        box-shadow: 0 1px 2px rgba(0,0,0,0.3);
        color: #000;
        & li {
            padding: 5px 10px;
            margin: 0;
            & span {
                width: 70px;
                display: inline-block;
                font-size:  16px;
                font-weight: 600;
                & i {
                    color: #000;
                    margin: 0;
                }
            }
        }
        & h2 {
            padding: 10px;
            font-size: 15px;
            font-weight: 600;
            border-bottom: 1px solid #e4e4e4;
            margin: 0 0 10px;
            color: #000;
        }
        &:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid white;
            top: -8px;
            right: 98px;
        }
    }
}
.slider_invoice {
    width: 500px;
    margin: 0 30px;
    .slick-track{
        margin:0;
    }
    div:focus{
        outline:none;
    }
    .slick-prev:before, .slick-next:before{
        font-size: 18px;
    }
}
.navigation_list_invoice{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #018a47;
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    opacity: 0.67;
    width: 120px !important;
    & button {
        background: none !important;
        border:none;
        color:#fff;
        padding: 0;
    }
    & .la {
        font-size: 20px;
        cursor: pointer;
        float: right;
        line-height: 50px;
    }
}
.navigation_list_invoice.active button,.navigation_list_invoice.active .la,
.navigation_list_invoice:hover button,.navigation_list_invoice:hover .la{
    color: #018a47;
}
.navigation_list_invoice.active,.navigation_list_invoice:hover {
    background: #fff;
    color: #018a47;
    opacity: 1;
}
.btn-add-invoice{
    min-width: auto !important;
    background:none;
    cursor: pointer;
    i {
        font-size: 20px;
    }
    &:hover{
        opacity:.7;
    }
}
.navigation_search_sendo{
    .output_complete_search ul {
        width: 100%;
        float: left;
        margin: 0;
        padding: 0;
    }
    .navigation_search_sell {
        width: 100%;
        position: relative;
        margin: 0;
        border:1px solid #ddd;
    }
}
@import "../_variable.scss";
.popconfirm {
    position: fixed;
    top: 0;
    left: 0;
    visibility: hidden;
    animation-duration: 300ms;
    z-index: 15;
    &.show {
        visibility: visible;
    }
    .popconfirm_content_root {
        position: relative;
        &::after {
            position: absolute;
            content: " ";
            width: 0px;
            height: 0px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid #fff;
            left: 51%;
        }
        .popconfirm_content {
            // position: absolute;
            top: 0;
            right: 0;
            min-width: 300px;
            min-height: 80px;
            background-color: #fff;
            background-clip: padding-box;
            box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
            padding: 15px;
            color: $primaryTextColor;
            .popconfirm_content_msg {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                .popconfirm_content_msg_info {
                    font-size: 24px;
                    margin-right: 10px;
                }
                .popconfirm_content_msg_info_txt {
                    max-width: 300px;
                }
            }
            .popconfirm_content_btn {
                display: flex;
                justify-content: flex-end;
                button {
                    float: left;
                    margin-left: 10px;
                    padding: 2px 15px;
                    background-color: #205b1f;
                    border: none;
                    &.default {
                        color: $primaryTextColor;
                        background-color: $neutralTertiaryColor;
                    }
                    &.primary {
                        color: $whiteColor;
                        background-color: $themePrimaryColor;
                    }
                }
            }
        }
    }
}
.popconfirm_modal{
    z-index: 1115 ;
}
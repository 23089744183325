@import "../_variable.scss";
.nav_header {
    background: $themePrimaryColor;
    width: 100%;
    position: fixed;
    height: $headerHeight;
    display: flex;
    flex: 1;
    color: white;
    padding: 0 24px;
    top: 0;
    left: 0;
    .nav_header_left {
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .nav_header_right {
        display: flex;
        flex: 1;
        position: relative;
        .nav_header_right_ul {
            display: flex;
            height: 100%;
            align-items: center;
            li {
                list-style: none;
                height: 100%;
                display: flex;
                cursor: pointer;
                position: relative;
                transition: all 200ms;
                a {
                    color: white;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    padding: 0 15px;
                    width: 100%;
                    &:hover {
                        color: white !important;
                    }
                }
                &:hover {
                    background-color: $themeDarkAltColor;
                    transition: all 200ms;
                    ul {
                        opacity: 1;
                        transition: opacity 200ms;
                        visibility: visible;
                    }
                }
                /*Submenu*/
                ul {
                    transition: all 200ms;
                    visibility: hidden;
                    opacity: 0;
                    position: absolute;
                    top: $headerHeight;
                    padding: 0px;
                    width: 240px;
                    color: red;
                    left: 0;
                    background-color: $themeDarkAltColor;
                    li {
                        &.sub_menu {
                            height: 40px;
                            a {
                                &:hover {
                                    background-color: $themeDarkColor;
                                }
                            }
                        }
                    }
                }
            }
        }
        .nav_header_right_profile {
            display: flex;
            position: absolute;
            right: 0;
            justify-content: center;
            height: 100%;
            align-items: center;
            .nav_header_right_profile_icon {
                display: flex;
                height: 100%;
                .icon {
                    font-size: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 15px;
                    &:hover {
                        background-color: $themeDarkAltColor;
                    }
                }
            }
            .nav_header_right_profile_pro {
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                height: 100%;
                cursor: pointer;
                .nav_header_right_profile_pro_avt {
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    padding-left: 5px;
                }
            }
        }
    }
}

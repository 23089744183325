.bpos_help {
    top: 50px;
    position: absolute;
    height: 100% !important;
    overflow-y: auto;
    float: left;
    width: 100%;
    min-height: 100vh;
    margin: 0 0 30px;
    .container  {
        margin-bottom:50px;
    }
    & .container-fluid {
        margin-bottom:80px;
    }
    & .col-md-10 {
        border-left: 1px solid #ddd;
    }
    & .col-md-2,& .col-md-10 {
        padding: 20px 15px 0;
    }
    &  ul {
        padding: 0;
        li {
            list-style-position: inside;
            padding: 0 0 5px;
        }
    }
    &_title{
            border-bottom: 1px solid #ddd;
            padding: 0 0 15px;
            width: 100%;
            float: left;
            margin-top: 20px;
         h1 {
            font-size: 20px;
            float: left;
            margin: 0;
        }
    }
    &_version a {
        font-size: 15px;
        color: #000;
    }
    &_title_box h2 a,&_title_box h2 {
        color: $themePrimaryColor;
        font-size: 18px;
        margin: 0 0 10px;
    }
    &_latest_release a {
        display: inline-block;
        padding: 3px 4px;
        font-size: 12px;
        font-weight: 600;
        line-height: 1;
        border-radius: 2px;
        color: $themePrimaryColor;
        border: 1px solid $themePrimaryColor;
        margin: 0px 0 10px;
        &:hover {
            border-color: #000;
        }
    }
}
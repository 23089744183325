.container_customer_online {
    position: fixed;
    margin-left: $with_nav_left;
    width: calc(100% - #{$with_nav_left});
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    .ant-table-wrapper {
        width: 100%;
    }
}
.add_customer_address_bpos .product_upload_image_child0 img{
    border: 1px solid#ddd;
    padding: 5px;
}
.customer_tabs{
    &_sub {
        background: #fff;
        width: 100%;
        overflow: hidden;
        & label {
            display: block;
            border-bottom: 1px solid #f5f5f5;
            padding: 5px 0 10px;
            font-weight: 200;
            margin: 10px 0;
        }
    }
    ul.nav {
        padding: 0 20px;
    }
    &_upload_image{
        .avatar{
            display: block;
            border-radius: 0;
        }
    }
    .customer_tabs_upload_image{
        .avatar{
            width: 100%;
            height: 125px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(255, 255, 255, .5);
            border: 1px solid #f5f5f5;
            padding: 2px;
            i{
                font-size: 80px;
            }
            img{
                object-fit: scale-down;
            }
        }
    }
}
.personnel_list_search{
    .btn-search {
        position: absolute;
        background: none;
        border: none;
        top: 59%;
        margin: 0;
        transform: translateY(-50%) rotate(-90deg);
        right: -2px;
        font-size: 20px;
        color: #b3b3b3;
        //z-index: 5;
    }
    .load_address_item_order {
        position: relative;
        float: left;
        width: 316px;
        margin: 0px 16px 0 0;
    }
    .btn-danger {
        margin: 23px 0 0;
    }
}
.no_margin_customer .form-group {
    margin: 0 0 15px;
}
.list_customer{
    .row11{
        width:200px;
    }
    div {
        margin: 0 0 5px;
        font-size: 13px;
        .la {
            font-size: 15px;
            margin: 0 5px 0 0;
        }
    }
}
.add_customer_info_bpos{
    & .css-1aya2g8,& .css-vj8t7z,& .css-1wy0on6,& .css-162g8z5 {
        min-height: 30px !important;
    }
}
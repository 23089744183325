.bnc_help_aff{
    &_list_tab {
        background: #eeeeee;
        ul {
            padding: 0;
            border:none;
            li {
                list-style: none;
                padding: 10px 15px;
                border-bottom: 1px solid #e6e6e6;
                border-left: 4px solid transparent;
                float:none;
                margin:0;
                a {
                    color: #757575;
                    font-size: 15px;
                    font-weight: 400;
                    padding:0;
                    margin:0;
                    border-radius:0;
                    border:none;
                    &:hover,&:focus{
                        border:none;
                        background:none;
                        color: unset !important;
                    }
                }
                &.active,&:hover,&:focus {
                    border-left: 4px solid #00b75e !important;
                    background: none;
                }
                &.active>a,&.active>a:hover,&.active>a:focus{
                    border:none;
                    background: none;
                }
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
    &_info {
        background: #eeeeee;
        padding: 15px;
        margin: 0 0 70px;
    }
    &_content{
        strong{
            font-weight:600 !important;
        }
        p {
            line-height: 20px;
            font-size: 14px;
        }
        h3{
            font-size: 14px; 
            font-weight: 600; 
            line-height: 25px;
        }
        img{
            @media only screen and (min-width: 768px){
                width: 800px;
                margin: auto;
            }
        }
    }
    &_title{
        h2 {
            margin: 0 0 15px;
            text-transform: uppercase;
            font-size: 16px;
            color: #00b75e;
            font-weight: 600;
            line-height: 23px;
            line-height: 25px;
        }
    }
}
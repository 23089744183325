.bnc_product_banner_aff_list_tab{
    ul {
        border-bottom: 1px solid #ddd;
        padding: 0 20px;
        position: relative;
        margin:0 0 30px;
        li {
            display: inline-block;
            padding: 10px;
            cursor: pointer;
            border:1px solid transparent;
            margin:0;
            float:none;
            a{
                padding:0;
                margin:0;
                border:none;
            }
            &.active>a, &.active>a:hover, &.active>a:focus,& a:hover,& a:focus{
                border:none;
                background: none;
            }
            &.active,&:hover,&:focus{
                border-bottom: 2px solid #00b75e;
                background: none;
            }
        }
    }
}
.bpos_product_banner_aff_bg{
    height: 200px;
}

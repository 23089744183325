.container_welcome {
    display: flex;
    height: 100%;
    width: 100%;
    position: fixed;
    flex: 1;
    .form_welcome {
        @media (max-width:640px){
            padding: 0px 20px 10px;
            box-shadow: none;
        }
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        position: relative;
        width: 100%;
        height: 100%;
        background: #ffffff;
        padding: 40px 40px 30px;
        border-radius: 4px;
        -webkit-box-shadow: 0 -25px 37.7px 11.3px rgba(8, 143, 220, 0.07);
        box-shadow:0 -25px 37.7px 11.3px rgba(8, 143, 220, 0.07);
        .avatar_form{
            height: 120px;
            width: 120px;
            margin: auto;
            img{
                border-radius: 50%;
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
            }
        }
        .email {
            position: relative;
            height: 120px;
            display: flex;
            align-items: center;
            *justify-content: center;
        }
        .title_email {
            font-weight: 600;
        }
        .content_welcome {
            padding: 20px 0 0 0;
            .desc {
                margin-bottom: 12px;
                line-height: 25px;
            }
            .using_serice{
                .nxt_checkbox{
                    float: left;
                    padding-right: 5px;
                }
            }
        }
        .btn{
            margin: 0;
            width: 100%;
            height: auto;
            font-size: 13px;
            padding: 9px 8px;
            font-weight: 600;
            border-radius: 0.1875rem;
        }
        .css-1rtrksz{
            padding: 9px 8px;
        }
    }
}
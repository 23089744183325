.bpos_box_sell_right_list_header {
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    padding: 10px 0;
    width: 100%;
    overflow: hidden;
    color:#000;
    .row {
        margin: 0 -5px;
    }
    [class*="col-"] {
        padding: 0 5px !important;
    }
}

.la-actions {
    cursor: pointer;
}

.ReactVirtualized__Table__Grid {
    outline: none;
    width: 101% !important;
    overflow: hidden !important;
}

.ReactVirtualized__Grid__innerScrollContainer {
    width: 100% !important;
    font-size: 14px;
    max-width: 100% !important; //max-height: 250px !important;
    //height: 70px !important;
    overflow-y: auto !important;
    & .active {
        background: rgba(0, 184, 94, .2) !important;
        
        cursor: pointer;
    }
    & .outstock {
        background: #ffd0d0;
        cursor: not-allowed;
    }
    & .row {
        padding: 15px 0 !important;
        margin: 0 0px;
        color: #000;
        font-weight: 500;
        overflow: unset !important;
        width: 100% !important;
        height: auto !important;
        border-bottom: 1px solid #ddd;
        user-select: none;
        &:hover {
            background: #ececec;
        } // &:nth-child(odd) {
        //     background-color: #f9f9f9;
        // }
        & [class*="col-"] {
            padding: 0 5px !important;
        }
        .form-group{
            margin:0;
        }
    }
    .la-actions {
        width: 20px;
        height: 20px;
        background: #fff;
        display: block;
        line-height: 20px;
        font-weight: 600;
        border-radius: 50%;
        cursor: pointer;
        margin: 0 10px;
    }
    .name_sell_bpos {
        //white-space: nowrap;
        //text-overflow: ellipsis;
        //max-width: 250px;
        overflow: hidden;
        margin: 0 0 2px;
        max-height: 53px;
    }
    .product_price {
        position: relative;
        .form-group-sale-box {
            position: absolute;
            background: #fff !important;
            z-index: 9999;
            width: 250px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 2px;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
            top: 15px;
            left: -215px;
            padding: 10px;
            transition: all ease .2s;
            opacity: 0;
            visibility: hidden;
            -webkit-transform: translateY(50px);
            transform: translateY(50px);
            &-table {
                &:before {
                    top: 23% !important;
                }
            }
            &:before {
                position: absolute;
                display: block;
                width: 0;
                height: 0;
                border-color: transparent;
                border-style: solid;
                content: "";
                border-width: 6px;
                right: -6px;
                border-right-width: 0;
                border-left-color: #fff;
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
            }
            &.active {
                opacity: 1;
                visibility: visible;
                -webkit-transform: translateY(0);
                transform: translateY(0);
            }
            .form-group-sale-box-input {
                display: flex;
                label {
                    width: 70px;
                    height: 20px;
                    line-height: 20px;
                    float: left;
                    font-size: 13px;
                    color: #000;
                    font-weight: 200;
                }
                .form-group {
                    float: left;
                    text-align: right;
                    width: auto;
                }
                .input-content {
                    position: relative;
                }
                .form-group .input-content input {
                    border-radius: 0px;
                    padding-left: 5px;
                }
                input {
                    border-radius: 0px;
                    padding-left: 5px;
                    border: none;
                    outline: none;
                    border-bottom: 1px solid #ddd;
                    background: none;
                    text-align: right;
                    width: 100px;
                }
                .form-group-sale-box-btn {
                    float: right;
                    margin: 2px 0;
                    a.active {
                        background: #28af6b !important;
                        color: #fff !important;
                    }
                    a {
                        background: #bdbdbd;
                        margin: 0 3px;
                        color: #fff;
                        padding: 1px 5px;
                        border-radius: 2px;
                    }
                }
            }
        }
    }
    .bpos_sell_quantity {
        display: flex;
        justify-content: center;
        .form-group {
            width: auto;
            float: left;
        }
        & .la {
            float: left;
            height: 24px;
            background: #e8e8e8;
            color: #151515;
            line-height: 24px;
            padding: 0 5px;
            cursor: pointer;
            transition: all ease .2s;
            &:hover {
                background: #8a8a8a;
                color: #fff;
            }
        }
    }
    .gift_sell_bpos {
        color: #efad4e;
        font-size: 11px;
        cursor: pointer;
    }
    .list_gift_sell_bpos {
        color: #02e61e;
        font-size: 11px;
        cursor: pointer;
    }
    .price_sell_bpos {
        border: none;
        background: none;
        text-align: right;
        width: 100%;
        border-bottom: 1px solid #ddd;
    }
    .number_sell_bpos {
        background: #fff;
        width: 50px;
        text-align: center;
        border: 1px solid #e8e8e8;
        height: 24px;
    }
    .cell_price_sell_bpos {
        color: #28af6b;
        font-size: 16px;
    }
    .sales_price_bpos {
        text-align: right;
        float: right;
        display: block;
        width: 100%;
        font-size: 11px;
        color: red;
        position: relative;
        top: 5px;
    }
    .col-md-2 .input-content:after {
        content: "";
        width: 100%;
        height: 1px;
        background: #28af6b;
        bottom: 0;
        position: absolute;
        left: 0;
        -webkit-transition: all ease .5s;
        -o-transition: all ease .5s;
        transition: all ease .5s;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
    }
    .col-md-2 .input-content:hover:after {
        transform: scale(1);
    }
    .col-md-4:hover .notes_sell_bpos {
        display: inline-block;
        cursor: pointer;
    }
    .notes_sell_bpos {
        color: #666666;
        font-size: 11px;
        position: relative;
        padding: 0 10px 0 0;
        display: none;
        &:hover .notes_sell_bpos_popover {
            display: block;
        }
    }
    .notes_sell_bpos_popover {
        position: absolute;
        top: 27px;
        width: 250px;
        display: none;
        z-index: 3;
        & textarea {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
            max-width: 300px;
            padding: 10px 10px;
            border: 1px solid #ddd;
            z-index: 3;
            width: 100%;
            height: 80px;
            outline: none;
        }
        &:before {
            content: "";
            border-width: 7px;
            position: absolute;
            right: auto;
            margin: 0;
            bottom: auto;
            top: -14px;
            display: block;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            border-bottom: 7px solid #ddd;
            left: 10px;
        }
        &:after {
            content: "";
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            top: -13px;
            margin-left: 10px;
            border-top-width: 0px;
            border-bottom-color: #fff;
            border-width: 7px;
        }
        &::-webkit-input-placeholder {
            color: #999 !important;
        }
    }
}

.bpos_sell_main {
    height: 100vh;
    .navigation {
        position: static;
    }
}

.bpos_sell_network_mode {
    font-size: 15px;
    display: table;
    margin: auto;
    padding: 10px;
    background: #018a47;
    width: 100%;
    color: #fff;
    & img {
        display: inline-block;
        margin: 0 5px;
    }
    & p {
        margin: 0;
    }
    & span {
        color: red;
    }
}

.bpos_box_sell_left_action {
    position: relative;
    padding: 10px 0px;
    height: 100%;
}

.bpos_sell_left_box {
    border-top: 1px solid #ddd;
    position: absolute;
    bottom: 0;
    z-index: 1;
    height: 346px;
    &.hide_box {
        transition: all 146ms;
        height: 0px;
    }
}

.bpos_box_sell {
    .bpos_box_sell_left_box {
        .bpos_sell_right_list {
            height: 100%;
        }
    }
    &_left_toggle {
        width: 40px;
        height: 20px;
        background: #ddd;
        line-height: 30px;
        color: #fff;
        position: absolute;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        z-index: 9;
        cursor: pointer;
        transition: all ease .3s;
        & .la {
            font-size: 17px;
        }
    }
    position: relative;
    overflow: hidden;
    background: #fff;
    height: calc(100% - 50px);
    display: flex;
    &_left_box {
        background: #fff;
        border: 1px solid $themeNavBorder;
        border-radius: 3px;
        height: 100%;
        overflow: hidden;
        position: relative;
        & .row,
        .col-md-3.bpos_sell_left_box,
        .col-md-9.bpos_sell_right_list {
            height: 100%;
            transition: all ease .3s;
        }
        &.active {
            .bpos_box_sell_left_action {
                transform: translateY(-1000%);
                position: absolute;
                opacity: 0;
                visibility: hidden;
            }
            .col-md-12.bpos_sell_right_list {
                width: 100%;
            }
            .bpos_box_sell_left_toggle {
                top: -20px;
                .la-angle-down:before {
                    content: "\f113";
                }
            } // .ReactVirtualized__Grid__innerScrollContainer {
            //     max-height: 680px !important;
            //     height: 680px !important;
            // }
            .ReactVirtualized__Table__Grid {
                height: 680px !important;
            }
        }
    }

    &_left_action_pagination{
        user-select: none;
            float:right;
        ul {
            margin: 10px 0;
        }
    }

    &_left_action_list {
        padding: 0;
        float: left;
        margin: 10px 0;
        z-index: 8;
        position: relative;
        & li {
            list-style: none;
            margin: 0 4px 0 0;
            float: left;
            padding: 2px;
            border: 1px solid #c2c2c2;
            border-radius: 2px;
            background: #e4e4e4;
            &:hover {
                background: $themeNavBorder;
                color: #fff;
                cursor: pointer;
            }
            &:hover .la {
                color: #fff;
            }
            & .la {
                width: 14px;
                height: 14px;
                line-height: 14px;
                font-size: 15px;
                text-align: center;
                color: #666666;
                font-weight: 700;
                display: block;
            }
        }
    }
    .bpos_list_pr_inner {
        width: 100%;
        float: left;
        height: 260px;
        //overflow-y: auto;
        overflow:hidden;
    }
    .bpos_list_pr_box {
        cursor: pointer;
        user-select: none;
        &:hover{
            .bpos_list_pr_box_name{
                h2{
                    color:red;
                }
            }
        }
    }
    .bpos_list_pr_vitual_root {
        &>div {
            width: 100% !important;
            height: 151px !important;
            display: flex;
        }
        width: 100% !important;
        overflow: auto !important;
        &:focus {
            outline: none;
        } // .ReactVirtualized__Grid__innerScrollContainer {
        //     width: 100% !important;
        // }
        .col-md-6 {
            width: 100% !important;
            position: static !important;
            height: auto !important;
        }
    }
    .bpos_list_pr_box_img {
        border: 1px solid $themeNavBorder;
        border-radius: 3px;
        padding: 5px;
        position: relative;
        height: 80px;
        // width: 130px;
        width: 100%;
        & p {
            background: $themeNavBorder;
            text-align: center;
            color: #fff;
            position: absolute;
            padding: 2px;
            bottom: 0px;
            left: 0;
        }
    }
    .bpos_list_pr_box_name {
        & h2 {
            margin: 5px 0;
            font-size: 13px;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 41px;
            line-height: 20px;
            user-select: none;
            &:hover{
                color:red;
            }
        }
    }
    &_right_box {
        border: 1px solid $themeNavBorder;
        border-radius: 3px;
        overflow: hidden;
        // overflow-y: auto;
        height: 100%;
        position: relative;
        background: #fff;
        & .modal-content {
            & .form-control {
                border: 1px solid #e5e5e5 !important;
                border-radius: 4px !important;
            }
            & .css-1aya2g8 {
                min-height: 32px !important;
                height: 32px;
                border: 1px solid #e5e5e5;
                &:hover {
                    //border: unset !important;
                }
            }
        }
        .row {
            margin: 0 -5px;
        }
        [class*="col-"] {
            padding: 0 5px;
        }
        .la {
            margin-right: 5px;
        }
        .btn-w100 {
            min-width: 100%;
        }
        & .css-2o5izw {
            //height: 26px !important;
            //min-height: auto !important;
            //border: none !important;
        }
        .css-1aya2g8 {
            border-color: #efefef; //min-height: 26px !important;
            //height: 26px;
            font-size: 13px;
            overflow: hidden;
            color: #000;
            &:hover {
                //border: none !important;
            }
        }
        .css-1492t68 {
            //color: #000;
        }
        .css-d8oujb {
            display: none;
        }
        .css-19bqh2r {
            color: #000;
            font-size: 11px !important;
            width: 15px;
        }
        & .css-1aya2g8,& .css-vj8t7z,& .css-162g8z5,& .css-2o5izw {
            border-bottom: 1px solid #efefef !important;
            border: none;
            border-radius: 0;
            padding: 0 !important;
            background: none;
        }
        .css-1hwfws3 {
            padding-left: 0;
        }
        &_price_table {
            border-bottom: 1px solid $themeNavBorder;
            margin: 0 0 5px;
            width: 100%;
            float: left;
            padding: 0 5px 5px;
            &_left {
                & .la {
                    font-size: 18px;
                    color: #999;
                    transform: rotate(-90deg);
                    float: left;
                    top: 7px;
                    position: relative;
                }
            }
            &_right {
                & .la {
                    font-size: 18px;
                    color: #999;
                    float: left;
                    position: relative;
                    top: 7px;
                }
            }
        }
        &_search {
            position: relative;
            padding: 0 5px 5px;
            // & .col-md-9{
            //     & .css-1aya2g8,& .css-vj8t7z,& .css-162g8z5,& .css-2o5izw {
            //         border: 1px solid #efefef !important;
            //         border-radius: 3px;
            //     }
            //     .css-1hwfws3 {
            //         padding-left: 10px;
            //     }
            // }
            & input {
                border: none;
                outline: none;
                *border-bottom: 1px solid #ddd;
                background: none;
                border-radius: 0 !important;
                *padding: 0 25px !important;
                &:hover {
                    border-color: $themeNavBorder !important;
                }
            }
            & .la-search {
                font-size: 18px;
                color: #999;
                transform: rotate(-90deg);
                float: left;
                margin: 8px 5px 8px 0;
            }
            & .add_customer_sell {
                background: #28af6b;
                font-size: 16px;
                color: #fff;
                font-weight: bold;
                cursor: pointer;
                z-index: 9;
                width: 100%;
                text-align: center;
                height: 32px;
                line-height: 32px;
                border-radius: 3px;
                border: none;
            }
            & .output_complete_search_customer {
                width: 95%;
                left: 50%;
                transform: translateX(-50%);
                top: 31px;
                & ul {
                    padding: 0;
                    & li {
                        padding: 0 10px;
                    }
                }
            }
            &:hover .output_complete_search_customer {
                display: block;
            }
        }
        &_user {
            padding: 5px;
            width: 100%;
            float: left;
            & time {
                text-align: right;
                float: right;
                color: #999999;
                //background-color: hsl(0, 0%, 98%);
                //border: 1px solid #efefef;
                width: 100%;
                height: 32px;
                line-height: 32px;
                //border-radius: 4px;
            }
            & .la-user {
                font-size: 18px;
                color: #999;
                float: left;
                position: relative;
                top: 7px;
            }
            & .form-group {
                max-width: 150px;
                float: left;
                margin: 0;
            }
            & .react-datepicker-wrapper,
            & .react-datepicker-wrapper input {
                //height: 26px !important;
            }
            & .react-datepicker-popper {
                right: 0px !important;
                left: unset !important;
            }
            & .react-datepicker__triangle {
                left: unset;
                right: 80px;
            }
        }
        &_content {
            .cutomer_support_phone {
                margin: 20px 0;
                text-align: center;
                font-size: 13px;
            }
            position: relative;
            height: calc(100% - 48px);
            width: 100%;
            float: left;
            & .color-green {
                color: $themeNavBorder;
                font-size: 18px;
            }
            & .form-group-price-all {
                width: 100%;
                float: left;
                position: relative;
                margin: 0 0 5px;
                & .la-credit-card {
                    font-size: 23px;
                    position: absolute;
                    top: 2px;
                    left: 150px;
                    z-index: 0;
                    cursor: pointer;
                    color: $themeNavBorder;
                }
            }
            & .sub_label {
                display: block;
                font-size: 12px;
                margin: 10px 0 0;
                color: #666666;
            }
            & .form-group-note-sell {
                & .form-group {
                    width: 100%;
                    margin: 0 0 15px;
                    & textarea {
                        width: 100%;
                        border: 1px solid #ddd;
                        padding: 5px 10px;
                        border-radius: 3px;
                        font-size: 13px;
                        color: #666666;
                    }
                }
            }
            .form-group-btn-sell {
                width: 100%;
                height: 320px;
                overflow: hidden;
                padding: 0 10px;
                & .row {
                    margin: 0 -5px;
                }
                & [class*="col-"] {
                    padding: 0 5px;
                }
                & .btn {
                    width: 100%;
                    min-width: 100%;
                    padding: 13px 8px;
                    font-size: 15px;
                    text-transform: uppercase;
                }
            }
        }
        &_title h2 {
            margin: 0;
            background: $themeNavBorder;
            text-align: center;
            color: #fff;
            font-size: 15px;
            font-weight: 600;
            padding: 13px 10px;
        } // .form-group-sale-box-hover:hover .form-group-sale-box {
        //     opacity: 1;
        //     visibility: visible;
        //     transform: translateY(0);
        // }
        .form-group-sale-box {
            position: absolute;
            background: #fff;
            z-index: 9999;
            width: 250px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 2px;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
            top: -50px;
            left: 0;
            padding: 10px;
            transition: all ease .2s;
            opacity: 0;
            visibility: hidden;
            transform: translateY(50px);
            &.active {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
            &:before {
                position: absolute;
                display: block;
                width: 0;
                height: 0;
                border-color: transparent;
                border-style: solid;
                content: "";
                border-width: 6px;
                right: -6px;
                border-right-width: 0;
                border-left-color: #fff;
                top: 50%;
                transform: translateY(-50%);
            }
            & label {
                width: 70px;
                height: 20px;
                line-height: 20px;
            }
            &-input {
                display: flex;
                .form-group {
                    float: left;
                }
            }
            &-btn {
                float: right;
                margin: 2px 0;
                & a {
                    background: #bdbdbd;
                    margin: 0 3px;
                    color: #fff;
                    padding: 1px 5px;
                    border-radius: 2px;
                    &.active,
                    &:hover {
                        background: $theme-default;
                        color: #fff !important;
                    }
                }
            }
            &-promotion {
                width: 100%;
                float: left;
                border-bottom: 1px dashed #ddd;
                margin: 0 0 10px;
                & .form-group-sale-box-promotion-view {
                    float: right;
                }
                & label {
                    min-width: 100px;
                }
                &-total-discount {
                    div {
                        float: right;
                    }
                    label {
                        min-width: 100px;
                    }
                }
            }
        }
        &_payment {
            padding: 0 10px;
            width: 100%;
            float: left;
            min-height: calc(100% - 320px);
            & label {
                width: 60%;
                float: left;
                font-size: 13px;
                color: #000;
                font-weight: 500;
                & .badge {
                    border-radius: 2px;
                    background: none;
                    border: 1px solid #000;
                    padding: 3px 5px;
                    color: #000;
                    font-weight: 200;
                    border: 1px solid #e1e1e1;
                    margin: 0 5px;
                }
            }
            & .form-group {
                float: right;
                text-align: right;
                width: auto;
                & .input-content:after {
                    content: "";
                    width: 100%;
                    height: 1px;
                    background: $themeNavBorder;
                    bottom: 0;
                    position: absolute;
                    left: 0;
                    transition: all ease .5s;
                    transform: scale(0);
                }
                &:hover .input-content:after {
                    transform: scale(1);
                }
            }
            & input {
                border: none;
                outline: none;
                border-bottom: 1px solid #ddd;
                background: none;
                text-align: right;
                width: 100px;
            }
        }
    }
}

/*== Produtcs Return  ==*/

.return_title {
    background: $themePrimaryColor;
    padding: 12px 10px;
    margin: 0 0 10px;
    h2 {
        margin: 0;
        font-size: 13px;
        color: $whiteColor;
        font-weight: 600;
    }
}
.table.table_return>tbody>tr>td {
    vertical-align: middle;
}
.transfer_bar label {
    width: 50%;
}
.bpos_box_sell{
    .bpos_box_sell_left,& .bpos_box_sell_right{
        height: 100%;
        overflow: hidden;
        position: relative;
    }
}
/*=== Modal Type Payment ==*/
.modal_type_payment{
    &_price {
        margin: 0 -15px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        & input {
            width: 100%;
            border: none;
            border-bottom: 1px solid #ddd;
            padding: 7px 0 !important;
            font-size: 14px !important;
            font-weight: 600;
            &:focus {
                font-size: 14px !important;
            }
        }
    }
    &_btn.row {
        margin: 0 -7.5px 15px;
        & .col-xl-20 {
            padding: 0 7.5px;
            .btn{
                min-width: 100%;
            }
        }
    }
    &_pay{
        margin: 15px 0 0;
        font-size:14px;
        strong {
            padding: 0 0 7px;
            display: block;
        }
    }
}
.bpos_box_sell_right_box_modal{
    .add_customer_info_bpos,& .add_customer_address_bpos{
        & .css-1aya2g8, 
        & .css-vj8t7z,
        & .css-162g8z5,
        & .css-2o5izw{
            border:1px solid #e5e5e5 !important;
            border-radius: 4px;
        }
        & .css-1hwfws3{
            padding:0px 8px;
        }
    }
}
.loadingFull {
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, .3);
}
.modal-body .table-sale-promotion thead,
.table-sale-promotion,
.table-sale-promotion tr {
    background: none !important;
}
.table-sale-promotion thead {
    color: #000;
}
.table-sale-promotion tr td:nth-child(1) {
    width: 40px;
}
.table-sale-promotion tr td:nth-child(1) .nxt_checkbox {
    // margin-top: 4px;
}
.table-sale-promotion tr td:nth-child(1) .nxt_checkbox label {
    margin: 0;
}
.table-sale-promotion tr td:nth-child(2) {
    width: 30%;
}
.table-sale-promotion tr td:nth-child(3) {
    width: 20%;
}
.table-sale-promotion tr td {
    vertical-align: top !important;
}
.modal-gift.output_complete_search,
.table-sale-promotion .output_complete_search {
    display: block;
    position: relative;
    top: 0;
    border: none;
}
.modal-gift.output_complete_search ul,
.table-sale-promotion .output_complete_search ul {
    padding: 0;
}
.modal-gift.output_complete_search {
    width: 100%;
    height: 212px;
}
.modal-gift .output_complete_search_img {
    position: relative;
}
.modal-gift .li_active .output_complete_search_img .la {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #28bd16;
    font-weight: bold;
    font-size: 20px;
}
.modal-gift .li_active .output_complete_search_img img {
    opacity: .2 !important;
}
.modal-gift.output_complete_search .li_active {
    background: #f3f3f3;
}
// .bpos_sell_main > div.bpos_box_sell.padding-top-15.padding-bottom-15 > div.col-md-3.bpos_box_sell_right > div:nth-child(4) {
//     z-index: 9999;
// }
// .bpos_sell_main > div.bpos_box_sell.padding-top-15.padding-bottom-15 > div.col-md-3.bpos_box_sell_right > div:nth-child(5) {
//     z-index: 9999;
// }
.md_overlay:nth-child(2) {
    z-index: 999;
}
.md_overlay:nth-child(2) + div {
    z-index: 999;
}
input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
    display: none;
}

@import "../_variable.scss";
.cke_dialog{
    &.cke_browser_webkit{
      &.cke_ltr  {
        max-width: 500px !important;
      }
    }
}  
.setting_general {
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom:80px !important;
    &_header_line{
        canvas{
            width:100% !important;
            height: auto !important;
        }
    }
    &_email_chart{
        .setting_general_header{
            background: #eeeeee;
            border: 1px solid #d6d6d6;
            label{
                margin: 0;
                padding: 10px 15px;
            }
        }
        &_title{
            font-size:18px;
            color:#000;
            margin:15px 0;
            text-transform:capitalize;
            width:100%;
            overflow: hidden;
        }
        &_caption {
            float: left;
            p:nth-child(1) span {
                background: #ee2633;
            }
            p:nth-child(2) span {
                background: #a5cad9;
            }
            p span {
                width: 10px;
                height: 10px;
                display: inline-block;
            }
        }
        &_caption_circle{
            margin:15px 0 0;
            display:flex;
            justify-content: center;
            text-align:center;
            float:none;
            p{
                padding:0 10px;
                display: inline-block;
            }
        }
    }
    .printed_form{
        margin:0 -15px;
        .printed_form_header{
            h3{
                text-align: center;
                margin: 0 0 13px 0;
            } 
        }
        .printed_form_design{
            margin-top: 15px;
            .printed_form_title{
                background-color: hsl(0,0%,98%);
                border:1px solid hsl(0,0%,80%);
                height: 32px;
                border-radius: 4px;
                line-height: 30px;
            } 
            button.btn-primary{
                margin-top: 15px;
                margin-right: 15px;
                float: left;
            }
            .printed_form_view{
                margin-top:15px;
                @media print {
                    html, body, table{
                        font-family: monospace !important;
                    }
                }
                .printed_form_view_content{
                    width:100%;
                    max-width: 100%;
                    margin: 0 auto;
                    display: block;
                    min-height: 400px;
                    max-height: 400px;
                    box-shadow: 0px 0px 4px #b5b5b5;
                    overflow-y: auto;
                    padding: 15px;
                    font-family: 'Starjout';
                    table tr td:nth-child(1){
                        text-align: left;
                    }
                }
                iframe{
                    width:100%;
                    max-width: 100%;
                    margin: 0 auto;
                    display: block;
                    min-height: 400px;
                    box-shadow: 0px 0px 4px #b5b5b5;
                    overflow-y: scroll;
                }
            }
        }
    }
    .setting_general_item {
        margin-bottom: 15px;
        &:last-child{
            margin:0;
        }
        p {
            margin: 0;
        }
        .setting_general_header {
            background: $setting_header_bg;
            border: 1px solid $setting_header_border;
            label {
                margin: 0;
                padding: 10px 15px;
                i {
                    font-weight: normal;
                }
            }
        }
        .setting_general_content {
            border: 1px solid $setting_header_border;
            border-top: 0;
            background: $whiteColor;
            display: flex;
            flex-direction: column;
            position: relative;
            .setting_general_content_item {
                display: flex;
                flex: 1;
                padding: 15px;
                border-bottom: 1px solid $setting_header_border;
                position: relative;
                &:last-child {
                    border: 0;
                }
                &.on_off {
                    flex-direction: row;
                    .setting_audio {
                        display: flex;
                        flex: 0;
                        margin: 0;
                    }
                    p {
                        display: flex;
                        flex: 9;
                    }
                    .on_off_content {
                        padding-left: 15px;
                    }
                }
                &.item_height {
                    .setting_general_edit_item {
                        top: 20px;
                    }
                }
                .setting_general_content_item_icon {
                    display: flex;
                    flex: 0.5 1;
                    float: left;
                    min-width: 50px;
                    i {
                        font-size: 30px;
                    }
                }
                .setting_general_content_item_content {
                    display: flex;
                    flex: 9.5;
                    flex-direction: column;
                    padding-right: 70px;
                    position: relative;
                    &.not_p{
                        label{
                            margin-top:13px;
                        }
                    }
                    & .form-group:last-child{
                        margin-bottom:15px;
                    }
                }
                .setting_general_content_item_content_sub{
                    display:none;
                    transition: max-height .5s ease-in;
                    margin-top: 10px;
                    &.show{
                        display: block;
                    }
                    .note_sdt{
                        font-size: 13px;
                        color: #8a8a8a;
                        margin-bottom: 15px;
                    }
                    select{
                        width: 100%;
                    }
                    .list_content_title{
                        display: table;
                        width: 100%;
                        margin: 15px 0;
                        font-weight: bold;
                        p:nth-child(1){
                           width: 30%;
                           float: left;     
                        }
                        p:nth-child(2){
                            width: 70%;
                            float: left;     
                            padding-left: 15px;
                         }
                    }
                    &.sms_on_customer_birthday{
                        .col_right{
                            .form-group:last-child{
                                margin-top: 30px;
                            }
                        } 
                        .sms_on_customer_birthday_note{
                            margin-bottom: 15px;
                            border: 1px solid #efe3a5;
                            p{
                                color: #8a6d3b;
                                background-color: #fcf8e3;
                                border-color: #faebcc;
                                padding: 10px;
                                span{
                                    color: #a94442;
                                    font-weight: bold;
                                }
                            }
                        }   
                    } 
                    &.sms_before_customer_birthday {
                       .sms_before_customer_birthday_item{
                           display: table;
                           width:100%;
                           padding: 10px 0;
                           border-top: 1px dashed #d2d2d2;
                           .sms_before_customer_birthday_item_day{
                                width:30%;
                                float: left;
                            }
                            .sms_before_customer_birthday_item_content{
                                width: 60%;
                                float: left;
                                padding: 0 15px;
                            }
                            .sms_before_customer_birthday_item_del{
                                width: 10%;
                                float: left;
                                button{
                                    min-width: auto;
                                    width: 100%;
                                    margin-top: 3px;
                                }
                            }
                        }
                        .block_action{
                            display: flex;
                            flex: 1;
                            width: 200px;
                            margin-top: 15px;
                            button{
                                flex: 5;
                                margin-right: 15px;
                            }
                        }  
                    }
                    &.create_order_retail{
                        .manager{
                            &:hover{
                                .showList{
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }
            }
            .setting_general_edit_item {
                position: absolute;
                right: 15px;
                top: 10px;
                button {
                    width: 60px;
                    min-width: 0;
                    font-size: 12px;
                    i {
                        font-size: 12px;
                        padding-right: 5px;
                    }
                }
            }
        }
        .is_input{
            .form-group{
                position: absolute;
                width: 50px;
                right: 90px;
                top: 21px;
                input{
                    text-align: center;
                }
            }
            &.is_select{
                .form-group{
                    width: auto;
                }
            }
            &.status_sell{
                .form-group{
                    width: 200px;
                    margin:0 !important;
                    position:static;
                }
            }
        }
        &.info{
            width:60%;
        }
    }
    
}
.setting_general_content_info{
    width:100%;
    &_box{
        & .form-group:last-child{
            margin-bottom:15px;
        }
    }
}

.setting_on_off {
    &.disabled {
        pointer-events: none;
        .setting_on_off_ctrol {
            opacity: 0.5;
        }
    }
    .setting_on_off_ctrol {
        max-width: 95px !important;
        background: #bfbfbf;
        height: 22px;
        border-radius: 20px;
        position: relative;
        cursor: pointer;
        user-select: none;
        &.active {
            background: $themeNavBorder;
            &:after {
                left: calc(100% - 20px);
            }
            .setting_on_off_status {
                &.on {
                    opacity: 1;
                }
                &.off {
                    opacity: 0;
                }
            }
        }
        .setting_on_off_status {
            color: white;
            position: absolute;
            top: 3px;
            font-size: 11px;
            background: none !important;
            &.on {
                left: 7px;
                opacity: 0;
            }
            &.off {
                right: 8px;
            }
        }
        &:after {
            content: '';
            width: 18px;
            height: 18px;
            background: white;
            border-radius: 50%;
            position: absolute;
            left: 2px;
            top: 2px;
            transition: all 160ms ease;
        }
    }
}
